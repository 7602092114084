import { useSelector } from "react-redux";

// Utilities
import { findIsNight } from "../index";

export default () => {
  const { user } = useSelector((state) => state.user);
  const { darkTheme = false } = user || {};
  const isNight = findIsNight();
  const isNightTheme = darkTheme || isNight;
  return { isNight: isNightTheme, isNightTime: isNight };
};
