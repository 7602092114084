import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as stateActions from "../../../../redux/actions/state-actions";
import * as userActions from "../../../../redux/actions/user-actions";
import moment from "moment";

// Components
import ViewLayout from "../../../components/view-layout";
import Toggle from "../../../components/common/toggle";
import SalesCommon from "../sales-common";
import Button from "../../../components/common/button";

// Utilities
import { verifyForm, shakeElement } from "../../../../utilites/animation";
import {
  getCurrentDate,
  convertDateToDbDate,
  formatDbDateToReadable,
} from "../../../../utilites/date";
import { editTickets } from "../../../../api/tickets";
import { toTitleCase } from "../../../../utilites/format";
import { isOpenDay } from "../../../../utilites/validate";
import { updateAdInAds } from "../../../../utilites/update";
import { updateListing } from "../../../../api/listing";

// Styles
import "../sales-business-styles.css";

export default ({ match }) => {
  const history = useHistory();
  const { adId, id: ticketId } = match.params || {};
  const { ads = [], tickets } = useSelector((store) => store.user.user);
  const isAdTickets = !!tickets.filter(
    ({ _id, isActive, listingId, isTicket }) =>
      isActive && _id !== ticketId && listingId === adId && isTicket === true
  ).length;
  const isAdVouchers = !!tickets.filter(
    ({ _id, isActive, listingId, isTicket }) =>
      isActive && _id !== ticketId && listingId === adId && isTicket === false
  ).length;
  const dispatch = useDispatch();
  const ad = ads.find(({ _id }) => _id === adId);
  const { days } = ad || {};
  const selectedTicket = tickets.find(({ _id }) => _id === ticketId);
  const { isActive: isActiveProp, name: saleName } = selectedTicket || {};
  const [isActiveInput, setIsActiveInput] = useState(isActiveProp);
  const [commonData, setCommonData] = useState({});
  const { presaleDate = false, startDate, dateAvailable } = days || {};
  const singleDateText = dateAvailable
    ? ` on ${formatDbDateToReadable({ date: dateAvailable, isForUser: true })}`
    : "";
  const {
    isTicket,
    isPresale,
    name,
    redeemablePeriod,
    price,
    quantity,
    min,
    max,
    description,
    hours,
  } = commonData || {};
  const ticketData = {
    ...selectedTicket,
    ...commonData,
    isActive: isActiveInput,
    lastUpdated: convertDateToDbDate(),
  };

  const handleActiveToggle = (evt, value) => {
    evt.preventDefault();
    setIsActiveInput(value);
  };

  const handleCommonData = (commonData) => {
    setCommonData(commonData);
  };

  const handleSubmit = async () => {
    const ids = {
      nameInput: name,
      priceInput: price === 0 || price > 2,
      quantityInput: quantity,
      minimumQuantityInput: min,
      maximumQuantityInput: max,
      descriptionInput: description.length > 40,
    };
    if (!isTicket) ids.redeemablePeriod = redeemablePeriod > 0;
    if (hours) ids.daysInput = isOpenDay({ hours });

    const hasRequiredInfo = verifyForm(ids);

    if (hasRequiredInfo) {
      dispatch(stateActions.setLoader(true));

      const promiseArray = [editTickets(ticketData)];

      if (isPresale && !presaleDate) {
        promiseArray.push(
          updateListing({
            ...ad,
            days: {
              ...days,
              presaleDate: convertDateToDbDate(
                getCurrentDate(
                  moment(
                    formatDbDateToReadable({
                      date: startDate || dateAvailable,
                      toFullYear: true,
                      isForUser: true,
                    })
                  )
                    .subtract(10, "days")
                    .format("L")
                )
              ),
            },
          })
        );
      } else {
        promiseArray.push({});
      }

      const [{ ticket }, { ad: updatedAd }] = await Promise.all(promiseArray);
      if (ticket) {
        const newTickets = tickets.map((thisTicket) => {
          const { _id } = thisTicket;
          if (_id === ticketId) return ticket;
          return thisTicket;
        });
        dispatch(userActions.updateUser({ tickets: newTickets }));
        dispatch(stateActions.setMessage({ message: "Successful" }));
        if (updatedAd) {
          dispatch(userActions.setAds(updateAdInAds(ads, updatedAd)));
        }
        history.push(`/business/sales/${adId}`);
      }
      dispatch(stateActions.setLoader(false));
    } else {
      shakeElement("create-corporate-profile");
    }
  };

  return (
    <ViewLayout id="create-corporate-profile">
      <div className="card card-form">
        <div className="card-info-form">
          <div className="tickets-title-container">
            <p className="tickets-title-text">EDIT SALES FOR</p>
            <p className="tickets-subtitle-text" style={{ marginTop: "10px" }}>
              {`${toTitleCase({ input: saleName })}${singleDateText}`}
            </p>
          </div>
        </div>
      </div>

      <div className="card card-form">
        <div className="card-info-form">
          <label className="label-form">Is Active:</label>
          <div className="edit-business-profile-item-right">
            <Toggle
              value={isActiveInput}
              handleToggle={handleActiveToggle}
              params={"isActive"}
            />
          </div>
          <legend className="legend-form">
            Use to stop or pause this sale.
          </legend>
        </div>
      </div>

      <SalesCommon
        page="create-tickets"
        ad={ad}
        ticket={selectedTicket}
        isAdTickets={isAdTickets}
        isAdVouchers={isAdVouchers}
        handleCommonData={handleCommonData}
        isTitle={false}
      />

      <div id="consentInput" className="card card-form">
        <div className="card-info-form">
          <div className="listing-edit-submit-container">
            <Button
              className="submit-button-CreateListing"
              text="Cancel"
              isPrimary={false}
              handleClick={() => history.goBack()}
            />
            <Button
              className="submit-button-CreateListing"
              text="Save"
              handleClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </ViewLayout>
  );
};
