import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import * as dataActions from "../../../redux/actions/data-actions";
import moment from "moment";

// Components
import DownloadAppModal from "../../components/download-app";
import Button from "../../components/common/button";
import ListingLoader from "./listing-loader";
import DetailViewLayout from "../../components/detail-view-scroll-layout";
import Bookmark from "./bookmark";
import ShareButton from "../../components/common/share/button";
import ButtonLink from "../business/button-link";
import Date from "./date";
import Features from "./features";
import AdMedtaData from "./ad-meta-data";
import Yelp from "./yelp";
import Contact from "./contact";
import Tickets from "./tickets";
import OtherAds from "./other-ads";
import Instagram from "../../components/instagram";
import Modal from "../../components/modal";

// Utilities
import SetMapLocation from "../../../utilites/hooks/setMapLocation";
import { userCheckin } from "../../../constants/feature-flags";
import UseGetTheme from "../../../utilites/hooks/useGetTheme";
import { toTitleCase } from "../../../utilites/format";
import { getAdStatus, checkinStatus } from "../../../utilites/status";
import { updateAdInResults } from "../../../utilites/update";
import { viewedListing, updateListing } from "../../../api/listing";
import { viewedGrowth } from "../../../api/growth";
import { getCurrentDate, convertDateToDbDate } from "../../../utilites/date";

// Assets
import DirectionArrow from "../../../assets/svg/directionArrow";

// Styles
import "./listing.css";

const Listing = ({
  ad,
  business,
  setHeaderTitle,
  setImage,
  isUser,
  user,
  history,
  setSavedPage,
  setLoader,
  setMessage,
  setAd,
  results,
  setResults,
  setPromoCode,
  location,
}) => {
  const {
    isActive,
    _id,
    stubhubId,
    ticketmasterId,
    isGrowth,
    categories,
    checkedIn = {},
    images: adImages,
    title,
    description,
    ticketLink,
    virtualLink,
    features,
    promoCode,
    businessName: growthBusinessName,
  } = ad || {};
  const {
    _id: businessId = "",
    name: businessName = "",
    images: businessImages = [],
    yelp = {},
    ads = [],
  } = business || {};
  const [
    { isDateCurrent, open, close, status: adStatus, hasStarted },
    setAdStatus,
  ] = useState(getAdStatus(ad));
  const hasViewedPromo = useRef(false);
  const [modalLoginMessage, setModalLoginMessage] = useState("");
  const { isNight } = UseGetTheme();
  const { _id: userId } = user || {};
  const { showCheckIn, checkInStatus } = checkinStatus(ad, userId);
  const cardHeaderStyle = isDateCurrent
    ? `listing-header-container-${adStatus}`
    : "listing-header-container-starting";
  const isCheckIn = isUser && showCheckIn && (hasStarted || checkInStatus);
  const isDarkColor = !isNight && adStatus === "starting";
  const currentDate = getCurrentDate();
  const creatorIsViewing = userId === businessId;
  const isActiveStatus = adStatus === "active" || adStatus === "ending";
  let images = [...adImages, ...businessImages];
  const { cat1, cat1Img, cat2Img } = categories;
  const { from } = location.state || {};
  const isBusiness = !!Object.keys(business).length;
  const isNoLargeSize = !!(stubhubId || ticketmasterId);
  if (from && from !== "Top" && from && cat2Img && cat1 !== from) {
    images = [cat2Img, cat1Img, ...images];
  } else {
    if (cat2Img) images.unshift(cat2Img);
    images.unshift(cat1Img);
  }
  SetMapLocation(true);

  useEffect(() => {
    if (!isActive && !creatorIsViewing) {
      history.push("/");
      setMessage({
        message: "Not Found",
        messageType: "error",
      });
    }
    if (isGrowth) viewedGrowth(_id);
    if (businessId && isBusiness && !creatorIsViewing) {
      viewedListing({
        businessId,
        listingId: _id,
        date: convertDateToDbDate(currentDate),
      });
    }
  }, []);

  useEffect(() => {
    setHeaderTitle({
      businessId,
      businessName: businessName || growthBusinessName,
    });
    return () => setHeaderTitle(null);
  }, [business]);

  useEffect(() => {
    setInterval(() => setAdStatus(getAdStatus(ad)), 10000);
    return () => clearInterval(setAdStatus);
  }, [ad]);

  const handlePromoClick = () => {
    if (!hasViewedPromo.current || !creatorIsViewing)
      viewedListing({
        businessId,
        listingId: _id,
        date: convertDateToDbDate(currentDate),
        isPromo: true,
      });
    hasViewedPromo.current = true;
    setPromoCode(promoCode);
  };

  const handleModalLoginAccept = () => {
    const { location } = history;
    const { pathname } = location;
    setSavedPage(pathname);
    history.push("/login");
  };

  const handleCheckIn = async () => {
    if (user) {
      setLoader(true);
      let updatedTimeStamp = [moment().format()];
      if (checkedIn && userId in checkedIn)
        updatedTimeStamp = [...updatedTimeStamp, ...checkedIn[userId]];
      const { ad: updatedAd } =
        (await updateListing({
          ...ad,
          checkedIn: { ...checkedIn, [userId]: updatedTimeStamp },
        })) || {};
      if (updatedAd) {
        setAd(updatedAd);
        if (results.length)
          setResults({
            results: updateAdInResults(results, updatedAd),
            pathname: "",
          });
      }
      setLoader(false);
    } else {
      setModalLoginMessage(
        "You are not logged in. Would you like to login now?"
      );
    }
  };

  const renderCheckedInMessage = () => {
    const checkInTime = moment(checkInStatus).format("MMM Do @ h:mma");
    return (
      <>
        <p className="checked-in-thanks">
          <i>Thank You!</i>
        </p>
        <div className="checked-in-info">
          <span>Checked-In On:</span>
          <span>{checkInTime}</span>
        </div>
      </>
    );
  };

  return (
    <>
      <DetailViewLayout
        isListing
        isNoLargeSize={isNoLargeSize}
        images={images}
        name={title}
        status={adStatus}
      >
        <div className="listing-content-container">
          <div className={`listing-header-container ${cardHeaderStyle}`}>
            <Bookmark data={ad} status={adStatus} />
            <ShareButton
              size="30px"
              isDarkColor={isDarkColor}
              title={title}
              name={businessName || growthBusinessName}
            />
            <p
              className={`listing-title-main ${
                isDarkColor ? "listing-title-main-dark" : ""
              }`}
            >
              {title.toUpperCase()}
            </p>
          </div>
          <div className="listing-business-info-conatiner">
            <p
              className="pointer listing-business-name"
              onClick={() => {
                if (isBusiness) history.push(`/business/${businessId}`);
              }}
            >
              {toTitleCase({
                input: businessName || growthBusinessName,
                lowerCaseRest: false,
              })}
            </p>
            {isBusiness && (
              <div
                className={`pointer listing-business-info-svg listing-fill-${adStatus}`}
                onClick={() => history.push(`/business/${businessId}`)}
              >
                <DirectionArrow />
              </div>
            )}
          </div>
          <p className="listing-spacing listing-description-main">
            {description}
          </p>

          {ticketLink && (
            <ButtonLink
              isDateCurrent={isDateCurrent}
              status={adStatus}
              text="MORE INFO"
              link={ticketLink}
            />
          )}

          {isActiveStatus && (
            <ButtonLink
              isDateCurrent={isDateCurrent}
              status={adStatus}
              text="WATCH NOW"
              link={virtualLink}
            />
          )}

          {promoCode && isActiveStatus && (
            <div className="listing-spacing listing-center-content">
              <div
                className={`redemtion-button-code-button ${cardHeaderStyle}`}
                onClick={handlePromoClick}
              >
                <p
                  className={`redemtion-button-code-text ${
                    hasStarted ? "color-white" : ""
                  }`}
                >
                  REDEMPTION CODE
                </p>
              </div>
            </div>
          )}
          <Date data={ad} startTime={open} endTime={close} status={adStatus} />
          <Features
            adTitle={title}
            name={businessName || growthBusinessName}
            categories={categories}
            features={features}
            adStatus={adStatus}
          />
          <AdMedtaData data={ad} />
        </div>

        <Tickets ad={ad} businessId={businessId} />

        {userCheckin && isCheckIn && (
          <div className="listing-content-container">
            <div className="check-in-container">
              <span className="check-in-title">
                {!checkInStatus ? "Claim Promo Here" : "You claimed this promo"}
              </span>
              {!checkInStatus && (
                <Button
                  text="claim"
                  size="medium"
                  handleClick={handleCheckIn}
                />
              )}
            </div>
            <div
              className={`checked-in-popup-container ${
                checkInStatus ? "checked-in-popup-container-open" : ""
              }`}
            >
              {!!checkInStatus && renderCheckedInMessage()}
            </div>
          </div>
        )}

        <OtherAds currentId={_id} ads={ads} name={businessName} />
        <Yelp yelp={yelp} name={businessName} setImage={setImage} />
        <Contact
          ad={ad}
          business={isBusiness ? business : ad}
          status={adStatus}
        />
        <Instagram business={business} />
        <Modal
          directModalMessage={modalLoginMessage}
          modalAccept="log in"
          modalCancel="cancel"
          handleCancelClick={() => setModalLoginMessage("")}
          handleAcceptClick={handleModalLoginAccept}
        />
      </DetailViewLayout>
      <DownloadAppModal image={cat1Img} />
    </>
  );
};

const mapStateToProps = (store) => ({
  user: store.user.user,
  isUser: store.user.isUser,
  results: store.data.results,
});

const mapDispatchToProps = (dispatch) => ({
  setHeaderTitle: (title) => dispatch(stateActions.setHeaderTitle(title)),
  setImage: (value) => dispatch(stateActions.setImage(value)),
  setSavedPage: (url) => dispatch(stateActions.setSavedPage(url)),
  setLoader: (value) => dispatch(stateActions.setLoader(value)),
  setMessage: (messageObj) => dispatch(stateActions.setMessage(messageObj)),
  setResults: (resultsObj) => dispatch(dataActions.setResults(resultsObj)),
  setPromoCode: (value) => dispatch(stateActions.setPromoCode(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListingLoader(Listing));
