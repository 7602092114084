import React, { useState } from "react";

// utilities
import { isSafariAgent, isMobileAgent } from "../../../../utilites";
import getFeatureIcon from "../../../components/features-input/getFeatureIcon";

// Assets
import AppleLogo from "../../../../assets/svg/apple-logo";

// styles
import "./app-download.css";

export default () => {
  const [listingFlowIndex, setListingFlowIndex] = useState(0);
  const isMobile = isMobileAgent();
  const isSafairMobile = isSafariAgent() && isMobile;
  const height = `calc(100vh - ${isSafairMobile ? 150 : 40}px`;
  const listingFlowData = [
    {
      title: "Click The Phone To See More",
      image: "/img/home-screen.png",
      index: 0,
      nextIndex: 1,
    },
    {
      title: "EXPLORE HIGHLIGHTS",
      image: "/img/list-view.png",
      index: 1,
      nextIndex: 2,
    },
    {
      title: "SUPPORT LOCAL BUSINESSES",
      image: "/img/map-view.png",
      index: 2,
      nextIndex: 3,
    },
    {
      title: "SHARE WHAT YOU FIND",
      image: "/img/post.png",
      index: 3,
      nextIndex: 4,
    },
    {
      title: "IN REAL-TIME",
      image: "/img/listing.png",
      index: 4,
      nextIndex: 5,
    },
    {
      title: "FIND YOUR FUN!®",
      image: "/img/profile.png",
      index: 5,
      nextIndex: 0,
    },
  ];

  return (
    <div className="full-page-carousel-wrapper" style={{ height }}>
      <img
        className="user-onboarding-background-image"
        alt="EventHound Concert"
        src={"/img/food.jpg"}
      />

      <div className="user-onboarding-wrapper" style={{ height }}>
        <div className="user-onboarding-container">
          <img
            className="weekly-giveaway-eventhound-icon"
            alt="EventHound - Local EventHound"
            src="/img/icons/event-hound-icon.png"
          />

          <p className="user-onboarding-title">DISCOVER • SHARE • CONNECT</p>

          <div className="listing-flow-wrapper">
            <div className="user-onboarding-conatiner">
              <img
                className="user-onboarding-image listing-flow-image-placeholder"
                alt="EventHound - Listing Fow"
                src={"/img/listing_flow_1.png"}
              />
              <div className="listing-flow-image-shadow" />

              {listingFlowData.map(({ image, index, nextIndex }) => (
                <img
                  className={`user-onboarding-image listing-flow-image-${index} ${
                    index === 0 || listingFlowIndex === index
                      ? "listing-flow-image-show"
                      : ""
                  }`}
                  alt="EventHound - Listing Fow"
                  src={image}
                  onClick={() => setListingFlowIndex(nextIndex)}
                  key={image}
                />
              ))}
            </div>
          </div>

          <div className="business-onboarding-arrow-outer">
            <div className="business-onboarding-arrow-inner">
              {getFeatureIcon("skinny arrow")}
            </div>
          </div>

          <p className="user-onboarding-title">
            {
              listingFlowData[listingFlowIndex > -1 ? listingFlowIndex : 0]
                .title
            }
          </p>

          <div className="weekly-giveaway-buttons-container">
            <a
              className="weekly-giveaway-button-container"
              href="https://play.google.com/store/apps/details?id=com.eventhound"
              target="blank"
            >
              <img
                className="weekly-giveaway-icon"
                alt="EventHound - Local EventHound"
                src="/img/google-play.png"
              />
              <div className="weekly-giveaway-button-text-container">
                <p className="weekly-giveaway-button-text-title">GET IT ON</p>
                <p className="weekly-giveaway-button-text">Google Play</p>
              </div>
            </a>

            <a
              className="weekly-giveaway-button-container"
              href="https://apps.apple.com/us/app/eventhound/id1530831818"
              target="blank"
            >
              <div className="weekly-giveaway-icon">
                <AppleLogo />
              </div>
              <div className="weekly-giveaway-button-text-container">
                <p className="weekly-giveaway-button-text-title">
                  Download on the
                </p>
                <p className="weekly-giveaway-button-text">App Store</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
