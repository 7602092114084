import React, { useState } from "react";

// Components
import Button from "../../../../components/common/button";

// Utilities
import { verifyUser, confirmVerifyUser } from "../../../../../api/veirfy";

// Styles
import "./verify-user.css";

export default ({ verified, id, setLoader, setMessage, updateUser }) => {
  const [code, setCode] = useState("");

  const handleChange = (evt) => {
    const { value } = evt.target;
    setCode(value.slice(0, 6));
  };

  const handleSendCode = async () => {
    setLoader(true);
    const { veirfy } = await verifyUser(id);
    setLoader(false);
    if (veirfy === "pending") {
      setMessage({ message: "Phone Call Pending" });
    } else {
      setMessage({ messageType: "error", message: "Verify Error" });
    }
  };

  const handleCode = async () => {
    if (code.length < 6)
      return setMessage({ messageType: "error", message: "Invalid Code" });
    setLoader(true);
    const { user } = await confirmVerifyUser({ id, code });
    setLoader(false);
    if (user) {
      updateUser(user);
      setMessage({ message: "Verified Successfully" });
    }
  };

  const handleKeyDown = (evt) => {
    const { which, keyCode } = evt;
    const isEnter = which === 13 || keyCode === 13;
    if (isEnter) handleCode();
  };

  return (
    !verified && (
      <div className="listing-content-container profile-verification-container">
        <div className="profile-title-container profile-accont-header">
          <p className="profile-account-text profile-text-dark">
            Please verify the account
          </p>
          <Button size="small" text="Send Code" handleClick={handleSendCode} />
        </div>
        <p className="profile-verification-text profile-text-dark">
          Before you can post you will need to verify the account. After
          clicking "send code" an automated phone call will dial the phone
          number associated with the account. You will be given a verification
          code to enter below.
        </p>
        <div className="profile-title-container profile-accont-promo">
          <p className="profile-account-promo-text profile-text-dark">
            VERIFICATION CODE
          </p>
          <div className="payment-form-group">
            <input
              type="text"
              className="payment-form-promoCode"
              placeholder="Enter code"
              name="verificationCode"
              value={code}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
            />
            <Button text="submit" size="small" handleClick={handleCode} />
          </div>
        </div>
      </div>
    )
  );
};
