import { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as userActions from "../../redux/actions/user-actions";
import * as stateActions from "../../redux/actions/state-actions";

// Utilities
import { getCoordinates } from "../../api/search";
import useDebounce from "../../utilites/hooks/debounce";

export default () => {
  const isMounted = useRef(false);
  const geoWatchId = useRef(false);
  const dispatch = useDispatch();
  const [latLng, setLatLng] = useState("");
  const debounceLatLng = useDebounce(latLng, 3 * 60 * 1000);
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  const success = ({ coords }) => {
    const { longitude, latitude } = coords;
    setLatLng(`${latitude}, ${longitude}`);

    dispatch(
      userActions.setGeoLocation({ coordinates: [longitude, latitude] })
    );
    dispatch(userActions.setGeoPermission(true));
  };

  const error = ({ code }) => {
    let message = "";
    switch (code) {
      case 1:
        message = "Enable Location Permissions";
        break;
      case 2:
        message = "Location Unavailable";
        break;
      default:
        message =
          "We can't find your location. Search a city or address instead";
    }

    dispatch(userActions.setGeoPermission(false));
    dispatch(
      stateActions.setMessage({
        messageType: "error",
        message,
      })
    );
  };

  const getCurrentPosition = () =>
    navigator.geolocation.getCurrentPosition(success, error, options);

  const watchPosition = () => {
    const id = navigator.geolocation.watchPosition(success, error, options);
    geoWatchId.current = id;
    return id;
  };

  useEffect(() => {
    if (!isMounted.current && latLng) {
      (async () => {
        const { location } = (await getCoordinates({ latlng: latLng })) || {};
        dispatch(userActions.setGeoAddress(location));
      })();
      isMounted.current = true;
    }
  }, [latLng]);

  useEffect(() => {
    if (debounceLatLng)
      (async () => {
        const { location } =
          (await getCoordinates({ latlng: debounceLatLng })) || {};
        dispatch(userActions.setGeoAddress(location));
      })();
  }, [debounceLatLng]);

  useEffect(() => {
    return () => {
      if (geoWatchId.current)
        navigator.geolocation.clearWatch(geoWatchId.current);
    };
  }, [geoWatchId]);

  return { getCurrentPosition, watchPosition };
};
