import React from "react";

// utilities
import getIcon from "../../../components/features-input/getFeatureIcon";
import { toTitleCase } from "../../../../utilites/format";

// Assets
import AppleLogo from "../../../../assets/svg/apple-logo";

// styles
import "./weekly-give-away-styles.css";

export default () => {
  const itemList = [
    {
      title: "horseback riding tours in malibu for two",
      image:
        "https://eventhoundassets163325-prod.s3.us-west-2.amazonaws.com/public/listingb7c15f12-cb54-4780-8a17-2366ed4bcf88",
      date: "5/21/21",
      link: "https://eventhound.com/listing/60747c5c1fecdc0009a44977",
    },
    {
      title: "$150 gift card to geoffrey's malibu",
      image:
        "https://eventhoundassets163325-prod.s3.us-west-2.amazonaws.com/public/businessd7a1e7df-85b4-48bc-adf4-af433690182b",
      date: "5/28/21",
      link: "https://eventhound.com/business/60898b234863ef000885cdec",
    },
    {
      title: "Wine and Cheese with Cheeky Goats",
      image:
        "https://eventhoundassets163325-prod.s3.us-west-2.amazonaws.com/public/listing1f0e182d-8153-45b1-9578-29577412e0ab",
      date: "6/4/21",
      link: "https://eventhound.com/listing/605bd71b21db64000737e26a",
    },
  ];

  const WinnerItemList = [
    {
      title: "Zodiac Whale Watching for two",
      image:
        "https://www.californiabeaches.com/wp-content/uploads/2016/06/dana-wharf-whale-watching.jpg",
      date: "5/14/21",
      link: "https://eventhound.com/listing/60909495c3c5430008218d28",
      winner: "Colten",
      location: "Los Angeles",
    },
    {
      title: "4 pack glow ride water bikes",
      image:
        "https://eventhoundassets163325-prod.s3.us-west-2.amazonaws.com/public/listinge2cb2b80-b69c-49fe-81ec-c76fac6b2fca",
      date: "5/7/21",
      link: "https://eventhound.com/listing/60747ab85223c7000883de21",
      winner: "Maria",
      location: "Long Beach",
    },
  ];

  return (
    <div className="weekly-giveaway-wrapper">
      <div className="weekly-giveaway-container">
        <img
          className="weekly-giveaway-eventhound-icon"
          alt="EventHound - Local EventHound"
          src="/img/icons/event-hound-icon.png"
        />
        <div className="weekly-giveaway-LogoName">{getIcon("name")}</div>
        <p className="weekly-giveaway-tagline">Find Your Fun!</p>
        <h1 className="weekly-giveaway-title">WEEKLY GIVEAWAY</h1>
        <p className="weekly-giveaway-text">
          Want to win one of EventHounds awesome weekly giveaways!? It’s easy,
          download the Eventhound app and create an account to be entered into
          our raffles!
        </p>
        <p className="weekly-giveaway-text">Winners announced weekly!</p>
        <p className="weekly-giveaway-text">
          Make sure to bookmark your favorite activities so we can include them
          in our next giveaway!
        </p>
        <div className="weekly-giveaway-items">
          {itemList.map(({ title, image, date, link }) => (
            <a
              className="weekly-giveaway-item"
              href={link}
              target="blank"
              key={title}
            >
              <p className="weekly-giveaway-item-date">{date}</p>
              <img
                className="weekly-giveaway-image"
                alt={toTitleCase({ input: title })}
                src={image}
              />
              <p className="weekly-giveaway-item-title">
                {toTitleCase({ input: title })}
              </p>
            </a>
          ))}
        </div>
        <div className="weekly-giveaway-buttons-container">
          <a
            className="weekly-giveaway-button-container"
            href="https://play.google.com/store/apps/details?id=com.eventhound"
            target="blank"
          >
            <img
              className="weekly-giveaway-icon"
              alt="EventHound - Local EventHound"
              src="/img/google-play.png"
            />
            <div className="weekly-giveaway-button-text-container">
              <p className="weekly-giveaway-button-text-title">GET IT ON</p>
              <p className="weekly-giveaway-button-text">Google Play</p>
            </div>
          </a>

          <a
            className="weekly-giveaway-button-container"
            href="https://apps.apple.com/us/app/eventhound/id1530831818"
            target="blank"
          >
            <div className="weekly-giveaway-icon">
              <AppleLogo />
            </div>
            <div className="weekly-giveaway-button-text-container">
              <p className="weekly-giveaway-button-text-title">
                Download on the
              </p>
              <p className="weekly-giveaway-button-text">App Store</p>
            </div>
          </a>
        </div>

        <label style={{ marginTop: "25px" }}>Previous Winners</label>
        <div className="weekly-giveaway-items">
          {WinnerItemList.map(
            ({ title, image, date, link, winner, location }) => (
              <a
                className="weekly-giveaway-item"
                href={link}
                target="blank"
                key={title}
              >
                <p className="weekly-giveaway-item-date">{date}</p>
                <img
                  className="weekly-giveaway-image"
                  alt={toTitleCase({ input: title })}
                  src={image}
                />
                <p className="weekly-giveaway-item-title">
                  {toTitleCase({ input: title })}
                </p>
                <p
                  className="weekly-giveaway-item-title color-primary"
                  style={{ fontWeight: "600" }}
                >
                  {`${toTitleCase({ input: winner })} in ${toTitleCase({
                    input: location,
                  })}`}
                </p>
              </a>
            )
          )}
        </div>
      </div>
    </div>
  );
};
