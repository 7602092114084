import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
    >
      <circle cx="50" cy="12.6" r="10.1" />
      <path
        d="M71.7,29.8c4.7-2.9,11.2-12.2,12.4-14c1.3-2,0.8-4.7-1.1-6c-2-1.3-4.7-0.8-6,1.1c-2.7,3.9-7.5,10.1-9.8,11.5
				c-2.5,1.5-6.9,3.3-11.3,3.3H50h-5.9c-4.4,0-8.8-1.8-11.3-3.3c-2.3-1.4-7.1-7.6-9.8-11.5c-1.3-2-4-2.5-6-1.1c-2,1.3-2.5,4-1.1,6
				c1.3,1.8,7.7,11.1,12.4,14c1.4,0.9,5.8,3.3,11.6,4.2c0.1,5.1,0.3,20.6,0.7,23.8c0.4,2.7,1.5,6.5,2.6,9.7c0.7,2,1.6,4.9,1.7,6.1
				c-0.6,2.3-5.2,10-9.2,16c-1.6,2.3-1,5.4,1.3,7c0.9,0.6,1.9,0.9,2.8,0.9c1.6,0,3.2-0.8,4.2-2.2c7.3-10.8,11-18,11-21.5
				c0-2.6-1.1-5.8-2.3-9.5c-0.3-0.8-0.6-1.7-0.8-2.6c1.1,0.6,2.5,1.3,4.3,2.2c3,1.6,6.7,3.4,9,4.8c0.3,2.8-0.1,10-1,16.7
				c-0.4,2.8,1.6,5.3,4.3,5.7c2.8,0.4,5.3-1.6,5.7-4.3C77.1,64.5,74,62.5,72,61.1c-2.6-1.7-7.2-4.1-11.2-6.1
				c-0.4-0.2-0.8-0.4-1.3-0.6L60.1,34C65.8,33.1,70.3,30.7,71.7,29.8z"
      />
    </svg>
  );
};
