import { callAPI } from "./";
import { baseURL } from "./url";

const reportURL = baseURL("/report");

export function reportOther(data) {
  return callAPI(`${reportURL}`, "POST", data);
}

export function sendEmail(data) {
  return callAPI(`${reportURL}`, "PUT", data);
}
