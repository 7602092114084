import React from "react";
import { CSSTransition } from "react-transition-group";
import { useDispatch, useSelector } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";

// Assets
import Back from "../../../assets/svg/back";
import EventHound from "../../../assets/svg/eventHound-name";

// Styles
import "./web-view.css";

export default () => {
  const dispatch = useDispatch();
  const { webView } = useSelector((store) => store.state);

  return (
    <CSSTransition in={!!webView} timeout={250} classNames="fade">
      <div className="web-view-wrapper" style={{ zIndex: 990 }}>
        <div className="web-view-header">
          <div
            className="header-svg-container-MobileHeader header-back-MobileHeader"
            onClick={() => dispatch(stateActions.setWebView(false))}
          >
            <Back />
          </div>
          <div className="pointer header-logo-MobileHeader">
            <EventHound />
          </div>
          <div />
        </div>
        <iframe
          className="web-view-iframe"
          style={{ zIndex: 990 }}
          src={webView}
        />
      </div>
    </CSSTransition>
  );
};
