import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as stateActions from "../../../../../redux/actions/state-actions";
import * as userActions from "../../../../../redux/actions/user-actions";

// Components
import Instagram from "../../../../components/instagram";
import Button from "../../../../components/common/button";
import SocialConnectButton from "./social-connect-button";

// Utilities
import getFeaturesIcon from "../../../../components/features-input/getFeatureIcon";
import { updateBusiness } from "../../../../../api/business";

// Styles
import "./instagram-styles.css";

export default () => {
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.user);
  const { scheduledSocial = [], social } = user || {};
  const { fbPageAccId, instagram } = social || {};
  const { igProfile, igPostList } = instagram || {};
  const isFacebook = social && fbPageAccId;
  const isInstagram = igProfile && igPostList && igPostList.length;

  const handleFbRemove = async () => {
    if (scheduledSocial && scheduledSocial.length) {
      return dispatch(
        stateActions.setMessage({
          messageType: "error",
          message: "Delete Scheduled Post",
        })
      );
    }
    dispatch(stateActions.setLoader(true));
    const { user: updatedUser } = await updateBusiness({ ...user, social: {} });
    if (updatedUser) dispatch(userActions.updateUser(updatedUser));
    dispatch(stateActions.setLoader(false));
  };

  return isInstagram ? (
    <Instagram handleDelete={handleFbRemove} business={user} />
  ) : (
    <div className="listing-content-container">
      <div className="business-title-container">
        <div className="business-line-break bgc-medium" />
        <div className="social-login-title-container">
          <div className="social-title-icon-container">
            {getFeaturesIcon("social media")}
          </div>
          <p className="social-login-title-text">Connect Social Media</p>
        </div>
        <div className="business-line-break bgc-medium" />
      </div>

      <div className="social-media-content-container">
        {isFacebook && (
          <div className="social-media-facebook-remove-container">
            <div className="social-media-facebook-remove-icon-container facebook_background">
              <div className="social-media-facebook-check-container">
                <div className="social-media-facebook-check">
                  {getFeaturesIcon("check")}
                </div>
              </div>

              <div className="social-media-facebook-remove-icon">
                {getFeaturesIcon("facebook")}
              </div>
            </div>

            <Button
              text="Remove"
              size="small"
              isPrimary={false}
              handleClick={handleFbRemove}
            />
          </div>
        )}

        <div className="social-media-details-container">
          <p className="social-media-list-title">Types:</p>
          <ul className="social-media-list-item">
            <li>Facebook</li>
            <li>Instagram - Accessed with Facebook login</li>
          </ul>
          <p className="social-media-list-title">Benefits:</p>
          <ul className="social-media-list-item">
            <li>Post to all apps. (EventHound, Facebook & Instagram)</li>
            <li>Schedule future post.</li>
            <li>Your profile will showcase your Instagram feed.</li>
            <li>A link will be provided to direct users to your Instagram.</li>
          </ul>
          <p className="social-media-list-title">Requirements:</p>
          <ul className="social-media-list-item">
            <li>Instagram account needs to be a Business Account.</li>
            <ul className="social-media-list-item">
              <li>
                <a
                  href="https://www.facebook.com/business/help/502981923235522"
                  target="blank"
                >
                  How to link - Instagram business account
                </a>
              </li>
            </ul>
            <li>Instagram account needs to be connected to a Facebook page.</li>
            <ul className="social-media-list-item">
              <li>
                <a
                  href="https://www.facebook.com/business/help/502981923235522"
                  target="blank"
                >
                  How to link - Connect Facebook page
                </a>
              </li>
            </ul>
          </ul>
        </div>
      </div>

      <SocialConnectButton style={{ margin: "15px 0px 10px 0px" }} />
    </div>
  );
};
