import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 75 90"
    >
      <path
        d="M70.4,64.5c-1.3-0.6-2.5-1.2-3.5-2.2c-2.9-2.7-4.9-6-5.4-10.1c-0.2-1.9-0.2-3.9,0.2-5.8c0.6-2.5,1.8-4.8,3.6-6.6
		c1.2-1.2,2.4-2.3,3.6-3.5c0.7-0.7,0.9-1.8,0.2-2.6c-1.3-1.7-2.8-3.2-4.5-4.5c-2.2-1.6-4.6-2.7-7.2-3.4c-1.9-0.4-3.7-0.7-5.7-0.5
		c-3.2,0.3-6.3,1.2-9.4,2.3c-2.4,0.8-4.8,1-7.3,0.3c-2.9-0.9-5.8-1.7-8.8-2c-3.1-0.3-6.1-0.1-9,1c-3.6,1.4-6.5,3.7-8.7,6.9
		c-1.7,2.3-2.9,4.9-3.7,7.6c-1,3.2-1.4,6.4-1.4,8.2c-0.1,4.7,0.3,7.8,1,10.9c0.5,2.4,1.2,4.6,2.1,6.9c2,5.4,4.7,10.4,8.4,14.8
		c1.9,2.4,4.2,4.4,7,5.7c1.8,0.8,3.7,1.1,5.6,0.8c2-0.3,4-1,5.9-1.8c1.2-0.5,2.4-0.9,3.7-1.1c2-0.3,4,0,5.9,0.6c2,0.6,4,1.4,6.1,1.9
		c3.1,0.8,5.9,0.5,8.6-1.2c3.4-2.1,5.9-5,8-8.3c2.4-3.6,4.5-7.5,5.8-11.7C71.9,66,71.5,64.9,70.4,64.5z"
      />
      <path
        d="M37.3,23.9c0.3,0,0.7-0.1,1.1-0.1c2.5-0.2,4.8-0.7,6.9-2.2c1.6-1.1,2.9-2.4,4.1-3.9c1.5-1.8,2.7-3.8,3.5-6
		c0.6-1.6,0.7-3.3,0.9-5c0.1-1,0.1-2,0.2-3c0.1-1.6-1-2.8-2.7-2.4c-1.7,0.4-3.4,0.9-5.1,1.7c-2.5,1.1-4.7,2.6-6.5,4.8
		c-3.1,3.9-4.5,8.4-4.8,13.3c0,0.3,0,0.5,0,0.8C35,23.3,36,24.1,37.3,23.9z"
      />
    </svg>
  );
};
