import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
    >
      <path
        d="M57,0c-0.8,0-1.5,0.4-2.1,0.9L0.9,55c-1.2,1.2-1.2,3.2,0,4.4c0,0,0,0,0,0l39.7,39.7c1.2,1.2,3.2,1.2,4.4,0c0,0,0,0,0,0
		L99.1,45c0.7-0.7,1-1.7,0.9-2.7L94.7,7.9c-0.2-1.3-1.3-2.4-2.6-2.6L57.7,0C57.5,0,57.3,0,57,0L57,0z M75.7,13.4
		c6,0,10.9,4.9,10.9,10.9c0,6-4.9,10.9-10.9,10.9c-6,0-10.9-4.9-10.9-10.9C64.8,18.3,69.7,13.4,75.7,13.4z M42.8,33.8h6.3v3.1v0.9
		c1,0.2,1.9,0.4,2.8,0.7c1.9,0.7,3.3,1.7,4.2,2.9c1,1.3,1.6,3.6,1.9,5.5l0.1,0.8h-6.4l-0.1-0.6c-0.2-1.4-0.8-2.4-1.7-3.1
		c-0.9-0.7-2.2-1.1-4.1-1.1c-2.2,0-3.7,0.4-4.5,1c-0.9,0.7-1.2,1.3-1.2,2.1c0,0.5,0.1,0.9,0.5,1.3v0c0.3,0.4,0.8,0.8,1.6,1.1
		c0.4,0.2,1.9,0.6,4.4,1.3c3.6,0.9,6,1.7,7.5,2.4v0c1.5,0.6,2.7,1.6,3.6,2.9c0.9,1.3,1.3,2.9,1.3,4.7c0,1.8-0.5,3.5-1.6,5.1
		c-1,1.6-2.6,2.8-4.5,3.7h0c-1.2,0.5-2.5,0.9-3.9,1v0.7v3.1h-6.3v-3.1v-0.8c-2.3-0.4-4.2-1.1-5.6-2.2c-2.2-1.8-3.6-5-4.2-8.3
		L32.8,58h3.4h3.2l0.1,0.6c0.3,1.9,0.9,3.2,2.1,4.1c1.1,0.9,2.7,1.4,4.9,1.4c2.2,0,3.7-0.4,4.7-1.2c1-0.8,1.4-1.7,1.4-2.7
		c0-0.9-0.3-1.5-1.2-2.1c-0.5-0.3-2.2-0.9-4.9-1.6c-3.7-0.9-6.3-1.7-7.8-2.4c-1.5-0.7-2.7-1.8-3.5-3.1c-0.8-1.3-1.2-2.8-1.2-4.3
		c0-1.4,0.3-2.8,1-4c0.7-1.2,1.6-2.2,2.7-3c0.9-0.6,2-1.1,3.4-1.5c0.6-0.2,1.2-0.3,1.8-0.4v-0.8L42.8,33.8z"
      />
    </svg>
  );
};
