import React from "react";

// Styles
import "./svg.css";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 80 100"
    >
      <path
        d="M27.2,56.5V37.4l4,2.3v19C29.8,58.1,28.5,57.4,27.2,56.5 M54.8,37.4l4,2.3v10.9c-1.1,1.6-2.5,3.1-4,4.4V37.4z M41.3,28.4
      l5.4,3.1v27.9c-1.7,0.5-3.5,0.9-5.4,1V28.4z M17.4,37.9c0-12.5,10.1-22.6,22.6-22.6s22.6,10.1,22.6,22.6c0,4-1,7.8-2.9,11V39
      l-5.1-3.4l-6.5,3.6v-8.5l-7-4.6L32,31v7.9l-5-3.3l-6.7,3.7v9.9C18.5,45.8,17.4,42,17.4,37.9 M3.6,37.9c0,26.9,35.9,60.7,36.4,60.7
      c0.5,0,36.4-33.8,36.4-60.7C76.4,17.8,60.1,1.4,40,1.4C19.9,1.4,3.6,17.8,3.6,37.9"
      />
    </svg>
  );
};
