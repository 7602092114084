import React from "react";

// Assets
import Finger from "../../../assets/svg/finger";
import Skyline from "../../../assets/svg/skyline";

// Styles
import "./confirm-email.css";

const ConfirmEmail = ({ location }) => {
  let email = "";
  if (location && location.state && location.state.email)
    email = location.state.email.toUpperCase();
  return (
    <div className="confirm-email-page-wrapper">
      {email && (
        <div className="confirm-email-redirect-container">
          <h2 className="confirm-email-email-text">{email}</h2>
          <h2 className="confirm-email-text-title">
            Please Confirm
            <div className="confirm-email-finger-container">
              <Finger />
            </div>
            That E-Mail Address
          </h2>
        </div>
      )}
      <div className="confirm-email-skyline-container">
        <Skyline />
      </div>
    </div>
  );
};

export default ConfirmEmail;
