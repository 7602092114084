import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as userActions from "../../redux/actions/user-actions";

// Utilities
import { getDistance } from "../";
import { getCoordinates } from "../../api/search";

export default (setLocation = false) => {
  const { geoLocation, location, mapLocation } = useSelector(
    (state) => state.user
  );
  const { coordinates: geoCoords } = geoLocation || {};
  const { coordinates: locationCoords } = location || {};
  const isMapLocation = !!mapLocation;
  const [isUpdatingLocation, setIsUpdatingLocation] = useState(isMapLocation);
  const [askUpdateLocationPermissions, setAskUpdateLocationPermissions] =
    useState(false);
  const dispatch = useDispatch();

  const cancelLocations = () => {
    setAskUpdateLocationPermissions(false);
    setIsUpdatingLocation(false);
    dispatch(userActions.setMapLocation(null));
  };

  const setMapLocation = async () => {
    const latlng = `${mapLocation[1]}, ${mapLocation[0]}`;
    const { location } = (await getCoordinates({ latlng })) || {};
    dispatch(
      userActions.setLocation({
        ...location,
        coordinates: mapLocation,
      })
    );
    dispatch(userActions.setMapLocation(null));
    setIsUpdatingLocation(false);
  };

  useEffect(() => {
    if (isMapLocation) {
      if (setLocation) {
        setMapLocation();
      } else if (locationCoords) {
        const locationDistance = getDistance(
          { lng: locationCoords[0], lat: locationCoords[1] },
          { lng: mapLocation[0], lat: mapLocation[1] }
        );

        if (locationDistance >= 10) {
          setAskUpdateLocationPermissions(true);
        } else {
          dispatch(userActions.setMapLocation(null));
          setIsUpdatingLocation(false);
        }
      } else if (geoCoords) {
        const geoDistance = getDistance(
          { lng: geoCoords[0], lat: geoCoords[1] },
          { lng: mapLocation[0], lat: mapLocation[1] }
        );
        if (geoDistance >= 10) {
          setAskUpdateLocationPermissions(true);
        } else {
          dispatch(userActions.setMapLocation(null));
          setIsUpdatingLocation(false);
        }
      } else {
        setMapLocation();
      }
    } else {
      setIsUpdatingLocation(false);
    }
  }, []);

  return {
    mapLocation,
    isUpdatingLocation,
    askUpdateLocationPermissions,
    cancelLocations,
    setMapLocation,
  };
};
