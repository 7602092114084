const mapDefaultCenter = [-118.2436849, 34.0522342];

export function getLocation(reduxUser) {
  const { location, geoLocation, user } = reduxUser || {};
  const { coordinates: enteredCoords } = location || {};
  const { coordinates: geoCoords } = geoLocation || {};
  if (enteredCoords) return enteredCoords;
  if (geoCoords) return geoCoords;
  if (
    user &&
    user.location &&
    user.location.coordinates &&
    user.location.coordinates.length > 1
  )
    return user.location.coordinates;
  return mapDefaultCenter;
}

export const getCityLocation = (reduxUser) => {
  const { location: enteredLocation, geoLocation, user } = reduxUser;
  const { location: userDefaultLocation } = user || {};
  if ("address" in enteredLocation) return enteredLocation.address;
  if ("address" in geoLocation) return geoLocation.address;
  if (userDefaultLocation && "address" in userDefaultLocation)
    return userDefaultLocation.address;
};
