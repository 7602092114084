import UAParser from "ua-parser-js";
import moment from "moment";
import { dayTimes } from "../constants/data-models";

export function generateUUID() {
  var d = new Date().getTime();
  var d2 = (performance && performance.now && performance.now() * 1000) || 0;
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export const getDistance = (p1, p2) => {
  const R = 6378137;
  const rad = (x) => (x * Math.PI) / 180;
  const dLat = rad(p2.lat - p1.lat);
  const dLong = rad(p2.lng - p1.lng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) *
      Math.cos(rad(p2.lat)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return Math.round(d * 0.000621371);
};

export function isMobileAgent() {
  const parser = new UAParser();
  const { type } = parser.getDevice();
  return type === "tablet" || type === "mobile";
}

export function isSafariAgent() {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
}

export function isStandAloneAgent() {
  return window.matchMedia("(display-mode: standalone)").matches;
}

export function isBottomSpacing(resultsLength) {
  const isMobile = isMobileAgent();
  if (!isMobile) return 75;
  if (!isStandAloneAgent())
    return resultsLength && resultsLength < 5 ? 190 : 125;
  return resultsLength && resultsLength < 6 ? 125 : 25;
}

export function findIsNight() {
  const { dayStart, dayEnd } = dayTimes;
  const currentTime = Number(moment().format("HHmm"));
  return currentTime < dayStart || currentTime > dayEnd;
}

export function setTheme(darkTheme) {
  const primaryColorValue = getComputedStyle(
    document.documentElement
  ).getPropertyValue("color");
  const isDayTheme = primaryColorValue !== "#F1F1F1";
  const isNight = findIsNight();
  if ((isDayTheme && isNight) || darkTheme) {
    document.documentElement.style.setProperty("color", "#F1F1F1");
    document.documentElement.style.setProperty("--text-extra-dark", "#F1F1F1");
    document.documentElement.style.setProperty("--text-dark", "#F1F1F1");
    document.documentElement.style.setProperty("--text-medium", "#C3C3C3");
    document.documentElement.style.setProperty("--text-light", "#999B9C");
    document.documentElement.style.setProperty("--text-white", "#323435");
    document.documentElement.style.setProperty("--backgroundColor", "#323435");
    document.documentElement.style.setProperty(
      "--backgroundColor-secondary",
      "#4a4d4e"
    );
    document.documentElement.style.setProperty(
      "--background-opacity",
      "rgba(52,52,52,0.95)"
    );
    document.documentElement.style.setProperty(
      "--background-on-opacity",
      "#4a4d4e"
    );
    document.documentElement.style.setProperty(
      "--opacity-dark",
      "rgba(10,10,10,0.8)"
    );
    document.documentElement.style.setProperty(
      "--opacity-medium",
      "rgba(10,10,10,0.7)"
    );
    document.documentElement.style.setProperty(
      "--opacity-light",
      "rgba(10,10,10,0.6)"
    );
    document.documentElement.style.setProperty(
      "--opacity-very-light",
      "rgba(10,10,10,0.05)"
    );
    document.documentElement.style.setProperty(
      "--skeleton",
      "rgb(105,105,105)"
    );
    document.documentElement.style.setProperty(
      "--skeleton-opacity",
      "rgb(70,70,70)"
    );
  } else {
    document.documentElement.style.setProperty("color", "rgb(80,80,80)");
    document.documentElement.style.setProperty(
      "--text-extra-dark",
      "rgb(40,40,40)"
    );
    document.documentElement.style.setProperty("--text-dark", "rgb(80,80,80)");
    document.documentElement.style.setProperty("--text-medium", "#999B9C");
    document.documentElement.style.setProperty("--text-light", "#C3C3C3");
    document.documentElement.style.setProperty("--text-white", "#F1F1F1");
    document.documentElement.style.setProperty(
      "--backgroundColor",
      "rgb(247,247,247)"
    );
    document.documentElement.style.setProperty(
      "--backgroundColor-secondary",
      "rgb(210,210,210)"
    );
    document.documentElement.style.setProperty(
      "--background-opacity",
      "rgba(247,247,247,0.95)"
    );
    document.documentElement.style.setProperty(
      "--background-on-opacity",
      "rgb(255,255,255)"
    );
    document.documentElement.style.setProperty(
      "--opacity-dark",
      "rgba(255,255,255,0.9)"
    );
    document.documentElement.style.setProperty(
      "--opacity-medium",
      "rgba(255,255,255,0.8)"
    );
    document.documentElement.style.setProperty(
      "--opacity-light",
      "rgba(255,255,255,0.6)"
    );
    document.documentElement.style.setProperty(
      "--opacity-very-light",
      "rgba(255,255,255,0.05)"
    );
    document.documentElement.style.setProperty(
      "--skeleton",
      "rgb(210,210,210)"
    );
    document.documentElement.style.setProperty(
      "--skeleton-opacity",
      "rgba(210,210,210,0.6)"
    );
  }
}
