import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 83.2 76"
    >
      <path
        d="M54.9,34c7.7,0,14-7.7,14-17.2s-6.3-15-14-15c-7.7,0-14,5.5-14,15C41,26.3,47.2,34,54.9,34z M82.1,61.3
	c-1.4-12.5-12.8-17.9-26.7-17.9c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0c-13.9,0-25.4,5.4-26.7,17.9c0,0.3-0.1,3.1-0.1,4.5
	c29.7,19,54.4,0,54.4,0C82.1,64.3,82.1,61.6,82.1,61.3z M32.7,26.8H20.8V14.9H13v11.9H1.1v7.9H13v11.9h7.9V34.7h11.9L32.7,26.8
	L32.7,26.8z"
      />
    </svg>
  );
};
