import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 74"
    >
      <path
        d="M85.6,2.2H25.1c-7.2,0.1-13,5.9-13,13v40.2L1.3,71.7l24.1-9h60.2c7.1,0,13-5.8,13-13V15.2C98.6,8.1,92.8,2.2,85.6,2.2z
	 M59.4,58.3H45.6v-5.4h13.8V58.3z M70.1,23.7c0,2.2-0.4,4.2-1.3,5.8c-0.9,1.6-2.8,3.7-5.7,6.5c-1.8,1.7-2.8,3.2-3.1,4.4
	c-0.3,1.3-0.6,3.9-0.6,7.6v2.5H45.6v-3.7c0-3.6,0.2-6.2,0.8-8c0.6-1.9,2.4-4.4,5.7-7.8c0.4-0.4,0.9-0.9,1.2-1.3H40.7V16.4
	c0-6.6,4.8-9.8,14.3-9.8c4.6,0,8.2,0.7,10.8,2c2.9,1.4,4.3,4.3,4.3,8.7C70.1,17.3,70.1,23.7,70.1,23.7z"
      />
      <path d="M54.9,15.6c-0.8,0-0.8,0.2-0.8,0.3v12.4c1.2-1.8,1.6-2.9,1.6-3.7v-8.9C55.7,15.8,55.7,15.6,54.9,15.6z" />
    </svg>
  );
};
