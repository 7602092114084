import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";

// Components
import InputLabeled from "../input-labeled";
import WeekdaysTimeInput from "../../components/WeekdaysTimeInput";
import Categories from "../categories-input";
import Features from "../features-input";
import Button from "../common/button";
import Modal from "../modal";

// Utilities
import defaultData from "../../routes/sign-up/business/data-weekdats-default";
import { locationOptions } from "../../../constants/data-models";
import getFeaturesIcon from "../features-input/getFeatureIcon";

export default ({ page, user, handleCommonData }) => {
  const {
    name,
    managerName,
    email,
    phone,
    showPhone: userShowPhone = true,
    website,
    locationType: userLocationType,
    locations = [],
    address,
    city,
    state,
    zip,
    hours: userHours,
    categories: businessCategories,
    features,
    description,
    deliveryLink,
    ads = [],
  } = user || {};
  const defaultCategories = {
    cat1: "",
    cat1Sub: "",
    cat2: "",
    cat2Sub: "",
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const nameParam = urlParams.get("name");
  const managerNameParam = urlParams.get("managerName");
  const emailParam = urlParams.get("email");
  const phoneParam = urlParams.get("phone");
  const contactPhoneParam = urlParams.get("contactPhone");
  const addressParam = urlParams.get("address");
  const cityParam = urlParams.get("city");
  const stateParam = urlParams.get("state");
  const zipParam = urlParams.get("zip");
  const [modalMessage, setModalMessage] = useState("");
  const [nameInput, setNameInput] = useState(nameParam || name || "");
  const [managerNameInput, setManagerNameInput] = useState(
    managerNameParam || managerName || ""
  );
  const [emailInput, setEmailInput] = useState(emailParam || email || "");
  const [phoneInput, setPhoneInput] = useState(
    contactPhoneParam || phoneParam || phone || ""
  );
  const [showPhone, setShowPhone] = useState(userShowPhone);
  const [locationType, setLocationType] = useState(
    emailParam && contactPhoneParam
      ? "Multiple Locations"
      : emailParam && !addressParam
      ? "No Physical Location"
      : userLocationType || "One Location"
  );
  const [addressInput, setAddressInput] = useState(
    addressParam || address || ""
  );
  const [cityInput, setCityInput] = useState(cityParam || city || "");
  const [stateInput, setStateInput] = useState(stateParam || state || "");
  const [zipInput, setZipInput] = useState(zipParam || zip || "");
  const [websiteInput, setWebsiteInput] = useState(website || "");
  const [hours, setHours] = useState(userHours || defaultData.hours);
  const [categories, setCategories] = useState(
    businessCategories || defaultCategories
  );
  const [deliveryLinkInput, setDeliveryLinkInput] = useState(
    deliveryLink || ""
  );
  const [featuresInput, setFeaturesInput] = useState(features || {});
  const [descriptionInput, setDescriptionInput] = useState(description || "");
  const isDiscriptionComplete = descriptionInput.length < 40;
  const isEditProfile = window.location.href.indexOf("edit") > -1;
  const isCorporate = locationType === "Multiple Locations";
  const discriptionLegend = isDiscriptionComplete
    ? `${40 - descriptionInput.trim().length} more characters needed`
    : `${descriptionInput.trim().length} characters`;

  useEffect(() => {
    const locationData =
      locationType === "One Location"
        ? {
            addressInput: addressInput.trim().toLowerCase(),
            cityInput: cityInput.trim().toLowerCase(),
            stateInput: stateInput.trim().toLowerCase(),
            zipInput,
          }
        : {};
    const data = {
      nameInput: nameInput.trim().toLowerCase(),
      managerNameInput: managerNameInput.trim().toLowerCase(),
      emailInput: emailInput.trim().toLowerCase(),
      phoneInput,
      showPhone,
      descriptionInput: descriptionInput.trim(),
      websiteInput: websiteInput.trim(),
      locationType,
      hours,
      categories,
      featuresInput,
      deliveryLinkInput: isCorporate ? "" : deliveryLinkInput,
      ...locationData,
    };
    handleCommonData(data);
  }, [
    nameInput,
    managerNameInput,
    emailInput,
    phoneInput,
    showPhone,
    websiteInput,
    locationType,
    addressInput,
    cityInput,
    stateInput,
    zipInput,
    hours,
    categories,
    featuresInput,
    deliveryLinkInput,
    descriptionInput,
  ]);

  const isAdsLocationDefault = () =>
    ads.find(({ location }) => location.locationIsdefault);

  const handleChange = (evt, type, newValue) => {
    const { value } = evt.target;
    switch (type) {
      case "nameInput":
        return setNameInput(value);
      case "managerNameInput":
        return setManagerNameInput(value);
      case "emailInput":
        return setEmailInput(value);
      case "phoneInput":
        const newPhoneValue = value
          .replace("-", "")
          .replace(".", "")
          .match(/^[0-9]*$/gi);
        if (newPhoneValue === null) return setPhoneInput(phoneInput);
        return setPhoneInput(newPhoneValue[0].slice(0, 10));
      case "addressInput":
        return setAddressInput(value);
      case "cityInput":
        return setCityInput(value);
      case "stateInput":
        let newStateInput = "";
        if (value) newStateInput = value.match(/\w/gi).join("").slice(0, 2);
        return setStateInput(newStateInput);
      case "zipInput":
        let newZipInput = "";
        if (value) newZipInput = value.match(/\d/gi).join("").slice(0, 5);
        return setZipInput(newZipInput);
      case "websiteInput":
        return setWebsiteInput(value);
      case "descriptionInput":
        return setDescriptionInput(value);
      case "setLocationType":
        if (newValue === "No Physical Location" && isAdsLocationDefault()) {
          return setModalMessage(
            'You have ads dependent on your business location. Change your ads location before switching to "No Physical Location".'
          );
        }
        if (locations.length && newValue !== "Multiple Locations") {
          return dispatch(
            stateActions.setMessage({
              messageType: "error",
              message: "Delete locations to switch model",
            })
          );
        }
        return setLocationType(newValue);
    }
  };

  const handleDeliveryLink = (evt, name) => {
    if (name === "DeliveryInput") {
      const { value } = evt.target;
      setDeliveryLinkInput(value);
    }
  };

  const handleShowToggle = (evt, newValue, params) => {
    evt.preventDefault();
    switch (params) {
      case "phoneInput":
        return setShowPhone(newValue);
    }
  };

  const contactInputs = [
    {
      name: "nameInput",
      value: nameInput,
      label: "Business Name",
    },
    {
      name: "managerNameInput",
      value: managerNameInput,
      label: "Owner/Manager Name",
    },
    {
      name: "phoneInput",
      value: phoneInput,
      label:
        locationType === "Multiple Locations"
          ? "Contact Phone"
          : "Business Phone",
      toggle: locationType !== "Multiple Locations",
      toggleValue: showPhone,
    },
    {
      name: "emailInput",
      value: emailInput,
      label: "Email",
      type: "email",
    },
    {
      name: "websiteInput",
      value: websiteInput,
      label: "Website",
    },
  ];

  const addressInputs = [
    {
      name: "addressInput",
      value: addressInput,
      label: "Address",
    },
    {
      name: "cityInput",
      value: cityInput,
      label: "City",
    },
    {
      name: "stateInput",
      value: stateInput,
      label: "State",
    },
    {
      name: "zipInput",
      value: zipInput,
      label: "Zip",
      type: "number",
    },
  ];

  const handleFeatureClick = (feature) => {
    const value = featuresInput[feature] || false;
    if (!value) return setFeaturesInput({ ...featuresInput, [feature]: true });
    const newFeaturesInput = { ...featuresInput };
    delete newFeaturesInput[feature];
    setFeaturesInput(newFeaturesInput);
  };

  const renderLocationTypes = () =>
    Object.keys(locationOptions()).map((title, idx) => {
      const isSelected = locationType === title;
      return (
        <div
          className={`business-feature-container business-feature-container-theme ${
            isSelected ? "business-feature-selected" : ""
          }`}
          onClick={(evt) => handleChange(evt, "setLocationType", title)}
          key={idx}
        >
          <div
            className={`business-feature-svg ${
              isSelected ? "business-feature-svg-selected" : ""
            }`}
          >
            {getFeaturesIcon(title)}
          </div>
          <p className="business-feature-text">{title}</p>
        </div>
      );
    });

  return (
    <>
      <div className="card card-form">
        <div className="card-info-form">
          <div className="card-features-container">{renderLocationTypes()}</div>
          <p className="business-location-type-description type-title-description">
            {locationOptions()[locationType].description}
          </p>
          {isEditProfile &&
            userLocationType === "Multiple Locations" &&
            locationType === "Multiple Locations" && (
              <div className="business-location-button-wrapper">
                <Button
                  size="large"
                  text="Manage Locations"
                  handleClick={() => history.push("/business/corporate")}
                />
              </div>
            )}
        </div>
      </div>

      <div className="card card-form">
        <div className="card-info-form">
          {contactInputs.map((input, idx) => {
            const { name, value, label, type, toggle, toggleValue } = input;
            return (
              <InputLabeled
                name={name}
                value={value}
                label={label}
                type={type}
                handleChange={(evt) => handleChange(evt, name)}
                toggle={toggle}
                toggleValue={toggleValue}
                handleToggle={handleShowToggle}
                key={idx}
              />
            );
          })}
        </div>
      </div>

      {locationType === "One Location" && (
        <div className="card card-form">
          <div className="card-info-form">
            <div style={{ height: "5px" }} />
            {addressInputs.map((input, idx) => {
              const { name, value, label, type, toggle, toggleValue } = input;
              return (
                <InputLabeled
                  name={name}
                  value={value}
                  label={label}
                  type={type}
                  handleChange={(evt) => handleChange(evt, name)}
                  toggle={toggle}
                  toggleValue={toggleValue}
                  handleToggle={handleShowToggle}
                  key={idx}
                />
              );
            })}
          </div>
        </div>
      )}

      <WeekdaysTimeInput
        page={page}
        title="Hours Of Operation:"
        hours={hours}
        setHours={setHours}
      />

      <Categories categories={categories} setCategories={setCategories} />

      <Features
        values={featuresInput}
        linkValue={deliveryLinkInput}
        handleClick={handleFeatureClick}
        handleChange={handleDeliveryLink}
        isDeliveryOption={!isCorporate}
      />

      <div id="descriptionInput" className="card card-form">
        <div className="card-info-form">
          <div
            className="legend-form-split-container"
            style={{ paddingTop: "10px" }}
          >
            <label className="label-form">Description:</label>
            <label className="label-form">
              <p
                className={`legend-form-text ${
                  isDiscriptionComplete
                    ? "legend-form-incomplete"
                    : "legend-form-complete"
                }`}
              >
                {discriptionLegend}
              </p>
            </label>
          </div>
          <textarea
            className="input-style input-form"
            rows="5"
            maxLength="1500"
            value={descriptionInput}
            onChange={(evt) => handleChange(evt, "descriptionInput")}
            spellCheck="true"
          />
        </div>
      </div>

      <Modal
        directModalMessage={modalMessage}
        modalAccept="OK"
        handleAcceptClick={() => setModalMessage("")}
      />
    </>
  );
};
