import moment from "moment";
import { getCurrentDate } from "./date";

export function verifyCurrentTime(stateTime) {
  const currentTime = Number(moment().format("HHmm"));
  let militaryTime = timeToMilitary({ time: stateTime });
  const is30 = militaryTime % 100 === 50;
  if (!is30) {
    militaryTime += 30;
  } else {
    militaryTime += 50;
  }
  return militaryTime < currentTime;
}

export function getCurrentTime(inReadable = true) {
  const currentTime = moment().format("HHmm");
  const is30 = currentTime % 100 >= 30;
  let formatedTime = Math.floor(currentTime / 100) * 100;
  if (is30) formatedTime += 50;
  if (inReadable) return militaryTimeToReadable(formatedTime);
  return formatedTime;
}

export function timeToMilitary({ time, isEndTime = false }) {
  if (!time) return "";
  let timeTotal = 0;
  const fullTimeSplit = time.split(" ");
  const timeSplit = fullTimeSplit[0].split(":");
  if (fullTimeSplit[1] === "PM" && timeSplit[0] !== "12") timeTotal += 1200;
  timeTotal += timeSplit[0] * 100;
  if (fullTimeSplit[1] === "AM" && timeSplit[0] === "12") timeTotal -= 1200;
  if (timeSplit[1] === "30") timeTotal += 50;
  if (
    (isEndTime && fullTimeSplit[1] === "AM" && Number(timeSplit[0]) <= 2) ||
    (isEndTime && fullTimeSplit[1] === "AM" && timeSplit[0] === "12")
  )
    timeTotal = timeTotal + 2400;
  return timeTotal;
}

export const militaryToCorrectMilitary = (time) => {
  let formattedTime = time;
  if (formattedTime % 100 === 50) formattedTime -= 20;
  return `${String(formattedTime).slice(-4, -2)}:${String(formattedTime).slice(
    -2
  )}`;
};

export function militaryTimeToReadable(time) {
  if (typeof time !== "number") return "";
  if (time % 100 === 50) time = time - 20;
  let timeString = String(time);
  if (time < 30) {
    return "12:00 AM";
  } else if (time < 100) {
    return "12:30 AM";
  } else if (time <= 930) {
    return `${timeString.slice(0, 1)}:${timeString.slice(-2)} AM`;
  } else if (time <= 1130) {
    return `${timeString.slice(0, 2)}:${timeString.slice(-2)} AM`;
  } else if (time <= 1230) {
    return `${timeString.slice(0, 2)}:${timeString.slice(-2)} PM`;
  } else if (time <= 2130) {
    time = time - 1200;
    timeString = String(time);
    return `${timeString.slice(0, 1)}:${timeString.slice(-2)} PM`;
  } else if (time < 2400) {
    time = time - 1200;
    timeString = String(time);
    return `${timeString.slice(0, 2)}:${timeString.slice(-2)} PM`;
  } else if (time === 2400) {
    return "12:00 AM";
  } else {
    time = time - 2400;
    return militaryTimeToReadable(time);
  }
}

export function filterTimeList(date) {
  const currentDate = getCurrentDate();
  let startAt = 0;
  if (!date || date === currentDate) startAt = getCurrentTime(false);
  const timeList = [];
  for (let i = startAt; i < 2400; i += 50) {
    timeList.push(militaryTimeToReadable(i));
  }
  return timeList;
}

export function timeSelectTimeList(startTime = 0, endTime = 2600) {
  const startAt = (startTime += 50);
  const timeList = [];
  for (let i = startAt; i <= endTime; i += 50) {
    timeList.push(militaryTimeToReadable(i));
  }
  return timeList;
}

export function convertToMinutes(time) {
  let timeInMin = time;
  const timeSplit = String(time).split("");
  if (timeSplit.length > 3) {
    const hoursInMin = Number(timeSplit.slice(0, 2).join("")) * 60;
    const Min = Number(timeSplit.slice(-2).join(""));
    timeInMin = hoursInMin + Min;
  } else if (timeSplit.length > 2) {
    const hoursInMin = Number(timeSplit.slice(0, 1).join("")) * 60;
    const Min = Number(timeSplit.slice(-2).join(""));
    timeInMin = hoursInMin + Min;
  }
  return timeInMin;
}

export function miilitaryTimeDifference(currTime, time) {
  const timeIs30 = time % 100 > 0;
  if (timeIs30) time -= 20;
  const timeInMin = convertToMinutes(time);
  const currTimeInMin = convertToMinutes(currTime);
  const differenceInMin = timeInMin - currTimeInMin;
  const hoursLeft = Math.floor(differenceInMin / 60);
  const minLeft = differenceInMin % 60;
  const formatedHours = hoursLeft ? `${hoursLeft}H` : "";
  const formatedMins = minLeft ? `${minLeft} min` : "";
  return `${formatedHours} ${formatedMins}`.trim();
}

export const formatEndTime = (endTime) => {
  if (endTime <= 200) return endTime + 2400;
  return endTime;
};

export function getTimeZone() {
  return /\((.*)\)/.exec(new Date().toString())[1];
}
