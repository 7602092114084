import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as stateActions from "../../../../redux/actions/state-actions";

// Components
import InputLabeled from "../../../components/input-labeled";
import TimeSelector from "../../../components/common/time-selector";
import SwitchButton from "../../../components/common/switch-button";
import WeekdaysTimeInput from "../../../components/WeekdaysTimeInput";
import Modal from "../../../components/modal";

// Utilities
import defaultData from "../../sign-up/business/data-weekdats-default";
import {
  formatEndTime,
  militaryTimeToReadable,
} from "../../../../utilites/time";
import { isOpenDay } from "../../../../utilites/validate";
import { formateServiceFee } from "../../../../utilites/format";
import { salesInvalidTypeMessage } from "../../../../constants/data-models";

// Styles
import "./sales-common-styles.css";

export default ({
  ad,
  ticket,
  isAdTickets,
  isAdVouchers,
  handleCommonData,
  isTitle = true,
  adIdx,
}) => {
  const dispatch = useDispatch();
  const {
    name = "",
    isTicket: isTicketProp = true,
    redeemablePeriod: ticketRedeemablePeriod = 0,
    startTime = 800,
    endTime = 2000,
    isAdHours: ticketIsAdHours = false,
    hours: ticketHours,
    originalPrice,
    price = "",
    quantity = "",
    min = "1",
    max = "10",
    description = "",
  } = ticket || {};
  const { days, isPresale: isPresaleProp } = ad || {};
  const { dateAvailable, hours: adHours } = days || {};
  const [modalMessage, setModalMessage] = useState("");
  const [isTicketInput, setIsTicketInput] = useState(Boolean(isTicketProp));
  const [nameInput, setNameInput] = useState(name);
  const [redeemablePeriod, setRedeemablePeriod] = useState(
    String(ticketRedeemablePeriod)
  );
  const [startTimeInput, setStartTimeInput] = useState(startTime);
  const [endTimeInput, setEndTimeInput] = useState(endTime);
  const [isAdHours, setIsAdHours] = useState(ticketIsAdHours);
  const [hours, setHours] = useState(
    ticketHours || adHours || defaultData.hours
  );
  const [originalPriceInput, setOriginalPriceInput] = useState(
    originalPrice ? String(originalPrice) : ""
  );
  const [priceInput, setPriceInput] = useState(String(price));
  const [quantityInput, setQuantityInput] = useState(String(quantity));
  const [minimumQuantityInput, setMinimumQuantityInput] = useState(String(min));
  const [maximumQuantityInput, setMaximumQuantityInput] = useState(String(max));
  const [descriptionInput, setDescriptionInput] = useState(description);
  const [isPresale, setIsPresale] = useState(isPresaleProp);
  const isDiscriptionComplete = descriptionInput.length < 40;
  const discriptionLegend = isDiscriptionComplete
    ? `${40 - descriptionInput.trim().length} more characters needed`
    : `${descriptionInput.trim().length} characters`;

  useEffect(() => {
    if (adIdx) {
      setNameInput("");
      setStartTimeInput(800);
      setEndTimeInput(2000);
      setHours(adHours || defaultData.hours);
      setPriceInput("");
      setQuantityInput("");
      setMinimumQuantityInput("1");
      setMaximumQuantityInput("10");
    }
  }, [adIdx]);

  useEffect(() => {
    handleCommonData({
      name: nameInput.trim().toLowerCase(),
      isTicket: isTicketInput,
      isPresale,
      redeemablePeriod: redeemablePeriod.length
        ? Number(redeemablePeriod)
        : false,
      isAdHours,
      ...(dateAvailable
        ? { startTime: startTimeInput, endTime: endTimeInput }
        : { hours }),
      originalPrice: originalPriceInput,
      price: priceInput.length ? Number(priceInput) : false,
      quantity: quantityInput.length ? Number(quantityInput) : false,
      min: minimumQuantityInput.length ? Number(minimumQuantityInput) : false,
      max: maximumQuantityInput.length ? Number(maximumQuantityInput) : false,
      description: descriptionInput.trim(),
    });
  }, [
    nameInput,
    redeemablePeriod,
    startTimeInput,
    endTimeInput,
    isAdHours,
    hours,
    originalPriceInput,
    priceInput,
    quantityInput,
    minimumQuantityInput,
    maximumQuantityInput,
    descriptionInput,
  ]);

  const handleChange = (evt, type) => {
    const { value } = evt.target;
    switch (type) {
      case "nameInput":
        return setNameInput(value);
      case "redeemablePeriod":
        const newRedeemablePeriod = value
          .replace("-", "")
          .replace(".", "")
          .match(/^[0-9]*$/gi);
        if (newRedeemablePeriod === null) return setRedeemablePeriod(90);
        return setRedeemablePeriod(newRedeemablePeriod[0]);
      case "startTimeInput":
        return setStartTimeInput(value);
      case "endTimeInput":
        return setEndTimeInput(formatEndTime(value));
      case "originalPriceInput":
        const newOriginalPriceValue = value
          .replace("-", "")
          .replace(".", "")
          .match(/^[0-9]*$/gi);
        if (newOriginalPriceValue === null)
          return setOriginalPriceInput(originalPrice);
        return setOriginalPriceInput(newOriginalPriceValue[0]);
      case "priceInput":
        const newPriceValue = value
          .replace("-", "")
          .replace(".", "")
          .match(/^[0-9]*$/gi);
        if (newPriceValue === null) return setPriceInput("0");
        return setPriceInput(newPriceValue[0]);
      case "quantityInput":
        const newQuantityValue = value
          .replace("-", "")
          .replace(".", "")
          .match(/^[0-9]*$/gi);
        if (newQuantityValue === null) return setQuantityInput(quantityInput);
        return setQuantityInput(newQuantityValue[0]);
      case "minimumQuantityInput":
        const newMinimumQuantityValue = value
          .replace("-", "")
          .replace(".", "")
          .match(/^[0-9]*$/gi);
        if (newMinimumQuantityValue === null)
          return setMinimumQuantityInput(quantityInput);
        return setMinimumQuantityInput(newMinimumQuantityValue[0]);
      case "maximumQuantityInput":
        const newMaximumQuantityValue = value
          .replace("-", "")
          .replace(".", "")
          .match(/^[0-9]*$/gi);
        if (newMaximumQuantityValue === null)
          return setMaximumQuantityInput(quantityInput);
        return setMaximumQuantityInput(newMaximumQuantityValue[0]);
      case "descriptionInput":
        return setDescriptionInput(value);
    }
  };

  const handleType = (isTicket) => {
    setIsPresale(isTicket);
    setIsTicketInput(isTicket);
    setRedeemablePeriod(isTicket ? "0" : "90");
  };

  const handleSwitch = (type) => {
    const isTicket = type === "Ticket";
    if (isTicket === isTicketInput) return;
    if (isAdTickets || isAdVouchers) {
      if (isAdTickets) return setModalMessage("tickets");
      if (isAdVouchers) return setModalMessage("vouchers");
    }
    handleType(isTicket);
  };

  const handleSwitchTimesClick = (type) => {
    if (type === "Ad Hours" && !isOpenDay({ hours: adHours }))
      return dispatch(
        stateActions.setMessage({
          messageType: "error",
          message: "No Ad Hours",
        })
      );
    setIsAdHours(type === "Ad Hours");
  };

  const renderBusinessDays = () => {
    const filteredHours = Object.keys(hours).filter(
      (day) => day !== "isAdHours"
    );
    return filteredHours.map((day, key) => {
      const { isOpen, open, close } = adHours[day] || {};
      return (
        <div
          className="business-hour-conatiner business-hour-conatiner-inactive"
          style={{ backgroundColor: "transparent" }}
          key={key}
        >
          <p className="business-hour-item">{day}</p>
          {isOpen ? (
            <>
              <p className="business-hour-item">
                {militaryTimeToReadable(open)}
              </p>
              <p className="business-hour-item">
                {militaryTimeToReadable(close)}
              </p>
            </>
          ) : (
            <>
              <p className="business-hour-item">Closed</p>
              <p className="business-hour-item">Closed</p>
            </>
          )}
        </div>
      );
    });
  };

  return (
    <>
      <div id="consentInput" className="card card-form">
        <div className="card-info-form">
          <div className="listing-selector-container" style={{ width: "100%" }}>
            <SwitchButton
              button1Name="Ticket"
              button2Name="Voucher"
              isFirst={isTicketInput}
              handleClick={handleSwitch}
            />
          </div>
          <p className="sales-type-text">
            {isTicketInput
              ? "Tickets are available for presale prior to the event date and are only redeemable on the day and time they are purchased for."
              : "Vouchers offer a redemption period set by you. The redemption period starts on the first available date."}
          </p>

          {isTitle && (
            <InputLabeled
              name="nameInput"
              value={nameInput}
              label="Name - e.g General Admission"
              handleChange={(evt) => handleChange(evt, "nameInput")}
            />
          )}
          <InputLabeled
            name="originalPriceInput"
            value={originalPriceInput}
            label="If Discounted Enter Original Price Here"
            handleChange={(evt) => handleChange(evt, "OriginalPriceInput")}
          />
          <InputLabeled
            name="priceInput"
            value={priceInput}
            label="Price Per Item"
            handleChange={(evt) => handleChange(evt, "priceInput")}
          />
          {priceInput && (
            <p className="ticket-adjusted-price-text">
              {priceInput === "0"
                ? "Items will be free"
                : `$2 + 10% fee per sale, revenue will be $${formateServiceFee(
                    priceInput
                  )}`}
            </p>
          )}
        </div>
      </div>

      <div id="consentInput" className="card card-form">
        <div className="card-info-form">
          {dateAvailable ? (
            <>
              <label className="label-form" style={{ marginTop: "25px" }}>
                Time:
              </label>
              <div className="time-selector-wrapper-CreateListing">
                <TimeSelector
                  name="startTimeInput"
                  label="Start"
                  startTime={0}
                  value={startTimeInput}
                  handleChange={handleChange}
                />
                <TimeSelector
                  name="endTimeInput"
                  label="End"
                  startTime={startTimeInput}
                  value={endTimeInput}
                  handleChange={handleChange}
                />
              </div>
            </>
          ) : (
            <>
              <div style={{ height: "10px" }} />
              {isTicketInput && (
                <div
                  className="listing-selector-container"
                  style={{ width: "100%" }}
                >
                  <SwitchButton
                    button1Name="Custom Hours"
                    button2Name="Ad Hours"
                    isFirst={!isAdHours}
                    handleClick={handleSwitchTimesClick}
                  />
                </div>
              )}

              {isTicketInput && isAdHours ? (
                renderBusinessDays()
              ) : (
                <WeekdaysTimeInput
                  openLabel="Start"
                  closeLabel="End"
                  page="create-tickets"
                  text="Availability days & hours:"
                  hours={hours}
                  setHours={setHours}
                  isCard={false}
                  adHours={adHours}
                  isTimesSame={!isTicketInput}
                />
              )}

              <label
                id="daysInput"
                className="label-form"
                style={{ marginTop: "25px", textAlign: "center" }}
              >
                {`Your post is repeated, select the days &
                times your ${
                  isTicketInput ? "tickets" : "vouchers"
                } are available.`}
              </label>
            </>
          )}
        </div>
      </div>

      <div id="consentInput" className="card card-form">
        <div className="card-info-form">
          <div style={{ width: "100%", height: "5px" }} />

          <div className="legend-form-split-container">
            <label id="descriptionInput" className="label-form">
              Description:
            </label>
            <label className="label-form">
              <p
                className={`legend-form-text ${
                  isDiscriptionComplete
                    ? "legend-form-incomplete"
                    : "legend-form-complete"
                }`}
              >
                {discriptionLegend}
              </p>
            </label>
          </div>

          <textarea
            className="input-style input-form"
            rows="5"
            maxLength="500"
            value={descriptionInput}
            onChange={(evt) => handleChange(evt, "descriptionInput")}
            spellCheck="true"
          />

          {!isTicketInput && (
            <InputLabeled
              style={{ marginTop: "25px" }}
              name="redeemablePeriod"
              value={redeemablePeriod}
              label="Redeemable Time Period (Days)"
              handleChange={(evt) => handleChange(evt, "redeemablePeriod")}
            />
          )}

          <div style={{ width: "100%", height: "5px" }} />
          <InputLabeled
            name="quantityInput"
            value={quantityInput}
            label={`${dateAvailable ? "" : "Daily "}Available Quantity`}
            handleChange={(evt) => handleChange(evt, "quantityInput")}
          />

          <label className="label-form" style={{ marginTop: "25px" }}>
            Per Order Quantity:
          </label>
          <div className="time-selector-wrapper-CreateListing">
            <div className="time-outer-wrapper-TimeSelector">
              <InputLabeled
                name="minimumQuantityInput"
                value={minimumQuantityInput}
                label="Minimum Quantity"
                handleChange={(evt) =>
                  handleChange(evt, "minimumQuantityInput")
                }
              />
            </div>

            <div className="time-outer-wrapper-TimeSelector">
              <InputLabeled
                name="maximumQuantityInput"
                value={maximumQuantityInput}
                label="Maximum Quantity"
                handleChange={(evt) =>
                  handleChange(evt, "maximumQuantityInput")
                }
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        directModalMessage={!!modalMessage}
        modalAccept="got it"
        handleAcceptClick={() => setModalMessage("")}
      >
        <div className="guildelines-wrapper">
          <p className="guildelines-sub-title">
            {salesInvalidTypeMessage(modalMessage)}
          </p>
        </div>
      </Modal>
    </>
  );
};
