import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";

// Components
import businessLoader from "./business-loader";
import DetailViewLayout from "../../components/detail-view-scroll-layout";
import Bookmark from "../listing/bookmark";
import ShareButton from "../../components/common/share/button";
import Features from "../listing/features";
import ButtonLink from "./button-link";
import AdMetaData from "../listing/ad-meta-data";
import Yelp from "../listing/yelp";
import Contact from "../listing/contact";
import Social from "./social";
import OtherAds from "../listing/other-ads";
import Instagram from "../../components/instagram";
import Modal from "../../components/modal";
import BusinessHours from "./business-hours";

// Utilities
import UseGetTheme from "../../../utilites/hooks/useGetTheme";
import { getBusinessStatus } from "../../../utilites/status";
import { getCurrentDate, convertDateToDbDate } from "../../../utilites/date";
import { viewedBusiness } from "../../../api/business";
import { covidMessage } from "../../../constants/data-models";

// Assets
import Covid from "../../../assets/svg/covid";

//Styles
import "../listing/listing.css";
import "./business.css";

const Business = ({ business }) => {
  const { user } = useSelector((store) => store.user);
  const [modalMessage, setModalMessage] = useState("");
  const { userId } = user || {};
  const { isNight } = UseGetTheme();
  const {
    _id: businessId,
    name,
    hours,
    images,
    description,
    categories,
    features,
    yelp,
    ads,
    deliveryLink,
    covid,
  } = business || {};
  const { covidCompliant } = covid || {};
  const dispatch = useDispatch();
  const { isDateCurrent, status } = getBusinessStatus(business);
  const isDarkColor = !isNight && status === "starting";
  const cardHeaderStyle = isDateCurrent
    ? `listing-header-container-${status}`
    : "listing-header-container-starting";
  const isActiveStatus = status === "active" || status === "ending";

  useEffect(() => {
    if (userId !== businessId) {
      const currentDate = getCurrentDate();
      viewedBusiness(businessId, convertDateToDbDate(currentDate));
    }
  }, []);

  return (
    <>
      <DetailViewLayout images={images} name={name} status={status}>
        {covidCompliant && (
          <div
            className="business-card-covid"
            onClick={(evt) => {
              evt.stopPropagation();
              setModalMessage(covidMessage(name));
            }}
          >
            <Covid />
          </div>
        )}
        <div className="listing-content-container">
          <div className={`listing-header-container ${cardHeaderStyle}`}>
            <Bookmark data={business} status={status} />
            <ShareButton
              size="30px"
              isDarkColor={isDarkColor}
              title={name}
              name={name}
            />
            <p
              className={`listing-title-main ${
                isDarkColor ? "listing-title-main-dark" : ""
              }`}
            >
              {name.toUpperCase()}
            </p>
          </div>

          <div className="business-sub-content-container">
            <p className="listing-description">{description}</p>
          </div>

          {isActiveStatus && <ButtonLink link={deliveryLink} />}
          <BusinessHours hours={hours} status={status} />
          <Features
            name={name}
            categories={categories}
            features={features}
            adStatus={status}
          />
          <AdMetaData data={business} />
        </div>
        <OtherAds isAd={false} ads={ads} name={name} />
        <Yelp
          yelp={yelp}
          name={name}
          setImage={(value) => dispatch(stateActions.setImage(value))}
        />
        <Contact business={business} status={status} />
        <Instagram business={business} />
        <Social business={business} />
      </DetailViewLayout>

      <Modal
        directModalMessage={!!modalMessage}
        title={name}
        modalAccept="Ok"
        handleAcceptClick={() => setModalMessage("")}
      >
        <div className="modal-covid-icon">
          <Covid />
        </div>
        <p>{covidMessage(name)}</p>
        <a
          className="results-covid-modal-link"
          href="https://files.covid19.ca.gov/pdf/guidance-dine-in-restaurants.pdf"
          target="blank"
        >
          CDC Guildlines
        </a>
        <p className="results-covid-modal-legal">
          EventHound is not responsible for claims made by the businesses.
        </p>
      </Modal>
    </>
  );
};

export default businessLoader(Business);
