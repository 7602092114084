import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// Components
import InputLabeled from "../../../components/input-labeled";
import WeekdaysTimeInput from "../../../components/WeekdaysTimeInput";
import Features from "../../../components/features-input";
import ImageUpload from "../../../components/image-upload";

// Utilities
import { features as featuresModel } from "../../../../constants/data-models";

// Styles
import "./corporate-common-styles.css";

export default ({ data, page, handleCommonData }) => {
  const {
    phone,
    showPhone: locationShowPhone = true,
    address,
    city,
    state,
    zip,
    hours: corporateHours,
    features: adFeatures,
    website,
    deliveryLink,
    images = [],
  } = data || {};
  const {
    hours: mainHours,
    features: userFeatures,
    website: userWebsite,
    deliveryLink: userDeliveryLink,
    images: userImages = [],
  } = useSelector((store) => store.user.user);
  const isCreate = page === "create-corporate";
  const [phoneInput, setPhoneInput] = useState(phone || "");
  const [showPhone, setShowPhone] = useState(locationShowPhone);
  const [addressInput, setAddressInput] = useState(address || "");
  const [cityInput, setCityInput] = useState(city || "");
  const [stateInput, setStateInput] = useState(state || "");
  const [zipInput, setZipInput] = useState(zip || "");
  const [hours, setHours] = useState(corporateHours || mainHours);
  const [featuresInput, setFeaturesInput] = useState(
    adFeatures || userFeatures || {}
  );
  const [websiteInput, setWebsiteInput] = useState(
    isCreate ? userWebsite : website
  );
  const [deliveryLinkInput, setDeliveryLinkInput] = useState(
    isCreate ? userDeliveryLink : deliveryLink
  );
  const [locationImageInput, setLocationImageInput] = useState(
    isCreate ? userImages[0] : images[0]
  );

  useEffect(() => {
    handleCommonData({
      phoneInput,
      showPhone,
      addressInput: addressInput.trim().toLowerCase(),
      cityInput: cityInput.trim().toLowerCase(),
      stateInput: stateInput.trim().toLowerCase(),
      zipInput,
      hours,
      featuresInput,
      websiteInput: websiteInput.trim().toLowerCase(),
      deliveryLinkInput: deliveryLinkInput.trim().toLowerCase(),
      locationImageInput: locationImageInput ? [locationImageInput] : null,
    });
  }, [
    phoneInput,
    showPhone,
    addressInput,
    cityInput,
    stateInput,
    zipInput,
    hours,
    featuresInput,
    websiteInput,
    deliveryLinkInput,
    locationImageInput,
  ]);

  const handleChange = (evt, type, newValue) => {
    const { value } = evt.target;
    switch (type) {
      case "phoneInput":
        const newPhoneValue = value
          .replace("-", "")
          .replace(".", "")
          .match(/^[0-9]*$/gi);
        if (newPhoneValue === null) return setPhoneInput(phoneInput);
        return setPhoneInput(newPhoneValue[0].slice(0, 10));
      case "websiteInput":
        return setWebsiteInput(value);
      case "deliveryLinkInput":
        return setDeliveryLinkInput(value);
      case "addressInput":
        return setAddressInput(value);
      case "cityInput":
        return setCityInput(value);
      case "stateInput":
        let newStateInput = "";
        if (value) newStateInput = value.match(/\w/gi).join("").slice(0, 2);
        return setStateInput(newStateInput);
      case "zipInput":
        let newZipInput = "";
        if (value) newZipInput = value.match(/\d/gi).join("").slice(0, 5);
        return setZipInput(newZipInput);
      case "imageInput":
        return setLocationImageInput(newValue);
    }
  };

  const handleShowToggle = (evt, newValue, params) => {
    evt.preventDefault();
    switch (params) {
      case "phoneInput":
        return setShowPhone(newValue);
    }
  };

  const handleFeatureClick = (feature) => {
    const value = featuresInput[feature] || false;
    if (!value) return setFeaturesInput({ ...featuresInput, [feature]: true });
    const newFeaturesInput = { ...featuresInput };
    delete newFeaturesInput[feature];
    setFeaturesInput(newFeaturesInput);
  };

  const contactInputs = [
    {
      name: "websiteInput",
      value: websiteInput,
      label: "Website",
    },
    {
      name: "deliveryLinkInput",
      value: deliveryLinkInput,
      label: "Delivery Link",
    },
  ];

  const addressInputs = [
    {
      name: "phoneInput",
      value: phoneInput,
      label: "This Locations Phone",
      toggle: true,
      toggleValue: showPhone,
    },
    {
      name: "addressInput",
      value: addressInput,
      label: "Address",
    },
    {
      name: "cityInput",
      value: cityInput,
      label: "City",
    },
    {
      name: "stateInput",
      value: stateInput,
      label: "State",
    },
    {
      name: "zipInput",
      value: zipInput,
      label: "Zip",
      type: "number",
    },
  ];

  return (
    <>
      <div className="card card-form">
        <div className="card-info-form">
          <div className="corperate-section-title-conatiner"></div>
          {addressInputs.map((input, idx) => {
            const { name, value, label, type, toggle, toggleValue } = input;
            return (
              <InputLabeled
                name={name}
                value={value}
                label={label}
                type={type}
                handleChange={(evt) => handleChange(evt, name)}
                toggle={toggle}
                toggleValue={toggleValue}
                handleToggle={handleShowToggle}
                key={idx}
              />
            );
          })}
        </div>
      </div>

      <div className="card card-form">
        <div className="card-info-form">
          <WeekdaysTimeInput
            page={page}
            title="Hours Of Operation:"
            hours={hours}
            setHours={setHours}
            isCard={false}
          />
        </div>
      </div>

      <div className="card card-form">
        <div className="card-info-form">
          <Features
            options={featuresModel}
            values={featuresInput}
            handleClick={handleFeatureClick}
            handleChange={handleChange}
            isCard={false}
          />
        </div>
      </div>

      <div className="card card-form">
        <div className="card-info-form">
          {contactInputs.map((input, idx) => {
            const { name, value, label, type, toggle, toggleValue } = input;
            return (
              <InputLabeled
                name={name}
                value={value}
                label={label}
                type={type}
                handleChange={(evt) => handleChange(evt, name)}
                toggle={toggle}
                toggleValue={toggleValue}
                handleToggle={handleShowToggle}
                key={idx}
              />
            );
          })}

          <label id="locationImageInput" className="label-form">
            Image:
          </label>
          <div className="time-selector-wrapper-CreateListing">
            <ImageUpload
              imageInput={locationImageInput}
              handleChange={handleChange}
            />
          </div>
          <legend className="legend-form">
            Prefer pictures in landscape format
          </legend>
        </div>
      </div>
    </>
  );
};
