import React, { useMemo, useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { connect } from "react-redux";
import * as userActions from "../../../redux/actions/user-actions";
import * as stateActions from "../../../redux/actions/state-actions";

// Utilities
import { isBottomSpacing, setTheme } from "../../../utilites";
import { userLogin } from "../../../api/user";
import { businessLogin } from "../../../api/business";
import useGeoLocation from "../../../utilites/hooks/useGeoLocation";

// Assets
import EventHoundName from "../../../assets/svg/eventHound-name";

// Styles
import "./splash-page.css";

const SplashPage = (WrappedComponent) => {
  const Component = (props) => {
    const { getCurrentPosition } = useGeoLocation();
    const [isLoaded, setIsLoaded] = useState(false);
    const { user, setUser, setMessage } = props || {};
    const { darkTheme = false } = user || {};
    const locationMemo = useMemo(() => {
      const doNotGetLocationList = [
        "/listing/",
        "/login",
        "/user/sign-up",
        "/business/sign-up/",
        "/business/profile",
        "/business/analytics",
        "/business/community",
        "/business/edit",
        "/business/corporate",
        "/business/corporate/create",
        "/business/corporate/edit/",
        "/create-post",
        "/business/post/",
        "/business/",
        "/user/edit",
        "/welcome/start-up",
        "/find-your-fun",
        "/weekly-giveaway",
      ];
      const isFound = doNotGetLocationList.find((url) => {
        return window.location.href.includes(url);
      });
      if (!isFound) getCurrentPosition();
      return !isFound;
    }, []);
    setTheme(darkTheme);

    useEffect(() => {
      (async () => {
        try {
          if (!user) {
            const { user: storageUser, business: storageBusiness } =
              window.localStorage || {};
            if (storageUser) {
              const { user } = await userLogin({ token: storageUser });
              if (user) setUser({ isUser: true, user });
            }
            if (!storageUser && storageBusiness) {
              const { user } = await businessLogin({ token: storageBusiness });
              if (user) setUser({ isUser: false, user });
            }
          }
        } catch (err) {
          setIsLoaded(true);
          setMessage({
            messageType: "error",
            message: "No Network Connection",
          });
        }
        setIsLoaded(true);
      })();
    }, []);

    return isLoaded ? (
      <CSSTransition in={isLoaded} timeout={250} classNames="fade">
        <WrappedComponent {...props} />
      </CSSTransition>
    ) : (
      <CSSTransition in={isLoaded} timeout={250} classNames="fade">
        <div className="splash-page-wrapper">
          <div className="splash-icon-container">
            <img
              className="splash-icon"
              alt="EventHound - Local EventHound"
              src="/img/icons/event-hound-icon.png"
            />
          </div>
          <div
            className="splash-Name-container"
            style={{ marginBottom: isBottomSpacing() }}
          >
            <EventHoundName />
          </div>
        </div>
      </CSSTransition>
    );
  };

  const mapStateToProps = (store) => ({
    user: store.user.user,
  });

  const mapDispatchToProps = (dispatch) => ({
    setUser: (userObj) => dispatch(userActions.setUser(userObj)),
    setMessage: (message) => dispatch(stateActions.setMessage(message)),
  });

  return connect(mapStateToProps, mapDispatchToProps)(Component);
};

export default SplashPage;
