export const setGeoPermission = (value) => ({
  type: "setGeoPermission",
  payload: value,
});

export const setGeoLocation = (locationObj) => ({
  type: "setGeoLocation",
  payload: locationObj,
});

export const setGeoAddress = (address) => ({
  type: "setGeoAddress",
  payload: address,
});

export const setUser = (userObj) => ({
  type: "setUser",
  payload: userObj,
});

export const updateUser = (userObj) => ({
  type: "updateUser",
  payload: userObj,
});

export const setLocationInput = (locationInput) => ({
  type: "setLocationInput",
  payload: locationInput,
});

export const setLocation = (locationObj) => ({
  type: "setLocation",
  payload: locationObj,
});

export const setAds = (adsArray) => ({
  type: "setAds",
  payload: adsArray,
});

export const setMapLocation = (mapCoordsArr) => ({
  type: "setMapLocation",
  payload: mapCoordsArr,
});
