import React, { useRef, useState, useEffect } from "react";
import moment from "moment";

// Components
import TicketCard from "./ticket-card";

// Utilities
import { getTickets } from "../../../../api/tickets";
import { formatDbDateToReadable } from "../../../../utilites/date";
import {
  formatSalesDays,
  filterRepeatTicketSales,
  filterSingleDayTicketSales,
  filterRepeatVoucherSales,
  filterSingleDayVoucherSales,
} from "../../../../utilites/format";

// Styles
import "./tickets-styles.css";

export default ({ ad, businessId }) => {
  const { _id: id, days } = ad;
  const { presaleDate, startDate, endDate, dateAvailable, hours } = days || {};
  const formattedPresaleDate = presaleDate
    ? moment(
        formatDbDateToReadable({
          date: presaleDate,
          toFullYear: true,
          isForUser: true,
        })
      ).startOf("day")
    : false;
  const formattedStartDate = startDate
    ? moment(
        formatDbDateToReadable({
          date: startDate,
          toFullYear: true,
          isForUser: true,
        })
      ).startOf("day")
    : false;
  const ticketDateStartPosition = useRef(
    formattedStartDate
      ? moment(formattedStartDate).startOf("day")
      : moment().startOf("day")
  );
  const formattedEndDate = useRef(
    formatDbDateToReadable({
      date: endDate,
      toFullYear: true,
      isForUser: true,
    })
  ).current;
  const [dateInput, setDateInput] = useState(
    moment(formattedStartDate ? formattedStartDate : Date.now())
      .format("llll")
      .split(" ")
      .slice(0, 4)
      .join(" ")
  );
  const [sales, setTickets] = useState(null);
  const [ticketsDates, setTicketsDates] = useState(null);
  const [endDateInput, setEndDateInput] = useState(
    endDate === 999999
      ? moment().add(30, "day").endOf("week")
      : moment().add(30, "day").endOf("week").isBefore(moment(formattedEndDate))
      ? moment().add(30, "day").endOf("week")
      : moment(formattedEndDate)
  );
  const isSales = sales && !!sales.length;
  const isTickets = isSales ? sales.find(({ isTicket }) => isTicket) : null;
  const isVouchers = isSales
    ? sales.find(({ isTicket }) => isTicket === false)
    : null;

  useEffect(() => {
    (async () => {
      const { tickets: listingTickets } = await getTickets(id);
      const isTicketDates = listingTickets.find(({ isTicket }) => isTicket);

      if (
        (isTicketDates &&
          formattedStartDate &&
          moment().isSameOrAfter(moment(formattedStartDate))) ||
        (formattedPresaleDate &&
          moment().isSameOrAfter(moment(formattedPresaleDate)))
      )
        setTicketsDates(createDates(listingTickets, endDateInput));
      setTickets(listingTickets);
    })();
  }, []);

  const createDates = (foundTickets, stopDate) => {
    const adHours = formatSalesDays(foundTickets);
    const result = [];
    const current = ticketDateStartPosition.current.clone();

    while (current.isBefore(stopDate)) {
      const daysOfTheWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];

      daysOfTheWeek.forEach((day, idx) => {
        if (
          hours[day].isOpen &&
          adHours[day].isOpen &&
          current
            .clone()
            .day(idx)
            .isSameOrAfter(ticketDateStartPosition.current) &&
          current.clone().day(idx).isSameOrBefore(stopDate)
        )
          result.push(current.clone().day(idx));
      });
      current.day(7);
    }

    ticketDateStartPosition.current = stopDate
      .clone()
      .add(1, "day")
      .startOf("day");
    return result.map((m) => m.format("llll").split(" ").slice(0, 4).join(" "));
  };

  const handleLoadMore = () => {
    const startDatePlus30 = ticketDateStartPosition.current
      .clone()
      .add(30, "day");
    const newEndDate =
      endDate === 999999
        ? startDatePlus30
        : startDatePlus30.isBefore(moment(formattedEndDate))
        ? startDatePlus30
        : moment(formattedEndDate);

    setEndDateInput(newEndDate);
    const newTicketsDates = createDates(sales, newEndDate);
    setTicketsDates([...ticketsDates, ...newTicketsDates]);
  };

  const renderDates = (dates) =>
    dates.map((date) => (
      <p
        className={`pointer tickets-sales-multiple-dates-text ${
          date === dateInput ? "tickets-sales-multiple-dates-text-active" : ""
        }`}
        onClick={() => setDateInput(date)}
        key={date}
      >
        {date}
      </p>
    ));

  const renderTickets = () => {
    let foundSales = filterSingleDayTicketSales({
      presaleDate,
      dateAvailable,
      sales,
    });

    if (ticketsDates) {
      const foundRepeatSales = filterRepeatTicketSales({
        dateInput,
        sales,
      });
      foundSales = [...foundSales, ...foundRepeatSales];
    }

    return !foundSales || !foundSales.length ? (
      <p>No Tickets Found</p>
    ) : (
      foundSales
        .sort(({ price: priceA }, { price: priceB }) => priceA - priceB)
        .map((sale) => {
          const { _id } = sale;
          return (
            <TicketCard sale={sale} ad={ad} businessId={businessId} key={_id} />
          );
        })
    );
  };

  const renderVouchers = () => {
    console.log("test: sales ", sales);
    const foundSales = [
      ...filterSingleDayVoucherSales({
        presaleDate,
        dateAvailable,
        sales,
      }),
      ...filterRepeatVoucherSales({
        presaleDate,
        startDate,
        endDate,
        sales,
      }),
    ];

    return !foundSales || !foundSales.length ? (
      <p>No Vouchers Found</p>
    ) : (
      foundSales
        .sort(({ price: priceA }, { price: priceB }) => priceA - priceB)
        .map((sale) => {
          const { _id } = sale;
          return (
            <TicketCard sale={sale} ad={ad} businessId={businessId} key={_id} />
          );
        })
    );
  };

  return (
    isSales && (
      <div className="listing-content-container">
        {isTickets && (
          <>
            <div className="tickets-sales-title-container">
              <p className="tickets-sales-title">Tickets:</p>

              <p className="tickets-sales-text">
                {dateAvailable
                  ? formatDbDateToReadable({
                      date: dateAvailable,
                      toFullYear: true,
                      isForUser: true,
                    })
                  : dateInput}
              </p>
            </div>

            {ticketsDates && (
              <div className="no-scroll-bars tickets-sales-multiple-dates-container">
                {renderDates(ticketsDates)}
                {(endDate === 999999 ||
                  ticketDateStartPosition.current.isBefore(
                    moment(formattedEndDate)
                  )) && (
                  <p
                    className="pointer tickets-sales-multiple-dates-text tickets-sales-multiple-dates-load-more"
                    onClick={handleLoadMore}
                  >
                    Load More
                  </p>
                )}
              </div>
            )}

            <div className="ticket-sale-wrapper">{renderTickets()}</div>
          </>
        )}

        {isVouchers && (
          <>
            <div className="tickets-sales-title-container">
              <p className="tickets-sales-title">Vouchers:</p>
              <p className="tickets-sales-text" />
            </div>

            <div className="ticket-sale-wrapper">{renderVouchers()}</div>
          </>
        )}
      </div>
    )
  );
};
