import { verifyForm, shakeElement } from "../utilites/animation";
import { convertDateToDbDate } from "../utilites/date";

export const validatePassword = (password = "") => {
  const strongRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return strongRegex.test(password);
};

export const validateEmail = (email) => {
  var regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};

export const validateUrl = (url) => {
  var regex =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
  return regex.test(String(url).toLowerCase());
};

const verifyCharacterValues = (value1, value2, threshold = 80) => {
  const formatString = (string) => string.toLowerCase().match(/[a-z0-9]/gi);
  const string1 = formatString(value1);
  const string2 = formatString(value2);
  const foundArray = [];
  string1.forEach((letter) => {
    const position = string2.indexOf(letter);
    if (position > -1) {
      foundArray.push(letter);
      string2.splice(position, 1);
    }
  });
  const foundPercent = Math.round((foundArray.length / string1.length) * 100);
  return foundPercent >= threshold;
};

export const verifyYelpBusiness = (business, yelpBusiness) => {
  if (!business || !yelpBusiness) return false;
  const {
    name: busniessName,
    phone: busniessPhone,
    address: businessAddress,
  } = business;
  const {
    name: yelpName,
    phone: yelpPhone,
    location: yelpLocation,
  } = yelpBusiness;
  const { address1: yelpAddress } = yelpLocation;
  const verifiedName = verifyCharacterValues(busniessName, yelpName);
  const verifiedPhone = verifyCharacterValues(
    `1${busniessPhone}`,
    yelpPhone,
    100
  );
  const verifiedAddress = verifyCharacterValues(businessAddress, yelpAddress);
  return verifiedName && verifiedPhone && verifiedAddress;
};

export const validateBusines = (business, additionalIds = {}, formId) => {
  const {
    name,
    managerName,
    email,
    phone,
    locationType,
    address,
    city,
    state,
    zip,
    categories,
    description,
    features,
    deliveryLink,
  } = business;
  const { cat1, cat1Sub, cat2, cat2Sub } = categories || {};
  const validateIds = {
    nameInput: name,
    managerNameInput: managerName,
    emailInput: validateEmail(email),
    phoneInput: phone.length > 9,
    cat1: cat1 && cat1Sub,
    descriptionInput: description.length > 40,
    ...additionalIds,
  };
  if (cat2) validateIds.cat2 = cat2 && cat2Sub;
  if (
    locationType !== "Multiple Locations" &&
    features["Delivery"] &&
    !deliveryLink
  )
    validateIds.deliveryLink = false;
  if (locationType === "One Location") {
    validateIds.addressInput = address;
    validateIds.cityInput = city;
    validateIds.stateInput = state;
    validateIds.zipInput = zip;
  }
  const hasRequiredInfo = verifyForm(validateIds);
  if (!hasRequiredInfo) shakeElement(formId);
  return hasRequiredInfo;
};

export const isOpenDay = (days) => {
  const { hours } = days || {};
  const values = Object.values(hours);
  const isOpenFound = values.filter(({ isOpen }) => isOpen);
  return isOpenFound.length > 0;
};

export const validateBusinessCarousel = (
  business,
  additionalIds = {},
  formId,
  carouselPosition
) => {
  const {
    name,
    managerName,
    email,
    phone,
    locationType,
    address,
    city,
    state,
    zip,
    categories,
    description,
    features,
    deliveryLink,
  } = business;
  const { cat1, cat1Sub, cat2, cat2Sub } = categories || {};
  const validateIds = {};

  switch (carouselPosition) {
    case 1:
      validateIds.nameInput = name;
      validateIds.managerNameInput = managerName;
      validateIds.phoneInput = phone.length > 9;
      validateIds.emailInput = validateEmail(email);
      validateIds.cat1 = cat1 && cat1Sub;
      if (cat2) validateIds.cat2 = cat2 && cat2Sub;
      break;
    case 2:
      if (locationType === "One Location") {
        validateIds.addressInput = address;
        validateIds.cityInput = city;
        validateIds.stateInput = state;
        validateIds.zipInput = zip;
      }
      break;
    case 3:
      if (locationType === "One Location") {
        validateIds.hours = isOpenDay(business);
      }
      break;
    case 4:
      validateIds.descriptionInput = description.length > 40;
      validateIds.imageInput = additionalIds.imageInput;
      break;
    case 5:
      if (
        locationType !== "Multiple Locations" &&
        features["Delivery"] &&
        !deliveryLink
      )
        validateIds.deliveryLink = false;
      break;
  }

  const hasRequiredInfo = verifyForm(validateIds);
  if (!hasRequiredInfo) shakeElement(formId);
  return hasRequiredInfo;
};

export const validateListing = (listing, additionalIds = {}, formId) => {
  const {
    categories,
    type,
    title,
    description,
    ticketLink,
    features,
    virtualLink,
    days,
  } = listing;
  const { cat1, cat1Sub, cat1Img, cat2, cat2Sub, cat2Img } = categories || {};
  const ids = {
    typeSelect: type,
    cat1: cat1 && cat1Sub && cat1Img,
    titleInput: title,
    descriptionInput: description.trim().length > 40,
    ...additionalIds,
  };
  const currentDBDate = convertDateToDbDate();
  if (cat2) ids.cat2 = cat2 && cat2Sub && cat2Img;
  if (ticketLink) ids.ticketInput = validateUrl(ticketLink);
  if (features["Virtual"]) ids.virtualLink = validateUrl(virtualLink);
  if ("startDate" in days) {
    ids.startDateInput =
      String(days.startDate).length === 6 && days.startDate >= currentDBDate;
    ids.endDateInput =
      String(days.endDate).length === 6 && days.endDate >= days.startDate;
    ids.daysInput = isOpenDay(days);
  }
  if ("dateAvailable" in days) {
    ids.oneTimeDate =
      String(days.dateAvailable).length === 6 &&
      days.dateAvailable >= currentDBDate;
  }

  const hasRequiredInfo = verifyForm(ids);
  if (!hasRequiredInfo) shakeElement(formId);
  return hasRequiredInfo;
};

export const validateListingCarousel = (
  listing,
  additionalIds = {},
  formId,
  carouselPosition
) => {
  const {
    categories,
    type,
    title,
    description,
    ticketLink,
    features,
    virtualLink,
    days,
  } = listing;
  const { cat1, cat1Sub, cat1Img, cat2, cat2Sub, cat2Img } = categories || {};
  const currentDBDate = convertDateToDbDate();
  const validateIds = {};

  switch (carouselPosition) {
    case 1:
      validateIds.typeSelect = type;
      validateIds.cat1 = cat1 && cat1Sub;
      if (cat2) validateIds.cat2 = cat2 && cat2Sub;
      break;
    case 2:
      validateIds.titleInput = title;
      validateIds.descriptionInput = description.length > 40;
      validateIds.cat1Img = cat1Img;
      if (cat2) validateIds.cat2Img = cat2Img;
      if (ticketLink) validateIds.moreInfoInput = validateUrl(ticketLink);
      break;
    case 3:
      if ("startDate" in days) {
        validateIds.startDateInput =
          String(days.startDate).length === 6 &&
          days.startDate >= currentDBDate;
        validateIds.endDateInput =
          String(days.endDate).length === 6 && days.endDate >= days.startDate;
      }
      if ("dateAvailable" in days) {
        validateIds.oneTimeDate =
          String(days.dateAvailable).length === 6 &&
          days.dateAvailable >= currentDBDate;
      }
      break;
    case 4:
      if ("startDate" in days) {
        validateIds.daysInput = isOpenDay(days);
      }
      break;
    case 5:
      if (features["Virtual"])
        validateIds.virtualLink = validateUrl(virtualLink);
      break;
  }

  const hasRequiredInfo = verifyForm(validateIds);
  if (!hasRequiredInfo) shakeElement(formId);
  return hasRequiredInfo;
};

export const validateSalesCarousel = (data, carouselPosition) => {
  const {
    name,
    isTicket,
    redeemablePeriod,
    hours,
    price,
    quantity,
    min,
    max,
    description,
  } = data;
  const validateIds = {};

  switch (carouselPosition) {
    case 1:
      validateIds.nameInput = name;
      validateIds.priceInput = price === 0 || price > 2;
      break;
    case 2:
      if (hours) {
        validateIds.daysInput = isOpenDay({ hours });
      }
      break;
    case 3:
      validateIds.descriptionInput = description.length > 40;
      if (!isTicket) {
        validateIds.redeemablePeriod = redeemablePeriod > 0;
      }
      validateIds.quantityInput = quantity;
      validateIds.minimumQuantityInput = min;
      validateIds.maximumQuantityInput = max;
      break;
  }

  const hasRequiredInfo = verifyForm(validateIds);
  return hasRequiredInfo;
};

export const validateNewLocation = (data, carouselPosition) => {
  const { phone, address, city, state, zip, hours, images } = data;
  const validateIds = {};

  switch (carouselPosition) {
    case 1:
      validateIds.phoneInput = phone;
      validateIds.addressInput = address;
      validateIds.cityInput = city;
      validateIds.stateInput = state;
      validateIds.zipInput = zip;
      break;
    case 2:
      validateIds.locationImageInput = images.length;
      break;
    case 3:
      validateIds.hours = isOpenDay({ hours });
      break;
  }

  const hasRequiredInfo = verifyForm(validateIds);
  return hasRequiredInfo;
};

export const evaluateAvailabilityDays = (hours) =>
  Object.values(hours).filter(({ isOpen }) => isOpen).length;

export const validateSocialPost = (data) => {
  const { categories, shareDetails, description } = data;
  const { cat1Img } = categories;
  const { shareAccounts } = shareDetails || {};
  const { facebook, instagram } = shareAccounts || {};
  const validateIds = { account: facebook || instagram, cat1Img };

  if (instagram) validateIds.descriptionInput = description;

  const hasRequiredInfo = verifyForm(validateIds);
  return hasRequiredInfo;
};
