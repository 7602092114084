import React from "react";

export default () => {
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const isLargeScreen = width > 750;

  return (
    <div
      className="recommended-card-container"
      style={{ marginBottom: isLargeScreen ? "10px" : "0px" }}
    >
      <div className="recommended-skeleton-image recommended-skeleton-animation" />
    </div>
  );
};
