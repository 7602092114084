import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import moment from "moment";

// Components
import WeekdaysTimeInput from "../../components/WeekdaysTimeInput";
import Checkbox from "../common/checkbox";
import TimeSelector from "../common/time-selector";
import Map from "../leaflet-map";
import Categories from "../categories-input";
import Features from "../features-input";
import ImageUpload from "../image-upload";
import Toggle from "../common/toggle";
import SwitchButton from "../common/switch-button";

// Utilities
import {
  features as featuresModel,
  searchTypes,
} from "../../../constants/data-models";
import { userCheckin } from "../../../constants/feature-flags";
import { formatEndTime, militaryTimeToReadable } from "../../../utilites/time";
import {
  getCurrentDate,
  convertDateToDbDate,
  formatDbDateToReadable,
  plus7Days,
} from "../../../utilites/date";
import {
  isOpenDay,
  evaluateAvailabilityDays,
} from "../../../utilites/validate";
import defaultData from "../../routes/sign-up/business/data-weekdats-default";
import useDebounce from "../../../utilites/hooks/debounce";
import { getCoordinates } from "../../../api/search";
import { createSingleDayHours } from "../../../utilites/format";

// Assets
import Close from "../../../assets/svg/close";
import Location from "../../../assets/svg/location";
import Arrow from "../../../assets/svg/back";

// Styles
import "../../routes/sign-up/sign-up.css";
import "./listing-common.css";

export default ({
  page,
  user = {},
  ad,
  uniqueListingDate,
  setUpgradeModalMessage,
  handleCommonData,
}) => {
  const {
    name,
    location: userLocation,
    locationType,
    features: userFeatures = {},
    hours: businessHours,
  } = user;
  const {
    type: adtype = "",
    title = "",
    categories: adCategories,
    description = "",
    days,
    checkInRequired,
    checkInOnce = true,
    ticketLink,
    promoCode = "",
    features: adFeatures,
    virtualLink = "",
  } = ad || {};
  const {
    isBusinessHours: adIsBusinessHours,
    presaleDate: adPresaleDate = false,
    startDate,
    endDate,
    dateAvailable: adDateAvailable,
    hours: adHours,
  } = days || {};
  const currentDBDate = convertDateToDbDate();
  let isRepeatListing = true;
  let formattedDate = "";
  let startTime = 800;
  let endTime = 2000;
  if (adDateAvailable) {
    startTime = days.hours.Monday.open;
    endTime = days.hours.Monday.close;
    isRepeatListing = false;
    const dateToFiormat =
      adDateAvailable >= currentDBDate ? adDateAvailable : currentDBDate;
    const splitDate = String(dateToFiormat).split("");
    formattedDate = splitDate.reduce((acc, cur, idx) => {
      if (idx === 2 || idx === 4) {
        return `${acc}-${cur}`;
      }
      return `${acc}${cur}`;
    }, "20");
  }
  const dispatch = useDispatch();
  const [typeSelect, setTypeSelect] = useState(adtype);
  const [titleInput, setTitleInput] = useState(title);
  const [categories, setCategories] = useState(
    adCategories || {
      cat1: "",
      cat1Sub: "",
      cat1Img: "",
      cat2: "",
      cat2Sub: "",
      cat2Img: "",
    }
  );
  const [featuresInput, setFeaturesInput] = useState(
    adFeatures || userFeatures || {}
  );
  const [virtiualInput, setVirtiualInput] = useState(virtualLink);
  const [descriptionInput, setDescriptionInput] = useState(description);
  const debouncedDescriptionInput = useDebounce(descriptionInput, 250);
  const [repeatListingInput, setRepeatListingInput] = useState(isRepeatListing);
  const [checkInInput, setCheckInInput] = useState(checkInRequired);
  const [checkInOnceInput, setCheckInOnceInput] = useState(checkInOnce);
  const [dateInput, setDateInput] = useState(formattedDate || getCurrentDate());
  const [startDateInput, setStartDateInput] = useState(
    startDate
      ? formatDbDateToReadable({
          date: startDate >= currentDBDate ? startDate : currentDBDate,
          toFullYear: true,
        })
      : getCurrentDate()
  );
  const [endDateInput, setEndDateInput] = useState(
    endDate && endDate !== 999999
      ? formatDbDateToReadable({ date: endDate, toFullYear: true })
      : plus7Days()
  );
  const [isIndefinite, setIsIndefinite] = useState(
    endDate && endDate !== 999999 ? false : true
  );
  const [isMap, setIsMap] = useState(false);
  const [location, setLocation] = useState(
    ad && ad.location ? ad.location : userLocation
  );
  const [ticketInput, setTicketInput] = useState(ticketLink);
  const [promoCodeInput, setPromoCodeInput] = useState(promoCode);
  const [enteredLocation, setEnteredLocation] = useState("");
  const [isBusinessHours, setIsBusinessHours] = useState(
    adIsBusinessHours || false
  );
  const [hours, setHours] = useState(
    !adIsBusinessHours && adHours ? adHours : defaultData.hours
  );
  const [startTimeInput, setStartTimeInput] = useState(startTime);
  const [endTimeInput, setEndTimeInput] = useState(endTime);
  const [isPresale, setIsPresale] = useState(!!adPresaleDate);
  const { locationIsdefault = true, address, coordinates } = location;
  const isDiscriptionComplete = descriptionInput.length < 40;
  const isAddressRequired = locationType === "No Physical Location";
  const isCorporate = locationType === "Multiple Locations";
  const discriptionLegend = isDiscriptionComplete
    ? `${40 - descriptionInput.trim().length} more characters needed`
    : `${descriptionInput.trim().length} characters`;

  useEffect(() => {
    handleCommonData({
      typeSelect,
      categories,
      titleInput: (titleInput || "").trim().toLowerCase(),
      descriptionInput: debouncedDescriptionInput,
      featuresInput,
      days: repeatListingInput
        ? {
            rate: evaluateAvailabilityDays(
              isBusinessHours ? businessHours : hours
            ),
            presaleDate:
              ticketInput && isPresale
                ? convertDateToDbDate(
                    getCurrentDate(
                      moment(startDateInput).subtract(10, "days").format("L")
                    )
                  )
                : null,
            startDate: convertDateToDbDate(startDateInput),
            endDate: isIndefinite ? 999999 : convertDateToDbDate(endDateInput),
            isBusinessHours,
            hours: isBusinessHours ? businessHours : hours,
          }
        : {
            rate: 1,
            presaleDate:
              ticketInput && isPresale
                ? convertDateToDbDate(
                    getCurrentDate(
                      moment(dateInput).subtract(10, "days").format("L")
                    )
                  )
                : null,
            dateAvailable: convertDateToDbDate(dateInput),
            hours: createSingleDayHours(startTimeInput, endTimeInput),
          },
      checkInInput,
      checkInOnceInput,
      isBusinessHours,
      startTimeInput,
      endTimeInput,
      location: isCorporate ? {} : location,
      promoCodeInput,
      ticketInput: (ticketInput || "").trim(),
      virtiualInput: (virtiualInput || "").trim(),
    });
  }, [
    typeSelect,
    categories,
    titleInput,
    debouncedDescriptionInput,
    featuresInput,
    repeatListingInput,
    dateInput,
    isIndefinite,
    checkInInput,
    checkInOnceInput,
    isBusinessHours,
    startDateInput,
    endDateInput,
    startTimeInput,
    endTimeInput,
    hours,
    location,
    promoCodeInput,
    ticketInput,
    isPresale,
    virtiualInput,
  ]);

  const verifyTitle = (value) => {
    const allTerms = [
      "instagram",
      "promote",
      "cater",
      name.toLowerCase(),
      ...searchTypes.map((term) => term.toLowerCase().split(" ")).flat(),
    ];
    const found = allTerms.find((term) => value.toLowerCase().includes(term));
    if (found) {
      dispatch(
        stateActions.setMessage({
          messageType: "error",
          message: `Cannot use term "${found}"`,
        })
      );
      return false;
    }
    return true;
  };

  const upgradeMessage = (date) =>
    `Upgrade your account from this screen or wait till ${date} to edit the title and descritption on this ad.`;

  const handleChange = (evt, type, newValue) => {
    const { value } = evt.target;
    switch (type) {
      case "titleInput":
        if (uniqueListingDate) {
          const futureDate = moment(uniqueListingDate).add(1, "M").format("L");
          return setUpgradeModalMessage(upgradeMessage(futureDate));
        }
        const isVerifiedTitle = verifyTitle(value);
        if (isVerifiedTitle) {
          return setTitleInput(value);
        }
        break;
      case "descriptionInput":
        if (uniqueListingDate) {
          const futureDate = moment(uniqueListingDate).add(1, "M").format("L");
          return setUpgradeModalMessage(upgradeMessage(futureDate));
        }
        return setDescriptionInput(value);
      case "dateInput":
        return setDateInput(value);
      case "startDateInput":
        if (!isIndefinite && moment(value).isSameOrAfter(endDateInput)) {
          setEndDateInput(plus7Days(value));
        }
        return setStartDateInput(value);
      case "endDateInput":
        return setEndDateInput(value);
      case "startTimeInput":
        if (value >= endTimeInput) setEndTimeInput(formatEndTime(value + 50));
        return setStartTimeInput(value);
      case "endTimeInput":
        return setEndTimeInput(formatEndTime(value));
      case "checkInRequired":
        return setCheckInInput(!checkInInput);
      case "checkInOnceBox":
        return setCheckInOnceInput(newValue);
      case "location":
        return setEnteredLocation(value);
      case "ticketInput":
        return setTicketInput(value);
      case "promoCodeInput":
        return setPromoCodeInput(newValue);
      case "VirtualInput":
        return setVirtiualInput(value);
      case "typeSelect":
        return setTypeSelect(value);
      case "isBusinessHours":
        return setIsBusinessHours(newValue);
    }
  };

  const handleSwitchLengthClick = (type) => {
    const isRepeat = type === "Repeat";
    if (isRepeat) {
      setDateInput(null);
    } else {
      setDateInput(getCurrentDate());
    }
    setCheckInOnceInput(true);
    return setRepeatListingInput(isRepeat);
  };

  const handleSwitchTimesClick = (type) => {
    if (type === "Business Hours" && !isOpenDay({ hours: businessHours }))
      return dispatch(
        stateActions.setMessage({
          messageType: "error",
          message: "No Business Hours",
        })
      );
    setIsBusinessHours(type === "Business Hours");
  };

  const handleToggle = (evt, value) => {
    if (!value && moment(startDateInput).isSameOrAfter(endDateInput)) {
      setEndDateInput(plus7Days(startDateInput));
    }
    setIsIndefinite(value);
  };

  const handleFeatureClick = (feature) => {
    const value = featuresInput[feature] || false;
    if (!value) return setFeaturesInput({ ...featuresInput, [feature]: true });
    const newFeaturesInput = { ...featuresInput };
    delete newFeaturesInput[feature];
    setFeaturesInput(newFeaturesInput);
  };

  const handleLocationEnter = async (evt) => {
    evt.preventDefault();
    if (!enteredLocation) return;
    const { location } =
      (await getCoordinates({ address: enteredLocation })) || {};
    if ("coordinates" in location) {
      location.locationIsdefault = false;
      setEnteredLocation("");
      setLocation(location);
    }
  };

  const handleKeyDown = (evt) => {
    const { which, keyCode } = evt;
    const isEnter = which === 13 || keyCode === 13;
    if (isEnter) handleLocationEnter(evt);
  };

  const renderCheckInOnceBoxes = () =>
    repeatListingInput &&
    checkInInput && (
      <div className="card-info-form">
        <label className="label-form">User can claim special:</label>
        <div className="checkbox-outer-wrapper-CreateListing">
          <div className="checkbox-inner-wrapper-CreateListing">
            <label>Only Once</label>
            <Checkbox
              state={checkInOnceInput}
              handleClick={handleChange}
              params={[{ target: { value: "" } }, "checkInOnceBox", true]}
              checkbox-inner-wrapper-CreateListing
            />
          </div>
          <div className="checkbox-inner-wrapper-CreateListing">
            <label>Every Repeat</label>
            <Checkbox
              state={!checkInOnceInput}
              handleClick={handleChange}
              params={[{ target: { value: "" } }, "checkInOnceBox", false]}
            />
          </div>
        </div>
      </div>
    );

  const renderBusinessDays = () => {
    const filteredHours = Object.keys(hours).filter(
      (day) => day !== "isBusinessHours"
    );
    return filteredHours.map((day, key) => {
      const { isOpen, open, close } = businessHours[day] || {};
      return (
        <div
          className="business-hour-conatiner business-hour-conatiner-inactive"
          key={key}
        >
          <p className="business-hour-item">{day}</p>
          {isOpen ? (
            <>
              <p className="business-hour-item">
                {militaryTimeToReadable(open)}
              </p>
              <p className="business-hour-item">
                {militaryTimeToReadable(close)}
              </p>
            </>
          ) : (
            <>
              <p className="business-hour-item">Closed</p>
              <p className="business-hour-item">Closed</p>
            </>
          )}
        </div>
      );
    });
  };

  return (
    <>
      <div className="card card-form">
        <div className="card-info-form">
          <label id="typeSelect" className="label-form">
            Type:
          </label>
          <select
            className="input-style input-form select-form"
            value={typeSelect}
            onChange={(evt) => handleChange(evt, "typeSelect")}
          >
            {["", ...searchTypes].slice(0, -1).map((type, idx) => {
              const text = type ? type : "- Select -";
              return (
                <option value={type} key={idx}>
                  {text}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      <div id="titleInput" className="card card-form">
        <div className="card-info-form">
          <label className="label-form">Title:</label>
          <input
            className="input-style input-form text-form"
            type="text"
            value={titleInput}
            onChange={(evt) => handleChange(evt, "titleInput")}
            spellCheck="true"
          />
        </div>
      </div>

      <Categories
        categories={categories}
        setCategories={setCategories}
        isListing
      />

      <div id="descriptionInput" className="card card-form">
        <div className="card-info-form">
          <div
            className="legend-form-split-container"
            style={{ paddingTop: "10px" }}
          >
            <label className="label-form">Description:</label>
            <label className="label-form">
              <p
                className={`legend-form-text ${
                  isDiscriptionComplete
                    ? "legend-form-incomplete"
                    : "legend-form-complete"
                }`}
              >
                {discriptionLegend}
              </p>
            </label>
          </div>
          <textarea
            className="input-style input-form"
            rows="5"
            maxLength="1500"
            value={descriptionInput}
            onChange={(evt) => handleChange(evt, "descriptionInput")}
            spellCheck="true"
          />
        </div>
      </div>

      <div id="ticketInput" className="card card-form">
        <div className="card-info-form">
          <label className="label-form">More Information Link:</label>
          <input
            className="input-style input-form text-form"
            type="text"
            value={ticketInput}
            onChange={(evt) => handleChange(evt, "ticketInput")}
            spellCheck="true"
          />
          <legend>For additional info or external ticket sales</legend>
        </div>
      </div>

      <div className="card card-form">
        <div className="card-info-form">
          <label className="label-form">Date:</label>
          <div className="checkbox-outer-wrapper-CreateListing">
            <div className="listing-selector-container">
              <SwitchButton
                button1Name="One-Time"
                button2Name="Repeat"
                isFirst={!repeatListingInput}
                handleClick={handleSwitchLengthClick}
              />
            </div>
          </div>

          {repeatListingInput ? (
            <>
              <label id="startDateInput" className="label-form">
                Start Date:
              </label>
              <input
                className="input-style input-form text-form"
                type="date"
                value={startDateInput}
                onChange={(evt) => handleChange(evt, "startDateInput")}
                placeholder="Format: yyyy-mm-dd"
              />
              <label
                id="endDateInput"
                className="label-form"
                style={{ marginTop: "20px" }}
              >
                End Date:
                <div className="show-form-toggle">
                  <p className="show-form-toggle-text">Indefinite</p>
                  <Toggle
                    value={isIndefinite}
                    handleDebouceToggle={handleToggle}
                  />
                </div>
              </label>
              {!isIndefinite && (
                <input
                  className="input-style input-form text-form"
                  type="date"
                  value={endDateInput}
                  onChange={(evt) => handleChange(evt, "endDateInput")}
                  placeholder="Format: yyyy-mm-dd"
                />
              )}
            </>
          ) : (
            <>
              <label id="oneTimeDate" className="label-form">
                Date:
              </label>
              <input
                className="input-style input-form text-form"
                type="date"
                value={dateInput}
                onChange={(evt) => handleChange(evt, "dateInput")}
                placeholder="Format: yyyy-mm-dd"
              />
            </>
          )}

          {ticketInput && (
            <label
              id="endDateInput"
              className="label-form"
              style={{ marginTop: "20px" }}
            >
              Enable Presale:
              <Toggle
                value={isPresale}
                handleDebouceToggle={(evt, value) => setIsPresale(value)}
              />
            </label>
          )}
          <legend className="legend-form" />
        </div>
      </div>

      <div className="card card-form">
        <div className="card-info-form">
          <label className="label-form">Times:</label>
          {repeatListingInput ? (
            <>
              <div className="checkbox-outer-wrapper-CreateListing">
                <div className="listing-selector-container">
                  <SwitchButton
                    button1Name="Custom Hours"
                    button2Name="Business Hours"
                    isFirst={!isBusinessHours}
                    handleClick={handleSwitchTimesClick}
                  />
                </div>
              </div>
              {isBusinessHours ? (
                isCorporate ? (
                  <p className="use-business-hours-corporate-text">
                    Will use the indivdual locations hours of operation.
                  </p>
                ) : (
                  renderBusinessDays()
                )
              ) : (
                <>
                  <label
                    id="daysInput"
                    className="label-form"
                    style={{ marginTop: "20px" }}
                  >
                    Listing Days & Times:
                  </label>
                  <WeekdaysTimeInput
                    openLabel="Start"
                    closeLabel="End"
                    page={page}
                    hours={hours}
                    setHours={setHours}
                    isCard={false}
                  />
                </>
              )}
            </>
          ) : (
            <>
              <label className="label-form" style={{ marginTop: "25px" }}>
                Time:
              </label>
              <div className="time-selector-wrapper-CreateListing">
                <TimeSelector
                  name="startTimeInput"
                  label="Start"
                  startTime={0}
                  value={startTimeInput}
                  handleChange={handleChange}
                />
                <TimeSelector
                  name="endTimeInput"
                  label="End"
                  startTime={startTimeInput}
                  value={endTimeInput}
                  handleChange={handleChange}
                />
              </div>
            </>
          )}
        </div>
      </div>

      {userCheckin && (
        <div className="card card-form">
          <div className="card-info-form">
            <label className="label-form">User Check-In Required:</label>
            <div className="checkbox-outer-wrapper-CreateListing">
              <div className="checkbox-inner-wrapper-CreateListing" />
              <div className="checkbox-inner-wrapper-CreateListing">
                <Checkbox
                  state={checkInInput}
                  handleClick={handleChange}
                  params={[{ target: { value: "" } }, "checkInRequired", false]}
                />
              </div>
            </div>
            {renderCheckInOnceBoxes()}
          </div>
        </div>
      )}

      <Features
        options={[...featuresModel, "Virtual"]}
        values={featuresInput}
        handleClick={handleFeatureClick}
        linkValue={virtiualInput}
        handleChange={handleChange}
        isVirtualOption
      />

      {!isCorporate && (
        <div className="card card-form">
          <div className="card-info-form">
            <div
              className="signup-toggle-split-container"
              onClick={() => (isAddressRequired ? null : setIsMap(!isMap))}
            >
              <label id="locationInput" className="label-form">
                Location:
              </label>
              <div
                className={`signup-toggle-subsection-svg-container ${
                  isMap ? "signup-toggle-subsection-svg-container-open" : ""
                }`}
              >
                {!isAddressRequired && <Arrow />}
              </div>
            </div>

            <div
              className={`show-form-toggle signup-toggle-subsection ${
                isMap || isAddressRequired
                  ? "signup-toggle-subsection-open"
                  : ""
              }`}
            >
              <div style={{ height: `300px`, width: "100%" }}>
                <Map
                  mapCenter={coordinates}
                  coordinates={coordinates}
                  defaultZoom={12}
                  isMarkerShown={false}
                  status="active"
                />
              </div>
              {!isAddressRequired && (
                <p className="label-form">If held at a different location</p>
              )}
              <div className="input-style input-form text-form listingCommon-location-conatiner">
                {!locationIsdefault && (
                  <div
                    className="pointer listingCommon-close-svg-conatiner"
                    onClick={() => setLocation(userLocation)}
                  >
                    <Close />
                  </div>
                )}
                <input
                  className="listingCommon-location-input"
                  placeholder={address ? address : "Enter full address"}
                  type="text"
                  value={enteredLocation}
                  onKeyDown={handleKeyDown}
                  onChange={(evt) => handleChange(evt, "location")}
                  spellCheck="true"
                />
                <div
                  className="pointer listingCommon-location-button"
                  onClick={handleLocationEnter}
                >
                  <div className="listingCommon-location-svg-conatiner">
                    <Location />
                  </div>
                  <p>FIND</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div id="ImageInputContainer" className="card card-form">
        <div className="card-info-form">
          <label className="label-form">Promo Code :</label>
          <div className="time-selector-wrapper-CreateListing">
            <ImageUpload
              text={"add code"}
              type={"promoCodeInput"}
              imageInput={promoCodeInput}
              handleChange={handleChange}
              isPromoCode
            />
          </div>
          <legend className="legend-form">
            Upload QR or bar code code if needed to redeem.
          </legend>
        </div>
      </div>
    </>
  );
};
