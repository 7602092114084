import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import * as userActions from "../../../redux/actions/user-actions";

// Components
import ViewLayout from "../../components/view-layout";
import Button from "../../components/common/button";
import Modal from "../../components/modal";

// Utilities
import { toTitleCase, formatPhoneNumber } from "../../../utilites/format";
import { deleteImage } from "../../../api/image";
import { deleteCorporate } from "../../../api/corporate";

// Assets
import Trash from "../../../assets/svg/trash";

// Styles
import "./corporate-business-styles.css";

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.user);
  const listingToBeDeleted = useRef(null);
  const [modalMessage, setModalMessage] = useState("");
  const {
    _id: mainBusinessId,
    locations,
    images: userImages = [],
  } = user || {};

  const confrimDelete = (evt, location) => {
    evt.stopPropagation();
    const { address } = location;
    listingToBeDeleted.current = location;
    setModalMessage(`Confirm deleting the business located at ${address}`);
  };

  const handleDelete = async () => {
    dispatch(stateActions.setLoader(true));
    const { _id: id, images: currImages = [] } = listingToBeDeleted.current;
    const userImage = userImages[0] || "";
    const currentImage = currImages[0] || "";

    if (userImage !== currentImage) await deleteImage(currentImage);

    const { locations } = (await deleteCorporate({ mainBusinessId, id })) || {};
    if (locations) dispatch(userActions.updateUser({ locations }));
    listingToBeDeleted.current = null;
    setModalMessage("");
    dispatch(stateActions.setLoader(false));
  };

  const renderLocations = () =>
    !!locations &&
    !!locations.length &&
    locations.map((location) => {
      const { _id, address, city, state, zip, phone, images } = location;
      const image = images && images.length ? images[0] : "";
      return (
        <div
          className="corporate-location-container"
          style={{ backgroundImage: `url(${image})` }}
          onClick={() => history.push(`/business/corporate/edit/${_id}`)}
        >
          <div className="corporate-overlay">
            <div className="corporate-metadata-container">
              <p className="corporate-metadata-text">
                {toTitleCase({ input: address })}
              </p>
              <div className="corporate-state-container">
                <p className="corporate-metadata-text">
                  {toTitleCase({ input: city })}
                </p>
                <p className="corporate-metadata-text">{`${state.toUpperCase()},`}</p>
                <p className="corporate-metadata-text">{zip}</p>
              </div>
              <p className="corporate-metadata-text">
                {formatPhoneNumber({ phone })}
              </p>
            </div>
            <div className="corporate-options-container">
              <div
                className="corporate-delete-container"
                onClick={(evt) => confrimDelete(evt, location)}
              >
                <Trash />
              </div>
            </div>
          </div>
        </div>
      );
    });

  return (
    <>
      <ViewLayout>
        <div className="corporate-location--wrapper">
          <div className="card card-form created-ticket-card">
            <div className="card-info-form">
              <div className="corporate-title-container">
                <h2>MANAGE LOCATIONS</h2>
              </div>
              <div
                className="legend-form-split-container"
                style={{ paddingTop: "20px", paddingLeft: "20px" }}
              >
                <p className="corporate-total-text">
                  Locations:{" "}
                  <span className="corporate-total-highlight-text">
                    ({(locations && locations.length) || 0})
                  </span>
                </p>
                <Button
                  text="Add Location"
                  handleClick={() => history.push("/business/corporate/create")}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="corporate-location--wrapper">{renderLocations()}</div>
      </ViewLayout>

      <Modal
        directModalMessage={modalMessage}
        modalAccept="confirm"
        modalCancel="Cancel"
        handleCancelClick={() => {
          listingToBeDeleted.current = null;
          setModalMessage("");
        }}
        handleAcceptClick={handleDelete}
      />
    </>
  );
};
