import { useSelector } from "react-redux";

// Utilities
import { isDev } from "../status";
import { getLocation } from "../location";

export default function useAnalytics() {
  const {
    location: enteredLocation,
    geoLocation,
    user,
  } = useSelector((store) => store.user);
  const location = getLocation(enteredLocation, geoLocation, user);
  return isDev() ? null : location;
}
