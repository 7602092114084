import React, { useRef, useState } from "react";

// utilies
import { isSafariAgent, isMobileAgent } from "../../../../utilites";

// Assets
import Button from "../../../components/common/button";
import getFeatureIcon from "../../../components/features-input/getFeatureIcon";

// Styles
import "./business-start-up-styles.css";

export default () => {
  const carousel = useRef();
  const isMobile = isMobileAgent();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [listingFlowIndex, setListingFlowIndex] = useState(0);
  const isSafairMobile = isSafariAgent() && isMobile;
  const height = `calc(100vh - ${isSafairMobile ? 150 : 40}px`;
  const listingFlowData = [
    {
      title: "INNOVATIVE PLATFORM",
      image: "/img/home-screen.png",
      index: 0,
      nextIndex: 1,
    },
    {
      title: "FOR LOCAL BUSINESSES",
      image: "/img/list-view.png",
      index: 1,
      nextIndex: 2,
    },
    {
      title: "TO REACH NEW CUSTOMERS",
      image: "/img/map-view.png",
      index: 2,
      nextIndex: 3,
    },
    {
      title: "WITH ENGAGING POSTS",
      image: "/img/listing.png",
      index: 3,
      nextIndex: 4,
    },
    {
      title: "TO DISCOVER YOUR BUSINESS",
      image: "/img/business.png",
      index: 4,
      nextIndex: 0,
    },
  ];

  const slideTo = (index) => {
    if (index > -1 && index < 3) {
      const carouselElememt = carousel ? carousel.current : null;
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      const position = width * index;
      if (carouselElememt)
        carouselElememt.style.transform = `translate(-${position}px, 0)`;
      setListingFlowIndex(0);
      setCurrentIndex(index);
    }
  };

  return (
    <div className="full-page-carousel-wrapper" style={{ height }}>
      <img
        className="carousel-page-background-image"
        alt="EventHound Concert"
        src="/img/food.jpg"
      />

      {!isMobile && (
        <ul className="carousel-pagination">
          {[0, 1, 2].map((idx) => (
            <li
              className={`carousel-bullet ${
                idx === currentIndex ? "carousel-bullet-active" : ""
              }`}
              onClick={() => {
                setListingFlowIndex(0);
                slideTo(idx);
              }}
              key={idx}
            />
          ))}
        </ul>
      )}

      <ul
        ref={carousel}
        className="carousel-container"
        style={{ width: "300%" }}
      >
        <li className="carousel-item" style={{ height }}>
          <div className="carousel-item-content-container">
            <p className="carousel-sub-title color-primary">
              Post Your Events, Promotions & Deals With EventHound!
            </p>
            <div className="carousel-video-container">
              <video
                width="100%"
                height="100%"
                poster="/img/Video Thumbnail.png"
                controls
              >
                <source src="/img/eventhound intro.mp4" type="video/mp4" />
              </video>
            </div>
            <p className="carousel-text" style={{ marginTop: "15px" }}>
              Welcome to EventHound, we look forward to being your favorite
              promotional assistant.
            </p>

            <ul className="carousel-list-container">
              <li className="carousel-list-item">
                FREE service - No terms, no conditions
              </li>
              <li className="carousel-list-item">
                Trusted by more than 2000 businesses
              </li>
              <li className="carousel-list-item">Over 100k monthly users</li>
              <li className="carousel-list-item">
                Benefiting all businesses - From pop-ups to corporate &
                everything in between
              </li>
            </ul>

            <div className="carousel-button-container">
              <Button
                text="How to post"
                styles={{ margin: "5px" }}
                handleClick={() => {
                  setListingFlowIndex(0);
                  slideTo(1);
                }}
              />
            </div>
          </div>

          <div className="carousel-item-content-container">
            <h2 className="carousel-title">PROUD PARTNERS WITH</h2>
            <div className="welcome-parnter-image-wrapper">
              <img
                className="welcome-parnter-image"
                alt="Facebook"
                src={`/img/facebook-name.png`}
              />
              <img
                className="welcome-parnter-image"
                alt="Instagram"
                src={`/img/instagram-name.png`}
              />
              <img
                className="welcome-parnter-image"
                alt="Ticketmaster"
                src={`/img/ticketmaster-name.png`}
              />
              <img
                className="welcome-parnter-image"
                alt="Yelp"
                src={`/img/yelp.png`}
              />
            </div>
          </div>
        </li>

        <li className="carousel-item" style={{ height }}>
          <div className="carousel-item-content-container">
            <p className="carousel-sub-title color-primary">
              Promote Your Business In 2 Minutes!
            </p>

            <div className="carousel-video-container">
              <video width="100%" height="100%" preload="metadata" controls>
                <source
                  src="/img/eventhound onboarding.mp4#t=0.1"
                  type="video/mp4"
                />
              </video>
            </div>

            <p className="carousel-text" style={{ marginTop: "15px" }}>
              Posting to EventHound is quick and easy with set it and forget it
              options that leave you worry-free. All posts go live instantly and
              will be seen by users in real-time. This means only relevant posts
              will be discovered, making EventHound a go-to source for trusted
              local information.
            </p>

            <ul className="carousel-list-container">
              <li className="carousel-list-item">No skills required</li>
              <li className="carousel-list-item">Unlimited postings</li>
              <li className="carousel-list-item">
                Set it and forget marketing
              </li>
              <li className="carousel-list-item">Social media integration</li>
              <li className="carousel-list-item">Ticket link options</li>

              <li className="carousel-list-item">
                Location based promoting - pop-ups, etc.
              </li>
            </ul>

            <div className="carousel-button-container">
              <Button
                text="Who we are"
                styles={{ margin: "5px" }}
                handleClick={() => slideTo(2)}
              />
            </div>
          </div>
        </li>

        <li className="carousel-item" style={{ height }}>
          <div className="carousel-item-content-container">
            <p className="carousel-sub-title color-primary">
              {
                listingFlowData[listingFlowIndex > -1 ? listingFlowIndex : 0]
                  .title
              }
            </p>

            <div className="listing-flow-wrapper">
              <div className="listing-flow-conatiner">
                <img
                  className="listing-flow-image listing-flow-image-placeholder"
                  alt="EventHound - Listing Fow"
                  src="/img/listing_flow_1.png"
                />
                <div className="listing-flow-image-shadow" />

                {listingFlowData.map(({ image, index, nextIndex }) => (
                  <img
                    className={`listing-flow-image listing-flow-image-${index} ${
                      index === 0 || listingFlowIndex === index
                        ? "listing-flow-image-show"
                        : ""
                    }`}
                    alt="EventHound - Listing Fow"
                    src={image}
                    onClick={() => setListingFlowIndex(nextIndex)}
                    key={image}
                  />
                ))}
              </div>
            </div>

            <div className="business-onboarding-arrow-outer">
              <div className="business-onboarding-arrow-inner">
                {getFeatureIcon("skinny arrow")}
              </div>
            </div>

            <p className="business-onboarding-sub-title">
              Click The Phone To See More
            </p>

            <p className="carousel-text" style={{ marginTop: "5px" }}>
              EventHound, headquartered in Los Angeles, is an innovative mobile
              networking platform that connects businesses directly to the
              community. Targeting food, drinks, activities and events, our
              tagline “Find Your Fun”, is in essence, what we are about.
            </p>
            <p className="carousel-text" style={{ marginTop: "10px" }}>
              In these industries, specials and promotions are commonly marketed
              on-site or with social media, but nowhere else. With a
              business-first approach, EventHound grants you full access to post
              what you want, when you want, with instant results. This provides
              limitless options to showcase what you do best! Engaging locals
              and travelers alike with promotions they were unaware of, driving
              new clientele to your business.
            </p>
            <p className="carousel-text" style={{ marginTop: "15px" }}>
              What are you waiting for? Take part and start posting now!
            </p>
            <div className="carousel-button-container">
              <a className="carousel-button" href="/" target="blank">
                VIEW WEBSITE
              </a>
              <a
                className="carousel-button"
                href="/business/sign-up"
                target="blank"
              >
                START POSTING
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};
