import React, { useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./redux/store";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import lottie from "lottie-web";
import { isDev } from "./utilites/status";

// Components
import App from "./app/app-router/App";
import Button from "./app/components/common/button";

// Assets
import Error from "./assets/lotti/error.json";

// Universal styles
import "./styles.css";
import "./styles-transitions.css";

if (!isDev())
  Bugsnag.start({
    apiKey: "9f94c101969e91ff977b55196db02e85",
    plugins: [new BugsnagPluginReact()],
  });

const ErrorBoundary = isDev()
  ? null
  : Bugsnag.getPlugin("react").createErrorBoundary(React);

const ErrorView = () => {
  const errorLotti = useRef();

  useEffect(() => {
    lottie.loadAnimation({
      container: errorLotti.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
      animationData: Error,
    });
  }, []);

  return (
    <div className="error-wrapper">
      <div ref={errorLotti} className="error-animation-container" />
      <p className="error-description">
        Sorry you found a bug. It has been reported to our team and we will
        squash it soon.
      </p>
      <Button
        text="refresh"
        handleClick={() => window.open("https://eventhound.com/")}
      />
    </div>
  );
};

const WrappedApp = () =>
  isDev() ? (
    <Provider store={store}>
      <App />
    </Provider>
  ) : (
    <ErrorBoundary FallbackComponent={ErrorView}>
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorBoundary>
  );

Amplify.configure(awsconfig);
ReactDOM.render(<WrappedApp />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
