import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from "../../../../redux/actions/user-actions";
import moment from "moment";

// Components
import ViewLayout from "../../../components/view-layout";
import Toggle from "../../../components/common/toggle";
import Button from "../../../components/common/button";
import SalesCards from "./sales-card";

// Utilities
import Debounce from "../../../../utilites/hooks/debounce";
import {
  convertDateToDbDate,
  getCurrentDate,
  formatDbDateToReadable,
} from "../../../../utilites/date";
import { toTitleCase } from "../../../../utilites/format";
import { updateAdInAds } from "../../../../utilites/update";
import { updateListing } from "../../../../api/listing";

// Styles
import "../sales-business-styles.css";

export default ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = match.params || {};
  const { ads = [], tickets } = useSelector((store) => store.user.user);
  const ad = ads.find(({ _id }) => _id === id);
  const { title, days } = ad || {};
  const { presaleDate = false, startDate, dateAvailable } = days || {};
  const [isPresale, setIsPresale] = useState(!!presaleDate);
  const debouncedPresaleValue = Debounce(isPresale, 200);
  const adTickets = tickets.filter(({ listingId }) => id === listingId);
  const isAdTickets = !!adTickets.filter(
    ({ isActive, listingId, isTicket }) =>
      isActive && listingId === id && isTicket === true
  ).length;
  const isAdVouchers = !!adTickets.filter(
    ({ isActive, listingId, isTicket }) =>
      isActive && listingId === id && isTicket === false
  ).length;
  const singleDateText = dateAvailable
    ? ` on ${formatDbDateToReadable({ date: dateAvailable, isForUser: true })}`
    : "";
  const isNoTickets = !adTickets || (adTickets && !adTickets.length);

  useEffect(() => {
    if (
      (debouncedPresaleValue && !presaleDate) ||
      (!debouncedPresaleValue && presaleDate)
    ) {
      (async () => {
        const { ad: updatedAd } = await updateListing({
          ...ad,
          days: {
            ...days,
            presaleDate: debouncedPresaleValue
              ? convertDateToDbDate(
                  getCurrentDate(
                    moment(
                      formatDbDateToReadable({
                        date: startDate || dateAvailable,
                        toFullYear: true,
                        isForUser: true,
                      })
                    )
                      .subtract(10, "days")
                      .format("L")
                  )
                )
              : null,
          },
        });

        if (updatedAd) {
          dispatch(userActions.setAds(updateAdInAds(ads, updatedAd)));
        }
      })();
    }
  }, [debouncedPresaleValue]);

  return (
    <ViewLayout id="create-corporate-profile">
      <div className="corporate-location--wrapper">
        <div className="card card-form created-ticket-card">
          <div className="card-info-form">
            <div className="tickets-title-container">
              <h2 className="tickets-title-text">SALES FOR</h2>
              <p className="tickets-subtitle-text" style={{ marginTop: "5px" }}>
                {`${toTitleCase({ input: title })}${singleDateText}`}
              </p>

              {isNoTickets && (
                <div className="created-ticket-title-container">
                  <p
                    className="created-ticket-no-ticket"
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      textAlign: "center",
                    }}
                  >
                    {`No sales items for - ${toTitleCase({ input: title })}`}
                  </p>
                  <p></p>
                </div>
              )}

              {(isAdTickets || isAdVouchers) && (
                <label
                  className="tickets-presale-container"
                  style={{ marginTop: "15px" }}
                >
                  Presale Items:
                  <Toggle
                    value={isPresale}
                    handleDebouceToggle={(evt, value) => setIsPresale(value)}
                  />
                </label>
              )}

              <div
                className="ticket-form-split-container"
                style={{ marginTop: "15px" }}
              >
                <p className="ticket-text">Create a new sales item</p>
                <Button
                  className="submit-button-CreateListing"
                  text="sell item"
                  handleClick={() =>
                    history.push(`/business/sales/create/${id}`)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="corporate-location--wrapper">
        <SalesCards
          adId={id}
          adTickets={adTickets}
          isAdTickets={isAdTickets}
          isAdVouchers={isAdVouchers}
          tickets={tickets}
        />
      </div>
    </ViewLayout>
  );
};
