import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 35 40"
    >
      <path
        d="M9.4,11.8L2.9,5.8c-1.2-1.1-1.2-3,0-4.1c1.2-1.1,3.3-1.1,4.5,0l10.5,9.6c2.2-1.2,4.2,0.5,5.7,1.9c1.7,1.5,3.2,2.9,1.6,5.1
		c-0.4,0.5-0.5,0.9-0.3,1.5c0.2,0.6,0.7,1.4,1.4,2.3l7.7,7c0.1,0.1,0.1,0.3,0,0.4l-10.7,9.7c-0.1,0.1-0.3,0.1-0.4,0l-7.7-7
		c-3.1,0.5-6,0.1-8.4-1.7c-0.3-0.1-0.5-0.3-0.7-0.5L1.7,26c-1.2-1.1-1.2-2.8,0-3.9c0.7-0.6,1.6-0.9,2.5-0.8
		c-1.3-1.2-1.7-2.8-0.3-4.1c0.7-0.6,1.6-0.9,2.5-0.8c-1.3-1.1-1.5-2.8-0.2-4C7.1,11.7,8.3,11.5,9.4,11.8z M8.2,17.2l3.7,3.4
		c1,0.9,2.5,0.8,3.4-0.1c-0.9,0-1.7-0.3-2.3-0.8c-1.2-1.1-1.2-3,0-4.1l0.1,0l-3-2.7c-0.9-0.8-2.4-0.8-3.4,0c-0.9,0.8-0.9,2.2,0,3.1
		L8.2,17.2z M13.1,21.7c0.7,1.1,0.6,2.5-0.4,3.4c-0.7,0.6-1.6,0.9-2.5,0.8l0.3,0.2c1.2,1.1,1.2,2.8,0,3.9c-0.5,0.5-1.2,0.7-1.9,0.8
		c2.1,1,4.4,1.2,6.7,0.9c0.1-0.1,0.3-0.1,0.4,0l7.6,6.9l10.2-9.3l-7.5-6.8c-0.7-0.8-1.3-1.6-1.6-2.6c-0.2-0.8-0.1-1.4,0.4-1.9
		c1.4-1.9-0.1-3-1.5-4.4c-1.3-1.2-3.3-2.9-5.1-1.7c-0.1,0.1-0.2,0.2-0.3,0.3L14,15.6c-0.2,0.2-0.3,0.3-0.5,0.4c-1,0.9-1,2.4,0,3.3
		c1,0.9,2.6,0.9,3.6,0l2.9-2.6c0.3-0.3,0.7,0.1,0.4,0.4l-2.5,2.3c-0.3,0.7-0.3,1.5,0.2,2.4c0.5,1,1.5,2.1,2.9,3.3
		c0.3,0.3-0.1,0.7-0.4,0.4c-1.5-1.3-2.5-2.4-3-3.5c-0.4-0.7-0.5-1.4-0.4-2c-0.3,0.2-0.6,0.3-0.9,0.4C15.6,21.4,14.3,21.9,13.1,21.7z
		 M10,29.6c0.9-0.8,0.9-2.2,0-3.1l-1.5-1.4l-2.9-2.7c-0.9-0.8-2.4-0.8-3.4,0c-0.9,0.8-0.9,2.2,0,3.1l4.5,4.1
		C7.5,30.4,9.1,30.4,10,29.6z M17.4,11.7L6.9,2.2c-1-0.9-2.6-0.9-3.6,0c-1,0.9-1,2.4,0,3.3L13.8,15l3.5-3.2L17.4,11.7z M4.4,17.6
		c-0.9,0.8-0.9,2.2,0,3.1c1.5,1.4,3,2.7,4.5,4.1c0.9,0.8,2.4,0.8,3.4,0c0.9-0.8,0.9-2.2,0-3.1L11.5,21l-3.7-3.4
		C6.8,16.8,5.3,16.8,4.4,17.6z"
      />
    </svg>
  );
};
