import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { useDispatch } from "react-redux";
import * as stateActions from "../../../../redux/actions/state-actions";

// Components
import ListingSkeleton from "../listing-skeleton";

// Utilities
import { listingGet } from "../../../../api/listing";
import { businessGet } from "../../../../api/business";
import { getStubhubVenue, getStubhubDetails } from "../../../../api/stubhub";
import { getTicketMasterDetails } from "../../../../api/ticketMaster";
import { growthGet } from "../../../../api/growth";

export default (WrappedComponent) => {
  const Component = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { match, results } = props || {};
    const { id: currLessonId } = match.params || {};
    const idSplit = currLessonId.split("_");
    const isStubHub = idSplit[0] === "stubhub";
    const isTicketMaster = idSplit[0] === "ticketmaster";
    const [ad, setAd] = useState(false);
    const [business, setBusiness] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const showComponent = !!(isLoaded && ad && business);

    useEffect(() => {
      const { _id } = ad || {};
      if (_id === currLessonId) return;
      const foundAd = results.find(({ _id }) => _id === currLessonId);

      if (foundAd) {
        const { isGrowth, stubhubId, ticketmasterId } = foundAd || {};

        if (isGrowth || isTicketMaster || ticketmasterId) {
          setBusiness({});
          setAd(foundAd);
          setInProgress(false);
          setIsLoaded(true);
        } else if (isStubHub || stubhubId) {
          addStubHubVenue(foundAd);
        } else {
          getBusiness(foundAd);
        }
      } else {
        if (isStubHub) {
          getStubHub(true);
        } else if (isTicketMaster) {
          getTicketMaster(true);
        } else {
          getData();
        }
      }
    }, [currLessonId, ad]);

    const notFound = () => {
      dispatch(
        stateActions.setMessage({
          message: "Not Found",
          messageType: "error",
        })
      );
      return history.push("/");
    };

    const addStubHubVenue = async (ad) => {
      if (!inProgress) {
        const { venueId } = ad;
        const { location } = (await getStubhubVenue(venueId)) || {};
        if (location) ad = { ...ad, ...location };

        setBusiness({});
        setAd(ad);
        setInProgress(false);
        setIsLoaded(true);
      }
    };

    const getStubHub = async (isFound = true) => {
      if (!inProgress) {
        setIsLoaded(false);
        setInProgress(true);

        const stubhubId = idSplit[isFound ? 1 : 0];
        const { ad } = (await getStubhubDetails(stubhubId)) || {};
        if (!ad) return getTicketMaster(isFound);

        setBusiness({});
        setAd(ad);
        setInProgress(false);
        setIsLoaded(true);
      }
    };

    const getTicketMaster = async (isFound = true) => {
      if (!inProgress) {
        setIsLoaded(false);
        setInProgress(true);

        const ticketId = idSplit[isFound ? 1 : 0];
        const { ad } = (await getTicketMasterDetails(ticketId)) || {};
        if (!ad) return notFound();

        setBusiness({});
        setAd(ad);
        setInProgress(false);
        setIsLoaded(true);
      }
    };

    const getGrowth = async () => {
      const { ad } = (await growthGet(currLessonId)) || {};
      if (!ad) return getStubHub(false);

      setBusiness({});
      setAd(ad);
      setInProgress(false);
      setIsLoaded(true);
    };

    const getBusiness = (ad) =>
      new Promise(async (resolve) => {
        const { businessId: adBusinessId } = ad || {};
        const { id: businessId } = business || {};

        if (!business || adBusinessId !== businessId) {
          const { user } = (await businessGet(adBusinessId)) || {};
          if (!user) return notFound();

          setBusiness(user);
          setAd(ad);
          setInProgress(false);
          setIsLoaded(true);
        }
        resolve();
      });

    const getData = async () => {
      if (!inProgress) {
        setIsLoaded(false);
        setInProgress(true);

        const { ad } = (await listingGet(currLessonId)) || {};

        if (ad) {
          getBusiness(ad);
        } else {
          getGrowth();
        }
      }
    };

    return showComponent ? (
      <CSSTransition in={showComponent} timeout={250} classNames="fade">
        <WrappedComponent
          {...props}
          ad={ad}
          business={business}
          setAd={setAd}
        />
      </CSSTransition>
    ) : (
      <ListingSkeleton />
    );
  };
  return Component;
};
