import React from "react";

export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
    >
      <path
        d="M83.9,4.8H16.1c-7.4,0-13.4,6-13.4,13.4v49.7c0,7.4,6,13.4,13.4,13.4h20.8L50,95.2l13.1-13.8h20.8c7.4,0,13.4-6,13.4-13.4
	V18.3C97.3,10.8,91.3,4.8,83.9,4.8z M70.8,44.5L50,65.3L29.2,44.5c-5.4-5.4-5.4-14.2,0-19.6s14.2-5.4,19.6,0l1.2,1.2l1.2-1.2
	c5.4-5.4,14.2-5.4,19.6,0S76.2,39.1,70.8,44.5z"
      />
    </svg>
  );
};
