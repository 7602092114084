import React, { useState } from "react";

// Assets
import Privacy from "../../../../assets/svg/privacy";

// Styles
import "./text-input-underline.css";

const TextInput = ({
  type: paramsType = "text",
  placeholder,
  value,
  handleChange,
  children,
  handleEnter,
}) => {
  const [type, setType] = useState(paramsType);
  const isPassword = placeholder === "Password";
  const isText = type === "text";

  const handleKeyDown = (evt) => {
    const { which, keyCode } = evt;
    const isEnter = which === 13 || keyCode === 13;
    if (isEnter) handleEnter();
  };

  const handlePrivacy = () => (isText ? setType("password") : setType("text"));

  return (
    <div className="text-input-container">
      {children && <div className="text-input-svg-container">{children}</div>}
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
      />
      <span className="text-input-bar" />
      {isPassword && (
        <div
          className={`input-privacy-container ${
            isText ? "input-privacy-container-show" : ""
          }`}
          onClick={handlePrivacy}
        >
          <Privacy />
        </div>
      )}
    </div>
  );
};

export default TextInput;
