import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as userActions from "../../../../redux/actions/user-actions";

// Components
import Toggle from "../../../components/common/toggle";
import TextExpand from "../../../components/common/text-expand";

// Utilities
import Debounce from "../../../../utilites/hooks/debounce";
import { toTitleCase } from "../../../../utilites/format";
import { editTickets } from "../../../../api/tickets";

// Styles
import "../sales-business-styles.css";

export default ({ adId, adTickets, isAdTickets, isAdVouchers, tickets }) => {
  const history = useHistory();

  return adTickets.map(
    ({
      _id,
      isActive: isActiveProp,
      isTicket,
      name,
      min,
      max,
      price,
      description,
    }) => {
      const dispatch = useDispatch();
      const sold = 10;
      const totalSoldAmount = Number(price) * sold;
      const [toggleValue, setToggleValue] = useState(isActiveProp);
      const debouncedToggleValue = Debounce(toggleValue, 200);
      const isAvailable =
        (isTicket && !isAdVouchers) || (!isTicket && !isAdTickets);

      const handleToggle = (evt, value) => {
        setToggleValue(value);
      };

      useEffect(() => {
        if (isActiveProp !== debouncedToggleValue) {
          (async () => {
            const updateTicket = {
              _id,
              isActive: debouncedToggleValue,
            };
            const { ticket } = (await editTickets(updateTicket)) || {};

            if (ticket) {
              const newTickets = tickets.map((thisTicket) => {
                const { _id: ticketId } = thisTicket;
                if (_id === ticketId) return ticket;
                return thisTicket;
              });
              dispatch(userActions.updateUser({ tickets: newTickets }));
            }
          })();
        }
      }, [debouncedToggleValue]);

      return (
        <div
          className={`card card-form created-ticket-card ${
            isAvailable ? "pointer created-ticket-card-hover" : ""
          }`}
          onClick={() => history.push(`/business/sales/edit/${adId}/${_id}`)}
        >
          <div className="card-info-form" style={{ padding: "0px 5px" }}>
            {!isAvailable && (
              <div className="created-ticket-not-available-container">
                <p className="created-ticket-not-available-text">
                  {`${isTicket ? "Tickets" : "Vouchers"} Not Available`}
                </p>
              </div>
            )}

            <div className="created-ticket-title-container">
              <p className="created-ticket-title">
                {toTitleCase({ input: name })}
              </p>
              {isAvailable && (
                <Toggle
                  value={toggleValue}
                  handleDebouceToggle={handleToggle}
                />
              )}
            </div>

            <div className="created-ticket-title-container">
              <p className="created-ticket-time">{`Type: ${
                isTicket ? "Ticket" : "Voucher"
              }`}</p>
              <p className="created-ticket-time">
                {price === 0 ? "Free" : `$${price}.00`}
              </p>
            </div>

            <div className="created-ticket-title-container">
              <p className="created-ticket-time">{`Min/Max: ${min}/${max}`}</p>
              <p
                className="created-ticket-time"
                style={{ color: "green" }}
              >{`Revenue: ${(totalSoldAmount - totalSoldAmount * 0.13).toFixed(
                2
              )}`}</p>
            </div>

            <TextExpand
              text={description}
              constainerStyle={{ marginTop: "7px" }}
            />
          </div>
        </div>
      );
    }
  );
};
