import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import lottie from "lottie-web";
import moment from "moment";

// Utilites
import { convertDateToDbDate } from "../../../../../utilites/date";

// Assets
import Welcome from "../../../../../assets/lotti/welcome.json";
import EventHound from "../../../../../assets/svg/eventHound-name";

// Styles
import "./welcome-banner.css";

export default ({ name }) => {
  const welcomeLotti = useRef();
  const { user } = useSelector((store) => store.user);
  const { createdDate } = user || {};
  const today = convertDateToDbDate();
  const { welcomeDate } = window.localStorage || {};
  const todaysDate = moment().format();
  const hasWelcomedToday = welcomeDate
    ? moment(todaysDate).isSame(welcomeDate, "d")
    : false;
  const isFirstDay = Number(createdDate) === today;

  useEffect(() => {
    if (isFirstDay || !hasWelcomedToday) {
      lottie.loadAnimation({
        container: welcomeLotti.current,
        renderer: "svg",
        loop: false,
        autoplay: true,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
        animationData: Welcome,
      });
    }
    localStorage.setItem("welcomeDate", todaysDate);
  }, []);

  return (
    (isFirstDay || !hasWelcomedToday) && (
      <div className="welcome-wrapper">
        <div ref={welcomeLotti} className="welcome-animation" />
        <p className="welcome-to-text">{isFirstDay ? "To" : "Back"}</p>
        {isFirstDay ? (
          <div className="welcome-logo">
            <EventHound />
          </div>
        ) : (
          <p className="welcome-name">{name}</p>
        )}
      </div>
    )
  );
};
