import React from "react";

// Utilities
import { toTitleCase } from "../../../../utilites/format";

// Styles
import "./social-styles.css";

const Social = ({ business }) => {
  const { name, facebook, instagram } = business;
  const isSocial = !!facebook || !!instagram;
  const facebookUrl =
    facebook && facebook.indexOf("facebook.com") > 0
      ? facebook
      : `https://www.facebook.com/${facebook}`;
  const instagramUrl =
    instagram && instagram.indexOf("instagram.com") > 0
      ? instagram
      : `https://www.instagram.com/${instagram}`;

  return isSocial ? (
    <div className="listing-content-container">
      <div className="other-ads-specs-container">
        <p>
          <span className="other-ads-name" style={{ marginRight: "5px" }}>
            {toTitleCase({ input: name, lowerCaseRest: false })}
          </span>
          Social:
        </p>
      </div>
      <div className="social-icons-conatiner">
        {facebook && (
          <a
            className="pointer social-icon-conatiner"
            href={facebookUrl}
            target="blank"
          >
            <img
              className="social-icon"
              src="/img/facebook.png"
              alt="EventHound - Facebook"
            />
          </a>
        )}
        {instagram && (
          <a
            className="pointer social-icon-conatiner"
            href={instagramUrl}
            target="blank"
          >
            <img
              className="social-icon"
              src="/img/instagram.png"
              alt="EventHound - Instagram"
            />
          </a>
        )}
      </div>
    </div>
  ) : null;
};

export default Social;
