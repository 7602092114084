import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as stateActions from "../../../../redux/actions/state-actions";

// Components
import ListingSkeleton from "../../listing/listing-skeleton";

// Utilities
import { businessGet } from "../../../../api/business";

const BusinessLoader = (WrappedComponent) => {
  const Component = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { match } = props || {};
    const { id: businessId } = match.params || {};
    const [business, setBusiness] = useState(null);

    useEffect(() => {
      (async () => {
        const { user } = await businessGet(businessId);
        if (!user) {
          dispatch(
            stateActions.setMessage({
              message: "Not Found",
              messageType: "error",
            })
          );
          return history.push("/");
        }
        setBusiness(user);
      })();
    }, [businessId]);

    return !!business ? (
      <CSSTransition in={!!business} timeout={250} classNames="fade">
        <WrappedComponent {...props} business={business} />
      </CSSTransition>
    ) : (
      <ListingSkeleton />
    );
  };
  return Component;
};

export default BusinessLoader;
