import React, { useState } from "react";
import moment from "moment";

// Utilities
import { militaryTimeToReadable } from "../../../../utilites/time";

// Assets
import Arrow from "../../../../assets/svg/back";

//Styles
import "../../listing/listing.css";
import "../business.css";

export default ({
  Details,
  title = "Hours:",
  text = "Closed",
  hours,
  status,
  isOpen = false,
}) => {
  const [fullHours, setFullHours] = useState(isOpen);
  const dayOfTheWeek = moment().format("dddd");
  const hasHours = !!Object.values(hours).filter(({ isOpen }) => isOpen).length;

  const renderDay = (day, idx = 0) => {
    const { isOpen, open, close } = hours[day];
    const isToday = day === dayOfTheWeek;
    return (
      <div
        className={`business-hour-conatiner result-card-${
          isToday ? status : "none"
        } ${
          isToday
            ? "business-hour-conatiner-active"
            : "business-hour-conatiner-inactive"
        }`}
        style={{ animationDelay: `${idx * 60}ms` }}
        key={day}
      >
        <p className="business-hour-item">{day}</p>
        {isOpen ? (
          <>
            <p className="business-hour-item">{militaryTimeToReadable(open)}</p>
            <p className="business-hour-item">
              {militaryTimeToReadable(close)}
            </p>
          </>
        ) : (
          <>
            <p className="business-hour-item">{text}</p>
            <p className="business-hour-item">{text}</p>
          </>
        )}
      </div>
    );
  };

  const renderHours = () => {
    if (!fullHours) return renderDay(dayOfTheWeek);
    return (
      <div className="business-hours-container">
        {Object.keys(hours).map((day, key) => {
          return renderDay(day, key);
        })}
      </div>
    );
  };

  return (
    hasHours && (
      <>
        {Details && <p className="hours-details-title">{Details}</p>}
        <div
          className={`pointer listing-split-content ${
            Details ? "" : "listing-sub-content-container"
          }`}
          onClick={() => setFullHours(!fullHours)}
        >
          <p className="listing-business-hours">{title}</p>
          <div
            className={`signup-toggle-subsection-svg-container ${
              fullHours ? "signup-toggle-subsection-svg-container-open" : ""
            }`}
          >
            <Arrow />
          </div>
        </div>
        {renderHours()}
      </>
    )
  );
};
