import React, { useState } from "react";

// Utilities
import { charLimitTo } from "../../../../utilites/format";

// Assets
import Arrow from "../../../../assets/svg/arrow";

// Styles
import "./text-expand-styles.css";

export default ({
  text = "",
  constainerStyle = {},
  style = {},
  length = 80,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = (evt) => {
    evt.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className="expand-conatiner"
      style={constainerStyle}
      onClick={handleClick}
    >
      <p style={style}>{isExpanded ? text : charLimitTo(text, length)}</p>
      {text.length > length && (
        <p className="expand-see-more">
          {isExpanded ? "See Less" : "See More"}
        </p>
      )}
      {/* <div
        className={`expand-arrow ${isExpanded ? "expand-arrow-active" : ""}`}
      >
        <Arrow />
      </div> */}
    </div>
  );
};
