import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as userActions from "../../../../../redux/actions/user-actions";

// Components
import Pagination from "../../../../components/pagination";
import SavedAd from "./saved-ad";

// Utilities
import { getAdStatus, getBusinessStatus } from "../../../../../utilites/status";
import { bookmarksGet, updateUser } from "../../../../../api/user";
import { getStubhubDetails } from "../../../../../api/stubhub";
import { getTicketMasterDetails } from "../../../../../api/ticketMaster";

// Styles
import "../../../list-view/event-card/result.css";
import "./saved-ad.css";

export default ({ user }) => {
  const { saved: savedList } = user;
  const dispatch = useDispatch();
  const [fetchingListings, setFetchingListings] = useState(false);
  const [saved, setSaved] = useState([]);
  const [savedBusinesses, setSavedBusinesses] = useState([]);
  const [savedSelected, setSavedSelected] = useState(true);
  const [savedPageNumber, setSavedPageNumber] = useState(1);

  useEffect(() => {
    if (savedList.length) getListings();
  }, [savedList]);

  const getListings = async () => {
    setFetchingListings(true);
    // Temp fix to remove the old ticketmaster saves
    const objectsRemovedList = savedList.filter((id) => typeof id === "string");
    const { eventhoundList, stubhubList, ticketmasterList } =
      objectsRemovedList.reduce(
        (acc, id) => {
          if (id.indexOf("stubhub") > -1) {
            acc.stubhubList.push(id);
          } else if (id.indexOf("ticketmaster") > -1) {
            acc.ticketmasterList.push(id);
          } else {
            acc.eventhoundList.push(id);
          }
          return acc;
        },
        { eventhoundList: [], stubhubList: [], ticketmasterList: [] }
      );

    const getAllStubHubDetails = (id) =>
      new Promise(async (resolve) => {
        const { ad } = await getStubhubDetails(id.split("_")[1]);
        return resolve(ad);
      });

    const getAllTicketmasterDetails = (id) =>
      new Promise(async (resolve) => {
        const { ad } = await getTicketMasterDetails(id.split("_")[1]);
        return resolve(ad);
      });

    const [
      { ads: savedListings = [], businesses = [] },
      stubhubListings,
      ticketmasterListings,
    ] = await Promise.all([
      bookmarksGet(eventhoundList),
      Promise.all(stubhubList.map((id) => getAllStubHubDetails(id))),
      Promise.all(ticketmasterList.map((id) => getAllTicketmasterDetails(id))),
    ]);

    const orderedAdsList = savedList.reduce((acc, cur) => {
      const savedListing = [
        ...savedListings,
        ...stubhubListings,
        ...ticketmasterListings,
      ].find(
        ({ _id, stubhubId, ticketmasterId }) =>
          _id === cur || stubhubId === cur || ticketmasterId === cur
      );
      if (savedListing) {
        const { isDateCurrent, hasStarted } = getAdStatus(savedListing);
        return isDateCurrent && hasStarted
          ? [savedListing, ...acc]
          : [...acc, savedListing];
      }
      return acc;
    }, []);

    const orderedBusinessList = savedList.reduce((acc, cur) => {
      const savedListing = businesses.find(({ _id }) => _id === cur);
      if (savedListing) {
        const { isDateCurrent, hasStarted } = getBusinessStatus(savedListing);
        return isDateCurrent && hasStarted
          ? [savedListing, ...acc]
          : [...acc, savedListing];
      }
      return acc;
    }, []);

    const activeAdList = orderedAdsList.filter(({ isActive }) => isActive);
    const activeBusinessList = orderedBusinessList.filter(
      ({ isActive }) => isActive
    );
    setSaved(activeAdList);
    setSavedBusinesses(activeBusinessList);
    setFetchingListings(false);
  };

  const handleAdDelete = (evt, deleteId) => {
    evt.stopPropagation();
    const updatedSaved = saved.filter(
      ({ _id, stubhubId, ticketmasterId }) =>
        _id !== deleteId && _id !== stubhubId && _id !== ticketmasterId
    );
    const updateBusiness = savedBusinesses.filter(
      ({ _id }) => _id !== deleteId
    );
    const updatedSavedList = savedList.filter((id) => id !== deleteId);
    const updatedUser = { ...user, saved: updatedSavedList };

    setSaved(updatedSaved);
    setSavedBusinesses(updateBusiness);
    updateUser(updatedUser);
    dispatch(userActions.updateUser(updatedUser));
  };

  const renderAds = () => {
    const ads = savedSelected ? saved : savedBusinesses;
    const sliceStartPosition = savedPageNumber * 5 - 5;
    let sliceEndPosition = savedPageNumber * 5;
    if (sliceEndPosition > ads.length) sliceEndPosition = ads.length;
    const displayAds = ads.slice(sliceStartPosition, sliceEndPosition);

    return displayAds.map((ad, idx) => (
      <SavedAd data={ad} handleAdDelete={handleAdDelete} key={idx} />
    ));
  };

  return (
    <Pagination
      title="SAVED"
      page={savedPageNumber}
      setPage={setSavedPageNumber}
      isLoading={fetchingListings}
      noResultsText="No Saved"
      list={savedSelected ? saved : savedBusinesses}
    >
      <>
        <div className="profile-title-container profile-text-container-split">
          <div
            className={`pagination-title-container ${
              savedSelected ? "pagination-title-selected" : ""
            }`}
            onClick={() => {
              setSavedSelected(true);
              setSavedPageNumber(1);
            }}
          >
            <p>Events</p>
            {!!saved.length && (
              <p className="profile-pagination-total">{`(${saved.length})`}</p>
            )}
          </div>
          <div
            className={`pagination-title-container ${
              savedSelected ? "" : "pagination-title-selected"
            }`}
            onClick={() => {
              setSavedSelected(false);
              setSavedPageNumber(1);
            }}
          >
            <p>Businesses</p>
            {!!savedBusinesses.length && (
              <p className="profile-pagination-total">{`(${savedBusinesses.length})`}</p>
            )}
          </div>
        </div>
        {renderAds()}
      </>
    </Pagination>
  );
};
