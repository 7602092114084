import { callAPI } from "./";
import { baseURL } from "./url";
import { formatQueryParams } from "../utilites/format";

const growthURL = baseURL("/growth");

export function growthGet(id) {
  const url = `${growthURL}?id=${id}`;
  return callAPI(url, "GET");
}

export function searchGrowth(options) {
  const url = `${growthURL}/search?${formatQueryParams(options)}`;
  return callAPI(url, "GET");
}

export function searchMapGrowth(options) {
  const url = `${growthURL}/search/map?${formatQueryParams(options)}`;
  return callAPI(url, "GET");
}

export function viewedGrowth(id) {
  const url = `${growthURL}/viewed?listingId=${id}`;
  return callAPI(url, "GET");
}

export function reportGrowth(data) {
  return callAPI(`${growthURL}/report`, "POST", data);
}
