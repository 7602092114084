import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as stateActions from "../../../../redux/actions/state-actions";
import * as userActions from "../../../../redux/actions/user-actions";

// Components
import Modal from "../../../components/modal";

// Utilities
import UseGetTheme from "../../../../utilites/hooks/useGetTheme";
import { updateUser } from "../../../../api/user";

// Assets
import Bookmark from "../../../../assets/svg/bookmark";

//Styles
import "../listing.css";

export default ({ data, status }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { savedPage } = useSelector((store) => store.state);
  const { isUser, user } = useSelector((store) => store.user);
  const { isNight } = UseGetTheme();
  const { _id: userId, saved = [] } = user || {};
  const { _id, stubhubId, ticketmasterId } = data;
  const [modalLoginMessage, setModalLoginMessage] = useState("");
  const isBookmarked = saved.find(
    (id) => id === _id || id === stubhubId || id === ticketmasterId
  );
  const isDarkColor = !isNight && status === "starting";

  const handleBookMark = async () => {
    if (user) {
      // Temp fix to remove the old ticketmaster saves
      const objectsRemovedList = saved.filter((id) => typeof id === "string");
      const userData = {
        _id: userId,
        saved,
      };
      isBookmarked
        ? (userData.saved = objectsRemovedList.filter(
            (id) => id !== _id && id !== stubhubId && id !== ticketmasterId
          ))
        : (userData.saved = [
            stubhubId || ticketmasterId || _id,
            ...objectsRemovedList,
          ]);
      const { user } = (await updateUser(userData)) || {};
      if (user) dispatch(userActions.updateUser(user));
    } else {
      setModalLoginMessage(
        "You are not logged in. Would you like to login now?"
      );
    }
  };

  const handleModalLoginAccept = () => {
    const { location } = history;
    const { pathname } = location;
    dispatch(stateActions.setSavedPage(pathname));
    history.push("/login");
  };

  useEffect(() => {
    const { location } = history;
    const { pathname } = location;
    if (savedPage === pathname) {
      handleBookMark();
      dispatch(stateActions.setSavedPage(""));
    }
  }, []);

  return (
    isUser && (
      <>
        <div
          className={`lisitng-bookmark-container ${
            isBookmarked
              ? `active-bookmark ${isDarkColor ? "active-bookmark-dark" : ""}`
              : `inactive-bookmark ${
                  isDarkColor ? "inactive-bookmark-dark" : ""
                }`
          }`}
          onClick={handleBookMark}
        >
          <Bookmark />
        </div>
        <Modal
          directModalMessage={modalLoginMessage}
          modalAccept="log in"
          modalCancel="cancel"
          handleCancelClick={() => setModalLoginMessage("")}
          handleAcceptClick={handleModalLoginAccept}
        />
      </>
    )
  );
};
