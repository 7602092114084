import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as dataActions from "../../../redux/actions/data-actions";

// Utilities
import GetCategoryIcon from "../../components/category-icons";

// Styles
import "./footer-nav.css";

const MobileNav = ({ location, setFilter, history }) => {
  const { pathname } = location || {};
  const navItems = ["Food", "Drinks", "Activities", "Events"];

  const handleClick = (item) => {
    const windowUrl = window.location.href;
    const isMap = pathname.indexOf("map") > -1;
    const url = `/${item.toLowerCase()}${isMap ? "/map" : ""}`;
    const isSamePage = windowUrl.indexOf(url) > -1;

    setFilter({ subCat: "" });
    history.push(isSamePage ? `/top${isMap ? "/map" : ""}` : url);
  };

  return (
    <div id="footer-nav" className="card nav-outer-wrapper-MobileNav">
      <ul className="nav-inner-wrapper-MobileNav">
        {navItems.map((item, idx) => {
          const isActive = pathname.indexOf(item.toLowerCase()) > -1;
          return (
            <div
              onClick={() => handleClick(item)}
              className={`nav-item-container-MobileNav ${
                isActive ? "nav-item-isActive-MobileNav" : ""
              }`}
              key={idx}
            >
              {isActive && <div className="nav-item-container-active" />}
              <div className="nav-svg-container-MobileNav">
                {GetCategoryIcon(item)}
              </div>
              <h3 className="nav-text-MobileNav">{item}</h3>
            </div>
          );
        })}
      </ul>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setFilter: (filterObj) => dispatch(dataActions.setFilter(filterObj)),
});

export default connect(null, mapDispatchToProps)(withRouter(MobileNav));
