import React from "react";

// Styles
import "./svg.css";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
    >
      <path
        d="M94.6,69.8L94.6,69.8c-2.7,0-5,2.2-5,5v7.9L68.4,61.4c-1.9-1.9-5.1-1.9-7,0c-1.9,1.9-1.9,5.1,0,7l21.3,21.3h-7.9
		c-2.7,0-5,2.2-5,5s2.2,5,5,5h19.8c2.7,0,5-2.2,5-5V74.8C99.6,72.1,97.4,69.8,94.6,69.8z"
      />
      <path
        d="M31.6,61.4L10.3,82.7v-7.9c0-2.7-2.2-5-5-5l0,0c-2.7,0-5,2.2-5,5v19.8c0,2.7,2.2,5,5,5h19.8c2.7,0,5-2.2,5-5s-2.2-5-5-5
		h-7.9l21.3-21.3c1.9-1.9,1.9-5.1,0-7C36.7,59.4,33.6,59.4,31.6,61.4z"
      />
      <path
        d="M94.6,0.4H74.8c-2.7,0-5,2.2-5,5s2.2,5,5,5h7.9L61.4,31.6c-1.9,1.9-1.9,5.1,0,7c1.9,1.9,5.1,1.9,7,0l21.3-21.3v7.9
		c0,2.7,2.2,5,5,5l0,0c2.7,0,5-2.2,5-5V5.4C99.6,2.6,97.4,0.4,94.6,0.4z"
      />
      <path
        d="M17.3,10.3h7.9c2.7,0,5-2.2,5-5s-2.2-5-5-5H5.4c-2.7,0-5,2.2-5,5v19.8c0,2.7,2.2,5,5,5l0,0c2.7,0,5-2.2,5-5v-7.9l21.3,21.3
		c1.9,1.9,5.1,1.9,7,0c1.9-1.9,1.9-5.1,0-7L17.3,10.3z"
      />
    </svg>
  );
};
