import moment from "moment";
import { sortResults } from "../../utilites/sort";
import { getCurrentDate } from "../../utilites/date";
import { getCurrentTime } from "../../utilites/time";

const defaultFilters = {
  type: "",
  subCat: "",
  feature: "",
  search: "",
  date: "",
  time: "",
  distance: "25 Miles",
  sortBy: "Distance",
  limit: 30,
};

const initialState = {
  isLiveSearch: true,
  results: [],
  autoComplete: [],
  filters: { ...defaultFilters },
  isDefaultFilters: true,
  searchBarInput: "",
  skipIndex: 0,
  searchMinDistance: 0,
  growthIndex: 0,
  stubHubIndex: 0,
  ticketMasterIndex: 0,
};

const dataReducer = (state = initialState, action) => {
  const { type, payload } = action;
  const { time, sortBy } = state;
  let filters = {};
  let isDefaultFilters = true;
  let results = [];
  let override = {};

  const currentDate = moment().format("L");
  const isDateBefore = moment(state.filters.date).isBefore(currentDate);

  if (isDateBefore) override.date = getCurrentDate();

  switch (type) {
    case "setResults":
      results = payload.results;
      if (
        (sortBy === "Start Time" && payload.pathname !== "") ||
        payload.pathname === "/"
      )
        results = sortResults(results, time);
      return { ...state, results, ...override };

    case "addResults":
      results = payload.results;
      if (
        (sortBy === "Start Time" && payload.pathname !== "") ||
        payload.pathname === "/"
      )
        results = sortResults(results, time);
      return { ...state, results: [...state.results, ...results] };

    case "setSkipIndex":
      return { ...state, skipIndex: payload };

    case "setSearchMinDistance":
      return { ...state, searchMinDistance: payload };

    case "setGrowthIndex":
      return { ...state, growthIndex: payload };

    case "setStubHubIndex":
      return { ...state, stubHubIndex: payload };

    case "setTicketMasterIndex":
      return { ...state, ticketMasterIndex: payload };

    case "setAutoComplete":
      return { ...state, autoComplete: payload, ...override };

    case "setSearchBarInput":
      return { ...state, searchBarInput: payload };

    case "setIsLiveSearch":
      return { ...state, isLiveSearch: payload };

    case "setFilter":
      filters = {
        ...state.filters,
        ...override,
        ...payload,
      };
      for (let key in filters) {
        if (
          key !== "type" &&
          key !== "searchInput" &&
          key !== "search" &&
          filters[key] !== defaultFilters[key]
        ) {
          isDefaultFilters = false;
        }
        if (key === "time" && filters[key] === getCurrentTime())
          filters[key] = "";
      }
      return { ...state, filters, isDefaultFilters, results };

    case "resetFilters":
      return {
        ...state,
        results,
        filters: {
          ...defaultFilters,
          search: state.filters.search,
          type: state.filters.type,
        },
        isDefaultFilters,
      };

    default:
      return state;
  }
};

export default dataReducer;
