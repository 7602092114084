import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as stateActions from "../../../../redux/actions/state-actions";
import * as userActions from "../../../../redux/actions/user-actions";

// Components
import ViewLayout from "../../../components/view-layout";
import ProfileImage from "../../../components/profile-image";
import Button from "../../../components/common/button";
import DeleteDropDown from "../../../components/delete-drop-down";
import Modal from "../../../components/modal";

// Utilities
import { verifyForm, shakeElement } from "../../../../utilites/animation";
import { validateEmail } from "../../../../utilites/validate";
import { updateUser, deleteUser } from "../../../../api/user";
import { deleteImage } from "../../../../api/image";

// Assets
import Skyline from "../../../../assets/svg/skyline";

const EditUser = ({
  user: currentUser,
  setLoader,
  setUser,
  updateUser,
  history,
}) => {
  const { _id, fName = "", lName = "", dob, email, location, image } =
    currentUser || {};
  const { zip = "" } = location;
  let convertedDob = "";
  if (dob) {
    const dobSplit = dob.split("/");
    convertedDob = `${dobSplit[2]}-${dobSplit[0]}-${dobSplit[1]}`;
  }
  const [fNameInput, setfNameInput] = useState(fName);
  const [lNameInput, setlNameInput] = useState(lName);
  const [dobInput, setdobInput] = useState(convertedDob);
  const [emailInput, setEmailInput] = useState(email);
  const [zipInput, setZipInput] = useState(zip);
  const [passwordInput, setPasswordInput] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const userInfoHasChanged =
    fNameInput !== fName ||
    lNameInput !== lName ||
    dobInput !== convertedDob ||
    zipInput !== zip ||
    emailInput !== email;

  function handleChange(evt, type) {
    const { value } = evt.target;
    switch (type) {
      case "fNameInput":
        return setfNameInput(value);
      case "lNameInput":
        return setlNameInput(value);
      case "zipInput":
        return setZipInput(value);
      case "dobInput":
        return setdobInput(value);
      case "emailInput":
        return setEmailInput(value.toLowerCase());
      default:
        break;
    }
  }

  async function handleSubmit(evt) {
    evt.preventDefault();
    if (!userInfoHasChanged) return history.push("/user/profile");
    const validateIds = {
      emailInput: validateEmail(emailInput),
    };
    const hasRequiredInfo = verifyForm(validateIds);
    if (hasRequiredInfo) {
      setLoader(true);
      const userData = { _id };
      const dobSplit = dobInput.split("-");
      const formattedDob = `${dobSplit[1]}/${dobSplit[2]}/${dobSplit[0]}`;
      if (email !== emailInput) userData.email = emailInput;
      if (fName !== fNameInput) userData.fName = fNameInput;
      if (lName !== lNameInput) userData.lName = lNameInput;
      if (zip !== zipInput) userData.zip = zipInput;
      if (dob !== formattedDob) userData.dob = formattedDob;
      const { user } = (await updateUser(userData)) || {};
      if (user) {
        updateUser(user);
        history.push("/user/profile");
      }
      setLoader(false);
    } else {
      setLoader(false);
      shakeElement("form-business-sign-up");
    }
  }

  const handleAcceptClick = async (evt) => {
    evt.preventDefault();
    setModalMessage("");
    setLoader(true);
    const { deleted } =
      (await deleteUser({ password: passwordInput, email })) || {};
    if (deleted) {
      if (image) await deleteImage(image);
      localStorage.removeItem("user");
      setUser();
      history.push("/");
    }
    setLoader(false);
  };

  return (
    <ViewLayout>
      <form
        id="form-business-sign-up"
        className="card-wrapper signup-wrapper-BusinessSignUp"
      >
        <div
          className="card profile-top-selection-wrapper"
          style={{ backgroundImage: `url(${image})` }}
        >
          <>
            {!image && (
              <div className="profile-skyline-container">
                <Skyline />
              </div>
            )}
            <div className="profile-gradient-overlay" />
            <div className="profile-top-selection-container">
              <ProfileImage />
            </div>
          </>
        </div>

        <div className="card card-form">
          <div className="card-info-form">
            <label id="fNameInput" className="label-form">
              First Name:
            </label>
            <input
              className="input-style input-form text-form"
              type="text"
              value={fNameInput}
              onChange={(evt) => handleChange(evt, "fNameInput")}
            />
            <label id="lNameInput" className="label-form">
              Last Name:
            </label>
            <input
              className="input-style input-form text-form"
              type="text"
              value={lNameInput}
              onChange={(evt) => handleChange(evt, "lNameInput")}
            />
            <label id="zipInput" className="label-form">
              Zip:
            </label>
            <input
              className="input-style input-form text-form"
              type="number"
              value={zipInput}
              onChange={(evt) => handleChange(evt, "zipInput")}
            />
            <label id="dobInput" className="label-form">
              Date Of Birth:
            </label>
            <input
              className="input-style input-form text-form"
              type="date"
              value={dobInput}
              onChange={(evt) => handleChange(evt, "dobInput")}
              placeholder="yyyy-mm-dd"
            />
            <label id="emailInput" className="label-form">
              Email:
            </label>
            <input
              className="input-style input-form text-form"
              type="email"
              value={emailInput}
              onChange={(evt) => handleChange(evt, "emailInput")}
            />
            <div className="submit-wapper-UserSignUp">
              <div className="submit-button-UserSignUp">
                {userInfoHasChanged ? (
                  <Button text="update" handleClick={handleSubmit} />
                ) : (
                  <Button
                    text="cancel"
                    isPrimary={false}
                    handleClick={handleSubmit}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <DeleteDropDown
          password={passwordInput}
          handlePassword={setPasswordInput}
          handleDelete={() =>
            setModalMessage("Are you sure you want to delete your profile?")
          }
        />

        <Modal
          directModalMessage={modalMessage}
          modalAccept="Confirm"
          modalCancel="Cancel"
          handleCancelClick={() => setModalMessage("")}
          handleAcceptClick={handleAcceptClick}
        />
      </form>
    </ViewLayout>
  );
};

const mapStateToProps = (store) => ({
  user: store.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (loaderState) => dispatch(stateActions.setLoader(loaderState)),
  setUser: (userObj) => dispatch(userActions.setUser(userObj)),
  updateUser: (userObj) => dispatch(userActions.updateUser(userObj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditUser));
