export const setLoader = (loaderState = false) => {
  return {
    type: "setLoader",
    payload: loaderState,
  };
};

export const setMessage = (dataObj) => {
  return {
    type: "setMessage",
    payload: dataObj,
  };
};

export const setHeaderTitle = (title) => {
  return {
    type: "setHeaderTitle",
    payload: title,
  };
};

export const setImage = (value) => {
  return {
    type: "setImage",
    payload: value,
  };
};

export const setMap = (value) => {
  return {
    type: "setMap",
    payload: value,
  };
};

export const setMapMoved = (value) => {
  return {
    type: "setMapMoved",
    payload: value,
  };
};

export const setLocationKey = (key) => {
  return {
    type: "setLocationKey",
    payload: key,
  };
};

export const setPagePosition = (value) => {
  return {
    type: "setPagePosition",
    payload: value,
  };
};

export const setSavedPage = (url) => {
  return {
    type: "setSavedPage",
    payload: url,
  };
};

export const setPaymentModal = (boolean) => {
  return {
    type: "setPaymentModal",
    payload: boolean,
  };
};

export const setPurchaseModal = (boolean) => {
  return {
    type: "setPurchaseModal",
    payload: boolean,
  };
};

export const setMapModal = (value) => {
  return {
    type: "setMapModal",
    payload: value,
  };
};

export const setCovidModal = (boolean) => {
  return {
    type: "setCovidModal",
    payload: boolean,
  };
};

export const setShareDrawer = (value) => {
  return {
    type: "setShareDrawer",
    payload: value,
  };
};

export const setPromoCode = (value) => {
  return {
    type: "setPromoCode",
    payload: value,
  };
};

export const setWebView = (value) => {
  return {
    type: "setWebView",
    payload: value,
  };
};
