import React, { useRef, useMemo, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import * as dataActions from "../../../redux/actions/data-actions";
import * as userActions from "../../../redux/actions/user-actions";

// Componets
import LeafletMap from "../../components/leaflet-map";
import ResultCard from "../google-map-view/result-card";
import BusniessCard from "../google-map-view/business-card";
import Button from "../../components/common/app-style-button";
import RadarLoader from "../google-map-view/radar-loader";
import NoResults from "../google-map-view/no-results-card";
import DidntFind from "../list-view/didnt-find";

// Assetes
import Radar from "../../../assets/svg/radar";

// Utilities
import Debounce from "../../../utilites/hooks/debounce";
import { isMobileAgent } from "../../../utilites";
import { toTitleCase } from "../../../utilites/format";
import { getLocation } from "../../../utilites/location";
import {
  formatSearchAnalytics,
  formatMapSearchQuery,
} from "../../../utilites/format";
import { getAdStatus, getBusinessStatus } from "../../../utilites/status";
import { searchMapListing, searchBusinessMap } from "../../../api/search";
import { searchMapGrowth } from "../../../api/growth";
import { searchStubhubMap } from "../../../api/stubhub";
import { searchTicketMasterMap } from "../../../api/ticketMaster";
import { searchAnalytics } from "../../../api/analytics";
import useGeoLocation from "../../../utilites/hooks/useGeoLocation";

// styles
import "./map-view-styles.css";

const LeafletMapView = ({
  isLiveSearch,
  reduxUser,
  filters,
  results,
  setHeaderMapIcon,
  setResults,
  match,
  setMessage,
  setMapLocation,
  history,
  locationKey,
  setLocationKey,
  autoComplete,
  setAutoComplete,
  setSearchInput,
  setLocationInput,
}) => {
  const { userLocation, geoLocation, mapLocation } = reduxUser || {};
  const { type, subCat, feature, search, date, time, limit } = filters || {};
  const hasSearched = useRef(false);
  const { watchPosition } = useGeoLocation();
  const geoWatchMemo = useMemo(() => geoLocation, []);
  const [enteredLocation, setEnteredLocation] = useState(userLocation);
  const [hasMounted, setHasMounted] = useState(false);
  const [enteredLocationChanged, setEnteredLocationChanged] = useState(false);
  const [selectedId, setSelectedId] = useState(false);
  const [mapMoved, setMapMoved] = useState(false);
  const [loading, setLoading] = useState(true);
  const [mapCoords, setMapCoords] = useState(false);
  const [zoom, setZoom] = useState(13);
  const debouncedMapCoords = Debounce(mapCoords, 600);
  const debouncedSearch = Debounce(search, 750);
  const debouncedDate = Debounce(date, 600);
  const isGeoLocation = "coordinates" in geoWatchMemo;
  const mapCenter = getLocation(reduxUser);
  const isMobile = isMobileAgent();
  const mapClassName = isMobile ? "map-container-mobile" : "map-container-web";
  const { path } = match || {};
  const pathName = path.split("/")[1];
  const category = toTitleCase({ input: pathName });
  const isTopPage = pathName === "top";
  const markersData = {};
  useMemo(() => watchPosition(), []);

  (() => {
    const eventMakers = () => {
      results.forEach((result) => {
        const { _id, businessId, location } = result;
        const { coordinates } = location;
        const { status } = getAdStatus(result, date);
        if (markersData[businessId]) {
          const markerData = markersData[businessId];
          markerData.status[_id] = status;
          markerData.ads = [...markerData.ads, result];
        } else {
          markersData[businessId] = {
            id: businessId,
            status: { [_id]: status },
            coordinates,
            ads: [result],
          };
        }
      });
    };
    const businessMarkers = () => {
      results.forEach((result) => {
        const { _id, location } = result;
        const { coordinates } = location;
        const { status } = getBusinessStatus(result, date);
        markersData[_id] = {
          id: _id,
          status: { [_id]: status },
          coordinates,
          ads: [result],
        };
      });
    };

    type === "Business" ? businessMarkers() : eventMakers();
  })();

  useEffect(() => {
    if (!hasMounted && debouncedMapCoords) {
      locationKey !== history.location.key ? getData() : setLoading(false);
      setHasMounted(true);
      setLocationKey(history.location.key);
    } else if (enteredLocationChanged) {
      setEnteredLocationChanged(false);
      getData();
    } else if (hasMounted) {
      setMapMoved(true);
    }
  }, [debouncedMapCoords]);

  useEffect(() => {
    if (userLocation !== enteredLocation) {
      setEnteredLocation(userLocation);
      setEnteredLocationChanged(true);
    }
  }, [userLocation]);

  useEffect(() => {
    setSelectedId(false);
    if (debouncedMapCoords) getData();
    if (hasMounted) setLocationKey(history.location.key);
    setHeaderMapIcon();
  }, [
    isLiveSearch,
    type,
    path,
    debouncedSearch,
    subCat,
    feature,
    debouncedDate,
    time,
    limit,
  ]);

  const getData = async () => {
    searchAnalytics(
      formatSearchAnalytics({
        location: userLocation,
        geoLocation,
        category: category.toLowerCase(),
        filters,
      })
    );
    setResults({ results: [], pathname: "" });
    setLoading(true);
    setSelectedId(false);
    setMapMoved(false);

    const isEventsApi =
      category === "Events" || (type && type === "Promotions");
    const searchOption = formatMapSearchQuery({
      mapCoords: debouncedMapCoords,
      isLiveSearch,
      filters,
      category: isTopPage ? "" : category,
      reduxUser,
    });
    let newResults = [];

    if (type === "Business") {
      const { results: topResults = [] } = await searchBusinessMap(
        searchOption
      );
      newResults = topResults;
    } else {
      const { results: searchResults = [] } = await searchMapListing(
        searchOption
      );
      newResults = searchResults;

      if (isEventsApi) {
        const stubhubLimit = limit - newResults.length;
        const { results: stubhubResults = [] } = await searchStubhubMap({
          ...searchOption,
          limit: stubhubLimit < 5 ? 5 : stubhubLimit,
        });

        const ticketMasterLimit = limit - newResults.length;
        const { results: ticketMasterResults = [] } =
          await searchTicketMasterMap({
            ...searchOption,
            limit: ticketMasterLimit < 5 ? 5 : ticketMasterLimit,
          });

        newResults = [...newResults, ...stubhubResults, ...ticketMasterResults];
      }

      if (newResults.length < limit) {
        const { results: growthResults = [] } = await searchMapGrowth({
          ...searchOption,
          limit: limit - newResults.length,
        });
        newResults = [...newResults, ...growthResults];
      }
    }

    if (newResults && newResults.length) {
      setResults({ results: newResults, pathname: "" });
    } else {
      setMessage({ messageType: "error", message: "No Results" });
    }

    setLoading(false);
    hasSearched.current = true;
  };

  const handleMarkerClick = (listing) => {
    setSelectedId(listing);
  };

  const handleCenterMovedCoords = (windowCoords, centerCoords) => {
    setMapCoords(windowCoords);
    setMapLocation(centerCoords);
    if (autoComplete.length) {
      setSearchInput("");
      setLocationInput("");
      setAutoComplete([]);
    }
  };

  const handleNewMapSearch = () => {
    setMapMoved(false);
    getData();
  };

  const sortAds = (ads, statusObj) => {
    const sortedAds = [];
    const notStarted = [];
    for (const id in statusObj) {
      const ad = ads.find(({ _id }) => _id === id);
      const status = statusObj[id];
      if (status === "ending") {
        sortedAds.unshift(ad);
      } else if (status === "active") {
        sortedAds.push(ad);
      } else {
        notStarted.push(ad);
      }
    }
    return [...sortedAds, ...notStarted];
  };

  const renderResults = () => {
    if (!(selectedId in markersData)) return null;
    let { status, ads } = markersData[selectedId];
    if (ads.length > 1) ads = sortAds(ads, status);
    return ads
      .slice(0, 4)
      .map((ad, idx) => (
        <ResultCard
          category={category}
          result={ad}
          filterDate={date}
          key={idx}
        />
      ));
  };

  return (
    <div className={mapClassName}>
      <div className={mapClassName}>
        <LeafletMap
          mapCenter={mapCenter}
          isGeoLocation={isGeoLocation}
          userMarkerPostion={geoLocation.coordinates}
          results={loading ? [] : Object.values(markersData)}
          isUserMarker={true}
          defaultZoom={13}
          setZoom={setZoom}
          handleMarkerClick={handleMarkerClick}
          handleClick={() => setSelectedId(false)}
          handleCenterMovedCoords={handleCenterMovedCoords}
          selectedId={selectedId}
          results={loading ? [] : Object.values(markersData)}
        />
      </div>

      {!loading && mapMoved && (
        <div
          className={`map-update-container ${isMobile ? "zoomIn" : ""}`}
          style={{ transform: `translateX(-${isMobile ? 75 : 118}px)` }}
        >
          <Button
            size="medium"
            text={mapMoved ? "search here" : "show more"}
            handleClick={handleNewMapSearch}
          >
            <Radar isMobile={isMobile} />
          </Button>
        </div>
      )}

      {loading && <RadarLoader />}

      {selectedId && (
        <div
          className="card-center-conatiner"
          style={{
            width: isMobile ? "100%" : "calc(100% - 70px)",
            bottom: isMobile ? "95px" : "100px",
          }}
        >
          {type === "Business" ? (
            selectedId in markersData && (
              <BusniessCard
                date={date}
                result={markersData[selectedId].ads[0]}
                filterDate={date}
              />
            )
          ) : (
            <div className="card-stack-conatiner">{renderResults()}</div>
          )}
        </div>
      )}

      {hasSearched.current && !loading && results && !results.length && (
        <div
          className="card-center-conatiner"
          style={{
            width: isMobile ? "100%" : "calc(100% - 70px)",
            bottom: isMobile ? "95px" : "100px",
          }}
        >
          {isLiveSearch ? (
            <DidntFind
              style={{
                width: "90%",
                maxWidth: "600px",
                height: "100%",
                minHeight: "85px",
              }}
            />
          ) : (
            <NoResults />
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (store) => ({
  isLiveSearch: store.data.isLiveSearch,
  reduxUser: store.user,
  filters: store.data.filters,
  results: store.data.results,
  locationKey: store.state.locationKey,
  autoComplete: store.data.autoComplete,
});

const mapDispatchToProps = (dispatch) => ({
  setHeaderMapIcon: () => dispatch(stateActions.setMap(true)),
  setResults: (resultsObj) => dispatch(dataActions.setResults(resultsObj)),
  setMessage: (messageObj) => dispatch(stateActions.setMessage(messageObj)),
  setMapLocation: (mapCoordsArr) =>
    dispatch(userActions.setMapLocation(mapCoordsArr)),
  setLocationKey: (key) => dispatch(stateActions.setLocationKey(key)),
  setSearchInput: (input) =>
    dispatch(dataActions.setFilter({ searchInput: input })),
  setLocationInput: (input) => dispatch(userActions.setLocationInput(input)),
  setAutoComplete: (autoCompleteList) =>
    dispatch(dataActions.setAutoComplete(autoCompleteList)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LeafletMapView));
