import React from "react";

// Styles
import "./spacer.css";

const Spacer = ({ height }) => {
  return (
    <div
      className="spacer"
      style={{ height: typeof height === "number" ? `${height}px` : height }}
    />
  );
};

export default Spacer;
