import React from "react";

// Utilities
import getIcon from "../../../components/features-input/getFeatureIcon";

// Styles
import "./no-results-card-styles.css";

export default () => (
  <div className="zoomIn result-map-card-container" style={{ cursor: "auto" }}>
    <img className="result-card-image" src="/img/food.jpg" />
    <div className="result-card-overlay result-card-map-overlay-gradient" />
    <div className="result-card-overlay result-card-status-overlay" />

    <div className="mapNoResultsInfoWrapper">
      <div className="mapNoResultsIcon">{getIcon("search")}</div>
      <div className="mapNoResultsTextWrapper">
        <p className="mapNoResultsTitle">No results found in this area</p>
        <div className="mapNoResultsDescriptionContainer">
          <p className="mapNoResultsDescription">
            Try different search parameters
          </p>
        </div>
      </div>
    </div>
  </div>
);
