import { callAPI } from "./";
import { baseURL } from "./url";

const facebookURL = baseURL("/facebook");

export function businessLogin({ id, userId, accessToken }) {
  const url = `${facebookURL}?id=${id}&userId=${userId}&accessToken=${accessToken}`;
  return callAPI(url, "GET");
}

export function postToSocial(data) {
  return callAPI(facebookURL, "PUT", data);
}

export function editScheduledPost(data) {
  return callAPI(facebookURL, "POST", data);
}

export function deleteScheduledPost(id) {
  const url = `${facebookURL}?id=${id}`;
  return callAPI(url, "DELETE");
}
