import React from "react";
import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Area,
} from "recharts";

// Utilities
import UseGetTheme from "../../../utilites/hooks/useGetTheme";

// Styles
import "./chart-styles.css";

const Chart = ({ primaryKey, secomdaryKey, promoKey, data }) => {
  const { isNight } = UseGetTheme();
  return data ? (
    <div className="chart-wrapper">
      <ResponsiveContainer>
        <AreaChart data={data}>
          <defs>
            <linearGradient
              id="horizontalpromoKey"
              x1="0"
              y1="0"
              x2="0"
              y2="1"
              gradientTransform="rotate(90 .5 .5)"
            >
              <stop offset="5%" stopColor="#48C826" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#48C826" stopOpacity={0.2} />
            </linearGradient>
            <linearGradient id="primaryKey" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor="hsl(215, 100%, 65%)"
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor="hsl(215, 100%, 65%)"
                stopOpacity={0.2}
              />
            </linearGradient>
            <linearGradient id="secomdaryKey" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor="hsl(339, 100%, 55%)"
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor="hsl(339, 100%, 55%)"
                stopOpacity={0.2}
              />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="day"
            type="category"
            stroke={isNight ? "#f1f1f1" : " rgb(80,80,80)"}
          />
          <YAxis
            type="number"
            stroke={isNight ? "#f1f1f1" : " rgb(80,80,80)"}
          />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          <Area
            type="monotone"
            dataKey={primaryKey}
            stroke="hsl(215, 100%, 65%)"
            fillOpacity={1}
            fill="url(#primaryKey)"
          />
          <Area
            type="monotone"
            dataKey={secomdaryKey}
            stroke="hsl(339, 100%, 55%)"
            fillOpacity={1}
            fill="url(#secomdaryKey)"
          />
          {promoKey && (
            <Area
              type="monotone"
              dataKey={promoKey}
              stroke="#2DA90C"
              fillOpacity={1}
              fill="url(#horizontalpromoKey)"
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  ) : null;
};

export default Chart;
