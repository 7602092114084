import moment from "moment";
import {
  getCurrentDate,
  convertDateToDbDate,
  formatDbDateToReadable,
} from "./date";
import {
  getCurrentTime,
  timeToMilitary,
  militaryToCorrectMilitary,
  militaryTimeToReadable,
} from "./time";
import { getLocation } from "../utilites/location";

export function charLimitTo(string = "", limitNumber = 120) {
  if (string && string.length > limitNumber) {
    string = string.slice(0, limitNumber - 3);
    string += "...";
  }
  return string;
}

export function toTitleCase({ input = "", lowerCaseRest = true }) {
  return input.replace(
    /\w\S*/g,
    (txt) =>
      txt.charAt(0).toUpperCase() +
      (lowerCaseRest ? txt.substr(1).toLowerCase() : txt.substr(1))
  );
}

export function formatPhoneNumber({ phone, href = false }) {
  const cleaned = ("" + phone).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (!match) return "";
  if (!href) return "(" + match[1] + ") " + match[2] + "-" + match[3];
  return "1-" + match[1] + "-" + match[2] + "-" + match[3];
}

export const formatCreditCardNumber = (cardNumber) => {
  const clearValue = cardNumber.replace(/\D+/g, "");
  return `${clearValue.slice(0, 4)} ${clearValue.slice(
    4,
    8
  )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`.trim();
};

export function formatSearchAnalytics({
  location,
  geoLocation,
  category,
  filters,
}) {
  const { county: enteredCounty } = location || {};
  const { county: geoCounty } = geoLocation || {};
  const { search, subCat, feature } = filters || {};
  const county = enteredCounty || geoCounty || "";
  return {
    term: search,
    category,
    location: county,
    subCat,
    feature,
  };
}

export function getSearchLimit({
  searchValues,
  isEventsApi,
  isDistanceSearch,
  type,
  totalLimit,
}) {
  if (isDistanceSearch || type === "Business") {
    const { skip } = searchValues || {};
    return { numberOfSearchableApis: skip > -1, searchLimit: totalLimit };
  }
  if (!isEventsApi) {
    searchValues.stubhub = -1;
    searchValues.ticketMaster = -1;
  }
  const numberOfSearchableApis = Object.values(searchValues).filter(
    (value) => value > -1
  ).length;
  const searchLimit = numberOfSearchableApis
    ? Math.round(totalLimit / numberOfSearchableApis)
    : 0;
  return { numberOfSearchableApis, searchLimit };
}

export function formatSearchQuery({
  category,
  isLiveSearch = true,
  reduxUser,
  filters,
  params = { skip: 0, limit: 20 },
}) {
  const {
    type,
    subCat,
    feature = "",
    search,
    date,
    distance,
    sortBy,
  } = filters || {};
  const { geoLocation, user } = reduxUser || {};
  const { _id: userId } = user || {};
  const { address, coordinates: geoCoordinates } = geoLocation || {};
  const [lng, lat] = getLocation(reduxUser);
  const currentDate = getCurrentDate();

  const searchOptions = {
    isLiveSearch,
    lng,
    lat,
    type,
    search,
    distance: distance.match(/\d/gi).join(""),
    category: toTitleCase({ input: category }),
    subCat,
    feature,
    day: moment(date || currentDate).format("dddd"),
    date: convertDateToDbDate(date || currentDate),
    time: isLiveSearch ? getCurrentTime(false) : 0,
    sortBy,
    analyticsDate: convertDateToDbDate(currentDate),
    ...params,
  };

  if (userId) searchOptions.userId = userId;
  if (address) {
    searchOptions.address = address;
    searchOptions.addressCoords = geoCoordinates;
  }
  return searchOptions;
}

export function formatMapSearchQuery({
  mapCoords,
  isLiveSearch = true,
  filters,
  category = "",
  reduxUser,
}) {
  const { geoLocation, user } = reduxUser || {};
  const { type, subCat, feature, search, date, time, limit } = filters || {};
  const { _id: userId } = user || {};
  const { address, coordinates: geoCoordinates } = geoLocation || {};
  const currentDate = getCurrentDate();

  const searchOptions = {
    isLiveSearch,
    coordinates: JSON.stringify(mapCoords),
    type,
    search,
    category: toTitleCase({ input: category }),
    subCat,
    feature,
    day: moment(date || currentDate).format("dddd"),
    date: convertDateToDbDate(date || currentDate),
    time: isLiveSearch
      ? timeToMilitary({ time: time ? time : getCurrentTime() })
      : 0,
    limit,
    skip: 0,
    analyticsDate: convertDateToDbDate(currentDate),
  };

  if (userId) searchOptions.userId = userId;
  if (address) {
    searchOptions.address = address;
    searchOptions.addressCoords = geoCoordinates;
  }
  return searchOptions;
}

export function formatQueryParams(options) {
  let searchOptions = "";
  Object.keys(options).forEach((item, idx) => {
    if (idx && idx !== idx.length - 1) searchOptions += "&";
    searchOptions += `${item}=${options[item]}`;
  });
  return searchOptions;
}

export function formatListingTime({ ad, business }) {
  const localTime = Number(moment().format("HHmm"));
  if (localTime >= 200) return ad || business;
  const currentDay = moment().format("dddd");
  const oneDayEarlier = moment().subtract(1, "days");
  const earlierDate = convertDateToDbDate(moment(oneDayEarlier).format("L"));
  const earlierDay = moment(oneDayEarlier).format("dddd");
  const { days: adDays, date: adDate } = ad || {};
  const { hours } = adDays || {};
  const { dateAvailable } = adDate || {};
  const { hours: businessHours } = business || {};

  if (
    (ad && hours[earlierDay] && hours[earlierDay].close > 2400) ||
    (dateAvailable === earlierDate && adDate.close > 2400)
  ) {
    const formattedAd = { ...ad };

    if (dateAvailable) {
      formattedAd.date = {
        dateAvailable: convertDateToDbDate(),
        open: 0,
        close: adDate.close - 2400,
      };
    } else {
      formattedAd.days = {
        ...adDays,
        hours: {
          ...hours,
          [currentDay]: {
            isOpen: true,
            open: 0,
            close: hours[earlierDay].close - 2400,
          },
        },
      };
    }
    return formattedAd;
  }

  if (
    business &&
    businessHours[earlierDay].isOpen &&
    businessHours[earlierDay].close > 2400
  ) {
    const close = businessHours[earlierDay].close;
    return {
      ...business,
      hours: {
        ...businessHours,
        [currentDay]: {
          isOpen: true,
          open: 0,
          close: close - 2400,
        },
      },
    };
  }
  return ad || business;
}

export const convertZoomToMiles = (zoom) => {
  if (zoom > 16) return 1;
  switch (zoom) {
    case 16:
      return 2;
    case 15:
      return 3;
    case 14:
      return 5;
    case 13:
      return 10;
    case 12:
      return 20;
    case 11:
      return 50;
    default:
      return 100;
  }
};

export const createSingleDayHours = (open, close) => ({
  Monday: {
    isOpen: false,
    open,
    close,
  },
  Tuesday: {
    isOpen: false,
    open,
    close,
  },
  Wednesday: {
    isOpen: false,
    open,
    close,
  },
  Thursday: {
    isOpen: false,
    open,
    close,
  },
  Friday: {
    isOpen: false,
    open,
    close,
  },
  Saturday: {
    isOpen: false,
    open,
    close,
  },
  Sunday: {
    isOpen: false,
    open,
    close,
  },
});

export const formatAfter12Hours = (hours) => {
  const daysOfTheWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  daysOfTheWeek.forEach((day, idx) => {
    const { isOpen, close } = hours[day];
    const previousDay = daysOfTheWeek[idx !== 6 ? idx + 1 : 0];
    if (close > 2400) {
      hours[previousDay].adjustedTime = {
        isOpen,
        open: 0,
        close: close - 2400,
      };
    } else {
      delete hours[previousDay].adjustedTime;
    }
  });
  return hours;
};

export const formatSalesDays = (tickets) => {
  const days = {
    Friday: { isOpen: false },
    Monday: { isOpen: true },
    Saturday: { isOpen: false },
    Sunday: { isOpen: false },
    Thursday: { isOpen: false },
    Tuesday: { isOpen: true },
    Wednesday: { isOpen: true },
  };
  tickets.forEach(({ hours }) => {
    for (const key in hours) {
      if (hours[key].isOpen) days[key].isOpen = true;
    }
  });
  return days;
};

export const filterSingleDayTicketSales = ({
  presaleDate,
  dateAvailable,
  sales,
}) => {
  const formattedPresaleDate = formatDbDateToReadable({
    date: presaleDate,
    toFullYear: true,
    isForUser: true,
  });
  const formattedDate = formatDbDateToReadable({
    date: dateAvailable,
    toFullYear: true,
    isForUser: true,
  });

  if (moment().isSame(formattedDate, "days")) {
    return sales.reduce((acc, item) => {
      const { isTicket, startTime, endTime } = item;

      if (
        isTicket &&
        moment().isBefore(
          moment(`${formattedDate} ${militaryToCorrectMilitary(startTime)}`)
        )
      )
        return [
          ...acc,
          {
            ...item,
            start: militaryTimeToReadable(startTime),
            end: militaryTimeToReadable(endTime),
            date: formattedDate,
            dbDate: dateAvailable,
            startTime,
            endTime,
          },
        ];
      return acc;
    }, []);
  } else if (
    moment().diff(formattedDate, "days") < 0 &&
    presaleDate &&
    moment().isSameOrAfter(moment(formattedPresaleDate).startOf("day"), "days")
  ) {
    return sales.reduce((acc, item) => {
      const { isTicket, startTime, endTime } = item;

      if (isTicket)
        return [
          ...acc,
          {
            ...item,
            start: militaryTimeToReadable(startTime),
            end: militaryTimeToReadable(endTime),
            date: formattedDate,
            dbDate: dateAvailable,
            startTime,
            endTime,
          },
        ];
      return acc;
    }, []);
  } else {
    return [];
  }
};

export const filterRepeatTicketSales = ({ dateInput, sales }) => {
  const selectedDay = moment(dateInput).format("dddd");

  if (moment().startOf("day").isSameOrBefore(dateInput, "days")) {
    return sales.reduce((acc, item) => {
      const { isTicket, hours } = item;
      const { isOpen, open, close } = hours[selectedDay];

      if (
        isTicket &&
        isOpen &&
        moment().isBefore(
          moment(`${dateInput} ${militaryToCorrectMilitary(open)}`)
        )
      )
        return [
          ...acc,
          {
            ...item,
            start: militaryTimeToReadable(open),
            end: militaryTimeToReadable(close),
            date: dateInput,
            dbDate: convertDateToDbDate(getCurrentDate(dateInput)),
            startTime: open,
            endTime: close,
          },
        ];
      return acc;
    }, []);
  } else {
    return [];
  }
};

export const filterSingleDayVoucherSales = ({
  presaleDate,
  dateAvailable,
  sales,
}) => {
  const formattedDate = formatDbDateToReadable({
    date: dateAvailable,
    toFullYear: true,
    isForUser: true,
  });
  const isPresale = presaleDate
    ? moment().isSameOrAfter(
        moment(
          formatDbDateToReadable({
            date: presaleDate,
            toFullYear: true,
            isForUser: true,
          })
        ).startOf("day"),
        "days"
      ) && moment().isBefore(moment(formattedDate), "day")
    : false;

  if (
    isPresale ||
    moment().isSame(moment(formattedDate).endOf("day"), "days")
  ) {
    return sales.reduce((acc, item) => {
      const { isTicket, startTime, endTime } = item;

      if (isTicket === false)
        return [
          ...acc,
          {
            ...item,
            start: militaryTimeToReadable(startTime),
            end: militaryTimeToReadable(endTime),
            date: formattedDate,
            dbDate: dateAvailable,
            startTime,
            endTime,
          },
        ];
      return acc;
    }, []);
  }
  return [];
};

export const filterRepeatVoucherSales = ({
  presaleDate,
  startDate: adStartDate,
  endDate: adEndDate,
  sales,
}) => {
  const startDate = presaleDate ? presaleDate : adStartDate;
  if (!startDate) return [];

  const formattedStartDate = formatDbDateToReadable({
    date: startDate,
    toFullYear: true,
    isForUser: true,
  });
  const formattedEndDate = formatDbDateToReadable({
    date: adEndDate,
    toFullYear: true,
    isForUser: true,
  });

  if (
    moment().isSameOrAfter(formattedStartDate, "days") &&
    (adEndDate === 999999 || moment().isSameOrBefore(formattedEndDate, "days"))
  ) {
    return sales.reduce((acc, item) => {
      const { isTicket, hours } = item;
      const { open, close } = Object.values(hours).find(({ isOpen }) => isOpen);

      if (isTicket === false)
        return [
          ...acc,
          {
            ...item,
            start: militaryTimeToReadable(open),
            end: militaryTimeToReadable(close),
            date: moment().format("llll").split(" ").slice(0, 4).join(" "),
            dbDate: convertDateToDbDate(getCurrentDate()),
            startTime: open,
            endTime: close,
          },
        ];
      return acc;
    }, []);
  } else {
    return [];
  }
};

export const formateServiceFee = (price) => {
  const adjustedPrice = price - 2;
  return (Number(adjustedPrice) - Number(adjustedPrice) * 0.1).toFixed(2);
};

export const formatSameHours = (hours) => {
  const sameHours = JSON.parse(JSON.stringify(hours));
  let start = 0;
  let end = 2600;
  Object.values(sameHours).forEach(({ isOpen, open, close }) => {
    if (isOpen) {
      if (open > start) start = open;
      if (end > close) end = close;
    }
  });
  Object.keys(sameHours).forEach((day) => {
    sameHours[day].open = start;
    sameHours[day].close = end;
  });
  return sameHours;
};

export function formatUserTags(userTags) {
  return userTags.split(" ").reduce((acc, tag) => {
    const trimedTag = tag.trim().replace("@", "");
    const hasTag = !!acc.filter(({ username }) => username === trimedTag)
      .length;
    if (!trimedTag || hasTag) return acc;
    return [...acc, { username: trimedTag, x: 0.0, y: 0.9 }];
  }, []);
}
