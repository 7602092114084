import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";

// Components
import Button from "../common/button";

// Styles
import "./instagram-styles.css";

export default ({ business, handleDelete }) => {
  const dispatch = useDispatch();
  const [height, setHeight] = useState("100%");
  const { social } = business || {};
  const { instagram } = social || {};
  const { igProfile, igPostList } = instagram || {};
  const isInstagram = igProfile && igPostList && igPostList.length;
  const {
    username,
    name,
    profile_picture_url,
    media_count,
    followers_count,
    biography,
  } = igProfile || {};
  const biographyList = biography ? biography.split("\n") : [];

  useEffect(() => {
    const handleResize = () => {
      const imageContainer = document.querySelector(
        ".instagram-post-container"
      );
      const imageContainerWidth = imageContainer
        ? imageContainer.offsetWidth
        : 0;
      const imageHeight = imageContainerWidth
        ? imageContainerWidth / (imageContainerWidth > 749 ? 4 : 3)
        : "100%";
      setHeight(imageHeight);
    };

    setTimeout(() => handleResize());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    !!isInstagram && (
      <div className="listing-content-container">
        <div className="instagram-metaData-container">
          <a
            className="instagram-username"
            href={`https://www.instagram.com/${username}/?hl=en`}
            target="blank"
          >
            {username}
          </a>
          {!!handleDelete && (
            <div className="instagram-remove">
              <Button
                text="Remove"
                size="small"
                isPrimary={false}
                handleClick={handleDelete}
              />
            </div>
          )}
          <div className="instagram-header-container">
            <a
              className="instagram-profile-image-container"
              href={`https://www.instagram.com/${username}/?hl=en`}
              target="blank"
            >
              <img
                className="instagram-profile-image"
                src={profile_picture_url}
              />
            </a>
            <div className="instagram-stats-wrapper">
              <div
                className="instagram-stats-conatiner"
                style={{ marginRight: "10%" }}
              >
                <p className="instagram-stats">{media_count}</p>
                <p className="instagram-stats-title">Posts</p>
              </div>
              <div
                className="instagram-stats-conatiner"
                style={{ marginRight: "4%" }}
              >
                <p className="instagram-stats">{followers_count}</p>
                <p className="instagram-stats-title">Followers</p>
              </div>
            </div>
          </div>
          <p className="instagram-name">{name}</p>
          {biographyList.map((text, idx) => (
            <p className="instagram-biography" key={idx}>
              {text}
            </p>
          ))}
        </div>
        <div className="instagram-post-container">
          {igPostList.map(({ media_type, media_url }, idx) =>
            media_type === "VIDEO" ? (
              <video
                className="instagram-post-image"
                style={{
                  height,
                }}
                autoplay="autoplay"
                key={idx}
              >
                <source
                  src={media_url}
                  type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                />
                <source
                  src={media_url}
                  type='video/webm; codecs="vp8, vorbis"'
                />
                <source
                  src={media_url}
                  type='video/ogg; codecs="theora, vorbis"'
                />
                Your browser does not support the video tag.
              </video>
            ) : (
              <div
                className="pointer instagram-post-image"
                style={{
                  height,
                  backgroundImage: `url(${media_url})`,
                }}
                src={media_url}
                onClick={() => dispatch(stateActions.setImage(media_url))}
                key={idx}
              />
            )
          )}
        </div>
      </div>
    )
  );
};
