import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as dataActions from "../../../../redux/actions/data-actions";

// Utilities
import { isMobileAgent } from "../../../../utilites";

// Styles
import "./didnt-find-styles.css";

export default ({ style }) => {
  const dispatch = useDispatch();
  const isMobile = isMobileAgent();
  const { type } = useSelector((store) => store.data.filters);

  const handleClick = () => {
    if (type !== "Business") dispatch(dataActions.setFilter({ type: "" }));
    dispatch(dataActions.setIsLiveSearch(false));
  };

  return (
    <div
      className={`cant-find-container ${
        isMobile ? "result-card-mobile" : "result-card-web"
      }`}
      style={style || { flex: "350px" }}
      onClick={handleClick}
    >
      <p className="cant-find-title">Find What You're Looking For?</p>
      <p className="cant-find-description">Click here to search later dates</p>
    </div>
  );
};
