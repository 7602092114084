import { callAPI } from ".";
import { baseURL } from "./url";
import { formatQueryParams } from "../utilites/format";

const stubhubURL = baseURL("/stubhub");

export function searchStubhub(params) {
  const url = `${stubhubURL}?${formatQueryParams(params)}`;
  return callAPI(url, "GET");
}

export function searchStubhubMap(params) {
  const url = `${stubhubURL}/map?${formatQueryParams(params)}`;
  return callAPI(url, "GET");
}

export function getStubhubDetails(id) {
  const url = `${stubhubURL}/event?id=${id}`;
  return callAPI(url, "GET");
}

export function getStubhubVenue(id) {
  const url = `${stubhubURL}/venue?id=${id}`;
  return callAPI(url, "GET");
}
