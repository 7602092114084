import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const ProtectedBusinessRoute = ({
  isUser,
  user,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      user && !isUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: {
              from: props.location,
            },
          }}
        />
      )
    }
  />
);

const mapStateToProps = (store) => ({
  isUser: store.user.isUser,
  user: store.user.user,
});

export default connect(mapStateToProps)(ProtectedBusinessRoute);
