import React from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

// Assets
import Food from "../../../../../assets/svg/food";
import Drinks from "../../../../../assets/svg/drinks";
import Activities from "../../../../../assets/svg/activities";
import Event from "../../../../../assets/svg/event";
import Trash from "../../../../../assets/svg/trash";
import Skyline from "../../../../../assets/svg/skyline";

// Utilities
import { getAdStatus, getBusinessStatus } from "../../../../../utilites/status";
import {
  formatListingTime,
  charLimitTo,
  toTitleCase,
} from "../../../../../utilites/format";

// Styles
import "../../../list-view/event-card/result.css";
import "./saved-ad.css";

const SavedAd = ({ data, handleAdDelete, history }) => {
  const filterDate = useSelector((store) => store.data.filters.date);
  const {
    _id,
    stubhubId,
    ticketmasterId,
    businessName,
    title,
    name,
    categories,
    images,
  } = data;
  const { cat1Img } = categories;
  const isAd = !!businessName;
  const formattedData = formatListingTime(
    isAd ? { ad: data } : { business: data }
  );
  const { status } = isAd
    ? getAdStatus(formattedData, filterDate)
    : getBusinessStatus(formattedData, filterDate);
  const image = isAd ? cat1Img : images && images.length ? images[0] : "";

  const getIcon = (type) => {
    switch (type) {
      case "Food":
        return <Food />;
      case "Drinks":
        return <Drinks />;
      case "Activities":
        return <Activities />;
      case "Events":
        return <Event />;
      default:
        break;
    }
  };

  const renderCategory = (categories) => {
    return Object.values(categories).reduce((acc, cur, idx) => {
      const isCategory =
        cur === "Food" ||
        cur === "Drinks" ||
        cur === "Events" ||
        cur === "Activities";
      if (isCategory)
        return [
          ...acc,
          <div className="profile-ad-category-container" key={idx}>
            {getIcon(cur)}
          </div>,
        ];
      return acc;
    }, []);
  };

  const handleClick = () => {
    const type = isAd ? "listing" : "business";
    history.push(`/${type}/${stubhubId || ticketmasterId || _id}`);
  };

  return (
    <div
      className={`card saved-ad-wrapper result-card-${status}`}
      style={{
        backgroundImage: `url(${image})`,
      }}
      onClick={handleClick}
    >
      {!image && (
        <div className="saved-ad-skyline-overlay">
          <Skyline />
        </div>
      )}
      <div className={`result-card-overlay result-card-overlay-gradient`} />
      <div
        className={`result-card-overlay result-card-status-overlay result-card-overlay-${status}`}
      />
      <div className="saved-ad-container">
        <div className="saved-ad-sub-container">
          {renderCategory(categories)}
        </div>
        <div className="saved-ad-sub-container-split">
          <div>
            <p className="saved-ad-title">
              {charLimitTo(isAd ? title : name, 35).toUpperCase()}
            </p>
            <p className="saved-ad-name">
              {toTitleCase({
                input: charLimitTo(businessName, 25),
                lowerCaseRest: false,
              })}
            </p>
          </div>
          <div
            className="profile-ad-delete-container"
            onClick={(evt) =>
              handleAdDelete(evt, stubhubId || ticketmasterId || _id)
            }
          >
            <Trash />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SavedAd);
