import React, { useState } from "react";

// Assets
import Edit from "../../../assets/svg/edit";
import Card from "../../../assets/svg/card";
import PostAd from "../../../assets/svg/post-ad";
import Password from "../../../assets/svg/password";
import Contact from "../../../assets/svg/contact-filled";
import LogOut from "../../../assets/svg/logout";
import Legal from "../../../assets/svg/legal";
import Arrow from "../../../assets/svg/back";
import PrivacyPolicy from "../../../assets/svg/privacypolicy";
import TermOfService from "../../../assets/svg/termsofservice";
import Covid from "../../../assets/svg/covid";
import ManyLocations from "../../../assets/svg/many-locations";

// Styles
import "./side-menu.css";

const MenuItemComponent = ({
  title,
  dropDownItems,
  handleClick,
  isOpen,
  setIsOpen,
}) => {
  function getIcon(title) {
    switch (title) {
      case "edit profile":
        return <Edit />;
      case "marketing":
        return <Card />;
      case "new post":
        return <PostAd />;
      case "Manage locations":
        return <ManyLocations />;
      case "change password":
        return <Password />;
      case "contact":
        return <Contact />;
      case "log out":
        return <LogOut />;
      case "legal":
        return <Legal />;
      case "privacy policy":
        return <PrivacyPolicy />;
      case "terms of service":
        return <TermOfService />;
      case "covid compliance":
        return <Covid />;
    }
  }

  return title !== "contact" ? (
    <div
      className="sideMenu-menu-item-container"
      onClick={dropDownItems ? () => setIsOpen(!isOpen) : handleClick}
    >
      <div className="sideMenu-menu-icon-container">{getIcon(title)}</div>
      <p>{title.toUpperCase()}</p>
      {dropDownItems && (
        <div
          className={`sideMenu-menu-carret-container ${
            isOpen ? "sideMenu-menu-carret-container-open" : ""
          }`}
        >
          <Arrow />
        </div>
      )}
    </div>
  ) : (
    <a
      className="sideMenu-menu-item-container"
      href="mailto:contact@eventhound.com"
    >
      <div className="sideMenu-menu-icon-container">{getIcon(title)}</div>
      <p>{title.toUpperCase()}</p>
    </a>
  );
};

const MenuItem = ({ title, dropDownItems = false, handleClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="sideMenu-menu-item">
      <MenuItemComponent
        title={title}
        dropDownItems={dropDownItems}
        handleClick={handleClick}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <div
        className={`sideMenu-menu-item-dropDown-subsection ${
          isOpen ? "sideMenu-menu-item-dropDown-subsection-open" : ""
        }`}
      >
        {dropDownItems &&
          dropDownItems.map(({ title, handleClick }, idx) => (
            <MenuItemComponent
              title={title}
              dropDownItems={false}
              handleClick={handleClick}
              key={idx}
            />
          ))}
      </div>
    </div>
  );
};

export default MenuItem;
