import React from "react";
import { connect } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import * as userActions from "../../../redux/actions/user-actions";

// Utilities
import { storeImage, deleteImage } from "../../../api/image";
import { updateUser } from "../../../api/user";

// Assets
import Camera from "../../../assets/svg/camera";
import Close from "../../../assets/svg/close";

// Styles
import "./profile-image.css";

const ProfileImage = ({ user, setLoader, updateUser: updateReduxUser }) => {
  const { image } = user || {};

  async function handleChange(evt) {
    setLoader(true);
    const file = evt.target.files[0];
    const imageUrl = await storeImage(file, "user", image);
    setTimeout(async () => {
      if (imageUrl) {
        const newUserData = { ...user, image: imageUrl };
        const { user: updatedUser } = await updateUser(newUserData);
        if (updatedUser) updateReduxUser(updatedUser);
      }
      setLoader(false);
    }, 1500);
  }

  async function handleDeleteClick() {
    setLoader(true);
    if (image) {
      await deleteImage(image);
      const newUserData = { ...user, image: "" };
      const { user: updatedUser } = await updateUser(newUserData);
      if (updatedUser) updateReduxUser(updatedUser);
    }
    setLoader(false);
  }

  return (
    <div className="profile-image-wrapper-position profile-images-wrapper">
      <div className="profile-image-wrapper">
        {image && (
          <div className="profile-image-delete" onClick={handleDeleteClick}>
            <Close />
          </div>
        )}
        <div
          className="profile-image-container"
          onClick={() => document.getElementById("userImageInput").click()}
        >
          {image ? (
            <img
              className="profile-image"
              src={image}
              alt="EventHound"
              height="100%"
              width="100%"
            />
          ) : (
            <div className="profile-svg-container">
              <Camera />
            </div>
          )}
        </div>
      </div>
      <input
        id="userImageInput"
        type="file"
        name="photo"
        accept="image/png, image/jpeg, image/jpg"
        hidden="hidden"
        onChange={handleChange}
      />
    </div>
  );
};

const mapStateToProps = (store) => ({
  user: store.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (loaderState) => dispatch(stateActions.setLoader(loaderState)),
  updateUser: (userObj) => dispatch(userActions.updateUser(userObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileImage);
