import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { useSelector } from "react-redux";
import moment from "moment";

// utilities
import getIcon from "../features-input/getFeatureIcon";
import getFeatureIcon from "../../components/features-input/getFeatureIcon";

// Assets
import AppleLogo from "../../../assets/svg/apple-logo";

// Styles
import "./download-app-modal.css";

export default ({ image }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const igParam = urlParams.get("ig");
  const { user } = useSelector((store) => store.user);
  const [showModal, setShowModal] = useState(false);
  const [listingFlowIndex, setListingFlowIndex] = useState(0);

  const listingFlowData = [
    {
      title: "Click The Phone To See More",
      image: "/img/home-screen.png",
      index: 0,
      nextIndex: 1,
    },
    {
      title: "EVENTS, PROMOTIONS & DEALS",
      image: "/img/list-view.png",
      index: 1,
      nextIndex: 2,
    },
    {
      title: "REAL-TIME SERVICES",
      image: "/img/listing.png",
      index: 2,
      nextIndex: 3,
    },
    {
      title: "FIND YOUR FUN!®",
      image: "/img/map-view.png",
      index: 3,
      nextIndex: 4,
    },
    {
      title: "SUPPORT LOCAL BUSINESSES",
      image: "/img/profile.png",
      index: 4,
      nextIndex: 0,
    },
  ];

  useEffect(() => {
    // const { appDownload } = window.localStorage || {};
    // const showAppDownload = appDownload
    //   ? moment(appDownload).isSameOrAfter(
    //       moment().subtract(10, "minutes").format()
    //     )
    //   : true;

    if (!user) {
      if (igParam) {
        setTimeout(() => {
          setShowModal(true);
        }, [4000]);
      } else {
        setShowModal(true);
      }
      // localStorage.setItem("appDownload", moment().format());
    }
  }, [user]);

  return (
    <>
      <CSSTransition in={showModal} timeout={250} classNames="fade">
        <img
          className="modal-login-page-click-overlay"
          style={{ zIndex: 998 }}
          src={image ? image : "/img/food.jpg"}
          onClick={() => setShowModal(false)}
          alt="EventHound Concert"
        />
      </CSSTransition>
      <CSSTransition in={showModal} timeout={250} classNames="top-slide">
        <div className="sign-in-modal-wrapper" style={{ zIndex: 999 }}>
          <div
            className="sliderValueCloseIcon"
            onClick={() => setShowModal(false)}
          >
            {getIcon("close")}
          </div>
          <img
            className="weekly-giveaway-eventhound-icon"
            alt="EventHound - Local EventHound"
            src="/img/icons/event-hound-icon.png"
          />

          <p className="user-onboarding-title">DON'T HAVE THE APP?</p>

          <div className="listing-flow-wrapper">
            <div className="user-onboarding-conatiner">
              <img
                className="user-onboarding-image listing-flow-image-placeholder"
                alt="EventHound - Listing Fow"
                src={"/img/listing_flow_1.png"}
              />
              <div className="listing-flow-image-shadow" />

              {listingFlowData.map(({ image, index, nextIndex }) => (
                <img
                  className={`user-onboarding-image listing-flow-image-${index} ${
                    index === 0 || listingFlowIndex === index
                      ? "listing-flow-image-show"
                      : ""
                  }`}
                  alt="EventHound - Listing Fow"
                  src={image}
                  onClick={() => setListingFlowIndex(nextIndex)}
                  key={image}
                />
              ))}
            </div>
          </div>

          <div className="business-onboarding-arrow-outer">
            <div className="business-onboarding-arrow-inner">
              {getFeatureIcon("skinny arrow")}
            </div>
          </div>

          <p className="user-onboarding-title">
            {
              listingFlowData[listingFlowIndex > -1 ? listingFlowIndex : 0]
                .title
            }
          </p>

          <div className="weekly-giveaway-buttons-container">
            <a
              className="weekly-giveaway-button-container"
              href="https://play.google.com/store/apps/details?id=com.eventhound"
              target="blank"
            >
              <img
                className="weekly-giveaway-icon"
                alt="EventHound - Local EventHound"
                src="/img/google-play.png"
              />
              <div className="weekly-giveaway-button-text-container">
                <p className="weekly-giveaway-button-text-title">GET IT ON</p>
                <p className="weekly-giveaway-button-text">Google Play</p>
              </div>
            </a>

            <a
              className="weekly-giveaway-button-container"
              href="https://apps.apple.com/us/app/eventhound/id1530831818"
              target="blank"
            >
              <div className="weekly-giveaway-icon">
                <AppleLogo />
              </div>
              <div className="weekly-giveaway-button-text-container">
                <p className="weekly-giveaway-button-text-title">
                  Download on the
                </p>
                <p className="weekly-giveaway-button-text">App Store</p>
              </div>
            </a>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};
