import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30"
    >
      <path
        d="M19.3,16.8l3.2-2.7c0.3-0.3,0.5-0.8,0.3-1.2c-0.2-0.4-0.5-0.7-1-0.8l-4.1-0.3l-1.6-3.9c-0.2-0.4-0.6-0.7-1.1-0.7l0,0
  c-0.5,0-0.9,0.3-1.1,0.7l-1.6,3.9l-4.1,0.3c-0.5,0-0.8,0.3-1,0.8c-0.2,0.4,0,0.9,0.3,1.2l3.2,2.7l-1,4.1c-0.1,0.4,0.1,0.9,0.4,1.2
  c0.4,0.3,0.9,0.3,1.2,0.1l3.6-2.2l3.6,2.2c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.5-0.1,0.7-0.2c0.4-0.3,0.5-0.7,0.4-1.2L19.3,16.8z
   M18.5,15.9L18.5,15.9c-0.3,0.3-0.5,0.7-0.4,1.1l0.9,3.9l-3.4-2.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2L11,20.9
  l0.9-3.9c0.1-0.4,0-0.8-0.4-1.1l-3-2.6l4-0.3c0.4,0,0.8-0.3,1-0.7L15,8.6l1.6,3.7c0.2,0.4,0.5,0.7,1,0.7l4,0.3L18.5,15.9z"
      />
      <path d="M15,4.3c0.3,0,0.6-0.3,0.6-0.6V0.8c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6v2.9C14.4,4.1,14.7,4.3,15,4.3z" />
      <path
        d="M6.6,7.4C6.7,7.5,6.9,7.6,7,7.6s0.3-0.1,0.4-0.2c0.2-0.2,0.2-0.7,0-0.9l-2-2c-0.2-0.2-0.7-0.2-0.9,0
  c-0.2,0.2-0.2,0.7,0,0.9L6.6,7.4z"
      />
      <path d="M3.7,14.3H0.8c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h2.9c0.3,0,0.6-0.3,0.6-0.6S4.1,14.3,3.7,14.3z" />
      <path
        d="M6.6,22.5l-2,2c-0.2,0.2-0.2,0.7,0,0.9c0.1,0.1,0.3,0.2,0.4,0.2s0.3-0.1,0.4-0.2l2-2c0.2-0.2,0.2-0.7,0-0.9
  C7.2,22.3,6.8,22.3,6.6,22.5z"
      />
      <path d="M15,25.7c-0.3,0-0.6,0.3-0.6,0.6v2.9c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6v-2.9C15.6,25.9,15.3,25.7,15,25.7z" />
      <path
        d="M23.4,22.6c-0.2-0.2-0.7-0.2-0.9,0c-0.2,0.2-0.2,0.7,0,0.9l2,2c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2
  c0.2-0.2,0.2-0.7,0-0.9L23.4,22.6z"
      />
      <path
        d="M29.2,14.4h-2.9c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h2.9c0.3,0,0.6-0.3,0.6-0.6C29.8,14.7,29.5,14.4,29.2,14.4z
  "
      />
      <path
        d="M23,7.7c0.2,0,0.3-0.1,0.4-0.2l2-2c0.2-0.2,0.2-0.7,0-0.9c-0.2-0.2-0.7-0.2-0.9,0l-2,2c-0.2,0.2-0.2,0.7,0,0.9
  C22.7,7.6,22.9,7.7,23,7.7z"
      />
    </svg>
  );
};
