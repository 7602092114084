import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import * as userActions from "../../../redux/actions/user-actions";

// Components
import SkeletonLoader from "../analytics-community/analytics-loader";
import DetailViewLayout from "../../components/detail-view-scroll-layout";
import SwitchButton from "../../components/common/switch-button";
import HorizontalBarChart from "../../components/chart/horizontal-bar-chart";
// import RadarChart from "../../components/chart/radar-chart";
import BarChart from "../../components/chart/bar-chart";
import AreaChart from "../../components/chart/area-chart";

// Utilities
import { analyticsGet } from "../../../api/analytics";
import { toTitleCase } from "../../../utilites/format";
import GetFilterIcon from "../../components/filter-icons";
import {
  convertDateToDbDate,
  formatDbDateToReadable,
} from "../../../utilites/date";

// Styles
import "../profile/profile.css";
import "../business/business.css";
import "./analytics-styles.css";

const BusinessProfile = ({ user }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isTotals, setIsTotals] = useState(true);
  const [combinedTotals, setCombinedTotals] = useState(null);
  const [totalsByDate, setTotalsByDate] = useState(null);
  const [businessTotals, setBusinessTotals] = useState(null);
  const [impressionsTotals, setImpressionsTotals] = useState(null);
  const [viewTotals, setViewTotals] = useState(null);
  const [promoTotals, setPromoTotals] = useState(null);
  // const [radarTotals, setRadarTotals] = useState(null);
  const [businessByDate, setBusinessByDate] = useState(null);
  const [byDate, setByDate] = useState(null);
  const { _id: businessId, images, name, ads = [] } = user;

  const getCombinedTotals = (analytics) => {
    const { seenTotals, viewTotals, promoViewsTotals } = analytics;
    const Impressions = Object.values(seenTotals).reduce(
      (acc, cur) => acc + cur,
      0
    );
    const Views = Object.values(viewTotals).reduce((acc, cur) => acc + cur, 0);
    const Promos = Object.values(promoViewsTotals).reduce(
      (acc, cur) => acc + cur,
      0
    );
    setCombinedTotals([
      { name: "Totals", Impressions, Views, "Promos Clicked": Promos },
    ]);
  };

  const getTotalsDate = (analytics) => {
    const { seenByDate, viewsByDate, promoViewsByDate } = analytics;
    const days = Object.keys(seenByDate).slice(-7);
    const currentDbDate = String(convertDateToDbDate());
    const dates = days.map((day) => ({
      day: day === currentDbDate ? "Today" : day.slice(-2),
      Impressions: seenByDate[day]
        ? Object.values(seenByDate[day]).reduce((acc, cur) => acc + cur, 0)
        : 0,
      Views: viewsByDate[day]
        ? Object.values(viewsByDate[day]).reduce((acc, cur) => acc + cur, 0)
        : 0,
      "Promos Clicked": promoViewsByDate[day]
        ? Object.values(promoViewsByDate[day]).reduce(
            (acc, cur) => acc + cur,
            0
          )
        : 0,
    }));
    setTotalsByDate(dates);
  };

  const getBusinesTotals = (analytics) => {
    const { seenTotals, viewTotals } = analytics;
    const Impressions = seenTotals[businessId] || 0;
    const Views = viewTotals[businessId] || 0;
    setBusinessTotals([{ name: "Totals", Impressions, Views }]);
  };

  const getImpressionTotals = (analytics) => {
    const { seenTotals } = analytics;
    const Impressions = ads.map(({ _id, title }) => ({
      name: toTitleCase({
        input: title,
      }),
      Totals: seenTotals[_id],
    }));
    setImpressionsTotals(Impressions);
  };

  const getViewTotals = (analytics) => {
    const { viewTotals } = analytics;
    const views = ads.map(({ _id, title }) => ({
      name: toTitleCase({
        input: title,
      }),
      Totals: viewTotals[_id],
    }));
    setViewTotals(views);
  };

  const getPromoTotals = (analytics) => {
    const { promoViewsTotals } = analytics;
    const views = ads.reduce((acc, { _id, title, promoCode }) => {
      if (!promoCode) return acc;
      return [
        ...acc,
        {
          name: toTitleCase({
            input: title,
          }),
          Totals: promoViewsTotals[_id],
        },
      ];
    }, []);
    setPromoTotals(views);
  };

  // const getRadarTotals = (analytics) => {
  //   const { seenTotals, viewTotals, promoViewsTotals } = analytics;
  //   const radar = ads.map(({ _id, title }) => ({
  //     title: toTitleCase({
  //       input: title,
  //     }),
  //     Impressions: seenTotals[_id] || 0,
  //     Views: viewTotals[_id] || 0,
  //     "Promos Clicked": promoViewsTotals[_id] || 0,
  //   }));
  //   setRadarTotals(radar);
  // };

  const getBusinesByDate = (analytics) => {
    const { seenByDate, viewsByDate } = analytics;
    const days = Object.keys(seenByDate).slice(-7);
    const currentDbDate = String(convertDateToDbDate());
    const data = days.map((day) => ({
      day: day === currentDbDate ? "Today" : day.slice(-2),
      Impressions: seenByDate[day] ? seenByDate[day][businessId] || 0 : 0,
      Views: viewsByDate[day] ? viewsByDate[day][businessId] || 0 : 0,
    }));
    setBusinessByDate(data);
  };

  const getByDate = (analytics) => {
    const { seenByDate, viewsByDate, promoViewsByDate } = analytics;
    const days = Object.keys(seenByDate).slice(-7);
    const currentDbDate = String(convertDateToDbDate());
    const adsByDate = ads.map(({ _id, title, createdDate, promoCode }) => ({
      name: toTitleCase({
        input: title,
      }),
      date: formatDbDateToReadable({ date: createdDate, isForUser: true }),
      days: days.map((day) => {
        const data = {
          day: day === currentDbDate ? "Today" : day.slice(-2),
          Impressions: seenByDate[day] ? seenByDate[day][_id] || 0 : 0,
          Views: viewsByDate[day] ? viewsByDate[day][_id] || 0 : 0,
        };
        if (promoCode)
          data["Promos Clicked"] = promoViewsByDate[day]
            ? promoViewsByDate[day][_id] || 0
            : 0;
        return data;
      }),
    }));
    setByDate(adsByDate);
  };

  const getAnalytics = async () => {
    setIsLoading(true);
    const { analytics } = await analyticsGet(businessId);
    const { seenByDate, viewsByDate } = analytics;
    if ("undefined" in seenByDate) delete seenByDate["undefined"];
    if ("undefined" in viewsByDate) delete viewsByDate["undefined"];
    if (analytics) {
      getCombinedTotals(analytics);
      getTotalsDate(analytics);
      getBusinesTotals(analytics);
      getImpressionTotals(analytics);
      getViewTotals(analytics);
      getPromoTotals(analytics);
      // getRadarTotals(analytics);
      getBusinesByDate(analytics);
      getByDate(analytics);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAnalytics();
  }, []);

  const handleClick = (type) => {
    setIsTotals(type === "Totals");
  };

  return (
    <DetailViewLayout images={images} name={name} showImage={false}>
      <div className="analytics-header-container">
        <div className="flex-center">
          <p className="analytics-title">{`${toTitleCase({
            input: name,
          })} Analytics`}</p>
        </div>
        <div
          className="analytics-reset-container"
          onClick={() => getAnalytics()}
        >
          {GetFilterIcon("refresh")}
        </div>
        <div className="analytics-selector-container">
          <SwitchButton
            button1Name="Totals"
            button2Name="Detailed"
            isFirst={isTotals}
            handleClick={handleClick}
          />
        </div>
      </div>

      {isLoading ? (
        <SkeletonLoader />
      ) : isTotals ? (
        <>
          <div className="listing-content-container">
            <div className="flex-center">
              <p className="analytics-title">Combined</p>
            </div>
            <div className="analytics-spacing">
              <HorizontalBarChart
                primaryKey="Impressions"
                secondaryKey="Views"
                promoKey="Promos Clicked"
                data={combinedTotals}
              />
            </div>
          </div>

          <div className="listing-content-container">
            <div className="flex-center">
              <p className="analytics-title">Combined Per Day</p>
            </div>
            <div className="analytics-spacing">
              <AreaChart
                primaryKey="Impressions"
                secomdaryKey="Views"
                promoKey="Promos Clicked"
                data={totalsByDate}
              />
            </div>
          </div>

          <div className="listing-content-container">
            <div className="flex-center">
              <p className="analytics-title">Per Ad Impressions</p>
            </div>
            <p className="text-center">
              Impressions are when your ad is seen in search results
            </p>
            <div className="analytics-spacing">
              <BarChart primaryKey="Totals" data={impressionsTotals} />
            </div>
          </div>

          <div className="listing-content-container">
            <div className="flex-center">
              <p className="analytics-title">Per Ad Views</p>
            </div>
            <div className="analytics-spacing">
              <BarChart secomdaryKey="Totals" data={viewTotals} />
            </div>
          </div>

          {promoTotals && promoTotals.length && (
            <div className="listing-content-container">
              <div className="flex-center">
                <p className="analytics-title">Per Ad Promo Clicks</p>
              </div>
              <div className="analytics-spacing">
                <BarChart promoKey="Totals" data={promoTotals} />
              </div>
            </div>
          )}

          {/* <div className="listing-content-container">
            <div className="flex-center">
              <p className="analytics-title">Per Ad Overlay</p>
            </div>
            <div className="analytics-spacing">
              <RadarChart
                primaryKey="Impressions"
                secomdaryKey="Views"
                promoKey="Promos Clicked"
                data={radarTotals}
              />
            </div>
          </div> */}

          <div className="listing-content-container">
            <div className="flex-center">
              <p className="analytics-title">Business</p>
            </div>
            <div className="analytics-spacing">
              <HorizontalBarChart
                primaryKey="Impressions"
                secondaryKey="Views"
                data={businessTotals}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="listing-content-container">
            <div className="flex-center">
              <p className="analytics-title">
                {toTitleCase({
                  input: name,
                })}
              </p>
            </div>
            <div className="analytics-spacing">
              <AreaChart
                primaryKey="Impressions"
                secomdaryKey="Views"
                data={businessByDate}
              />
            </div>
          </div>

          {byDate &&
            byDate.map(({ name, days }) => {
              const isPromoCode = days.find((day) => "Promos Clicked" in day);
              return (
                <div className="listing-content-container">
                  <div className="flex-center">
                    <p className="analytics-title">{name}</p>
                  </div>
                  <div className="analytics-spacing">
                    <AreaChart
                      primaryKey="Impressions"
                      secomdaryKey="Views"
                      promoKey={isPromoCode ? "Promos Clicked" : ""}
                      data={days}
                    />
                  </div>
                </div>
              );
            })}
        </>
      )}
    </DetailViewLayout>
  );
};

const mapStateToProps = (store) => ({
  user: store.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (loaderState) => dispatch(stateActions.setLoader(loaderState)),
  setMessage: (dataObj) => dispatch(stateActions.setMessage(dataObj)),
  setAds: (adsArray) => dispatch(userActions.setAds(adsArray)),
  setImage: (value) => dispatch(stateActions.setImage(value)),
  setPaymentModal: (boolean) => dispatch(stateActions.setPaymentModal(boolean)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BusinessProfile));
