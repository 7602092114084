import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";

// Components
import SocialConnectButton from "../profile/business/instagram/social-connect-button";
import CheckBox from "../../components/common/checkbox";
import Button from "../../components/common/button";
import EventHoundPost from "./eventhound-post";
import SocialPost from "./social-post";

// Utilities
import { isSafariAgent, isMobileAgent } from "../../../utilites";
import useGetBusinessStatus from "../../../utilites/hooks/UseGetBusinessStatus";
import getFeaturesIcon from "../../components/features-input/getFeatureIcon";

// Assets
// import PostButtonFlow from "../../../assets/svg/new-post-button-flow.js";

// Styles
import "./create-post.css";

const CreateListing = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { verified, social } = useSelector((store) => store.user.user);
  const [page, setPage] = useState("new post");
  const [isNextButton, setIsNextButton] = useState(true);
  const [eventhoundPost, setEventhoundPost] = useState(true);
  const [facebookPost, setFacebookPost] = useState(false);
  const [instagramPost, setInstagramPost] = useState(false);
  const isMobile = isMobileAgent();
  const isSafairMobile = isMobile && isSafariAgent();
  const { canPostAd } = useGetBusinessStatus();
  const height = `calc(100vh - ${isSafairMobile ? 150 : 40}px`;
  const { fbPageAccId, instagram } = social || {};
  const isFacebook = social && fbPageAccId;
  const isInstagram = social && instagram;
  const isSocial = isFacebook || isInstagram;

  useEffect(() => {
    let message = "";
    if (!verified) message = "Please veirfy your account";
    if (!canPostAd) message = "Upgrade account to post more";
    if (message) {
      dispatch(stateActions.setMessage({ messageType: "error", message }));
      history.push("/business/profile");
    }
  }, []);

  return (
    <div className="full-page-carousel-wrapper" style={{ height }}>
      <img
        className="carousel-page-background-image"
        alt="EventHound Concert"
        src={"/img/food.jpg"}
      />

      {page === "eventhound post" ? (
        <EventHoundPost
          setPage={setPage}
          instagramPost={instagramPost}
          facebookPost={facebookPost}
        />
      ) : page === "social post" ? (
        <SocialPost
          setPage={setPage}
          facebookPost={facebookPost}
          setFacebookPost={setFacebookPost}
          instagramPost={instagramPost}
          setInstagramPost={setInstagramPost}
        />
      ) : (
        <div className="carousel-container">
          <div className="carousel-item" style={{ height }}>
            <div className="carousel-business-space-container" />
            <div className="carousel-business-item-content-container">
              <div className="business-signup-header-container">
                <div className="business-signup-icon">
                  {getFeaturesIcon("logo")}
                </div>
                <h2 className="card-title-SignUp">NEW POST</h2>
                <p style={{ fontSize: "1.1rem", textAlign: "center" }}>
                  {isSocial
                    ? "Select where you'd like to post to"
                    : "Would you like to add this post to social media?"}
                </p>
              </div>

              {isSocial ? (
                <>
                  <div className="create-post-business-wrapper">
                    {/* <div
                      className={`create-post-business-button create-post-business-button-hover create-post-business-button-2 eventhound_background ${
                        eventhoundPost
                          ? "create-post-business-button-active"
                          : ""
                      }`}
                      onClick={() => setEventhoundPost(!eventhoundPost)}
                    >
                      <div className="social-login-icon-container">
                        {getFeaturesIcon()}
                      </div>
                      <p className="create-post-business-button-text">
                        EventHound
                      </p>
                    </div> */}

                    <div className="create-post-business-button-warpper">
                      <div className="create-post-business-checkbox-container">
                        <CheckBox
                          state={eventhoundPost}
                          handleClick={() => setEventhoundPost(!eventhoundPost)}
                        />
                      </div>
                      <div
                        className="create-post-business-button create-post-business-button-hover eventhound_background"
                        onClick={() => setEventhoundPost(!eventhoundPost)}
                      >
                        <div className="social-login-icon-container">
                          {getFeaturesIcon()}
                        </div>
                        <p className="create-post-business-button-text">
                          EventHound
                        </p>
                      </div>
                    </div>

                    {isFacebook && (
                      <div className="create-post-business-button-warpper">
                        <div className="create-post-business-checkbox-container">
                          <CheckBox
                            state={facebookPost}
                            handleClick={() => setFacebookPost(!facebookPost)}
                          />
                        </div>
                        <div
                          className="create-post-business-button create-post-business-button-hover facebook_background"
                          onClick={() => setFacebookPost(!facebookPost)}
                        >
                          <img
                            className="social-login-icon-container"
                            src={"/img/facebook-icon.png"}
                          />
                          <p className="create-post-business-button-text">
                            Facebook
                          </p>
                        </div>
                      </div>
                    )}

                    {/* <div
                      className={`create-post-business-button create-post-business-button-hover create-post-business-button-1 eventhound_background ${
                        eventhoundPost
                          ? "create-post-business-button-active"
                          : ""
                      }`}
                      onClick={() => setEventhoundPost(!eventhoundPost)}
                    >
                      <div className="social-login-icon-container">
                        {getFeaturesIcon()}
                      </div>
                      <p className="create-post-business-button-text">
                        EventHound
                      </p>
                    </div> */}

                    {isInstagram && (
                      <div className="create-post-business-button-warpper">
                        <div className="create-post-business-checkbox-container">
                          <CheckBox
                            state={instagramPost}
                            handleClick={() => setInstagramPost(!instagramPost)}
                          />
                        </div>
                        <div
                          className="create-post-business-button create-post-business-button-hover insatgram_background"
                          onClick={() => setInstagramPost(!instagramPost)}
                        >
                          <div className="social-login-icon-container">
                            {getFeaturesIcon("instagram")}
                          </div>
                          <p className="create-post-business-button-text">
                            Instagram
                          </p>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* <div
                      className={`create-post-flow-button-container ${
                        eventhoundPost
                          ? "create-post-flow-button-container-active"
                          : ""
                      }`}
                    >
                      <PostButtonFlow />
                    </div> */}
                </>
              ) : (
                <SocialConnectButton
                  style={{ margin: "10px 0px 5px 0px" }}
                  setIsNextButton={setIsNextButton}
                />
              )}

              {isNextButton && (
                <div className="carousel-business-button-container">
                  <Button
                    size={isMobile ? "small" : "medium"}
                    text="Cancel"
                    handleClick={() => history.goBack()}
                  />
                  <Button
                    size={isMobile ? "small" : "medium"}
                    text={isSocial ? "Next" : "SKIP"}
                    handleClick={() => {
                      if (eventhoundPost) return setPage("eventhound post");
                      if (facebookPost || instagramPost)
                        return setPage("social post");
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateListing;
