import React, { useEffect } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ProtectedBusinessRoute from "./protected-route/business";
import ProtectedBusinessVerifiedRoute from "./protected-route/business-verified";
import ProtectedBusinessPremiumRoute from "./protected-route/business-premium";
import ProtectedUserRoute from "./protected-route/user";
import RedirectRoute from "./redirect-route";

// Components
import Home from "../routes/home";
import Results from "../routes/list-view";
// import Instaworthy from "../routes/instaworthy-view";
import Map from "../routes/leaflet-map-view";
import Listing from "../routes/listing";
import Business from "../routes/business";
import BusinessAnalytics from "../routes/analytics-business";
import CommunityAnalytics from "../routes/analytics-community";
import Header from "../components/header";
import SideNavSlider from "../components/side-nav-slider";
import FooterNav from "../components/footer-navbar";
import Login from "../routes/login";
import UserSignUp from "../routes/sign-up/user";
import BusinessSignUp from "../routes/sign-up/business";
import ConfirmEmail from "../routes/confirm-email";
import BusinessProfile from "../routes/profile/business";
import EditBusiness from "../routes/edit-profile/business";
import CorporateBusiness from "../routes/corporate-business";
import CreateCorporateBusiness from "../routes/corporate-business/create";
import EditCorporateBusiness from "../routes/corporate-business/edit";
import CreatePost from "../routes/create-post";
import EditListing from "../routes/edit-listing";
import EditScheduledPost from "../routes/edit-schuled-post";
import UserProfile from "../routes/profile/user";
import EditUser from "../routes/edit-profile/user";
import PrivacyPolicy from "../routes/legal/privacy-policy";
import TermsOfService from "../routes/legal/terms-of-service";
import Recovery from "../routes/recovery";
import BusinessLandingStartUp from "../routes/landing-pages/business-start-up";
import WeeklyGiveAway from "../routes/landing-pages/weekly-give-away";
import SalesBusiness from "../routes/sales-business";
import ViewSalesBusiness from "../routes/sales-business/view";
import CreateSalesBusiness from "../routes/sales-business/create";
import EditSalesBusiness from "../routes/sales-business/edit";
import AppDownload from "../routes/landing-pages/app-download";

// Utilities
import { isMobileAgent, setTheme } from "../../utilites";

// Styles
import "./app.css";

const MobileRouter = ({ user, location }) => {
  const { pathname } = location;
  const rootPath = pathname.split("/")[1];
  const { darkTheme = false } = user || {};
  const isHome = pathname === "/";
  const fullLayout = isFullLayout();
  const isMobile = isMobileAgent();
  const isSideNav = !isMobile && !isHome && fullLayout;

  useEffect(() => {
    setTheme(darkTheme);
    if ("ReactNativeWebView" in window)
      window.ReactNativeWebView.postMessage(JSON.stringify({ darkTheme }));
  }, [pathname]);

  function isFullLayout() {
    switch (pathname.split("/")[1]) {
      case "":
        return true;
      case "top":
        return true;
      case "food":
        return true;
      case "drinks":
        return true;
      case "activities":
        return true;
      case "events":
        return true;
      case "map":
        return true;
      default:
        return false;
    }
  }

  return (
    <div className="page-wrapper-AppRouter no-scroll-bars">
      {rootPath && <Header />}
      {isSideNav && <SideNavSlider />}
      <Switch>
        <Route path="/" exact component={Home} />
        <Route
          path={["/top", "/food", "/drinks", "/activities", "/events"]}
          exact
          component={Results}
        />
        {/* <Route path="/instaworthy" exact component={Instaworthy} /> */}
        <Route
          path={[
            "/top/map",
            "/food/map",
            "/drinks/map",
            "/activities/map",
            "/events/map",
          ]}
          exact
          component={Map}
        />
        <Route path="/listing/:id" exact component={Listing} />
        <RedirectRoute path={"/login"} exact component={Login} />
        <RedirectRoute path="/user/sign-up" component={UserSignUp} />
        <RedirectRoute path={"/business/sign-up/"} component={BusinessSignUp} />
        <RedirectRoute path="/confirm-email" component={ConfirmEmail} />
        <ProtectedBusinessRoute
          path="/business/profile"
          component={BusinessProfile}
          exact
        />
        <ProtectedBusinessRoute
          path="/business/analytics"
          component={BusinessAnalytics}
          exact
        />
        <ProtectedBusinessPremiumRoute
          path="/business/community"
          component={CommunityAnalytics}
          exact
        />
        <ProtectedBusinessRoute
          path="/business/edit"
          component={EditBusiness}
        />
        <ProtectedBusinessRoute
          exact
          path="/business/corporate"
          component={CorporateBusiness}
        />
        <ProtectedBusinessRoute
          path="/business/corporate/create"
          component={CreateCorporateBusiness}
        />
        <ProtectedBusinessRoute
          path="/business/corporate/edit/:id"
          component={EditCorporateBusiness}
        />
        <ProtectedBusinessVerifiedRoute
          path="/create-post"
          component={CreatePost}
        />
        <ProtectedBusinessVerifiedRoute
          path="/business/post/:id"
          component={EditListing}
        />
        <ProtectedBusinessRoute
          exact
          path="/business/sales"
          component={SalesBusiness}
        />
        <ProtectedBusinessRoute
          exact
          path="/business/sales/:id"
          component={ViewSalesBusiness}
        />
        <ProtectedBusinessRoute
          exact
          path="/business/sales/create/:id"
          component={CreateSalesBusiness}
        />
        <ProtectedBusinessRoute
          exact
          path="/business/sales/edit/:adId/:id"
          component={EditSalesBusiness}
        />
        <ProtectedBusinessRoute
          exact
          path="/business/scheduled-post/:id"
          component={EditScheduledPost}
        />
        <ProtectedUserRoute path="/user/profile" component={UserProfile} />
        <ProtectedUserRoute path="/user/edit" component={EditUser} />
        <Route path="/business/:id" exact component={Business} />
        <Route path="/terms-of-service" exact component={TermsOfService} />
        <Route path="/privacy-policy" exact component={PrivacyPolicy} />
        <Route path="/recovery/:token" exact component={Recovery} />
        <Route
          exact
          path="/welcome/start-up"
          component={BusinessLandingStartUp}
        />
        <Route exact path="/find-your-fun" component={AppDownload} />
        <Route exact path="/weekly-giveaway" component={WeeklyGiveAway} />
        <Route
          path="*"
          component={() => (
            <div
              style={{
                width: "100vw",
                height: "90vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h2>Page Not Found</h2>
            </div>
          )}
        />
      </Switch>
      {fullLayout && <FooterNav />}
    </div>
  );
};

const mapStateToProps = (store) => ({
  user: store.user.user,
});

export default connect(mapStateToProps, null)(withRouter(MobileRouter));
