import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import lottie from "lottie-web";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import * as stateActions from "../../../../../redux/actions/state-actions";
import * as userActions from "../../../../../redux/actions/user-actions";

// Components
import Toggle from "../../../../components/common/toggle";
import Modal from "../../../../components/modal";

// Utilities
import useGetBusinessStatus from "../../../../../utilites/hooks/UseGetBusinessStatus";
import Debounce from "../../../../../utilites/hooks/debounce";
import { charLimitTo } from "../../../../../utilites/format";
import { updateAdInAds } from "../../../../../utilites/update";
import { updateListing } from "../../../../../api/listing";
import { setBusinessFree } from "../../../../../api/business";
import { verbiage } from "../../../../../constants/data-models";
import getIcon from "../../../../components/features-input/getFeatureIcon";

// Assets
import Trash from "../../../../../assets/svg/trash";
import Skyline from "../../../../../assets/svg/skyline";
import CirclePulse from "../../../../../assets/lotti/circle-pulse-2.json";

// Styles
import "../../../list-view/event-card/result.css";
import "../../user/saved-ads/saved-ad.css";
import "./posted-ad.css";

export default ({
  ad,
  ads,
  url = "/business/post/",
  verified,
  busniessIsActive,
  setAds,
  isToggle = true,
  handleDelete,
}) => {
  const { _id, isActive, title, categories } = ad;
  const { cat1Img } = categories;
  const { tickets = [] } = useSelector((store) => store.user.user);
  const isMounted = useRef(false);
  const isUniqueMark = useRef(false);
  const uniqueMark = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const { accountIsActive, accountType, canPostAd, uniqueListingIds } =
    useGetBusinessStatus();
  const [modalMessage, setModalMessage] = useState("");
  const [toggleValue, setToggleValue] = useState(isActive);
  const debouncedToggleValue = Debounce(toggleValue, 200);
  const isUniqueListing = !!uniqueListingIds[_id];
  const foundSales = !!tickets.find(({ listingId }) => listingId === _id);
  const canToggle = (() => {
    if (!verified || !busniessIsActive) return false;
    return isUniqueListing || canPostAd;
  })();

  useEffect(() => {
    setToggleValue(isActive);
    isMounted.current = true;
    if (uniqueMark.current && !isUniqueMark.current) {
      lottie.loadAnimation({
        container: uniqueMark.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
        animationData: CirclePulse,
      });
      isUniqueMark.current = true;
    }
  }, [isActive]);

  useEffect(() => {
    if (isActive !== debouncedToggleValue) {
      const updateAd = {
        ...ad,
        isActive: debouncedToggleValue,
      };
      setAds(updateAdInAds(ads, updateAd));
      updateListing(updateAd);
    }
  }, [debouncedToggleValue]);

  const getToggleMessage = () => {
    if (!verified) return "Please veirfy your account";
    if (!busniessIsActive) return "Change business to active status";
    if ((!accountIsActive && !isUniqueListing) || !canToggle)
      return "Upgrade account to have more active listings";
  };
  const handleToggle = (evt, value) => {
    if (verified && busniessIsActive) {
      if (!value || isUniqueListing) return setToggleValue(value);
      if (!accountIsActive && canToggle)
        return setModalMessage(verbiage.acceptFreeMonthlyAd);
      if (accountIsActive && accountType === "premium" && canToggle)
        return setModalMessage(verbiage.acceptPremiumMonthlyAd);
      if (canToggle) return setToggleValue(value);
    }
    setToggleValue(false);
    if (isMounted.current && !canToggle && value) {
      dispatch(
        stateActions.setMessage({
          messageType: "error",
          message: getToggleMessage(),
        })
      );
    }
  };

  const handleSetFreeAd = async () => {
    setModalMessage("");
    dispatch(stateActions.setLoader(true));
    const listingData = { listingId: _id, date: moment().format() };
    const { user } = await setBusinessFree(listingData);
    if (user) {
      dispatch(userActions.updateUser(user));
      dispatch(stateActions.setMessage({ message: "Your ad is active" }));
    }
    dispatch(stateActions.setLoader(false));
  };

  return (
    <>
      <div
        className={`card saved-ad-wrapper ${
          isActive ? "posted-ad-active" : "posted-ad-inactive"
        }`}
        style={{
          backgroundImage: `url(${cat1Img})`,
        }}
        onClick={() => history.push(`${url}${_id}`)}
      >
        {!cat1Img && (
          <div className="saved-ad-skyline-overlay">
            <Skyline />
          </div>
        )}

        <div className="result-card-overlay posted-ad-overlay-gradient" />

        {isActive && (
          <div className="result-card-overlay result-card-status-overlay result-card-overlay-active" />
        )}

        {isUniqueListing && (
          <div ref={uniqueMark} className="posted-ad-unique-mark" />
        )}

        <div className="saved-ad-container">
          {isToggle && verified ? (
            <div
              className="saved-ad-sub-container-split"
              style={{ alignItems: "flex-start" }}
            >
              <div className="posted-ad-flex-container"></div>
              <Toggle value={toggleValue} handleDebouceToggle={handleToggle} />
            </div>
          ) : (
            <div className="saved-ad-sub-container-split" />
          )}

          <div
            className="saved-ad-sub-container-split"
            style={{ position: "relative" }}
          >
            <p className="saved-ad-title">
              {charLimitTo(title, 35).toUpperCase()}
            </p>

            {foundSales && (
              <div className="saved-ad-sales">{getIcon("sale")}</div>
            )}
            <div
              className="profile-ad-delete-container"
              onClick={(evt) => {
                evt.stopPropagation();
                handleDelete(ad);
              }}
            >
              <Trash />
            </div>
          </div>
        </div>
      </div>

      <Modal
        directModalMessage={modalMessage}
        modalAccept="accept"
        modalCancel="Cancel"
        handleCancelClick={() => setModalMessage("")}
        handleAcceptClick={handleSetFreeAd}
      />
    </>
  );
};
