import React from "react";

// Styles
import "./svg.css";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
    >
      <g className="smallBusines0">
        <g className="smallBusines1">
          <rect
            x="3.1"
            y="3.1"
            className="smallBusines2"
            width="93.8"
            height="93.8"
          />
          <line className="smallBusines2" x1="50" y1="3.1" x2="50" y2="96.9" />
          <line className="smallBusines2" x1="96.9" y1="50" x2="3.1" y2="50" />
          <line
            className="smallBusines2"
            x1="3.1"
            y1="3.1"
            x2="96.9"
            y2="96.9"
          />
          <rect
            x="15.6"
            y="15.6"
            className="smallBusines2"
            width="68.8"
            height="68.8"
          />
          <line
            className="smallBusines2"
            x1="96.9"
            y1="3.1"
            x2="3.1"
            y2="96.9"
          />
          <line className="smallBusines2" x1="3.1" y1="50" x2="50" y2="3.1" />
          <line className="smallBusines2" x1="50" y1="3.1" x2="96.9" y2="50" />
          <line className="smallBusines2" x1="96.9" y1="50" x2="50" y2="96.9" />
          <line className="smallBusines2" x1="50" y1="96.9" x2="3.1" y2="50" />
          <circle className="smallBusines2" cx="50" cy="50" r="32.8" />
        </g>
      </g>
      <path
        className="smallBusines3"
        d="M50,59.4c-2,0-3.8,1.2-4.4,3.1h8.8C53.8,60.6,52,59.4,50,59.4z"
      />
      <path
        className="smallBusines3"
        d="M37.5,59.4c-2,0-3.8,1.2-4.4,3.1h8.8C41.2,60.6,39.5,59.4,37.5,59.4z"
      />
      <path
        className="smallBusines3"
        d="M20.6,62.5h8.8c-0.6-1.9-2.3-3.1-4.4-3.1S21.2,60.6,20.6,62.5z"
      />
      <path
        className="smallBusines3"
        d="M12.5,59.4c-2,0-3.8,1.2-4.4,3.1h8.8C16.2,60.6,14.5,59.4,12.5,59.4z"
      />
      <path
        className="smallBusines3"
        d="M83.1,62.5h8.8c-0.6-1.9-2.3-3.1-4.4-3.1S83.8,60.6,83.1,62.5z"
      />
      <path
        className="smallBusines3"
        d="M75,59.4c-2,0-3.8,1.2-4.4,3.1h8.8C78.8,60.6,77,59.4,75,59.4z"
      />
      <path
        className="smallBusines3"
        d="M62.5,59.4c-2,0-3.8,1.2-4.4,3.1h8.8C66.2,60.6,64.5,59.4,62.5,59.4z"
      />
      <path
        className="smallBusines3"
        d="M23.3,56.4c-0.6-1.9-2.5-3.3-4.5-3.3c-2.2,0-3.9,1.4-4.5,3.3c1.7,0.5,3.1,1.4,4.2,2.5
      C19.7,57.7,21.4,56.7,23.3,56.4z"
      />
      <path
        className="smallBusines3"
        d="M35.8,56.4c-0.6-1.9-2.5-3.3-4.5-3.3c-2.2,0-3.9,1.4-4.5,3.3c1.7,0.5,3.1,1.4,4.2,2.5
      C32.2,57.7,33.9,56.7,35.8,56.4z"
      />
      <path
        className="smallBusines3"
        d="M48.3,56.4c-0.6-1.9-2.5-3.3-4.5-3.3c-2.2,0-3.9,1.4-4.5,3.3c1.7,0.5,3.1,1.4,4.2,2.5
      C44.7,57.7,46.4,56.7,48.3,56.4z"
      />
      <path
        className="smallBusines3"
        d="M54.5,50.2c-0.6-1.9-2.5-3.3-4.5-3.3c-2.2,0-3.9,1.4-4.5,3.3c1.7,0.5,3.1,1.4,4.2,2.5
      C50.9,51.4,52.7,50.5,54.5,50.2z"
      />
      <path
        className="smallBusines3"
        d="M79.5,50.2c-0.6-1.9-2.5-3.3-4.5-3.3c-2.2,0-3.9,1.4-4.5,3.3c1.7,0.5,3.1,1.4,4.2,2.5
      C75.9,51.4,77.7,50.5,79.5,50.2z"
      />
      <path
        className="smallBusines3"
        d="M60.8,56.4c-0.6-1.9-2.5-3.3-4.5-3.3c-2.2,0-3.9,1.4-4.5,3.3c1.7,0.5,3.1,1.4,4.2,2.5
      C57.2,57.7,58.9,56.7,60.8,56.4z"
      />
      <path
        className="smallBusines3"
        d="M73.3,56.4c-0.6-1.9-2.5-3.3-4.5-3.3c-2.2,0-3.9,1.4-4.5,3.3c1.7,0.5,3.1,1.4,4.2,2.5
      C69.7,57.7,71.4,56.7,73.3,56.4z"
      />
      <path
        className="smallBusines3"
        d="M76.7,56.4c1.7,0.5,3.1,1.4,4.2,2.5c1.2-1.2,3-2.2,4.8-2.5c-0.6-1.9-2.5-3.3-4.5-3.3
      C79.1,53.1,77.3,54.5,76.7,56.4z"
      />
      <path
        className="smallBusines3"
        d="M29.5,50.2c-0.6-1.9-2.5-3.3-4.5-3.3c-2.2,0-3.9,1.4-4.5,3.3c1.7,0.5,3.1,1.4,4.2,2.5
      C25.9,51.4,27.7,50.5,29.5,50.2z"
      />
      <rect
        x="3.1"
        y="65.6"
        className="smallBusines3"
        width="93.8"
        height="31.2"
      />
      <circle className="smallBusines3" cx="50" cy="12.5" r="9.4" />
      <path
        className="smallBusines3"
        d="M37.5,53.1c1.1-1.6,2.8-2.7,4.8-3c0.6-3.6,3.9-6.4,7.7-6.4s7,2.8,7.7,6.4c2,0.3,3.6,1.4,4.8,3
      c1.1-1.6,2.8-2.7,4.8-3c0.3-1.4,0.9-2.8,1.9-3.8l-1.6-14.1c-0.3-2.5-2-4.7-4.5-5.3l-6.9-2L50,37.5L43.8,25l-6.9,2
      c-2.5,0.6-4.2,2.8-4.5,5.3l-1.6,14.1c0.9,0.9,1.6,2.3,1.9,3.8C34.7,50.5,36.2,51.6,37.5,53.1z"
      />
    </svg>
  );
};
