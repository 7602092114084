import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as stateActions from "../../../../../redux/actions/state-actions";
import * as userActions from "../../../../../redux/actions/user-actions";

// Utilities
import { isDev } from "../../../../../utilites/status";
import getFeaturesIcon from "../../../../components/features-input/getFeatureIcon";
import { businessLogin } from "../../../../../api/facebook";

// Styles
import "./instagram-styles.css";

export default ({ style = {}, setIsNextButton = () => {} }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.user);
  const [facebookConnect, setFacebookConnect] = useState(false);
  const [instagramConnect, setInstagramConnect] = useState(false);
  const { _id, social } = user || {};
  const { fbPageAccId, instagram } = social || {};
  const isFacebook = social && fbPageAccId;
  const isInstagram = social && instagram;
  const isConnectButton = facebookConnect || instagramConnect;
  const backgroundColor =
    facebookConnect && instagramConnect
      ? "both_social_background"
      : facebookConnect
      ? "facebook_background"
      : instagramConnect
      ? "insatgram_background"
      : "";

  const apiAuthenticate = async (accessToken) => {
    if (isDev()) console.log("test: is logged on ", accessToken);
    // is user all ready loged in
  };

  const failedLogin = async (responseData) => {
    if (isDev()) console.log("Flow failed Fail" + responseData.status);
    // return window.flowFacebookData.loginFailFunc(responseData);
  };

  const successfulLogin = async (responseData) => {
    dispatch(stateActions.setLoader(true));
    const userId = responseData.authResponse.userID;
    const accessToken = responseData.authResponse.accessToken;
    const data = { id: _id, userId, accessToken };
    const { user: updatedUser } = await businessLogin(data);
    if (updatedUser) dispatch(userActions.updateUser(updatedUser));
    setIsNextButton(true);
    dispatch(stateActions.setLoader(false));
  };

  const handleFbLogin = async () => {
    await window.FB.login(
      (response) => {
        if (response.status === "connected") {
          return successfulLogin(response);
        }
        return failedLogin(response);
      },
      {
        scope: `email,public_profile,pages_show_list,pages_read_engagement,pages_manage_posts${
          instagramConnect ? ",instagram_basic,instagram_content_publish" : ""
        }`,
      }
    );
  };

  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: isDev() ? "346042120390537" : "409107940127951",
        cookie: true,
        xfbml: true,
        version: "v8.0",
      });

      window.FB.getLoginStatus(({ authResponse }) => {
        if (authResponse) apiAuthenticate(authResponse.accessToken);
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  return isInstagram ? null : (
    <div className="social-media-login-conatiner" style={style}>
      <div className="new-post-social-share-wrapper">
        {!isFacebook && (
          <div className="new-post-social-share-container">
            <div
              className={`social-login-select-button create-post-business-button-hover ${
                facebookConnect ? "facebook_forground" : "facebook_background"
              }`}
              onClick={() => {
                setFacebookConnect(!facebookConnect);
                setIsNextButton(!(!facebookConnect || instagramConnect));
              }}
            >
              {!facebookConnect && (
                <div className="social-login-icon-container">
                  {getFeaturesIcon("facebook")}
                </div>
              )}
              <p className="create-post-business-button-text">Facebook</p>
            </div>

            {isConnectButton && (
              <div
                className="business-onboarding-arrow-outer"
                style={{ margin: "10px 0px" }}
              >
                <div
                  className={`create-post-arrow-inner ${
                    facebookConnect ? "" : "create-post-arrow-inner-inactive"
                  }`}
                >
                  {getFeaturesIcon("skinny arrow")}
                </div>
              </div>
            )}
          </div>
        )}

        <div className="new-post-social-share-container">
          <div
            className={`social-login-select-button create-post-business-button-hover ${
              instagramConnect ? "instagram_forground" : "insatgram_background"
            }`}
            onClick={() => {
              setInstagramConnect(!instagramConnect);
              setIsNextButton(!(facebookConnect || !instagramConnect));
            }}
          >
            {!instagramConnect && (
              <div className="social-login-icon-container">
                {getFeaturesIcon("instagram")}
              </div>
            )}
            <p className="create-post-business-button-text">Instagram</p>
          </div>

          {isConnectButton && (
            <div
              className="business-onboarding-arrow-outer"
              style={{ margin: "10px 0px" }}
            >
              <div
                className={`create-post-instgram-arrow-inner ${
                  instagramConnect ? "" : "create-post-arrow-inner-inactive"
                }`}
              >
                {getFeaturesIcon("skinny arrow")}
              </div>
            </div>
          )}
        </div>
      </div>

      {isConnectButton && (
        <div
          className={`social-media-button ${backgroundColor}`}
          onClick={handleFbLogin}
        >
          {facebookConnect && (
            <div
              className="social-login-button-icon-container"
              style={{ marginRight: "5px" }}
            >
              {getFeaturesIcon("facebook")}
            </div>
          )}
          <p>Click to Connect</p>
          {instagramConnect && (
            <div
              className="social-login-button-icon-container"
              style={{ marginLeft: "10px" }}
            >
              {getFeaturesIcon("instagram")}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
