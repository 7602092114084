const initialState = {
  isFree: true,
  loader: false,
  message: "",
  messageType: "",
  headerTitle: "",
  image: "",
  map: false,
  mapMoved: false,
  locationKey: "",
  pagePosition: 0,
  savedPage: "",
  paymentModal: false,
  purchaseModal: false,
  mapModal: false,
  covidModal: false,
  shareDrawer: null,
  promoCode: null,
  webView: false,
};

const stateReducer = (state = initialState, action) => {
  const { payload } = action || {};
  switch (action.type) {
    case "setLoader":
      return { ...state, loader: payload };

    case "setMessage":
      const { message, messageType = "" } = payload || {};
      return { ...state, message, messageType };

    case "setHeaderTitle":
      return { ...state, headerTitle: payload };

    case "setImage":
      return { ...state, image: payload || "" };

    case "setMap":
      return { ...state, map: payload };

    case "setMapMoved":
      return { ...state, mapMoved: payload || false };

    case "setLocationKey":
      return { ...state, locationKey: payload };

    case "setPagePosition":
      return { ...state, pagePosition: payload };

    case "setSavedPage":
      return { ...state, savedPage: payload };

    case "setPaymentModal":
      return { ...state, paymentModal: payload };

    case "setPurchaseModal":
      return { ...state, purchaseModal: payload };

    case "setMapModal":
      return { ...state, mapModal: payload };

    case "setCovidModal":
      return { ...state, covidModal: payload };

    case "setShareDrawer":
      return { ...state, shareDrawer: payload };

    case "setPromoCode":
      return { ...state, promoCode: payload };

    case "setWebView":
      return { ...state, webView: payload };

    default:
      return state;
  }
};

export default stateReducer;
