import React from "react";

// Utilities
import {
  militaryTimeToReadable,
  timeSelectTimeList,
  timeToMilitary,
} from "../../../../utilites/time";

// Styles
import "./time-selector.css";

const TimeSelector = ({
  name,
  startTime,
  endTime,
  label,
  value,
  handleChange,
  params,
  isWhiteText = false,
}) => {
  const displayTime = militaryTimeToReadable(value);
  const selectOptions = () => {
    return timeSelectTimeList(startTime, endTime).map((option, idx) => (
      <option value={option} key={idx}>
        {option}
      </option>
    ));
  };

  const handleSelect = (evt) => {
    const { value } = evt.target || {};
    const isEndTime = name === "close" || name === "end";
    const newMilitaryValue = timeToMilitary({ time: value, isEndTime });
    handleChange({ target: { value: newMilitaryValue } }, name, params);
  };

  return label ? (
    <div className="time-outer-wrapper-TimeSelector">
      <label>{label}</label>
      <select
        className={`time-select ${isWhiteText ? "isWhiteTextWhite" : ""}`}
        style={{ marginTop: "3px" }}
        value={displayTime}
        onChange={handleSelect}
      >
        {selectOptions()}
      </select>
    </div>
  ) : (
    <select
      className={`time-select ${isWhiteText ? "isWhiteTextWhite" : ""}`}
      value={displayTime}
      onChange={handleSelect}
    >
      {selectOptions()}
    </select>
  );
};

export default TimeSelector;
