import { useDispatch } from "react-redux";
import * as dataActions from "../../redux/actions/data-actions";

// Utilities
import { searchTicketMaster } from "../../api/ticketMaster";
import { searchStubhub } from "../../api/stubhub";
import { searchGrowth } from "../../api/growth";

export default () => {
  const dispatch = useDispatch();

  const distanceSearch = ({
    results,
    isEventsApi,
    searchOptions,
    searchMinDistance,
  }) =>
    new Promise(async (resolve, reject) => {
      if (!results || (results && !results.length)) return resolve([]);
      try {
        const newResults = [...results];
        const { dist: maxDistance = "" } = results.pop();

        let searchPromises = [
          searchGrowth({
            ...searchOptions,
            minDistance: searchMinDistance,
            maxDistance,
            skip: 0,
            limit: 50,
          }),
        ];

        if (isEventsApi) {
          searchPromises.push(
            searchTicketMaster({
              ...searchOptions,
              minDistance: searchMinDistance,
              maxDistance,
              skip: 0,
              limit: 50,
            })
          );

          searchPromises.push(
            searchStubhub({
              ...searchOptions,
              minDistance: searchMinDistance,
              maxDistance,
              skip: 0,
              limit: 50,
            })
          );
        } else {
          searchPromises.push({ results: [] });
          searchPromises.push({ results: [] });
        }

        const [
          { results: growthResults = [] },
          { results: ticketMasterResults = [] },
          { results: stubHubResults = [] },
        ] = await Promise.all(searchPromises);

        dispatch(dataActions.setSearchMinDistance(Math.ceil(maxDistance)));
        return resolve(
          [
            ...newResults,
            ...growthResults,
            ...ticketMasterResults,
            ...stubHubResults,
          ].sort(({ dist: distA }, { dist: distB }) => distA - distB)
        );
      } catch {
        return reject([]);
      }
    });

  const standardSearch = ({
    results,
    isEventsApi,
    searchOptions,
    searchLimit,
    growth,
    ticketMaster,
    stubhub,
  }) =>
    new Promise(async (resolve, reject) => {
      let newResults = [...results];
      try {
        if (isEventsApi) {
          if (ticketMaster > -1) {
            const { results: ticketMasterResults = [] } =
              await searchTicketMaster({
                ...searchOptions,
                skip: ticketMaster ? ticketMaster + 1 : ticketMaster,
              });
            const newTicketMasterIndex =
              ticketMasterResults.length < searchLimit
                ? -1
                : ticketMaster + ticketMasterResults.length;
            dispatch(dataActions.setTicketMasterIndex(newTicketMasterIndex));
            newResults = [...newResults, ...ticketMasterResults];
          }

          if (stubhub > -1) {
            const { results: stubhubResults = [] } = await searchStubhub({
              ...searchOptions,
              skip: stubhub ? stubhub + 1 : stubhub,
            });
            const newStubHubIndex =
              stubhubResults.length < searchLimit
                ? -1
                : stubhub + stubhubResults.length;
            dispatch(dataActions.setStubHubIndex(newStubHubIndex));
            newResults = [...newResults, ...stubhubResults];
          }
        }

        if (growth > -1) {
          const { results: growthResults = [] } = await searchGrowth({
            ...searchOptions,
            skip: growth ? growth + 1 : growth,
          });
          const newGrowthIndex =
            growthResults.length < searchLimit
              ? -1
              : growth + growthResults.length;
          dispatch(dataActions.setGrowthIndex(newGrowthIndex));
          newResults = [...newResults, ...growthResults];
        }

        return resolve(newResults);
      } catch {
        return reject([]);
      }
    });

  return { distanceSearch, standardSearch };
};
