import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import * as userActions from "../../../redux/actions/user-actions";

// Components
import ViewLayout from "../../components/view-layout";
import Button from "../../components/common/button";
import PostedAd from "../profile/business/posted-ad";

// Utilities
import getIcon from "../../components/features-input/getFeatureIcon";
import { formatPhoneNumber } from "../../../utilites/format";
import { getAccount, getStripeSetUp } from "../../../api/sales";

// Styles
import "./sales-business-styles.css";

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const isCompleted = urlParams.get("completed");
  const isRedirect = urlParams.get("isRedirect");
  const { user } = useSelector((store) => store.user);
  const { _id, account, ads = [], tickets = [], phone, email } = user || {};
  const { stripe } = account || {};
  const { payouts_enabled } = stripe || {};

  useEffect(() => {
    if (isCompleted) {
      (async () => {
        dispatch(stateActions.setLoader(true));
        const { account } = await getAccount(_id);
        if (account) {
          dispatch(userActions.updateUser({ account }));
        }
        dispatch(stateActions.setLoader(false));
      })();
    }
    if (isRedirect)
      dispatch(
        stateActions.setMessage({
          messageType: "error",
          message: "Internal Server Error",
        })
      );
  }, []);

  const handleSetupStripe = async () => {
    dispatch(stateActions.setLoader(true));
    const { url } = await getStripeSetUp(phone, email);
    window.location.replace(url);
  };

  const renderSales = () => {
    const noSalesAds = [];
    ads.forEach((ad) => {
      const { _id: adId } = ad;
      const hasTickets = tickets.find(({ listingId }) => listingId === adId);
      if (hasTickets) {
        noSalesAds.unshift(ad);
      } else {
        noSalesAds.push(ad);
      }
    });

    return noSalesAds.map((ad, idx) => (
      <div className="tickets-ad-container">
        <PostedAd
          ad={ad}
          url="/business/sales/"
          isToggle={false}
          isStatus={false}
          key={idx}
        />
      </div>
    ));
  };

  return (
    <ViewLayout>
      {payouts_enabled ? (
        <>
          <div className="corporate-location--wrapper">
            <div className="card card-form created-ticket-card">
              <div className="card-info-form">
                <div className="corporate-title-container">
                  <h2>MANAGE SALES</h2>
                </div>
                <p className="tickets-description-text">
                  Select the posting below that you would like to promote with
                  sales. You can also edit the sales of a posting simply by
                  clicking on it. If you you don't have a post created, use the
                  button below to create a posting before adding sales to it.
                </p>
                <div className="strip-onboarding-button-container">
                  <Button
                    text="Manage Funds"
                    handleClick={() => history.push("/create-post")}
                  />
                  <div style={{ width: "15px" }} />
                  <Button
                    text="New Posting"
                    handleClick={() => history.push("/create-post")}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="corporate-location--wrapper">{renderSales()}</div>
        </>
      ) : (
        <div className="strip-onboarding-tagline-wrapper">
          <div className="strip-onboarding-card">
            <div className="card-info-form">
              <div className="strip-onboarding-logo-wrapper">
                <img
                  className="strip-onboarding-logo"
                  alt="Stripe"
                  src="/img/stripe.png"
                />
                <h4 className="strip-onboarding-logo-text">+</h4>
                <div className="strip-onboarding-eventhopund-name">
                  {getIcon("Name")}
                </div>
              </div>

              <div className="strip-onboarding-tagline-wrapper">
                <p className="strip-onboarding-tagline">
                  Fast Secure Payments & Financial Services
                </p>
              </div>

              <p className="strip-onboarding-description-text">
                Before you begin selling with us, you will need to set up your
                payouts with Stripe, an online payment processing platform.
                Payouts are immediately processed and go directly to your bank
                account. EventHound charges a service fee of $2 + 10% of each
                item sold. Our goal is to make selling with EventHound
                efficient, simple and effective allowing your business to get
                the max return. Start selling now!
              </p>

              <div className="strip-onboarding-button-container">
                <Button
                  styles={{ marginTop: "5px" }}
                  text="cancel"
                  handleClick={() => history.goBack()}
                />
                <div style={{ width: "15px" }} />
                <Button
                  styles={{ marginTop: "5px" }}
                  text="Setup payouts on stripe"
                  handleClick={handleSetupStripe}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </ViewLayout>
  );
};
