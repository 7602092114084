import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
    >
      <path
        d="M83.3,0H16.7C7.5,0,0,7.5,0,16.7v66.7C0,92.5,7.5,100,16.7,100h66.7c9.2,0,16.7-7.5,16.7-16.7V16.7C100,7.5,92.5,0,83.3,0z
	 M91.7,83.3c0,4.6-3.7,8.3-8.3,8.3H16.7c-4.6,0-8.3-3.7-8.3-8.3V16.7c0-4.6,3.7-8.3,8.3-8.3h66.7c4.6,0,8.3,3.7,8.3,8.3V83.3z"
      />
      <path
        d="M50,25c-13.8,0-25,11.2-25,25s11.2,25,25,25s25-11.2,25-25S63.8,25,50,25z M50,66.7c-9.2,0-16.7-7.5-16.7-16.7
	S40.8,33.3,50,33.3S66.7,40.8,66.7,50S59.2,66.7,50,66.7z"
      />
      <circle cx="77.1" cy="22.9" r="6.2" />
    </svg>
  );
};
