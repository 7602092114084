import React from "react";
import { useDispatch } from "react-redux";
import * as stateActions from "../../../../redux/actions/state-actions";

// Components
import TextExpand from "../../../components/common/text-expand";

// Utilities
import { toTitleCase } from "../../../../utilites/format";

// Styles
import "./tickets-styles.css";

export default ({ sale, ad, businessId }) => {
  const dispatch = useDispatch();
  const { categories, title, businessName } = ad;
  const { cat1Img: image } = categories;
  const {
    isTicket,
    name,
    hours,
    start,
    end,
    originalPrice,
    price,
    description,
    redeemablePeriod,
  } = sale;
  const time = `${start} - ${end}`;

  const voucherDays = () => {
    let availableDays = "";
    for (const day in hours) {
      if (hours[day].isOpen) {
        if (availableDays.length) availableDays += ", ";
        availableDays += day;
      }
    }
    return availableDays;
  };

  return (
    <div
      className="pointer ticket-sale-container"
      style={{ backgroundImage: `url(${image})` }}
      onClick={() =>
        dispatch(
          stateActions.setPurchaseModal({
            ...sale,
            title,
            businessId,
            businessName,
            time,
            image,
          })
        )
      }
    >
      <div className="ticket-sale-overlay" />
      <div className="ticket-content-container">
        <div className="ticket-sale-title-container">
          <p className="ticket-sale-title">{toTitleCase({ input: name })}</p>
          <div className="ticket-sale-price-container">
            {!!originalPrice && (
              <p className="ticket-sale-originalPrice">
                {price === 0 ? "Free" : `$${originalPrice}`}
              </p>
            )}
            <p className="ticket-sale-price">
              {price === 0 ? "Free" : `$${price}`}
            </p>
          </div>
        </div>
        <div className="ticket-sale-title-container">
          <div>
            {!isTicket && <p className="ticket-sale-times">{voucherDays()}</p>}
            <p className="ticket-sale-times">{time}</p>
          </div>
          {!!redeemablePeriod ? (
            <p className="ticket-sale-times ticket-redemption-text">{`Redeemable for ${redeemablePeriod} days`}</p>
          ) : (
            <div />
          )}
        </div>
        <TextExpand text={description} constainerStyle={{ marginTop: "7px" }} />
      </div>
    </div>
  );
};
