import React, { useState, useEffect } from "react";
// import { Analytics } from "aws-amplify";
import { connect } from "react-redux";
import moment from "moment";
import * as dataActions from "../../../redux/actions/data-actions";

// Components
import Select from "../common/select";
import GetFilterIcon from "../../components/filter-icons";
import getFeaturesIcon from "../features-input/getFeatureIcon";
import GetCategoryIcon from "../../components/category-icons";
import BottomDrawer from "../../components/bottom-drawer";
import Toggle from "../../components/common/toggle";

// Utilities
import { toTitleCase } from "../../../utilites/format";
import { getCurrentDate } from "../../../utilites/date";
import { getCurrentTime, filterTimeList } from "../../../utilites/time";
import {
  searchTypes,
  subCatOptions,
  features,
  getFilterList,
} from "../../../constants/data-models";
import getAnalytics from "../../../utilites/hooks/analytics";
import Debounce from "../../../utilites/hooks/debounce";

// Assets
import FiltersIcon from "../../../assets/svg/filters";

// Styles
import "./header.css";

const Filters = ({
  path,
  isLiveSearch,
  setIsLiveSearch,
  filters,
  setFilter,
  isDefaultFilters,
  resetFilters,
}) => {
  const { type, subCat, feature, date, time, distance, sortBy, limit } =
    filters || {};
  const [hasMounted, setHasMounted] = useState(false);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [dateInput, setDateInput] = useState(date);
  const debouncedDate = Debounce(dateInput, 400);
  const isMap = path.indexOf("map") > -1;
  const isTop = path.indexOf("top") > -1;
  const mainCategory = toTitleCase({ input: path.split("/")[1] });
  const subCatList = subCatOptions[mainCategory];
  const currentDate = getCurrentDate();
  const currentTime = getCurrentTime();
  const userAnalytics = getAnalytics();

  useEffect(() => {
    if (hasMounted) document.getElementById("filter-wrapper").scrollLeft = 0;
  }, [path]);

  useEffect(() => {
    if (hasMounted) {
      const isTimeBefore = time
        ? moment(moment(time, "h:mma")).isBefore(moment(currentTime, "h:mma"))
        : false;
      const newTime = !debouncedDate && isTimeBefore ? "" : time;
      setFilter({ date: debouncedDate, time: newTime });
    }
    setHasMounted(true);
  }, [debouncedDate]);

  const handleChange = (evt, type, name) => {
    const { value } = evt.target;
    switch (type) {
      case "select":
        if (name === "sortBy" && value === "Distance") {
          setFilter({ distance: "25 Miles" });
        }
        setFilter({ [name]: value });
        // if (userAnalytics)
        //   Analytics.record({
        //     name: "Filter",
        //     attributes: { path, subCat: value, location: userAnalytics },
        //   });
        break;
      case "dateFilterInput":
        const isDayBefore = moment(value).isBefore(currentDate, "d");
        const isDayToday = moment(value).isSame(currentDate, "d");
        const newDate = isDayBefore || isDayToday ? "" : value;
        return setDateInput(newDate);
    }
  };

  const handleIsLiveSearchToggle = (evt, value) => {
    evt.preventDefault();
    setIsLiveSearch(value);
  };

  const handleResetFilters = () => {
    setDateInput("");
    resetFilters();
  };

  const createFilters = () => {
    const values = [time || currentTime, distance, sortBy, limit];
    const filterList = getFilterList(values, filterTimeList(date), type);
    const isBusinessSearch = type === "Business";
    if (isTop || isBusinessSearch) delete filterList["Sort By"];
    if (isMap) {
      delete filterList.Distance;
      delete filterList["Sort By"];
    }
    if (!isMap) {
      delete filterList.Limit;
    }
    if (sortBy === "Distance") {
      delete filterList.Distance;
    }

    return Object.values(filterList).map(({ name, options, value }, idx) => (
      <Select
        classNames="filter-select-container"
        options={options}
        value={value}
        handleChange={(evt) => handleChange(evt, "select", name)}
        key={idx}
      >
        {GetFilterIcon(name)}
      </Select>
    ));
  };

  return (
    <>
      <div
        id="mobile-header-filters"
        className="header-el-wrapper header-el-shadow"
      >
        <div className="filter-outer-wrapper-MobileHeader">
          <div
            id="filter-wrapper"
            className="no-scroll-bars filter-inner-wrapper-MobileHeader filter-small-mobile"
          >
            <Select
              isActive={type !== ""}
              classNames="filter-select-container"
              options={["", ...searchTypes]}
              value={type}
              handleChange={(evt) => handleChange(evt, "select", "type")}
              styles={{ paddingLeft: "8px" }}
              name={"Search Types"}
            >
              {getFeaturesIcon(type)}
            </Select>

            <div
              className="filter-button-container"
              onClick={() => setDrawerIsOpen(true)}
            >
              <div className="filter-button-icon-container">
                <FiltersIcon />
              </div>
              <div className="filter-button-text-container">
                <p className="filter-button-text">Filters</p>
                <p className="filter-button-text-active">
                  {`- ${isLiveSearch ? "Live Search" : "Full Search"}`}
                </p>
              </div>
            </div>

            {!isDefaultFilters && (
              <div
                className="filter-reset-container"
                onClick={handleResetFilters}
              >
                <div className="filter-reset-icon-container">
                  {GetFilterIcon("refresh")}
                </div>
                <p className="filter-reset-text">Clear</p>
              </div>
            )}
            <div className="filter-spacer" />
          </div>

          <div
            id="filter-wrapper"
            className="no-scroll-bars filter-inner-wrapper-MobileHeader filter-large-mobile"
          >
            <Select
              isActive={!!type}
              classNames="filter-select-container"
              options={["", ...searchTypes]}
              value={type}
              handleChange={(evt) => handleChange(evt, "select", "type")}
              styles={{ paddingLeft: "8px" }}
              name={"Types"}
            >
              {getFeaturesIcon(type)}
            </Select>

            {subCatList && (
              <Select
                isActive={!!subCat}
                classNames="filter-select-container"
                options={["", ...subCatList]}
                value={subCat}
                handleChange={(evt) => handleChange(evt, "select", "subCat")}
                name={mainCategory}
              >
                {GetCategoryIcon(mainCategory)}
              </Select>
            )}

            <Select
              isActive={!!feature}
              classNames="filter-select-container"
              options={["", ...features]}
              value={feature}
              handleChange={(evt) => handleChange(evt, "select", "feature")}
              styles={{ paddingLeft: "8px" }}
              name={"Features"}
            >
              {!feature ? GetFilterIcon("features") : getFeaturesIcon(feature)}
            </Select>

            {createFilters()}

            {!isDefaultFilters && (
              <div
                className="filter-reset-container"
                onClick={handleResetFilters}
              >
                <div className="filter-reset-icon-container">
                  {GetFilterIcon("refresh")}
                </div>
                <p className="filter-reset-text">Clear</p>
              </div>
            )}
            <div className="filter-spacer" />
          </div>
        </div>
      </div>

      <BottomDrawer
        title="Filters"
        isOpen={drawerIsOpen}
        handleCancelClick={() => setDrawerIsOpen(false)}
        isTall
      >
        <div className="drawer-filters-toggle-container">
          <Toggle
            value={isLiveSearch}
            handleToggle={handleIsLiveSearchToggle}
          />
          <p className="drawer-filters-toggle-title">
            {isLiveSearch ? "Live Search" : "Full Search"}
          </p>
        </div>
        <p className="drawer-filters-toggle-text">
          {isLiveSearch
            ? "What's currently happening"
            : "Searching from the selected date on"}
        </p>

        <div className="drawer-filters-wrapper">
          {!isLiveSearch && (
            <div className="filter-date-container">
              <div className="filter-date-svg-container">
                {GetFilterIcon("date")}
              </div>
              <input
                className="filter-date-input filter-select-container"
                type="date"
                value={dateInput || currentDate}
                onChange={(evt) => handleChange(evt, "dateFilterInput")}
                placeholder="yyyy-mm-dd"
              />
            </div>
          )}

          {subCatList && (
            <Select
              isActive={!!subCat}
              classNames="filter-select-container"
              options={["", ...subCatList]}
              value={subCat}
              handleChange={(evt) => handleChange(evt, "select", "subCat")}
              name={mainCategory}
            >
              {GetCategoryIcon(mainCategory)}
            </Select>
          )}

          <Select
            isActive={!!feature}
            classNames="filter-select-container"
            options={["", ...features]}
            value={feature}
            handleChange={(evt) => handleChange(evt, "select", "feature")}
            name={"Features"}
          >
            {!feature ? GetFilterIcon("features") : getFeaturesIcon(feature)}
          </Select>

          {createFilters()}

          {!isDefaultFilters && (
            <div
              className="drawer-filters-container filter-reset-container-secondary"
              onClick={handleResetFilters}
            >
              <p className="filter-reset-text">CLEAR FILTERS</p>
            </div>
          )}
          <div
            className="drawer-filters-container drawer-filters-close-container"
            onClick={() => setDrawerIsOpen(false)}
          >
            <p className="filter-reset-text">CLOSE</p>
          </div>
        </div>
      </BottomDrawer>
    </>
  );
};

const mapStateToProps = (store) => ({
  isLiveSearch: store.data.isLiveSearch,
  filters: store.data.filters,
  isDefaultFilters: store.data.isDefaultFilters,
});

const mapDispatchToProps = (dispatch) => ({
  setFilter: (filterObj) => dispatch(dataActions.setFilter(filterObj)),
  resetFilters: () => dispatch(dataActions.resetFilters()),
  setIsLiveSearch: (value) => dispatch(dataActions.setIsLiveSearch(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
