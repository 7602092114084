import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 70.7 72.3"
    >
      <path
        d="M65.3,6.9l-1.2-1.2C62.4,4,60,3,57.5,3c0,0,0,0,0,0c-2.5,0-4.8,1-6.6,2.7L49,7.7c0,0,0,0,0,0l-3.7,3.7c0,0,0,0,0,0l-5,5
		H5.4c-1.9,0-3.4,1.5-3.4,3.4v45.2c0,1.9,1.5,3.4,3.4,3.4h45.2c1.9,0,3.4-1.5,3.4-3.4V31.4l11.3-11.3C68.9,16.5,68.9,10.5,65.3,6.9z
		 M52,64.9c0,0.8-0.6,1.4-1.4,1.4H5.4c-0.8,0-1.4-0.6-1.4-1.4V19.8c0-0.8,0.6-1.4,1.4-1.4h32.9L24,32.7c0,0,0,0,0,0
		c-0.2,0.2-0.3,0.4-0.3,0.6l-1,11.6c-0.1,0.9,0.3,1.8,0.9,2.5c0.6,0.6,1.4,0.9,2.2,0.9c0.1,0,0.2,0,0.3,0l11.6-1
		c0.2,0,0.4-0.1,0.6-0.3c0,0,0,0,0,0L52,33.4V64.9z M35.3,45.6l-4.4,0.4l-5.8-5.8l0.4-4.4l3.2,3.2L35.3,45.6z M59,23.6L47.5,12.1
		l2.2-2.2l11.5,11.5L59,23.6z"
      />
      <path d="M35.9,61.4c0,0.6,0.4,1,1,1H47c0.6,0,1-0.4,1-1V51.3c0-0.6-0.4-1-1-1s-1,0.4-1,1v9.1h-9.1C36.4,60.4,35.9,60.8,35.9,61.4z" />
      <path d="M19.1,22.4H9c-0.6,0-1,0.4-1,1v10.1c0,0.6,0.4,1,1,1s1-0.4,1-1v-9.1h9.1c0.6,0,1-0.4,1-1S19.6,22.4,19.1,22.4z" />
      <path d="M19.1,60.4H10v-9.1c0-0.6-0.4-1-1-1s-1,0.4-1,1v10.1c0,0.6,0.4,1,1,1h10.1c0.6,0,1-0.4,1-1S19.6,60.4,19.1,60.4z" />
    </svg>
  );
};
