import { callAPI } from ".";
import { baseURL } from "./url";

const oauthURL = baseURL("/user/oauth");
const appleURL = baseURL("/user/apple");
const facebookURL = baseURL("/user/facebook");
const googleURL = baseURL("/user/google");
const userURL = baseURL("/user");
const bookmarksURL = baseURL("/bookmarks");

export function oauthLogin({ apple, facebook, google }) {
  let url = `${oauthURL}?apple=${apple}`;
  if (facebook) url = `${oauthURL}?facebook=${facebook}`;
  if (google) url = `${oauthURL}?google=${google}`;
  return callAPI(url, "GET");
}

export function createOauthUser(oauthData) {
  return callAPI(oauthURL, "PUT", oauthData);
}

export function appleLogin(userData) {
  return callAPI(appleURL, "POST", userData);
}

export function facebookLogin(userData) {
  return callAPI(facebookURL, "POST", userData);
}

export function googleLogin(userData) {
  return callAPI(googleURL, "POST", userData);
}

export function userLogin(userData) {
  return callAPI(`${userURL}/login`, "post", userData);
}

export function createUser(userData) {
  return callAPI(userURL, "PUT", userData);
}

export function updateUser(userData) {
  if ("token" in userData) delete userData.token;
  return callAPI(userURL, "POST", userData);
}

export function deleteUser({ email, password }) {
  let url = `${userURL}?email=${email}&password=${password}`;
  return callAPI(url, "DELETE");
}

export function getUserPromoId(email) {
  let url = `${userURL}/promo?email=${email}`;
  return callAPI(url, "GET");
}

export function bookmarksGet(ids) {
  return callAPI(bookmarksURL, "POST", { ids });
}
