import { callAPI } from ".";
import { baseURL } from "./url";
import { formatQueryParams } from "../utilites/format";

const ticketMasterURL = baseURL("/ticketmaster");

export function searchTicketMaster(params) {
  const url = `${ticketMasterURL}?${formatQueryParams(params)}`;
  return callAPI(url, "GET");
}

export function searchTicketMasterMap(params) {
  const url = `${ticketMasterURL}/map?${formatQueryParams(params)}`;
  return callAPI(url, "GET");
}

export function getTicketMasterDetails(id) {
  const url = `${ticketMasterURL}/details?id=${id}`;
  return callAPI(url, "GET");
}
