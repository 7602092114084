import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
    >
      <path
        className="st0"
        d="M50,98c26.5,0,48-21.5,48-48S76.5,2,50,2S2,23.5,2,50S23.5,98,50,98z M50,20c1.7,0,3,1.3,3,3v24.8l0,0
		l14.5,14.5c1.2,1.2,1.2,3.1,0,4.2c-1.2,1.2-3.1,1.2-4.2,0c0,0,0,0,0,0L47,50l0,0V23C47,21.3,48.3,20,50,20z"
      />
    </svg>
  );
};
