import React from "react";
import { connect } from "react-redux";
import { Draggable } from "react-beautiful-dnd";
import * as stateActions from "../../../../redux/actions/state-actions";
import * as userActions from "../../../../redux/actions/user-actions";

// Utilities
import { storeImage, deleteImage } from "../../../../api/image";
import { updateBusiness } from "../../../../api/business";
import { updateListing } from "../../../../api/listing";
import { updateAdInAds } from "../../../../utilites/update";

// Assets
import Camera from "../../../../assets/svg/camera";
import Close from "../../../../assets/svg/close";

// Styles
import "../../../components/profile-image/profile-image.css";

const ListingProfileImage = ({
  isBusiness,
  input,
  image = "",
  user,
  setLoader,
  updateUser,
  index,
  setMessage,
}) => {
  const { images } = input || {};

  async function handleChange(evt) {
    setLoader(true);
    const file = evt.target.files[0];
    const imageUrl = await storeImage(
      file,
      isBusiness ? "business" : "listing"
    );
    if (imageUrl) {
      if (isBusiness) {
        setTimeout(async () => {
          const userUpdate = { ...input, images: [...images, imageUrl] };
          const { user: updatedUser } = await updateBusiness(userUpdate);
          if (updatedUser) updateUser(updatedUser);
          setLoader(false);
        }, 2000);
      } else {
        setTimeout(async () => {
          const { ads } = user;
          const adUpdate = { ...input, images: [...images, imageUrl] };
          const { ad: updatedAd } = await updateListing(adUpdate);
          if (updatedAd)
            updateUser({ ...user, ads: updateAdInAds(ads, updatedAd) });
          setLoader(false);
        }, 2000);
      }
    } else {
      setLoader(false);
      setMessage({ messageType: "error", message: "Image Error" });
    }
  }

  const handleDeleteClick = async () => {
    setLoader(true);
    if (image) {
      const stdDelete = await deleteImage(image);
      const largeDelete = await deleteImage(`${image}-large`);
      await Promise.all([stdDelete, largeDelete]);

      if (isBusiness) {
        const userUpdate = {
          ...input,
          images: images.filter((img) => img !== image),
        };
        const { user: updatedUser } = await updateBusiness(userUpdate);
        updateUser(updatedUser);
      } else {
        const { ads } = user;
        const adUpdate = {
          ...input,
          images: images.filter((img) => img !== image),
        };
        const { ad: updatedAd } = await updateListing(adUpdate);
        updateUser({ ...user, ads: updateAdInAds(ads, updatedAd) });
      }
    }
    setLoader(false);
  };

  return (
    <>
      {image ? (
        <Draggable draggableId={String(index)} index={index}>
          {(provided, snapshot) => (
            <div
              className="profile-image-wrapper"
              ref={provided.innerRef}
              isDragging={snapshot.isDragging}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <div className="profile-image-delete" onClick={handleDeleteClick}>
                <Close />
              </div>
              <div
                className={`profile-image-container ${
                  !index ? "profile-image-container-main" : ""
                } ${
                  snapshot.isDragging ? "profile-image-container-dragging" : ""
                }`}
              >
                <img
                  className="profile-image"
                  src={image}
                  alt="EventHound"
                  height="100%"
                  width="100%"
                />
              </div>
            </div>
          )}
        </Draggable>
      ) : (
        <div
          className="profile-image-container"
          style={{ marginLeft: "-5px" }}
          onClick={() => document.getElementById("userImageInput").click()}
        >
          <div className="profile-svg-container">
            <Camera />
          </div>
        </div>
      )}

      <input
        id="userImageInput"
        type="file"
        name="photo"
        accept="image/png, image/jpeg, image/jpg"
        hidden="hidden"
        onChange={handleChange}
      />
    </>
  );
};

const mapStateToProps = (store) => ({
  user: store.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (loaderState) => dispatch(stateActions.setLoader(loaderState)),
  updateUser: (userObj) => dispatch(userActions.updateUser(userObj)),
  setMessage: (dataObj) => dispatch(stateActions.setMessage(dataObj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListingProfileImage);
