import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import * as userActions from "../../../redux/actions/user-actions";
import momentTZ from "moment-timezone";
import moment from "moment";

// Components
import ViewLayout from "../../components/view-layout";
import SwitchButton from "../../components/common/switch-button";
import ImageUpload from "../../components/image-upload-fullframe";
import TimeSelector from "../../components/common/time-selector";
import Button from "../../components/common/button";

// Utilities
import {
  militaryToCorrectMilitary,
  militaryTimeToReadable,
} from "../../../utilites/time";
import { formatUserTags } from "../../../utilites/format";
import getFeaturesIcon from "../../components/features-input/getFeatureIcon";
import {
  formatDbDateToReadable,
  getCurrentDate,
  convertDateToDbDate,
} from "../../../utilites/date";
import { validateSocialPost } from "../../../utilites/validate";
import { storeImage, deleteImage } from "../../../api/image";
import { editScheduledPost, deleteScheduledPost } from "../../../api/facebook";

// Styles
import "./edit-scheduled-post.css";

export default ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { scheduledSocial = [], social } = useSelector(
    (store) => store.user.user
  );
  const { id: scheduledPostId } = match.params || {};
  const scheduledPost = scheduledSocial.find(
    ({ _id }) => _id === scheduledPostId
  );
  const {
    categories: categoriesParam,
    date,
    time,
    description,
    shareDetails,
  } = scheduledPost || {};
  const {
    isPostNowParam,
    instagramTags = [],
    shareAccounts,
  } = shareDetails || {};
  const { cat1Img: originalImage } = categoriesParam || {};
  const { facebook, instagram } = shareAccounts || {};
  const [isPostNow, setIsPostNow] = useState(isPostNowParam || false);
  const [facebookPost, setFacebookPost] = useState(facebook || false);
  const [instagramPost, setInstagramPost] = useState(instagram || false);
  const [categories, setCategories] = useState(
    categoriesParam || { cat1Img: "" }
  );
  const [userTagsInput, setUserTagsInput] = useState(
    instagramTags.map(({ username }) => username).join(" ") || ""
  );
  const [descriptionInput, setDescriptionInput] = useState(description || "");
  const [startDateInput, setStartDateInput] = useState(
    formatDbDateToReadable({
      date,
      toFullYear: true,
    }) || getCurrentDate()
  );
  const [startTimeInput, setStartTimeInput] = useState(time || 800);
  const { cat1Img } = categories;
  const { fbPageAccId, instagram: instagramPermissions } = social || {};
  const isFacebook = social && fbPageAccId;
  const isInstagram = social && instagramPermissions;

  const handleChange = (evt, type, newValue) => {
    const { value } = evt.target;
    switch (type) {
      case "userTags":
        return setUserTagsInput(value);
      case "descriptionInput":
        return setDescriptionInput(value);
      case "startDateInput":
        if (moment(value).isSameOrAfter(moment(), "days"))
          return setStartDateInput(value);
      case "startTimeInput":
        return setStartTimeInput(value);
    }
  };

  const handlePostNow = (type) => {
    setIsPostNow(type === "Right Now");
  };

  const handleCategoryImage = (evt, type, newValue) => {
    return setCategories({ cat1Img: newValue });
  };

  const createScheduledData = () => {
    const utcDateTime = momentTZ(
      `${startDateInput} ${militaryToCorrectMilitary(startTimeInput)}`
    ).utc();
    const postDate = convertDateToDbDate(
      getCurrentDate(moment(utcDateTime).format("L"))
    );
    const postTime = Number(moment(utcDateTime).format("HHmm"));

    return {
      ...scheduledPost,
      date: convertDateToDbDate(startDateInput),
      time: startTimeInput,
      postDate,
      postTime,
      categories,
      shareDetails: {
        isPostNow,
        shareAccounts: {
          facebook: facebookPost,
          instagram: instagramPost,
        },
        instagramTags: instagramPost ? formatUserTags(userTagsInput) : [],
      },
      description: descriptionInput.trim(),
      lastUpdated: convertDateToDbDate(),
    };
  };

  const handleDelete = async () => {
    dispatch(stateActions.setLoader(true));
    const { completed } = await deleteScheduledPost(scheduledPostId);

    if (completed) {
      const newScheduledPostList = scheduledSocial.filter(
        ({ _id }) => _id !== scheduledPostId
      );
      history.goBack();
      dispatch(
        userActions.updateUser({ scheduledSocial: newScheduledPostList })
      );
      dispatch(stateActions.setMessage({ message: "Post Deleted" }));
    } else {
      dispatch(
        stateActions.setMessage({ messageType: "error", message: "Error" })
      );
    }

    dispatch(stateActions.setLoader(false));
  };

  const handleSubmit = async () => {
    if (
      !isPostNow &&
      moment(
        `${startDateInput} ${militaryTimeToReadable(startTimeInput)}`
      ).isSameOrBefore(moment())
    )
      return dispatch(
        stateActions.setMessage({
          messageType: "error",
          message: "Invalid Date & Time",
        })
      );

    const socialData = createScheduledData();
    const hasRequiredInfo = validateSocialPost(socialData);

    if (!hasRequiredInfo) return;
    dispatch(stateActions.setLoader("Saving Image"));
    const newScheduledPostList = scheduledSocial.filter(
      ({ _id }) => _id !== scheduledPostId
    );

    if (isPostNow) socialData.social = social;
    if (originalImage !== cat1Img) {
      const imagePromises = [storeImage(cat1Img, "social")];
      if (originalImage) imagePromises.push(deleteImage(originalImage));

      const [imageUrl] = await Promise.all(imagePromises);
      socialData.categories.cat1Img = imageUrl;
    }
    dispatch(stateActions.setLoader("Updating Details"));

    const { completed, social: editedSocial } = await editScheduledPost(
      socialData
    );

    if (editedSocial) {
      dispatch(
        userActions.updateUser({
          scheduledSocial: [...newScheduledPostList, editedSocial],
        })
      );
      history.goBack();
    } else if (completed) {
      dispatch(
        userActions.updateUser({ scheduledSocial: newScheduledPostList })
      );
      history.goBack();
      dispatch(stateActions.setMessage({ message: "Posted Successfully" }));
    } else {
      dispatch(
        stateActions.setMessage({
          messageType: "error",
          message: "Posting Error",
        })
      );
    }
    dispatch(stateActions.setLoader(false));
  };

  return (
    <ViewLayout id="create-corporate-profile">
      <div className="card card-form">
        <div className="card-info-form">
          <div className="corporate-title-container">
            <h2>EDIT SCHEDULED POST</h2>
          </div>
          <div className="new-post-split-container">
            <div />
            <Button
              className="submit-button-CreateListing"
              text="delete"
              size="small"
              isPrimary={false}
              handleClick={handleDelete}
            />
          </div>
        </div>
      </div>

      <div id="consentInput" className="card card-form">
        <div className="card-info-form">
          <div className="create-post-selector-container">
            <SwitchButton
              button1Name="Right Now"
              button2Name="Schedule Time"
              isFirst={isPostNow}
              handleClick={handlePostNow}
            />
          </div>

          {!isPostNow && (
            <>
              <label id="startDateInput" className="label-form">
                Date & Time:
              </label>
              <div className="new-post-split-container">
                <input
                  className="input-style input-form text-form"
                  style={{ marginRight: "5px" }}
                  type="date"
                  value={startDateInput}
                  onChange={(evt) => handleChange(evt, "startDateInput")}
                  placeholder="Format: yyyy-mm-dd"
                />
                <TimeSelector
                  name="startTimeInput"
                  startTime={0}
                  value={startTimeInput}
                  handleChange={handleChange}
                />
              </div>
              <div style={{ height: "5px" }} />
            </>
          )}

          <label id="cat1Img" className="label-form">
            Image:
          </label>
          <ImageUpload
            type="cat1ImgInput"
            imageInput={cat1Img}
            handleChange={handleCategoryImage}
          />

          <label id="descriptionInput" className="label-form">
            Description:
          </label>
          <textarea
            className="input-style input-form"
            rows="5"
            maxLength="1500"
            value={descriptionInput}
            onChange={(evt) => handleChange(evt, "descriptionInput")}
            spellCheck="true"
          />

          {instagramPost && (
            <>
              <label className="label-form">
                User Tags - Separate tags with a space:
              </label>
              <textarea
                className="input-style input-form"
                placeholder="usertag1 usertag2 usertag3"
                rows="1"
                maxLength="1500"
                value={userTagsInput}
                onChange={(evt) => handleChange(evt, "userTags")}
              />
              <legend className="new-post-legend">
                You can only tag users with public Instagram accounts
              </legend>
            </>
          )}

          <div className="new-post-social-share-wrapper">
            {isFacebook && (
              <div className="new-post-social-share-container">
                <div
                  className="business-onboarding-arrow-outer"
                  style={{ margin: "10px 0px" }}
                >
                  <div
                    className={`create-post-arrow-inner ${
                      facebookPost ? "" : "create-post-arrow-inner-inactive"
                    }`}
                  >
                    {getFeaturesIcon("skinny arrow")}
                  </div>
                </div>
                <div
                  className="create-post-business-button create-post-business-button-hover facebook_background"
                  onClick={() => setFacebookPost(!facebookPost)}
                >
                  <img
                    className="social-media-facebook-icon"
                    src={"/img/facebook-icon.png"}
                  />
                  <p className="create-post-business-button-text">Facebook</p>
                </div>
              </div>
            )}

            {isInstagram && (
              <div className="new-post-social-share-container">
                <div
                  className="business-onboarding-arrow-outer"
                  style={{ margin: "10px 0px" }}
                >
                  <div
                    className={`create-post-instgram-arrow-inner ${
                      instagramPost ? "" : "create-post-arrow-inner-inactive"
                    }`}
                  >
                    {getFeaturesIcon("skinny arrow")}
                  </div>
                </div>
                <div
                  className="create-post-business-button create-post-business-button-hover insatgram_background"
                  onClick={() => setInstagramPost(!instagramPost)}
                >
                  <div className="social-login-icon-container">
                    {getFeaturesIcon("instagram")}
                  </div>
                  <p className="create-post-business-button-text">Instagram</p>
                </div>
              </div>
            )}
          </div>

          <div className="listing-edit-submit-container">
            <Button
              className="submit-button-CreateListing"
              text="Cancel"
              isPrimary={false}
              handleClick={() => history.goBack()}
            />
            <Button
              className="submit-button-CreateListing"
              text="Submit"
              handleClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </ViewLayout>
  );
};
