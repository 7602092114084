const initialState = {
  geoPermission: null,
  geoLocation: {},
  isUser: true,
  user: null,
  locationInput: "",
  location: {},
  mapLocation: null,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "setGeoPermission":
      return { ...state, geoPermission: payload };

    case "setGeoLocation":
      return {
        ...state,
        geoLocation: { address: state.geoLocation.address || "", ...payload },
      };

    case "setGeoAddress":
      const { address, county } = payload || {};
      return {
        ...state,
        geoLocation: { ...state.geoLocation, address, county },
      };

    case "setUser":
      const { isUser = true, user = null } = payload || {};
      return { ...state, isUser, user };

    case "updateUser":
      return { ...state, user: { ...state.user, ...payload } };

    case "setLocationInput":
      return { ...state, locationInput: payload };

    case "setLocation":
      return { ...state, location: payload, mapLocation: null };

    case "setMapLocation":
      return { ...state, mapLocation: payload };

    case "setAds":
      return { ...state, user: { ...state.user, ads: payload } };

    default:
      return state;
  }
};
