import React from "react";
import { useHistory } from "react-router-dom";

// Utilities
import getIcon from "../../../../components/features-input/getFeatureIcon";
import { charLimitTo } from "../../../../../utilites/format";
import { formatDbDateToReadable } from "../../../../../utilites/date";
import { militaryTimeToReadable } from "../../../../../utilites/time";

// Assets
import Skyline from "../../../../../assets/svg/skyline";
import Trash from "../../../../../assets/svg/trash";

// Styles
import "../../../list-view/event-card/result.css";
import "../../user/saved-ads/saved-ad.css";
import "./scheduled-post.css";

export default ({ post, handleDelete }) => {
  const { _id, categories, description, date, time, shareDetails } = post;
  const { cat1Img } = categories || {};
  const { shareAccounts } = shareDetails || {};
  const { facebook, instagram } = shareAccounts || {};
  const history = useHistory();

  return (
    <div
      className="card saved-ad-wrapper"
      style={{
        backgroundImage: `url(${cat1Img})`,
      }}
      onClick={() => history.push(`/business/scheduled-post/${_id}`)}
    >
      {!cat1Img && (
        <div className="saved-ad-skyline-overlay">
          <Skyline />
        </div>
      )}
      <div className="result-card-overlay posted-ad-overlay-gradient" />

      <div className="saved-ad-container">
        <div className="saved-ad-sub-container-split">
          <p className="scheduled-post-desc">{charLimitTo(description, 80)}</p>
          <div className="scheduled_post_icons_wrapper">
            {facebook && (
              <div className="scheduled_post_icon_container">
                {getIcon("facebook")}
              </div>
            )}
            {instagram && (
              <div className="scheduled_post_icon_container">
                {getIcon("instagram")}
              </div>
            )}
          </div>
        </div>

        <div className="saved-ad-sub-container-split">
          <p className="scheduled-post-date-time">{`${formatDbDateToReadable({
            date,
            isForUser: true,
          })} @ ${militaryTimeToReadable(time)}`}</p>

          <div
            className="profile-ad-delete-container"
            onClick={(evt) => {
              evt.stopPropagation();
              handleDelete(post);
            }}
          >
            <Trash />
          </div>
        </div>
      </div>
    </div>
  );
};
