import { createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { isDev } from "../utilites/status";

import reducers from "./reducers";

const store = createStore(
  reducers,
  isDev() ? composeWithDevTools() : undefined
);

export default store;
