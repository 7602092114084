import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
    >
      <path
        d="M5273.1,2400.1v-2c0-2.8-5-4-9.7-4s-9.7,1.3-9.7,4v2c0,1.8,0.7,3.6,2,4.9l5,4.9c0.3,0.3,0.4,0.6,0.4,1v6.4
				c0,0.4,0.2,0.7,0.6,0.8l2.9,0.9c0.5,0.1,1-0.2,1-0.8v-7.2c0-0.4,0.2-0.7,0.4-1l5.1-5C5272.4,2403.7,5273.1,2401.9,5273.1,2400.1z
				 M5263.4,2400c-4.8,0-7.4-1.3-7.5-1.8l0,0c0.1-0.5,2.7-1.8,7.5-1.8s7.3,1.3,7.5,1.8C5270.7,2398.7,5268.2,2400,5263.4,2400z"
      />
      <path d="M5268.4,2410.3c-0.6,0-1,0.4-1,1s0.4,1,1,1h4.3c0.6,0,1-0.4,1-1s-0.4-1-1-1H5268.4z" />
      <path d="M5272.7,2413.7h-4.3c-0.6,0-1,0.4-1,1s0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2414.1,5273.3,2413.7,5272.7,2413.7z" />
      <path d="M5272.7,2417h-4.3c-0.6,0-1,0.4-1,1s0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2417.5,5273.3,2417,5272.7,2417z" />
      <path
        d="M82.4,2.5H17.6c-5.1,0-9.1,4.1-9.1,9.1v76.7c0,5.1,4.1,9.1,9.1,9.1h64.7c5.1,0,9.1-4.1,9.1-9.1V11.6
			C91.5,6.6,87.4,2.5,82.4,2.5z M31.5,76.4h-1.4c-2.5,0-4.6-2-4.6-4.6c0-2.5,2-4.6,4.6-4.6h1.4c2.5,0,4.6,2,4.6,4.6
			C36.1,74.3,34.1,76.4,31.5,76.4z M31.5,54.6h-1.4c-2.5,0-4.6-2-4.6-4.6c0-2.5,2-4.6,4.6-4.6h1.4c2.5,0,4.6,2,4.6,4.6
			C36.1,52.5,34.1,54.6,31.5,54.6z M31.5,32.8h-1.4c-2.5,0-4.6-2-4.6-4.6s2-4.6,4.6-4.6h1.4c2.5,0,4.6,2,4.6,4.6
			S34.1,32.8,31.5,32.8z M69.9,76.4h-23c-2.5,0-4.6-2-4.6-4.6c0-2.5,2-4.6,4.6-4.6H70c2.5,0,4.6,2,4.6,4.6
			C74.5,74.3,72.4,76.4,69.9,76.4z M69.9,54.6h-23c-2.5,0-4.6-2-4.6-4.6c0-2.5,2-4.6,4.6-4.6H70c2.5,0,4.6,2,4.6,4.6
			C74.5,52.5,72.4,54.6,69.9,54.6z M69.9,32.8h-23c-2.5,0-4.6-2-4.6-4.6s2-4.6,4.6-4.6H70c2.5,0,4.6,2,4.6,4.6S72.4,32.8,69.9,32.8z
			"
      />
    </svg>
  );
};
