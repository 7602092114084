import React from "react";

// Assets
import Arrow from "../../../assets/svg/arrow";

// Styles
import "../../routes/listing/listing.css";
import "../../routes/profile/profile.css";
import "./pagination.css";

const Pagination = ({
  title,
  page,
  setPage,
  isLoading = false,
  noResultsText = "No Results",
  list,
  children,
}) => {
  const isList = !!list.length;

  const handleArrowClick = (direction) => {
    let newPageNumber;
    switch (direction) {
      case "left":
        newPageNumber = page - 1;
        if (newPageNumber) setPage(newPageNumber);
        break;
      case "right":
        newPageNumber = page + 1;
        if (newPageNumber <= Math.ceil(list.length / 5)) setPage(newPageNumber);
        break;
    }
  };

  const renderPaginationNumbers = () => {
    const paginationNumbers = Math.ceil(list.length / 5);
    const numbers = [];

    for (let i = 1; i <= paginationNumbers; i++) {
      const isActive = i === page;
      numbers.push(
        <p
          className={`pointer profile-ads-pagination-number ${
            isActive ? "profile-ads-pagination-number-active" : ""
          }`}
          onClick={() => setPage(i)}
          key={i}
        >
          {i}
        </p>
      );
    }
    return numbers;
  };

  const renderPagination = () => {
    const listLength = list.length;
    const paginationNumbers = Math.ceil(list.length / 5);
    const isRightArrow = page < paginationNumbers;
    return listLength > 5 ? (
      <div className="profile-ads-pagination">
        {page > 1 && (
          <div
            className="pointer profile-ads-pagination-arrow-container listing-image-arrow-left"
            onClick={() => handleArrowClick("left")}
          >
            <Arrow />
          </div>
        )}
        {renderPaginationNumbers()}
        {isRightArrow && (
          <div
            className="pointer profile-ads-pagination-arrow-container"
            onClick={() => handleArrowClick("right")}
          >
            <Arrow />
          </div>
        )}
      </div>
    ) : null;
  };

  return (
    <div className="listing-content-container">
      {title && <h3 className="listing-content-title">{title}</h3>}
      {children}
      {isLoading && (
        <div className="profile-text-container profile-text-container-center">
          <p>Loading...</p>
        </div>
      )}
      {isList && list.length > 5 && renderPagination()}
      {!isLoading && !list.length && (
        <div className="profile-text-container profile-text-container-center">
          <p className="profile-text-no-value">{noResultsText}</p>
        </div>
      )}
    </div>
  );
};

export default Pagination;
