import React, { useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import * as userActions from "../../../redux/actions/user-actions";
import momentTZ from "moment-timezone";
import moment from "moment";

// Components
import Toggle from "../../components/common/toggle";
import SwitchButton from "../../components/common/switch-button";
import ImageUpload from "../../components/image-upload";
import Checkbox from "../../components/common/checkbox";
import Categories from "../../components/categories-input";
import Button from "../../components/common/button";
import ConfirmListing from "../../components/confirm-listing";
import WeekdaysTimeInput from "../../components/WeekdaysTimeInput";
import TimeSelector from "../../components/common/time-selector";
import Features from "../../components/features-input";
import Map from "../../components/leaflet-map";
import Modal from "../../components/modal";

// Utilities
import {
  features as featuresModel,
  searchTypes,
} from "../../../constants/data-models";
import { isSafariAgent, isMobileAgent } from "../../../utilites";
import { createListing } from "../../../api/listing";
import { storeImage } from "../../../api/image";
import {
  getCurrentDate,
  convertDateToDbDate,
  plus7Days,
} from "../../../utilites/date";
import {
  isOpenDay,
  validateListingCarousel,
  validateListing,
  evaluateAvailabilityDays,
} from "../../../utilites/validate";
import { formatEndTime, militaryTimeToReadable } from "../../../utilites/time";
import defaultData from "../sign-up/business/data-weekdats-default";
import {
  createSingleDayHours,
  formatAfter12Hours,
} from "../../../utilites/format";
import getFeaturesIcon from "../../components/features-input/getFeatureIcon";
import { militaryToCorrectMilitary } from "../../../utilites/time";
import { formatUserTags } from "../../../utilites/format";
import { getCoordinates } from "../../../api/search";
import { postToSocial } from "../../../api/facebook";

// Assets
import SkinnyArrow from "../../../assets/svg/directionArrow";

// Styles
import "./create-post.css";

const CreateListing = ({
  setPage,
  instagramPost,
  facebookPost,
  user,
  setLoader,
  updateUser,
  setMessage,
  history,
}) => {
  const {
    _id,
    name,
    locationType,
    address: businessAddress,
    location: userLocation,
    features: userFeatures = {},
    hours: businessHours,
    social,
    scheduledSocial = [],
  } = user;
  const carousel = useRef();
  const isMobile = isMobileAgent();
  const isSafairMobile = isMobile && isSafariAgent();
  const [modalMessage, setModalMessage] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [typeSelect, setTypeSelect] = useState("");
  const [titleInput, setTitleInput] = useState("");
  const [categories, setCategories] = useState({
    cat1: "",
    cat1Sub: "",
    cat1Img: "",
    cat2: "",
    cat2Sub: "",
    cat2Img: "",
  });
  const [featuresInput, setFeaturesInput] = useState(userFeatures || {});
  const [virtiualInput, setVirtiualInput] = useState("");
  const [descriptionInput, setDescriptionInput] = useState("");
  const [repeatListingInput, setRepeatListingInput] = useState(true);
  const [checkInInput, setCheckInInput] = useState(false);
  const [checkInOnceInput, setCheckInOnceInput] = useState(true);
  const [dateInput, setDateInput] = useState(getCurrentDate());
  const [startDateInput, setStartDateInput] = useState(getCurrentDate());
  const [endDateInput, setEndDateInput] = useState(plus7Days());
  const [isPostNow, setIsPostNow] = useState(true);
  const [isPresale, setIsPresale] = useState(false);
  const [isIndefinite, setIsIndefinite] = useState(true);
  const [location, setLocation] = useState(userLocation);
  const [ticketInput, setTicketInput] = useState("");
  const [userTagsInput, setUserTagsInput] = useState("");
  const [promoCodeInput, setPromoCodeInput] = useState("");
  const [enteredLocation, setEnteredLocation] = useState("");
  const [isBusinessHours, setIsBusinessHours] = useState(false);
  const [hours, setHours] = useState(defaultData.hours);
  const [startTimeInput, setStartTimeInput] = useState(800);
  const [endTimeInput, setEndTimeInput] = useState(2000);
  const [imageFile1, setImageFile1] = useState("");
  const [imageFile2, setImageFile2] = useState("");
  const [consentInput, setConsentInput] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const { locationIsdefault = true, address, coordinates } = location;
  const images = [imageFile1, imageFile2];
  const isCorporate = locationType === "Multiple Locations";
  const isDiscriptionComplete = descriptionInput.length < 40;
  const discriptionLegend = isDiscriptionComplete
    ? `${40 - descriptionInput.trim().length} more characters needed`
    : `${descriptionInput.trim().length} characters`;
  const height = `calc(100vh - ${isSafairMobile ? 150 : 40}px`;
  const { cat1, cat1Sub, cat1Img, cat2, cat2Sub, cat2Img } = categories || {};
  const { fbPageAccId, instagram } = social || {};
  const isFacebook = social && fbPageAccId;
  const isInstagram = social && instagram;
  const isSocial =
    (isFacebook || isInstagram) && (instagramPost || facebookPost);
  const postIsToday = moment(
    repeatListingInput ? startDateInput : dateInput
  ).isSame(moment(), "day");

  const newData = {
    type: typeSelect,
    location: isCorporate ? {} : location,
    categories,
    title: (titleInput || "").trim().toLowerCase(),
    description: descriptionInput,
    features: featuresInput,
    days: repeatListingInput
      ? {
          rate: evaluateAvailabilityDays(
            isBusinessHours ? businessHours : hours
          ),
          presaleDate:
            ticketInput && isPresale
              ? convertDateToDbDate(
                  getCurrentDate(
                    moment(startDateInput).subtract(10, "days").format("L")
                  )
                )
              : null,
          startDate: convertDateToDbDate(startDateInput),
          endDate: isIndefinite ? 999999 : convertDateToDbDate(endDateInput),
          isBusinessHours,
          hours: isBusinessHours ? businessHours : hours,
        }
      : {
          rate: 1,
          presaleDate:
            ticketInput && isPresale
              ? convertDateToDbDate(
                  getCurrentDate(
                    moment(dateInput).subtract(10, "days").format("L")
                  )
                )
              : null,
          dateAvailable: convertDateToDbDate(dateInput),
          hours: createSingleDayHours(startTimeInput, endTimeInput),
        },
    checkInRequired: checkInInput,
    checkInOnce: checkInOnceInput,
    businessId: _id,
    businessName: name,
    ticketLink: (ticketInput || "").trim(),
    virtualLink: (virtiualInput || "").trim(),
    lastUpdated: convertDateToDbDate(),
    createdDate: convertDateToDbDate(),
    currentDate: moment().format(),
    timeStamp: moment().format("lll"),
  };

  const verifyTitle = (value) => {
    const allTerms = [
      "instagram",
      "promote",
      "cater",
      name.toLowerCase(),
      ...searchTypes.map((term) => term.toLowerCase().split(" ")).flat(),
    ];
    const found = allTerms.find((term) => value.toLowerCase().includes(term));
    if (found) {
      setMessage({
        messageType: "error",
        message: `Cannot use term "${found}"`,
      });
      return false;
    }
    return true;
  };

  const handleChange = (evt, type, newValue) => {
    const { value } = evt.target;
    switch (type) {
      case "titleInput":
        const isVerifiedTitle = verifyTitle(value);
        if (isVerifiedTitle) return setTitleInput(value);
        break;
      case "descriptionInput":
        return setDescriptionInput(value);
      case "dateInput":
        return setDateInput(value);
      case "startDateInput":
        if (!isIndefinite && moment(value).isSameOrAfter(endDateInput)) {
          setEndDateInput(plus7Days(value));
        }
        return setStartDateInput(value);
      case "endDateInput":
        return setEndDateInput(value);
      case "startTimeInput":
        if (value >= endTimeInput) setEndTimeInput(formatEndTime(value + 50));
        return setStartTimeInput(value);
      case "endTimeInput":
        return setEndTimeInput(formatEndTime(value));
      case "checkInRequired":
        return setCheckInInput(!checkInInput);
      case "checkInOnceBox":
        return setCheckInOnceInput(newValue);
      case "location":
        return setEnteredLocation(value);
      case "ticketInput":
        return setTicketInput(value);
      case "userTags":
        return setUserTagsInput(value);
      case "promoCodeInput":
        return setPromoCodeInput(newValue);
      case "VirtualInput":
        return setVirtiualInput(value);
      case "typeSelect":
        return setTypeSelect(value);
      case "isBusinessHours":
        return setIsBusinessHours(newValue);
      case "imageInput1":
        return setImageFile1(newValue);
      case "imageInput2":
        return setImageFile2(newValue);
      case "consentInput":
        return setConsentInput(!consentInput);
    }
  };

  const handleCategoryImage = (evt, type, newValue) => {
    const isComparableImage =
      newValue && typeof newValue !== "string" && "name" in newValue;

    switch (type) {
      case "cat1ImgInput":
        const isComparablecat2Img =
          cat2Img && typeof cat2Img !== "string" && "name" in cat2Img;
        if (
          isComparableImage &&
          isComparablecat2Img &&
          newValue.name === cat2Img.name
        ) {
          return setMessage({
            messageType: "error",
            message: "Cannot Use The Same Photo",
          });
        }
        return setCategories({ ...categories, cat1Img: newValue });
      case "cat2ImgInput":
        const isComparablecat1Img =
          cat1Img && typeof cat1Img !== "string" && "name" in cat1Img;
        if (
          isComparableImage &&
          isComparablecat1Img &&
          newValue.name === cat1Img.name
        ) {
          return setMessage({
            messageType: "error",
            message: "Cannot Use The Same Photo",
          });
        }
        return setCategories({ ...categories, cat2Img: newValue });
    }
  };

  const handleSwitchTimesClick = (type) => {
    if (type === "Business Hours" && !isOpenDay({ hours: businessHours }))
      return setMessage({ messageType: "error", message: "No Business Hours" });
    setIsBusinessHours(type === "Business Hours");
  };

  const handlePostNow = (type) => {
    setIsPostNow(type === "Right Now");
  };

  const handleSwitchLengthClick = (type) => {
    const isRepeat = type === "Repeat";
    if (isRepeat) {
      setDateInput(null);
    } else {
      setDateInput(getCurrentDate());
    }
    setCheckInOnceInput(true);
    return setRepeatListingInput(isRepeat);
  };

  const handleToggle = (evt, value) => {
    if (!value && moment(startDateInput).isSameOrAfter(endDateInput)) {
      setEndDateInput(plus7Days(startDateInput));
    }
    setIsIndefinite(value);
  };

  const handleFeatureClick = (feature) => {
    const value = featuresInput[feature] || false;
    if (!value) return setFeaturesInput({ ...featuresInput, [feature]: true });
    const newFeaturesInput = { ...featuresInput };
    delete newFeaturesInput[feature];
    setFeaturesInput(newFeaturesInput);
  };

  const handleLocationEnter = async (evt) => {
    evt.preventDefault();
    if (!enteredLocation) return;
    const { location = {} } =
      (await getCoordinates({ address: enteredLocation })) || {};
    if ("coordinates" in location) {
      location.locationIsdefault = false;
      setEnteredLocation("");
      setLocation(location);
    }
  };

  const handleKeyDown = (evt) => {
    const { which, keyCode } = evt;
    const isEnter = which === 13 || keyCode === 13;
    if (isEnter) handleLocationEnter(evt);
  };

  const slideTo = (index) => {
    const carouselElememt = carousel ? carousel.current : null;
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    const position = width * index;
    if (carouselElememt)
      carouselElememt.style.transform = `translate(-${position}px, 0)`;
    setCurrentIndex(index);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const isAddressRequired = locationType !== "Multiple Locations";
    const createListingAdditionalIds = {
      signUpConsetInput: consentInput,
    };
    if (isAddressRequired)
      createListingAdditionalIds.locationInput =
        location && location.coordinates && location.coordinates.length;
    const hasRequiredInfo = validateListing(
      newData,
      createListingAdditionalIds,
      "form-CreateListing"
    );
    if (!hasRequiredInfo) return;

    setIsConfirm(true);
  };

  const createScheduledData = () => {
    const date = repeatListingInput ? startDateInput : dateInput;
    const time = repeatListingInput
      ? (isBusinessHours ? businessHours : hours)[
          moment(repeatListingInput ? startDateInput : dateInput).format("dddd")
        ].open
      : startTimeInput;
    const utcDateTime = momentTZ(
      `${date} ${militaryToCorrectMilitary(time >= 2400 ? 2350 : time)}`
    ).utc();
    const postDate = convertDateToDbDate(
      getCurrentDate(moment(utcDateTime).format("L"))
    );
    const postTime = Number(moment(utcDateTime).format("HHmm"));

    return {
      businessId: _id,
      shareDetails: {
        isPostNow,
        shareAccounts: {
          facebook: facebookPost,
          instagram: instagramPost,
        },
        instagramTags: instagramPost ? formatUserTags(userTagsInput) : [],
      },
      description: descriptionInput.trim(),
      date: convertDateToDbDate(date),
      time,
      postDate,
      postTime,
      categories: { cat1Img: "" },
      lastUpdated: convertDateToDbDate(),
      createdDate: convertDateToDbDate(),
      timeStamp: moment().format("lll"),
    };
  };

  const handleSave = async () => {
    setLoader(true);
    const filteredImages = images.filter((image) => !!image);
    let promiseArray = [];

    if (isSocial) {
      promiseArray.push(storeImage(categories.cat1Img, "social"));
    } else {
      promiseArray.push(Promise.resolve(""));
    }

    if (categories.cat1Img) {
      promiseArray.push(storeImage(categories.cat1Img, "listing"));
    } else {
      promiseArray.push(Promise.resolve(""));
    }

    if (categories.cat2 && categories.cat2Img) {
      promiseArray.push(storeImage(categories.cat2Img, "listing"));
    } else {
      promiseArray.push(Promise.resolve(""));
    }

    if (promoCodeInput) {
      promiseArray.push(storeImage(promoCodeInput, "promo"));
    } else {
      promiseArray.push(Promise.resolve(""));
    }

    if (filteredImages.length) {
      const imagesPromises = images.reduce((acc, image) => {
        if (image) return [...acc, storeImage(image, "listing")];
        return acc;
      }, []);
      promiseArray = [...promiseArray, ...imagesPromises];
    } else {
      promiseArray.push(Promise.resolve(""));
    }

    setLoader("Saving Images");
    const [
      socialImgae,
      storedCat1Img,
      storedCat2Img,
      storedPromo,
      ...storedImages
    ] = await Promise.all(promiseArray);
    newData.categories.cat1Img = storedCat1Img;
    newData.categories.cat2Img = storedCat2Img;
    newData.promoCode = storedPromo;
    newData.images = storedImages.filter((image) => !!image);

    setLoader(isSocial ? "Creating EventHound Post" : "Creating Post");
    const { user: updatedUser } = await createListing({
      ...newData,
      days: {
        ...newData.days,
        hours: formatAfter12Hours(newData.days.hours),
      },
    });

    if (updatedUser) {
      if (isSocial) {
        const socialData = createScheduledData();
        socialData.categories.cat1Img = socialImgae;

        setLoader(
          isPostNow ? "Posting to social media" : "Scheduling social media Post"
        );
        const { completed, social: newSocial } = await postToSocial(socialData);
        if (newSocial) {
          updateUser({
            scheduledSocial: [...scheduledSocial, newSocial],
          });
          setMessage({ message: "Post Scheduled" });
        } else if (completed) {
          setMessage({ message: "Posted Successfully" });
        } else {
          setMessage({
            messageType: "error",
            message: "Posting Error",
          });
        }
      }

      setTimeout(() => {
        updateUser(updatedUser);
        history.push("/business/profile");
        setLoader(false);
      }, 1000);
    } else {
      setLoader(false);
    }
  };

  const renderBusinessDays = () => {
    const filteredHours = Object.keys(hours).filter(
      (day) => day !== "isBusinessHours"
    );
    return filteredHours.map((day, key) => {
      const { isOpen, open, close } = businessHours[day] || {};
      return (
        <div
          className="business-hour-conatiner business-hour-conatiner-inactive"
          style={{ backgroundColor: "transparent" }}
          key={key}
        >
          <p className="business-hour-item">{day}</p>
          {isOpen ? (
            <>
              <p className="business-hour-item">
                {militaryTimeToReadable(open)}
              </p>
              <p className="business-hour-item">
                {militaryTimeToReadable(close)}
              </p>
            </>
          ) : (
            <>
              <p className="business-hour-item">Closed</p>
              <p className="business-hour-item">Closed</p>
            </>
          )}
        </div>
      );
    });
  };

  return isConfirm ? (
    <ConfirmListing
      data={{
        ...newData,
        promoCode: promoCodeInput,
        images: images.filter((image) => !!image),
      }}
      setCurrentIndex={setCurrentIndex}
      setIsConfirm={setIsConfirm}
      handleSave={handleSave}
    />
  ) : (
    <>
      <ul
        ref={carousel}
        className="carousel-container"
        style={{ width: "600%" }}
      >
        <li className="carousel-item" style={{ height }}>
          <div className="carousel-business-space-container" />
          <div className="carousel-business-item-content-container">
            <div className="business-signup-header-container">
              <div className="business-signup-icon">
                {getFeaturesIcon("logo")}
              </div>
              <h2 className="card-title-SignUp">TYPE</h2>
              <p style={{ fontSize: "1.1rem", textAlign: "center" }}>
                Let's promote the great features of your business!
              </p>
              <p
                style={{
                  marginTop: "6px",
                  textAlign: "center",
                  color: "hsl(215, 100%, 65%)",
                  cursor: "pointer",
                }}
                onClick={() => setModalMessage(true)}
              >
                EventHound Posting Guidelines
              </p>
            </div>

            <label id="typeSelect" className="label-form">
              Type:
            </label>
            <select
              className="input-style input-form select-form"
              value={typeSelect}
              onChange={(evt) => handleChange(evt, "typeSelect")}
            >
              {["", ...searchTypes].slice(0, -1).map((type, idx) => {
                const text = type ? type : "- Select -";
                return (
                  <option value={type} key={idx}>
                    {text}
                  </option>
                );
              })}
            </select>

            <Categories
              isCard={false}
              categories={categories}
              setCategories={setCategories}
            />

            <div className="carousel-business-button-container">
              <Button
                size={isMobile ? "small" : "medium"}
                text="back"
                handleClick={() => setPage("new post")}
              />
              <Button
                size={isMobile ? "small" : "medium"}
                text="Next"
                handleClick={() => {
                  const hasRequiredInfo = validateListingCarousel(
                    newData,
                    {},
                    "form-business-sign-up",
                    1
                  );
                  if (hasRequiredInfo) slideTo(currentIndex + 1);
                }}
              />
            </div>
          </div>
        </li>

        <li className="carousel-item" style={{ height }}>
          <div className="carousel-business-space-container" />
          <div className="carousel-business-item-content-container">
            <div className="business-signup-header-container">
              <div className="business-signup-icon">
                {getFeaturesIcon("postad")}
              </div>
              <h2 className="card-title-SignUp">DETAILS</h2>
              {isSocial && (
                <p
                  className="color-primary"
                  style={{
                    width: "80%",
                    textAlign: "center",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                  }}
                >
                  *Fields in blue are shared to social media post
                </p>
              )}
              <p style={{ width: "80%", textAlign: "center" }}>
                Be as discritive as possibe, users want to know the details. For
                example, if its a happy hour list the items and prices.
              </p>
            </div>

            <label id="titleInput" className="label-form">
              Title:
            </label>
            <input
              className="input-style input-form text-form"
              type="text"
              value={titleInput}
              onChange={(evt) => handleChange(evt, "titleInput")}
              spellCheck="true"
            />

            <div
              className="legend-form-split-container"
              style={{ paddingTop: "10px" }}
            >
              <label
                id="descriptionInput"
                className={`label-form ${isSocial ? "color-primary" : ""}`}
              >
                Description:
              </label>
              <label className="label-form">
                <p
                  className={`legend-form-text ${
                    isDiscriptionComplete
                      ? "legend-form-incomplete"
                      : "legend-form-complete"
                  }`}
                >
                  {discriptionLegend}
                </p>
              </label>
            </div>
            <textarea
              className="input-style input-form"
              rows="5"
              maxLength="1500"
              value={descriptionInput}
              onChange={(evt) => handleChange(evt, "descriptionInput")}
              spellCheck="true"
            />

            <label id="moreInfoInput" className="label-form">
              More Info Link:
            </label>
            <input
              className="input-style input-form text-form"
              type="text"
              value={ticketInput}
              onChange={(evt) => handleChange(evt, "ticketInput")}
              spellCheck="true"
            />
            <legend>For additional info or external ticket sales</legend>

            {isSocial && instagramPost && (
              <>
                <label className="label-form color-primary">
                  User Tags - Separate tags with a space:
                </label>
                <textarea
                  className="input-style input-form"
                  placeholder="usertag1 usertag2 usertag3"
                  rows="1"
                  maxLength="1500"
                  value={userTagsInput}
                  onChange={(evt) => handleChange(evt, "userTags")}
                />
                <legend className="new-post-legend">
                  You can only tag users with public Instagram accounts
                </legend>
              </>
            )}

            <div
              className="image-category-input-container"
              style={{ marginTop: "30px" }}
            >
              <label
                id="cat1Img"
                className={`image-label-form ${
                  isSocial ? "color-primary" : ""
                }`}
              >
                Image: Must show
              </label>
              <p className="image-label-form-category">{`${cat1Sub} - ${cat1}`}</p>
            </div>
            <div className="time-selector-wrapper-CreateListing">
              <ImageUpload
                type="cat1ImgInput"
                imageInput={cat1Img}
                handleChange={handleCategoryImage}
              />
            </div>

            {cat2Sub && (
              <>
                <div className="image-category-input-container">
                  <label id="cat2Img" className="image-label-form">
                    Secondary Category Image: Must show
                  </label>
                  <p className="image-label-form-category">{`${cat2Sub} - ${cat2}`}</p>
                </div>
                <div className="time-selector-wrapper-CreateListing">
                  <ImageUpload
                    type="cat2ImgInput"
                    imageInput={cat2Img}
                    handleChange={handleCategoryImage}
                  />
                </div>
              </>
            )}

            <label className="label-form">Additional Images:</label>
            <label id="imageInput" className="label-form">
              Image 1:
            </label>
            <div className="time-selector-wrapper-CreateListing">
              <ImageUpload
                type="imageInput1"
                imageInput={imageFile1}
                handleChange={handleChange}
              />
            </div>
            {(imageFile1 || imageFile2) && (
              <>
                <label className="label-form">Image 2:</label>
                <div className="time-selector-wrapper-CreateListing">
                  <ImageUpload
                    type="imageInput2"
                    imageInput={imageFile2}
                    handleChange={handleChange}
                  />
                </div>
              </>
            )}

            <label className="label-form">Promo Code:</label>
            <div className="time-selector-wrapper-CreateListing">
              <ImageUpload
                text={"add code"}
                type={"promoCodeInput"}
                imageInput={promoCodeInput}
                handleChange={handleChange}
                isPromoCode
              />
            </div>
            <legend className="legend-form">
              Upload QR or bar code code if needed to redeem.
            </legend>

            <div className="carousel-business-button-container">
              <Button
                size={isMobile ? "small" : "medium"}
                text="Back"
                handleClick={() => slideTo(currentIndex - 1)}
              />
              <Button
                size={isMobile ? "small" : "medium"}
                text="Next"
                handleClick={() => {
                  const hasRequiredInfo = validateListingCarousel(
                    newData,
                    {},
                    "form-business-sign-up",
                    2
                  );
                  if (hasRequiredInfo) slideTo(currentIndex + 1);
                }}
              />
            </div>
          </div>
        </li>

        <li className="carousel-item" style={{ height }}>
          <div className="carousel-business-space-container" />
          <div className="carousel-business-item-content-container">
            <div className="business-signup-header-container">
              <div className="business-signup-icon">
                {getFeaturesIcon("date")}
              </div>
              <h2 className="card-title-SignUp">DATE</h2>
            </div>

            <div className="checkbox-outer-wrapper-CreateListing">
              <div className="listing-selector-container">
                <SwitchButton
                  button1Name="One-Time"
                  button2Name="Repeat"
                  isFirst={!repeatListingInput}
                  handleClick={handleSwitchLengthClick}
                />
              </div>
            </div>

            {repeatListingInput ? (
              <>
                <label id="startDateInput" className="label-form">
                  Start Date:
                </label>
                <input
                  className="input-style input-form text-form"
                  type="date"
                  value={startDateInput}
                  onChange={(evt) => handleChange(evt, "startDateInput")}
                  placeholder="Format: yyyy-mm-dd"
                />
                <label
                  id="endDateInput"
                  className="label-form"
                  style={{ marginTop: "20px" }}
                >
                  End Date:
                  <div className="show-form-toggle">
                    <p className="show-form-toggle-text">Indefinite</p>
                    <Toggle
                      value={isIndefinite}
                      handleDebouceToggle={handleToggle}
                    />
                  </div>
                </label>
                {!isIndefinite && (
                  <input
                    className="input-style input-form text-form"
                    type="date"
                    value={endDateInput}
                    onChange={(evt) => handleChange(evt, "endDateInput")}
                    placeholder="Format: yyyy-mm-dd"
                  />
                )}
              </>
            ) : (
              <>
                <label id="oneTimeDate" className="label-form">
                  Date:
                </label>
                <input
                  className="input-style input-form text-form"
                  type="date"
                  value={dateInput}
                  onChange={(evt) => handleChange(evt, "dateInput")}
                  placeholder="Format: yyyy-mm-dd"
                />
              </>
            )}

            {ticketInput && (
              <label
                id="endDateInput"
                className="label-form"
                style={{ marginTop: "20px" }}
              >
                Enable Presale:
                <Toggle
                  value={isPresale}
                  handleDebouceToggle={(evt, value) => setIsPresale(value)}
                />
              </label>
            )}

            {isSocial && !postIsToday && (
              <>
                <p
                  style={{
                    marginTop: "25px",
                    fontSize: "1.1rem",
                    textAlign: "center",
                  }}
                >
                  When would you like to post to social media?
                </p>
                <div className="checkbox-outer-wrapper-CreateListing">
                  <div className="listing-selector-container">
                    <SwitchButton
                      button1Name="Right Now"
                      button2Name="Start Date/Time"
                      isFirst={isPostNow}
                      handleClick={handlePostNow}
                    />
                  </div>
                </div>

                <div
                  className="new-post-social-share-wrapper"
                  style={{ marginTop: "0px" }}
                >
                  {facebookPost && (
                    <div className="new-post-social-share-container">
                      <div
                        className="business-onboarding-arrow-outer"
                        style={{ margin: "10px 0px" }}
                      >
                        <div className="create-post-arrow-inner">
                          {getFeaturesIcon("skinny arrow")}
                        </div>
                      </div>
                      <div className="create-post-business-button facebook_background">
                        <img
                          className="social-media-facebook-icon"
                          src={"/img/facebook-icon.png"}
                        />
                        <p className="create-post-business-button-text">
                          Facebook
                        </p>
                      </div>
                    </div>
                  )}

                  {instagramPost && (
                    <div className="new-post-social-share-container">
                      <div
                        className="business-onboarding-arrow-outer"
                        style={{ margin: "10px 0px" }}
                      >
                        <div className="create-post-instgram-arrow-inner">
                          {getFeaturesIcon("skinny arrow")}
                        </div>
                      </div>
                      <div className="create-post-business-button insatgram_background">
                        <div className="social-login-icon-container">
                          {getFeaturesIcon("instagram")}
                        </div>
                        <p className="create-post-business-button-text">
                          Instagram
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}

            <div className="carousel-business-button-container">
              <Button
                size={isMobile ? "small" : "medium"}
                text="Back"
                handleClick={() => slideTo(currentIndex - 1)}
              />
              <Button
                size={isMobile ? "small" : "medium"}
                text="Next"
                handleClick={() => {
                  const hasRequiredInfo = validateListingCarousel(
                    newData,
                    {},
                    "form-business-sign-up",
                    3
                  );
                  if (hasRequiredInfo) slideTo(currentIndex + 1);
                }}
              />
            </div>
          </div>
        </li>

        <li className="carousel-item" style={{ height }}>
          <div className="carousel-business-space-container" />
          <div className="carousel-business-item-content-container">
            <div className="business-signup-header-container">
              <div className="business-signup-icon">
                {getFeaturesIcon("clock")}
              </div>
              <h2 className="card-title-SignUp">TIME</h2>
            </div>

            {repeatListingInput ? (
              <>
                <div className="checkbox-outer-wrapper-CreateListing">
                  <div className="listing-selector-container">
                    <SwitchButton
                      button1Name="Custom Hours"
                      button2Name="Business Hours"
                      isFirst={!isBusinessHours}
                      handleClick={handleSwitchTimesClick}
                    />
                  </div>
                </div>
                {isBusinessHours ? (
                  isCorporate ? (
                    <p className="use-business-hours-corporate-text">
                      Will use the indivdual locations hours of operation.
                    </p>
                  ) : (
                    renderBusinessDays()
                  )
                ) : (
                  <>
                    <label
                      id="daysInput"
                      className="label-form"
                      style={{ marginTop: "20px" }}
                    >
                      Listing Days & Times:
                    </label>
                    <WeekdaysTimeInput
                      openLabel="Start"
                      closeLabel="End"
                      page="create-ad"
                      hours={hours}
                      setHours={setHours}
                      isCard={false}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                <label className="label-form" style={{ marginTop: "25px" }}>
                  Time:
                </label>
                <div className="time-selector-wrapper-CreateListing">
                  <TimeSelector
                    name="startTimeInput"
                    label="Start"
                    startTime={0}
                    value={startTimeInput}
                    handleChange={handleChange}
                  />
                  <TimeSelector
                    name="endTimeInput"
                    label="End"
                    startTime={startTimeInput}
                    value={endTimeInput}
                    handleChange={handleChange}
                  />
                </div>
              </>
            )}

            <div className="carousel-business-button-container">
              <Button
                size={isMobile ? "small" : "medium"}
                text="Back"
                handleClick={() => slideTo(currentIndex - 1)}
              />
              <Button
                size={isMobile ? "small" : "medium"}
                text="Next"
                handleClick={() => {
                  const hasRequiredInfo = validateListingCarousel(
                    newData,
                    {},
                    "form-business-sign-up",
                    4
                  );
                  if (hasRequiredInfo) slideTo(currentIndex + 1);
                }}
              />
            </div>
          </div>
        </li>

        <li className="carousel-item" style={{ height }}>
          <div className="carousel-business-space-container" />
          <div className="carousel-business-item-content-container">
            <div className="business-signup-header-container">
              <div className="business-signup-icon">
                {getFeaturesIcon("features")}
              </div>
              <h2 className="card-title-SignUp">FEATURES</h2>
            </div>

            <Features
              options={[...featuresModel, "Virtual"]}
              values={featuresInput}
              handleClick={handleFeatureClick}
              linkValue={virtiualInput}
              handleChange={handleChange}
              isVirtualOption
              isCard={false}
              isTitle={false}
            />

            <div className="carousel-business-button-container">
              <Button
                size={isMobile ? "small" : "medium"}
                text="Back"
                handleClick={() => slideTo(currentIndex - 1)}
              />
              <Button
                size={isMobile ? "small" : "medium"}
                text="Next"
                handleClick={() => {
                  const hasRequiredInfo = validateListingCarousel(
                    newData,
                    {},
                    "form-business-sign-up",
                    5
                  );
                  if (hasRequiredInfo) slideTo(currentIndex + 1);
                }}
              />
            </div>
          </div>
        </li>

        <li className="carousel-item" style={{ height }}>
          <div className="carousel-business-space-container" />
          <div className="carousel-business-item-content-container">
            <div className="business-signup-header-container">
              <div className="business-signup-icon">
                {getFeaturesIcon("pin")}
              </div>
              <h2 className="card-title-SignUp">LOCATION</h2>
              {isCorporate && (
                <p>Listings will be created for all of your locations</p>
              )}
            </div>

            {!isCorporate && (
              <>
                <div style={{ height: `300px`, width: "100%" }}>
                  <Map
                    mapCenter={coordinates}
                    coordinates={coordinates}
                    defaultZoom={12}
                    isMarkerShown={false}
                    status="active"
                  />
                </div>
                <label id="locationInput" className="label-form">
                  Address:
                </label>
                <div className="input-style input-form text-form listingCommon-location-conatiner">
                  {!locationIsdefault && (
                    <div
                      className="pointer listingCommon-close-svg-conatiner"
                      onClick={() => setLocation(userLocation)}
                    >
                      {getFeaturesIcon("close")}
                    </div>
                  )}
                  <input
                    className="listingCommon-location-input"
                    placeholder={
                      address
                        ? address
                        : businessAddress
                        ? businessAddress
                        : "enter address"
                    }
                    type="text"
                    value={enteredLocation}
                    onKeyDown={handleKeyDown}
                    onChange={(evt) => handleChange(evt, "location")}
                    spellCheck="true"
                  />
                  <div
                    className="pointer listingCommon-location-button"
                    onClick={handleLocationEnter}
                  >
                    <div className="listingCommon-location-svg-conatiner">
                      {getFeaturesIcon("location")}
                    </div>
                    <p>FIND</p>
                  </div>
                </div>
              </>
            )}

            <label className="label-form">Terms Of Use:</label>
            <div className="submit-outer-wrapper-CreateListing">
              <div className="submit-inner-wrapper-CreateListing">
                <div className="consent-terms-container">
                  <a
                    className="consent-terms-link"
                    href="/terms-of-service"
                    target="blank"
                  >
                    View Terms
                  </a>
                  <div className="business-signup-terms-arrow">
                    <SkinnyArrow />
                  </div>
                </div>
                <div className="consent-wrapper-CreateListing">
                  <Checkbox
                    state={consentInput}
                    handleClick={(evt) =>
                      handleChange(evt, "consentInput", !consentInput)
                    }
                    params={[{ target: { value: "" } }, "consentInput"]}
                  />
                  <p
                    id="signUpConsetInput"
                    className="consent-text-CreateListing"
                  >
                    I have read and accept the terms
                  </p>
                </div>
              </div>
            </div>

            <div className="carousel-business-button-container">
              <Button
                size={isMobile ? "small" : "medium"}
                text="Back"
                handleClick={() => slideTo(currentIndex - 1)}
              />
              <Button
                size={isMobile ? "small" : "medium"}
                text="Submit"
                handleClick={handleSubmit}
              />
            </div>
          </div>
        </li>
      </ul>

      <Modal
        directModalMessage={modalMessage}
        modalAccept="got it"
        handleAcceptClick={() => setModalMessage("")}
      >
        <div className="guildelines-wrapper">
          <h3 className="guildelines-title">Posting Guidelines</h3>

          <p className="guildelines-sub-title" style={{ marginTop: "10px" }}>
            General:
          </p>
          <ul
            className="carousel-list-container"
            style={{ marginLeft: "30px" }}
          >
            <li className="carousel-list-item">
              Ad posting must fit into the criteria of one of the categories
              listed below
            </li>
            <li className="carousel-list-item">
              EventHound provides a service, we are not responsible for your
              claims. Your post is your responsibility.
            </li>
            <li className="carousel-list-item">
              Be mindful and respectful of others
            </li>
            <li className="carousel-list-item">
              Refrain from using profanity{" "}
            </li>
            <li className="carousel-list-item">No nudity</li>
            <li className="carousel-list-item">
              No hateful, harassing, threatening or violent content
            </li>
            <li className="carousel-list-item">
              No hate speech – speech that targets people on race, ethnicity,
              religion, gender or any other protected status.
            </li>
            <li className="carousel-list-item">Stay on topic</li>
            <li className="carousel-list-item">
              No private or confidential information you don’t want to be viewed
              by the public
            </li>
            <li className="carousel-list-item">
              No deceptive or misleading content{" "}
            </li>
            <li className="carousel-list-item">No spam or political content</li>
            <li className="carousel-list-item">
              No endorsement or encouragement of illegal activities
            </li>
          </ul>

          <p className="guildelines-sub-title" style={{ marginTop: "10px" }}>
            EventHound Ad Categories:
          </p>
          <ul
            className="carousel-list-container"
            style={{ marginLeft: "30px" }}
          >
            <li className="carousel-list-item">
              <span style={{ fontWeight: "800" }}>Deals</span> – People love a
              good deal, let’s promote yours! Specials and discounts belong
              here.
            </li>
            <ul
              className="carousel-list-container"
              style={{ marginLeft: "30px", listStyleType: "circle" }}
            >
              <li className="carousel-list-item">
                Only place your business's “deals” in this category. This ranges
                from but is not limited to: Happy Hours, Special Pricing or
                Percentage Off Products/Services, Lunch Specials, Buy One Get
                One Offers, Discounts, etc.
              </li>
            </ul>

            <li className="carousel-list-item">
              <span style={{ fontWeight: "800" }}>Promotions</span> – Want to
              get the word out? We want to help. Use this type to promote to the
              local community.
            </li>
            <ul
              className="carousel-list-container"
              style={{ marginLeft: "30px", listStyleType: "circle" }}
            >
              <li className="carousel-list-item">
                This is where you promote fun things about your business to
                users. This ranges from but is not limited to: Live music and
                entertainment, pop-up locations, brunches, karaoke night,
                game/trivia nights, fun activities like paddleboard yoga,
                margarita yoga, glow events, whiskey with goats, turtle racing,
                etc.
              </li>
            </ul>

            <li className="carousel-list-item">
              <span style={{ fontWeight: "800" }}>Postworthy</span> – Have
              something that makes people say WOW and take a picture? That’s
              “Postworthy” and it belongs to this type.
            </li>
            <ul
              className="carousel-list-container"
              style={{ marginLeft: "30px", listStyleType: "circle" }}
            >
              <li className="carousel-list-item">
                This category is all about the WOW factor. If you have a
                product, service or location that customers frequently snap a
                photo of, post and tag, that’s “Postworthy.” These can range
                from elevated establishments, award-winning cuisine,
                over-the-top experiences or anything elevated.
              </li>
            </ul>
          </ul>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (store) => ({
  user: store.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  setMessage: (dataObj) => dispatch(stateActions.setMessage(dataObj)),
  setLoader: (loaderState) => dispatch(stateActions.setLoader(loaderState)),
  updateUser: (userObj) => dispatch(userActions.updateUser(userObj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateListing));
