import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

// Utilities
import { charLimitTo, toTitleCase } from "../../../../utilites/format";
import { getAdStatus } from "../../../../utilites/status";

// Styles
import "../../list-view/event-card/result.css";
import "./result-card.css";

const Result = ({ category, result, filterDate, history }) => {
  const [{ isDateCurrent, status, timeText, displayTime }, setAdStatus] =
    useState(getAdStatus(result, filterDate));
  const { _id, categories, title, businessName } = result || {};
  const { cat1, cat1Img, cat2Img } = categories;
  const image =
    category !== "Top" && cat2Img && cat1 !== category ? cat2Img : cat1Img;

  useEffect(() => {
    setAdStatus(getAdStatus(result, filterDate));
  }, [result, filterDate]);

  const handleClick = () => {
    history.push({ pathname: `/listing/${_id}`, state: { from: category } });
  };

  return (
    <div
      className={`zoomIn result-map-card-container result-map-card-container-${status} result-card-${status}`}
      onClick={handleClick}
    >
      {image && (
        <>
          <img className="result-card-image" src={image} />
          <div className="result-card-overlay result-card-map-overlay-gradient" />
          <div
            className={`result-card-overlay result-card-status-overlay result-card-overlay-${status}`}
          />
        </>
      )}
      <div className="result-map-info-container">
        <h3 className="result-map-title">
          {charLimitTo(title, 55).toUpperCase()}
        </h3>

        <div className="result-name-container">
          <p className="result-map-business-name">
            {toTitleCase({
              input: charLimitTo(businessName, 22),
              lowerCaseRest: false,
            })}
          </p>
        </div>
      </div>
      {isDateCurrent && (
        <div className="result-card-right-container">
          <div className="result-map-time-container">
            <p
              className={`result-map-time-title card-is-${
                image ? status : "none"
              }`}
            >
              {timeText.toUpperCase()}
            </p>
            <p className="result-map-time">{displayTime}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(Result);
