import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 106 43"
    >
      <path
        d="M103.2,19.8h-3.5C98.8,9.6,90.3,1.7,79.9,1.7c-8.7,0-16,5.5-18.7,13.3c-1.9-2.3-4.8-3.8-8-3.8c-3.2,0-6.1,1.5-8,3.8
	C42.5,7.2,35.1,1.7,26.5,1.7c-10.4,0-18.9,8-19.7,18.1H3.2v3.4h3.5c0.9,10.1,9.4,18.1,19.7,18.1c11,0,19.8-8.9,19.8-19.8l0,0
	c0-3.8,3.1-6.9,6.9-6.9c3.8,0,6.9,3.1,6.9,6.9h0c0,10.9,8.9,19.8,19.8,19.8c10.4,0,18.9-8,19.7-18.1h3.5V19.8z M26.5,37.9
	c-9,0-16.4-7.3-16.4-16.4c0-9,7.3-16.4,16.4-16.4c9,0,16.4,7.3,16.4,16.4C42.8,30.5,35.5,37.9,26.5,37.9z M79.9,37.9
	c-9,0-16.4-7.3-16.4-16.4c0-9,7.3-16.4,16.4-16.4c9,0,16.4,7.3,16.4,16.4C96.3,30.5,89,37.9,79.9,37.9z"
      />
    </svg>
  );
};
