import React, { useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import * as stateActions from "../../../../redux/actions/state-actions";
import * as userActions from "../../../../redux/actions/user-actions";

// Components
import Categories from "../../../components/categories-input";
import Button from "../../../components/common/button";
import InputLabeled from "../../../components/input-labeled";
import WeekdaysTimeInput from "../../../components/WeekdaysTimeInput";
import Features from "../../../components/features-input";
import Checkbox from "../../../components/common/checkbox";
import ImageUpload from "../../../components/image-upload";
import Modal from "../../../components/modal";

// Utilities
import { isSafariAgent, isMobileAgent } from "../../../../utilites";
import defaultData from "../../../routes/sign-up/business/data-weekdats-default";
import { locationOptions } from "../../../../constants/data-models";
import getFeaturesIcon from "../../../components/features-input/getFeatureIcon";
import {
  validatePassword,
  validateBusinessCarousel,
  validateBusines,
} from "../../../../utilites/validate";
import { yelpVerifyBusiness, createBusiness } from "../../../../api/business";
import { storeImage, deleteImage } from "../../../../api/image";
import { convertDateToDbDate } from "../../../../utilites/date";
import { formatAfter12Hours } from "../../../../utilites/format";

// Assets
import SkinnyArrow from "../../../../assets/svg/directionArrow";

// Styles
import "../sign-up.css";
import "./business.css";

const defaultCategories = {
  cat1: "",
  cat1Sub: "",
  cat2: "",
  cat2Sub: "",
};

const BusinessSignUp = ({ isFree, setLoader, setUser, history }) => {
  const carousel = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);
  const isMobile = isMobileAgent();
  const isSafairMobile = isMobile && isSafariAgent();
  const urlParams = new URLSearchParams(window.location.search);
  const promoCode = urlParams.get("promocode");
  const terms = urlParams.get("terms");
  const nameParam = urlParams.get("name");
  const managerNameParam = urlParams.get("managerName");
  const emailParam = urlParams.get("email");
  const phoneParam = urlParams.get("phone");
  const contactPhoneParam = urlParams.get("contactPhone");
  const addressParam = urlParams.get("address");
  const cityParam = urlParams.get("city");
  const stateParam = urlParams.get("state");
  const zipParam = urlParams.get("zip");
  const [nameInput, setNameInput] = useState(nameParam || "");
  const [managerNameInput, setManagerNameInput] = useState(
    managerNameParam || ""
  );
  const [emailInput, setEmailInput] = useState(emailParam || "");
  const [phoneInput, setPhoneInput] = useState(
    contactPhoneParam || phoneParam || ""
  );
  const [showPhone, setShowPhone] = useState(true);
  const [locationType, setLocationType] = useState(
    emailParam && contactPhoneParam
      ? "Multiple Locations"
      : emailParam && !addressParam
      ? "No Physical Location"
      : "One Location"
  );
  const [addressInput, setAddressInput] = useState(addressParam || "");
  const [cityInput, setCityInput] = useState(cityParam || "");
  const [stateInput, setStateInput] = useState(stateParam || "");
  const [zipInput, setZipInput] = useState(zipParam || "");
  const [websiteInput, setWebsiteInput] = useState("");
  const [hours, setHours] = useState(defaultData.hours);
  const [categories, setCategories] = useState(defaultCategories);
  const [deliveryLinkInput, setDeliveryLinkInput] = useState("");
  const [featuresInput, setFeaturesInput] = useState({});
  const [descriptionInput, setDescriptionInput] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [imageFile1, setImageFile1] = useState("");
  const [imageFile2, setImageFile2] = useState("");
  const [imageFile3, setImageFile3] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [verifyPasswordInput, setVerifyPasswordInput] = useState("");
  const [promoInput, setPromoInput] = useState(promoCode || "");
  const [consentInput, setConsentInput] = useState(terms || false);
  const images = [imageFile1, imageFile2, imageFile3];
  const isDiscriptionComplete = descriptionInput.length < 40;
  const isCorporate = locationType === "Multiple Locations";
  const discriptionLegend = isDiscriptionComplete
    ? `${40 - descriptionInput.trim().length} more characters needed`
    : `${descriptionInput.trim().length} characters`;
  const height = `calc(100vh - ${isSafairMobile ? 150 : 40}px`;
  const isBeta2y = promoCode ? promoCode.toLowerCase() === "beta2y" : false;

  const slideTo = (index) => {
    const carouselElememt = carousel ? carousel.current : null;
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    const position = width * index;
    if (carouselElememt)
      carouselElememt.style.transform = `translate(-${position}px, 0)`;
    setCurrentIndex(index);
  };

  const handleChange = (evt, type, newValue) => {
    const { value } = evt.target;
    switch (type) {
      case "nameInput":
        return setNameInput(value);
      case "managerNameInput":
        return setManagerNameInput(value);
      case "emailInput":
        return setEmailInput(value);
      case "phoneInput":
        const newPhoneValue = value
          .replace("-", "")
          .replace(".", "")
          .match(/^[0-9]*$/gi);
        if (newPhoneValue === null) return setPhoneInput(phoneInput);
        return setPhoneInput(newPhoneValue[0].slice(0, 10));
      case "addressInput":
        return setAddressInput(value);
      case "cityInput":
        return setCityInput(value);
      case "stateInput":
        let newStateInput = "";
        if (value) newStateInput = value.match(/\w/gi).join("").slice(0, 2);
        return setStateInput(newStateInput);
      case "zipInput":
        let newZipInput = "";
        if (value) newZipInput = value.match(/\d/gi).join("").slice(0, 5);
        return setZipInput(newZipInput);
      case "websiteInput":
        return setWebsiteInput(value);
      case "descriptionInput":
        return setDescriptionInput(value);
      case "setLocationType":
        return setLocationType(newValue);
      case "imageInput1":
        return setImageFile1(newValue);
      case "imageInput2":
        return setImageFile2(newValue);
      case "imageInput3":
        return setImageFile3(newValue);
      case "businessPasswordInput":
        return setPasswordInput(value);
      case "businessVerifyPasswordInput":
        return setVerifyPasswordInput(value);
      case "consentInput":
        return setConsentInput(newValue);
      case "promoInput":
        return setPromoInput(value.slice(0, 6));
    }
  };

  const handleDeliveryLink = (evt, name) => {
    if (name === "DeliveryInput") {
      const { value } = evt.target;
      setDeliveryLinkInput(value);
    }
  };

  const handleShowToggle = (evt, newValue, params) => {
    evt.preventDefault();
    switch (params) {
      case "phoneInput":
        return setShowPhone(newValue);
    }
  };

  const handleFeatureClick = (feature) => {
    const value = featuresInput[feature] || false;
    if (!value) return setFeaturesInput({ ...featuresInput, [feature]: true });
    const newFeaturesInput = { ...featuresInput };
    delete newFeaturesInput[feature];
    setFeaturesInput(newFeaturesInput);
  };

  const createBusinessData = () => {
    const locationData =
      locationType === "One Location"
        ? {
            addressInput: addressInput.trim().toLowerCase(),
            cityInput: cityInput.trim().toLowerCase(),
            stateInput: stateInput.trim().toLowerCase(),
            zipInput,
          }
        : {};
    const businessData = {
      name: nameInput.trim().toLowerCase(),
      managerName: managerNameInput.trim().toLowerCase(),
      email: emailInput.trim().toLowerCase(),
      phone: phoneInput,
      showPhone,
      description: descriptionInput.trim(),
      website: websiteInput.trim(),
      locationType,
      address: addressInput,
      city: cityInput,
      state: stateInput,
      zip: zipInput,
      password: passwordInput,
      features: featuresInput,
      deliveryLink: isCorporate ? "" : deliveryLinkInput,
      hours,
      categories,
      verified: false,
      lastUpdated: convertDateToDbDate(),
      createdDate: convertDateToDbDate(),
      timeStamp: moment().format("lll"),
      ...locationData,
    };
    if (promoInput) businessData.promoCode = promoInput;
    return businessData;
  };

  const handleBusiness = async (businessData) => {
    if (imageFile1 || imageFile2 || imageFile3) {
      setLoader("Saving Images");
      const storedImages = await Promise.all(
        images.reduce((acc, image) => {
          if (image) return [...acc, storeImage(image, "business")];
          return acc;
        }, [])
      );
      if (storedImages.length) businessData.images = storedImages;
    }

    setLoader("Creating Business");
    const { user } = await createBusiness({
      ...businessData,
      hours: formatAfter12Hours(businessData.hours),
    });
    if (user) {
      return setTimeout(() => {
        setUser({ isUser: false, user });
        setLoader(false);
        history.push("/business/profile");
      }, 1000);
    } else {
      if (businessData.images)
        await Promise.all(
          businessData.images.map((image) => deleteImage(image))
        );
      setLoader(false);
    }
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setLoader("Verifying Information");
    const businessData = createBusinessData();
    const isBetaPromo = promoInput === "beta2y";
    const passwordVerified = isBetaPromo
      ? true
      : passwordInput === verifyPasswordInput &&
        validatePassword(passwordInput);
    if (!passwordVerified) {
      setPasswordInput("");
      setVerifyPasswordInput("");
    }
    const createAccountAdditionalIds = {
      businessPasswordInput: passwordVerified,
      businessVerifyPasswordInput: passwordVerified,
      imageInput: imageFile1 || imageFile2 || imageFile3,
      signUpConsetInput: consentInput,
    };
    const hasRequiredInfo = validateBusines(
      businessData,
      createAccountAdditionalIds,
      "form-business-sign-up"
    );
    if (!passwordVerified || !hasRequiredInfo) return setLoader(false);
    if (businessData.locationType !== "One Location")
      return await handleBusiness(businessData);

    const { yelp, option, error } = await yelpVerifyBusiness(businessData);
    if (option) {
      setLoader(false);
      return setModalMessage(option);
    }
    if (!error) {
      if (yelp) {
        yelp.fetchedDate = moment().format();
        businessData.yelp = yelp;
      }
      return await handleBusiness(businessData);
    }
    setLoader(false);
  };

  const handleAcceptClick = async (evt) => {
    evt.preventDefault();
    setModalMessage("");
    setLoader(true);
    const businessData = createBusinessData();
    return await handleBusiness(businessData);
  };

  const contactInputs = [
    {
      name: "nameInput",
      value: nameInput,
      label: "Business Name",
    },
    {
      name: "managerNameInput",
      value: managerNameInput,
      label: "Owner/Manager Name",
    },
    {
      name: "phoneInput",
      value: phoneInput,
      label:
        locationType === "Multiple Locations"
          ? "Contact Phone"
          : "Business Phone",
      toggle: locationType !== "Multiple Locations",
      toggleValue: showPhone,
    },
    {
      name: "emailInput",
      value: emailInput,
      label: "Email",
      type: "email",
    },
    {
      name: "websiteInput",
      value: websiteInput,
      label: "Website",
    },
  ];

  const renderLocationTypes = () =>
    Object.keys(locationOptions(false)).map((title, idx) => {
      const isSelected = locationType === title;
      return (
        <div
          className={`business-feature-container business-feature-container-white ${
            isSelected ? "business-feature-selected" : ""
          }`}
          onClick={(evt) => handleChange(evt, "setLocationType", title)}
          key={idx}
        >
          <div
            className={`business-feature-svg business-signup-feature-svg ${
              isSelected ? "business-feature-svg-selected" : ""
            }`}
          >
            {getFeaturesIcon(title)}
          </div>
          <p className="business-feature-text">{title}</p>
        </div>
      );
    });

  const addressInputs = [
    {
      name: "addressInput",
      value: addressInput,
      label: "Address",
    },
    {
      name: "cityInput",
      value: cityInput,
      label: "City",
    },
    {
      name: "stateInput",
      value: stateInput,
      label: "State",
    },
    {
      name: "zipInput",
      value: zipInput,
      label: "Zip",
      type: "number",
    },
  ];

  const passwordInputs = [
    {
      name: "businessPasswordInput",
      value: passwordInput,
      label: "Password",
      type: "password",
    },
    {
      name: "businessVerifyPasswordInput",
      value: verifyPasswordInput,
      label: "Verify Password",
      type: "password",
    },
  ];

  return (
    <>
      <div className="full-page-carousel-wrapper" style={{ height }}>
        <img
          className="carousel-page-background-image"
          alt="EventHound Concert"
          src={"/img/food.jpg"}
        />

        <ul
          ref={carousel}
          className="carousel-container"
          style={{ width: "600%" }}
        >
          <li className="carousel-item" style={{ height }}>
            <div className="carousel-business-space-container" />
            <div className="carousel-business-item-content-container">
              <div className="business-signup-header-container">
                <div className="business-signup-icon">
                  {getFeaturesIcon("logo")}
                </div>
                <h2 className="card-title-SignUp">CREATE PROFILE</h2>
                <p>
                  Business profiles are free and get seen by users instantly
                </p>
              </div>

              {contactInputs.map((input, idx) => {
                const { name, value, label, type, toggle, toggleValue } = input;
                return (
                  <InputLabeled
                    name={name}
                    value={value}
                    label={label}
                    type={type}
                    handleChange={(evt) => handleChange(evt, name)}
                    toggle={toggle}
                    toggleValue={toggleValue}
                    handleToggle={handleShowToggle}
                    key={idx}
                  />
                );
              })}

              <Categories
                isCard={false}
                categories={categories}
                setCategories={setCategories}
              />

              <div className="carousel-business-button-container">
                <Button
                  size={isMobile ? "small" : "medium"}
                  text="Next"
                  handleClick={() => {
                    const hasRequiredInfo = validateBusinessCarousel(
                      createBusinessData(),
                      {},
                      "form-business-sign-up",
                      1
                    );
                    if (hasRequiredInfo) slideTo(currentIndex + 1);
                  }}
                />
              </div>
            </div>
          </li>

          <li className="carousel-item" style={{ height }}>
            <div className="carousel-business-space-container" />
            <div className="carousel-business-item-content-container">
              <div className="business-signup-header-container">
                <div className="business-signup-icon">
                  {getFeaturesIcon("business")}
                </div>
                <h2 className="card-title-SignUp">Business Type</h2>
              </div>

              <div className="card-features-container">
                {renderLocationTypes()}
              </div>
              <p className="business-location-type-description type-title-description">
                {locationOptions(false)[locationType].description}
              </p>

              {locationType === "One Location" &&
                addressInputs.map((input, idx) => {
                  const { name, value, label, type, toggle, toggleValue } =
                    input;
                  return (
                    <InputLabeled
                      name={name}
                      value={value}
                      label={label}
                      type={type}
                      handleChange={(evt) => handleChange(evt, name)}
                      toggle={toggle}
                      toggleValue={toggleValue}
                      handleToggle={handleShowToggle}
                      key={idx}
                    />
                  );
                })}

              <div className="carousel-business-button-container">
                <Button
                  size={isMobile ? "small" : "medium"}
                  text="Back"
                  handleClick={() => slideTo(currentIndex - 1)}
                />
                <Button
                  size={isMobile ? "small" : "medium"}
                  text="Next"
                  handleClick={() => {
                    const hasRequiredInfo = validateBusinessCarousel(
                      createBusinessData(),
                      {},
                      "form-business-sign-up",
                      2
                    );
                    if (hasRequiredInfo) slideTo(currentIndex + 1);
                  }}
                />
              </div>
            </div>
          </li>

          <li className="carousel-item" style={{ height }}>
            <div className="carousel-business-space-container" />
            <div className="carousel-business-item-content-container">
              <div className="business-signup-header-container">
                <div className="business-signup-icon">
                  {getFeaturesIcon("clock")}
                </div>
                <h2 className="card-title-SignUp">Hours Of Operation</h2>
              </div>

              <WeekdaysTimeInput
                page="sign-up"
                isCard={false}
                hours={hours}
                setHours={setHours}
              />

              <div className="carousel-business-button-container">
                <Button
                  size={isMobile ? "small" : "medium"}
                  text="Back"
                  handleClick={() => slideTo(currentIndex - 1)}
                />
                <Button
                  size={isMobile ? "small" : "medium"}
                  text="Next"
                  handleClick={() => {
                    const hasRequiredInfo = validateBusinessCarousel(
                      createBusinessData(),
                      {},
                      "form-business-sign-up",
                      3
                    );
                    if (hasRequiredInfo) slideTo(currentIndex + 1);
                  }}
                />
              </div>
            </div>
          </li>

          <li className="carousel-item" style={{ height }}>
            <div className="carousel-business-space-container" />
            <div className="carousel-business-item-content-container">
              <div className="business-signup-header-container">
                <div className="business-signup-icon">
                  {getFeaturesIcon("details")}
                </div>
                <h2 className="card-title-SignUp">Details</h2>
              </div>

              <div className="legend-form-split-container">
                <label id="descriptionInput" className="label-form">
                  Description:
                </label>
                <label className="label-form">
                  <p
                    className={`legend-form-text ${
                      isDiscriptionComplete
                        ? "legend-form-incomplete"
                        : "legend-form-complete"
                    }`}
                  >
                    {discriptionLegend}
                  </p>
                </label>
              </div>

              <textarea
                className="input-style input-form"
                rows="5"
                maxLength="1500"
                value={descriptionInput}
                onChange={(evt) => handleChange(evt, "descriptionInput")}
                spellCheck="true"
              />

              <label id="imageInput" className="label-form">
                Images:
              </label>
              <label id="imageInput" className="label-form">
                Front of Business:
              </label>
              <div className="time-selector-wrapper-CreateListing">
                <ImageUpload
                  type="imageInput1"
                  imageInput={imageFile1}
                  handleChange={handleChange}
                />
              </div>

              <label className="label-form">Inside of Business:</label>
              <div className="time-selector-wrapper-CreateListing">
                <ImageUpload
                  type="imageInput2"
                  imageInput={imageFile2}
                  handleChange={handleChange}
                />
              </div>

              <label className="label-form">Additional Business Photo:</label>
              <div className="time-selector-wrapper-CreateListing">
                <ImageUpload
                  type="imageInput3"
                  imageInput={imageFile3}
                  handleChange={handleChange}
                />
              </div>

              <div className="carousel-business-button-container">
                <Button
                  size={isMobile ? "small" : "medium"}
                  text="Back"
                  handleClick={() => slideTo(currentIndex - 1)}
                />
                <Button
                  size={isMobile ? "small" : "medium"}
                  text="Next"
                  handleClick={() => {
                    const hasRequiredInfo = validateBusinessCarousel(
                      createBusinessData(),
                      {
                        imageInput: imageFile1 || imageFile2 || imageFile3,
                      },
                      "form-business-sign-up",
                      4
                    );
                    if (hasRequiredInfo) slideTo(currentIndex + 1);
                  }}
                />
              </div>
            </div>
          </li>

          <li className="carousel-item" style={{ height }}>
            <div className="carousel-business-space-container" />
            <div className="carousel-business-item-content-container">
              <div className="business-signup-header-container">
                <div className="business-signup-icon">
                  {getFeaturesIcon("features")}
                </div>
                <h2 className="card-title-SignUp">Features</h2>
              </div>

              <Features
                values={featuresInput}
                linkValue={deliveryLinkInput}
                handleClick={handleFeatureClick}
                handleChange={handleDeliveryLink}
                isDeliveryOption={!isCorporate}
                isCard={false}
                isTitle={false}
              />

              <div className="carousel-business-button-container">
                <Button
                  size={isMobile ? "small" : "medium"}
                  text="Back"
                  handleClick={() => slideTo(currentIndex - 1)}
                />
                <Button
                  size={isMobile ? "small" : "medium"}
                  text="Next"
                  handleClick={() => {
                    const hasRequiredInfo = validateBusinessCarousel(
                      createBusinessData(),
                      {},
                      "form-business-sign-up",
                      5
                    );
                    if (hasRequiredInfo) slideTo(currentIndex + 1);
                  }}
                />
              </div>
            </div>
          </li>

          <li className="carousel-item" style={{ height }}>
            <div className="carousel-business-space-container" />
            <div className="carousel-business-item-content-container">
              <div className="business-signup-header-container">
                <div className="business-signup-icon">
                  {getFeaturesIcon("key")}
                </div>
                <h2 className="card-title-SignUp">Security</h2>
              </div>

              {!isBeta2y &&
                passwordInputs.map((input, idx) => {
                  const { name, value, label, type } = input;
                  return (
                    <InputLabeled
                      name={name}
                      value={value}
                      label={label}
                      type={type}
                      handleChange={(evt) => handleChange(evt, name)}
                      key={idx}
                    />
                  );
                })}
              {!isBeta2y && (
                <legend className="legend-form">
                  Requires: Min length 8, letter(lower & uppercase), number, and
                  a special character (@$!%*?&).
                </legend>
              )}

              {!isFree && (
                <InputLabeled
                  name="promoInput"
                  value={promoInput}
                  label="Promo Code"
                  handleChange={(evt) => handleChange(evt, "promoInput")}
                />
              )}

              <label className="label-form">Terms Of Use:</label>
              <div className="submit-outer-wrapper-CreateListing">
                <div className="submit-inner-wrapper-CreateListing">
                  <div className="consent-terms-container">
                    <a
                      className="consent-terms-link"
                      href="/terms-of-service"
                      target="blank"
                    >
                      View Terms
                    </a>
                    <div className="business-signup-terms-arrow">
                      <SkinnyArrow />
                    </div>
                  </div>
                  <div className="consent-wrapper-CreateListing">
                    <Checkbox
                      state={consentInput}
                      handleClick={(evt) =>
                        handleChange(evt, "consentInput", !consentInput)
                      }
                      params={[{ target: { value: "" } }, "consentInput"]}
                    />
                    <p
                      id="signUpConsetInput"
                      className="consent-text-CreateListing"
                    >
                      I have read and accept the terms
                    </p>
                  </div>
                </div>
              </div>

              <div className="carousel-business-button-container">
                <Button
                  size={isMobile ? "small" : "medium"}
                  text="Back"
                  handleClick={() => slideTo(currentIndex - 1)}
                />
                <Button
                  size={isMobile ? "small" : "medium"}
                  text="Submit"
                  handleClick={handleSubmit}
                />
              </div>
            </div>
          </li>
        </ul>
      </div>
      <Modal
        directModalMessage={modalMessage}
        modalAccept="Continue"
        modalCancel="Review"
        handleCancelClick={(evt) => {
          evt.preventDefault();
          setModalMessage("");
        }}
        handleAcceptClick={handleAcceptClick}
      />
    </>
  );
};

const mapStateToProps = (store) => ({
  isFree: store.state.isFree,
});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (loaderState) => dispatch(stateActions.setLoader(loaderState)),
  setUser: (userObj) => dispatch(userActions.setUser(userObj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BusinessSignUp));
