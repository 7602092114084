import React from "react";

// Styles
import "./select.css";

export default ({
  isActive,
  classNames = "",
  options,
  value,
  handleChange,
  children,
  name,
  styles,
  conatinerStyles,
}) => (
  <div
    className={`input-container-Input ${
      isActive ? "input-container-Input-active" : ""
    }`}
    style={conatinerStyles}
  >
    <div
      className={`input-svg-container-Input ${
        isActive ? "input-svg-container-Input-active" : ""
      }`}
    >
      {children}
    </div>
    <select
      className={classNames}
      style={styles}
      onChange={handleChange}
      value={value}
    >
      {options.map((option, idx) => (
        <option value={option} key={idx}>
          {option ? option : `All ${name}`}
        </option>
      ))}
    </select>
  </div>
);
