import React from "react";

// Components
import Spacer from "../../../components/common/spacer";

// Utilities
import { isBottomSpacing } from "../../../../utilites";

// Styles
import "../legal.css";

const PrivacyPolicy = () => {
  return (
    <div className="legal-wrapper">
      <h1>Welcome to our Privacy Policy</h1>
      <h3>Your privacy is critically important to us.</h3>
      <h1 style={{ margin: "20px 0 10px 0" }}>EventHound</h1>

      <p>
        It is EventHound's policy to respect your privacy regarding any
        information we may collect while operating our website. This Privacy
        Policy applies to{" "}
        <a href="http://www.EventHound.com">www.EventHound.com</a> (hereinafter,
        "us", "we", or "www.EventHound.com"). We respect your privacy and are
        committed to protecting personally identifiable information you may
        provide us through the Website. We have adopted this privacy policy
        ("Privacy Policy") to explain what information may be collected on our
        Website, how we use this information, and under what circumstances we
        may disclose the information to third parties. This Privacy Policy
        applies only to information we collect through the Website and does not
        apply to our collection of information from other sources.
      </p>
      <p>
        This Privacy Policy, together with the Terms and conditions posted on
        our Website, set forth the general rules and policies governing your use
        of our Website. Depending on your activities when visiting our Website,
        you may be required to agree to additional terms and conditions.
      </p>

      <h2>Website Visitors</h2>
      <p>
        Like most website operators, EventHound collects
        non-personally-identifying information of the sort that web browsers and
        servers typically make available, such as the browser type, language
        preference, referring site, and the date and time of each visitor
        request. EventHound's purpose in collecting non-personally identifying
        information is to better understand how EventHound's visitors use its
        website. From time to time, EventHound may release
        non-personally-identifying information in the aggregate, e.g., by
        publishing a report on trends in the usage of its website.
      </p>
      <p>
        EventHound also collects potentially personally-identifying information
        like Internet Protocol (IP) addresses for logged in users and for users
        leaving comments on http://www.EventHound.com blog posts. EventHound
        only discloses logged in user and commenter IP addresses under the same
        circumstances that it uses and discloses personally-identifying
        information as described below.
      </p>

      <h2>Gathering of Personally-Identifying Information</h2>
      <p>
        Certain visitors to EventHound's websites choose to interact with
        EventHound in ways that require EventHound to gather
        personally-identifying information. The amount and type of information
        that EventHound gathers depends on the nature of the interaction. For
        example, we ask visitors who sign up for a blog at
        http://www.EventHound.com to provide a username and email address.
      </p>

      <h2>Security</h2>
      <p>
        The security of your Personal Information is important to us, but
        remember that no method of transmission over the Internet, or method of
        electronic storage is 100% secure. While we strive to use commercially
        acceptable means to protect your Personal Information, we cannot
        guarantee its absolute security.
      </p>

      <h2>Advertisements</h2>
      <p>
        Ads appearing on our website may be delivered to users by advertising
        partners, who may set cookies. These cookies allow the ad server to
        recognize your computer each time they send you an online advertisement
        to compile information about you or others who use your computer. This
        information allows ad networks to, among other things, deliver targeted
        advertisements that they believe will be of most interest to you. This
        Privacy Policy covers the use of cookies by EventHound and does not
        cover the use of cookies by any advertisers.
      </p>

      <h2>Links To External Sites</h2>
      <p>
        Our Service may contain links to external sites that are not operated by
        us. If you click on a third party link, you will be directed to that
        third party's site. We strongly advise you to review the Privacy Policy
        and terms and conditions of every site you visit.
      </p>
      <p>
        We have no control over, and assume no responsibility for the content,
        privacy policies or practices of any third party sites, products or
        services.
      </p>

      <h2>Www.EventHound.com uses Google AdWords for remarketing</h2>
      <p>
        Www.EventHound.com uses the remarketing services to advertise on third
        party websites (including Google) to previous visitors to our site. It
        could mean that we advertise to previous visitors who haven't completed
        a task on our site, for example using the contact form to make an
        enquiry. This could be in the form of an advertisement on the Google
        search results page, or a site in the Google Display Network.
        Third-party vendors, including Google, use cookies to serve ads based on
        someone's past visits. Of course, any data collected will be used in
        accordance with our own privacy policy and Google's privacy policy.
      </p>
      <p>
        You can set preferences for how Google advertises to you using the
        Google Ad Preferences page, and if you want to you can opt out of
        interest-based advertising entirely by cookie settings or permanently
        using a browser plugin.
      </p>

      <h2>Protection of Certain Personally-Identifying Information</h2>
      <p>
        EventHound discloses potentially personally-identifying and
        personally-identifying information only to those of its employees,
        contractors and affiliated organizations that (i) need to know that
        information in order to process it on EventHound's behalf or to provide
        services available at EventHound's website, and (ii) that have agreed
        not to disclose it to others. Some of those employees, contractors and
        affiliated organizations may be located outside of your home country; by
        using EventHound's website, you consent to the transfer of such
        information to them. EventHound will not rent or sell potentially
        personally-identifying and personally-identifying information to anyone.
        Other than to its employees, contractors and affiliated organizations,
        as described above, EventHound discloses potentially
        personally-identifying and personally-identifying information only in
        response to a subpoena, court order or other governmental request, or
        when EventHound believes in good faith that disclosure is reasonably
        necessary to protect the property or rights of EventHound, third parties
        or the public at large.
      </p>
      <p>
        If you are a registered user of http://www.EventHound.com and have
        supplied your email address, EventHound may occasionally send you an
        email to tell you about new features, solicit your feedback, or just
        keep you up to date with what's going on with EventHound and our
        products. We primarily use our blog to communicate this type of
        information, so we expect to keep this type of email to a minimum. If
        you send us a request (for example via a support email or via one of our
        feedback mechanisms), we reserve the right to publish it in order to
        help us clarify or respond to your request or to help us support other
        users. EventHound takes all measures reasonably necessary to protect
        against the unauthorized access, use, alteration or destruction of
        potentially personally-identifying and personally-identifying
        information.
      </p>

      <h2>Aggregated Statistics</h2>
      <p>
        EventHound may collect statistics about the behavior of visitors to its
        website. EventHound may display this information publicly or provide it
        to others. However, EventHound does not disclose your
        personally-identifying information.
      </p>

      <h2>Affiliate Disclosure</h2>
      <p>
        This site uses affiliate links and does earn a commission from certain
        links. This does not affect your purchases or the price you may pay.
      </p>

      <h2>Cookies</h2>
      <p>
        To enrich and perfect your online experience, EventHound uses "Cookies",
        similar technologies and services provided by others to display
        personalized content, appropriate advertising and store your preferences
        on your computer.
      </p>
      <p>
        A cookie is a string of information that a website stores on a visitor's
        computer, and that the visitor's browser provides to the website each
        time the visitor returns. EventHound uses cookies to help EventHound
        identify and track visitors, their usage of http://www.EventHound.com,
        and their website access preferences. EventHound visitors who do not
        wish to have cookies placed on their computers should set their browsers
        to refuse cookies before using EventHound's websites, with the drawback
        that certain features of EventHound's websites may not function properly
        without the aid of cookies.
      </p>
      <p>
        By continuing to navigate our website without changing your cookie
        settings, you hereby acknowledge and agree to EventHound's use of
        cookies.
      </p>

      <h2>Privacy Policy Changes</h2>
      <p>
        Although most changes are likely to be minor, EventHound may change its
        Privacy Policy from time to time, and in EventHound's sole discretion.
        EventHound encourages visitors to frequently check this page for any
        changes to its Privacy Policy. Your continued use of this site after any
        change in this Privacy Policy will constitute your acceptance of such
        change.
      </p>

      <p>
        . If you have any questions about this Privacy Policy, please contact us
        via <a href="mailto:info@EventHound.com">email</a>
      </p>
      <Spacer height={isBottomSpacing(6)} />
    </div>
  );
};

export default PrivacyPolicy;
