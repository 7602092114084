import React from "react";

// Components
import Button from "../../../components/common/button";
import VerticalScroll from "../../../components/vertical-scroll";

// Assets
import RatingStarsClip from "../../../../assets/svg/rating-stars-clip";

// Styles
import "./yelp.css";

export default ({ yelp, name, setImage, handleDelete }) => {
  const { url, photos, rating = 0, review_count } = yelp || {};
  const ratingPercent = (rating / 5) * 100;
  const isYelp = yelp && "fetchedDate" in yelp;

  return isYelp ? (
    <div style={{ position: "relative" }}>
      <a href={url} target="blank">
        <img className="yelp-logo" alt="yelp" src="/img/yelp.png" />
      </a>
      <div className="listing-content-container">
        <div className="yelp-specs-container">
          <a className="yelp-review-wrapper" href={url} target="blank">
            <div className="yelp-review-result-conatiner">
              <div
                className="yelp-review-fill-clip"
                style={{ width: `${ratingPercent}%` }}
              >
                <RatingStarsClip />
              </div>
            </div>
            <p className="yelp-review-count-text">
              (<span className="yelp-review-count">{review_count}</span>)
              Reviews
            </p>
          </a>

          {handleDelete && (
            <div className="yelp-remove">
              <Button
                text="Remove"
                size="small"
                isPrimary={false}
                handleClick={handleDelete}
              />
            </div>
          )}
        </div>

        <VerticalScroll>
          {photos.map((url, idx) => (
            <img
              className="pointer yelp-image-preview"
              alt={name}
              src={url}
              onClick={() => setImage(url)}
              key={idx}
            />
          ))}
        </VerticalScroll>
      </div>
    </div>
  ) : null;
};
