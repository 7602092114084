import React, { useState } from "react";
import { connect } from "react-redux";
import * as dataActions from "../../../redux/actions/data-actions";
import * as userActions from "../../../redux/actions/user-actions";
import * as stateActions from "../../../redux/actions/state-actions";

// Components
import AutocompleteItems from "../../components/autocomplete-items.js";

// Utilities
import { getCityLocation } from "../../../utilites/location";
import { getCoordinates } from "../../../api/search";
import useGeoLocation from "../../../utilites/hooks/useGeoLocation";

// Assets
import Close from "../../../assets/svg/close";
import Search from "../../../assets/svg/search";
import Location from "../../../assets/svg/location";
import LocationStroke from "../../../assets/svg/location-stroke";

// Styles
import "./searchbar.css";

const SearchBar = ({
  user,
  filters,
  location,
  setFilter,
  setLocation,
  setMessage,
  searchBarInput,
  setSearchBarInput,
  locationInput,
  setLocationInput,
  autoComplete,
  setAutoComplete,
}) => {
  const { getCurrentPosition } = useGeoLocation();
  const { geoPermission } = user;
  const { search } = filters || {};
  const [autoCompleteType, setAutoCompleteType] = useState("");
  const isLocation = "coordinates" in location;
  const geoLocationIsCurrent = geoPermission && !isLocation;
  const cityLocation = getCityLocation(user);
  const isAutoComplete = !!autoComplete.length;
  const isSearchValue = !!search || !!searchBarInput;
  const isLocationValue = !!isLocation || !!locationInput;

  function handleChange(evt, type) {
    const { value } = evt.target;
    switch (type) {
      case "search":
        return setSearchBarInput(value);
      case "location":
        return setLocationInput(value);
    }
  }

  async function handleEnteredLocation() {
    if (locationInput) {
      const { location } =
        (await getCoordinates({ address: locationInput })) || {};
      if ("coordinates" in location) {
        setLocation(location);
      } else {
        setMessage({
          messageType: "error",
          message: "Location Not found",
        });
      }
    } else {
      setLocation({});
    }
  }

  const handleAutoCompleteChange = (list, type) => {
    setAutoCompleteType(type);
    setAutoComplete(list);
  };

  const handleAutoCompleteClick = async (item, type) => {
    setAutoComplete([]);
    switch (type) {
      case "search":
        setSearchBarInput("");
        setFilter({ search: item });
        break;
      case "location":
        const { location = {} } =
          (await getCoordinates({ address: item })) || {};
        if ("coordinates" in location) {
          setLocation(location);
          setLocationInput("");
        }
        break;
    }
  };

  const handleLocationClick = () => {
    getCurrentPosition();
    if (isLocation) setLocation({});
  };

  const handleKeyDown = (evt, type) => {
    const { which, keyCode, charCode } = evt;
    const isEnter = which === 13 || keyCode === 13;
    const isDelete = keyCode == 8 || charCode == 46;
    if (isEnter) {
      setAutoComplete([]);
      switch (type) {
        case "search":
          setFilter({ search: searchBarInput });
          setSearchBarInput("");
          break;
        case "location":
          handleEnteredLocation();
          setLocationInput("");
          break;
      }
    }
  };

  const handleClearInput = (type) => {
    switch (type) {
      case "search":
        if (searchBarInput) setSearchBarInput("");
        if (search) setFilter({ search: "" });
        break;
      case "location":
        if (locationInput) setLocationInput("");
        if (isLocation) setLocation({});
        break;
    }
  };

  return (
    <div className="searchbar-container-SearchBar">
      <div className="searchbar-input-container-SearchBar">
        <div className="searchbar-svg-container-SearchBar">
          <Search />
        </div>
        <input
          type="text"
          className="searchbar-input-SearchBar"
          placeholder={search || "Search titles & desc..."}
          value={searchBarInput}
          onKeyDown={(evt) => handleKeyDown(evt, "search")}
          onChange={(evt) => handleChange(evt, "search")}
          spellCheck="true"
        />
        {isSearchValue && (
          <div
            className="searchbar-input-clear-container"
            onClick={() => handleClearInput("search")}
          >
            <Close />
          </div>
        )}
      </div>

      <div className="searchbar-location-container-SearchBar">
        <div
          className={`searchbar-svg-container-SearchBar searchbar-svg-location ${
            geoLocationIsCurrent ? "searchbar-svg-current-location" : ""
          }`}
          onClick={handleLocationClick}
        >
          {geoLocationIsCurrent ? <Location /> : <LocationStroke />}
        </div>
        <input
          className="searchbar-location-SearchBar"
          placeholder={cityLocation || "Location"}
          value={locationInput}
          onKeyDown={(evt) => handleKeyDown(evt, "location")}
          onChange={(evt) => handleChange(evt, "location")}
          spellCheck="true"
        />
        {isLocationValue && (
          <div
            className="searchbar-input-clear-container"
            onClick={() => handleClearInput("location")}
          >
            <Close />
          </div>
        )}
      </div>

      <div
        className={`searchbar-autocomplete-container ${
          isAutoComplete ? "searchbar-autocomplete-container-show" : ""
        }`}
      >
        <div className="searchbar-autocomplete-divider" />
        {AutocompleteItems({
          searchBarInput,
          locationInput,
          handleChange: handleAutoCompleteChange,
          handleClick: handleAutoCompleteClick,
          list: autoComplete,
          type: autoCompleteType,
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (store) => ({
  user: store.user,
  searchBarInput: store.data.searchBarInput,
  locationInput: store.user.locationInput,
  autoComplete: store.data.autoComplete,
  filters: store.data.filters,
  location: store.user.location,
});

const mapDispatchToProps = (dispatch) => ({
  setSearchBarInput: (input) => dispatch(dataActions.setSearchBarInput(input)),
  setLocationInput: (input) => dispatch(userActions.setLocationInput(input)),
  setAutoComplete: (autoCompleteList) =>
    dispatch(dataActions.setAutoComplete(autoCompleteList)),
  setFilter: (filterObj) => dispatch(dataActions.setFilter(filterObj)),
  setLocation: (coordsArray) => dispatch(userActions.setLocation(coordsArray)),
  setMessage: (dataObj) => dispatch(stateActions.setMessage(dataObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
