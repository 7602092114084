import React from "react";

// Assets
import Map from "../../../assets/svg/map";
import Results from "../../../assets/svg/results";
import Search from "../../../assets/svg/search";
import Location from "../../../assets/svg/location";
import LocationStroke from "../../../assets/svg/location-stroke";
import Close from "../../../assets/svg/close";
import Refresh from "../../../assets/svg/refresh";
import Calender from "../../../assets/svg/calender-filled";
import Time from "../../../assets/svg/time";
import TimeFilled from "../../../assets/svg/time-filled";
import Category from "../../../assets/svg/category";
import Features from "../../../assets/svg/features";
import Distance from "../../../assets/svg/distance";
import Sort from "../../../assets/svg/sort";
import Limit from "../../../assets/svg/limit";
import Animals from "../../../assets/svg/animals";
import Arcade from "../../../assets/svg/arcade";
import Bar from "../../../assets/svg/bar";
import Patio from "../../../assets/svg/patio";
import Rooftop from "../../../assets/svg/rooftop";
import Dog from "../../../assets/svg/dog";
import Brewery from "../../../assets/svg/brewery";
import Speakeasy from "../../../assets/svg/speakeasy";
import SportsBar from "../../../assets/svg/sportsbar";
import Dance from "../../../assets/svg/dance";
import Pool from "../../../assets/svg/pool";
import FoodTruck from "../../../assets/svg/foodtruck";
import FirePit from "../../../assets/svg/firpit";
import Outdoors from "../../../assets/svg/outdoors";
import View from "../../../assets/svg/view";
import Vineyard from "../../../assets/svg/vineyard";
import WaterSport from "../../../assets/svg/watersport";
import IndoorDining from "../../../assets/svg/indoor dining";
import OutdoorDining from "../../../assets/svg/outdoor dining";
import TakeOut from "../../../assets/svg/take-out";
import CurbsidePickup from "../../../assets/svg/curbside pickup";
import Delivery from "../../../assets/svg/delivery";
import Heart from "../../../assets/svg/heart";
import Video from "../../../assets/svg/video";
import BlackOwned from "../../../assets/svg/black-owned";
import OutdoorHeater from "../../../assets/svg/outdoor heater";
import Logo from "../../../assets/svg/eventHound-logo";
import Instaworthy from "../../../assets/svg/instaworthy";
import Award from "../../../assets/svg/award";
import Business from "../../../assets/svg/business";
import Promote from "../../../assets/svg/promote";
import LogoIcon from "../../../assets/svg/event-hound-icon";
import Name from "../../../assets/svg/eventHound-name";
import Enlarge from "../../../assets/svg/enlarge";
import ManyLocations from "../../../assets/svg/many-locations";
import Popular from "../../../assets/svg/popular";
import Food from "../../../assets/svg/food";
import Drinks from "../../../assets/svg/drinks";
import Activities from "../../../assets/svg/activities";
import Events from "../../../assets/svg/event";
import Qr from "../../../assets/svg/qr";
import Bookmark from "../../../assets/svg/bookmark";
import Happy from "../../../assets/svg/happy";
import SmallBusiness from "../../../assets/svg/small-business";
import Deals from "../../../assets/svg/deals";
import Clock from "../../../assets/svg/time-filled";
import Star from "../../../assets/svg/features";
import Details from "../../../assets/svg/details";
import Key from "../../../assets/svg/key";
import PostAd from "../../../assets/svg/post-ad";
import Pin from "../../../assets/svg/pin";
import Sale from "../../../assets/svg/sales";
import LGBTQ from "../../../assets/svg/lgbtq";
import Glasses from "../../../assets/svg/glasses";
import SignUp from "../../../assets/svg/signup";
import NewPost from "../../../assets/svg/newpost";
import Question from "../../../assets/svg/question";
import Instagram from "../../../assets/svg/instagram";
import SkinnyArrow from "../../../assets/svg/directionArrow";
import SocialMedia from "../../../assets/svg/socialMedia";
import Facebook from "../../../assets/svg/facebook";
import Check from "../../../assets/svg/check";
import Kids from "../../../assets/svg/kids";
import Seasonal from "../../../assets/svg/seasonal";
import Attraction from "../../../assets/svg/attraction";
import Landmark from "../../../assets/svg/landmark";

export default (name = "", filled = false) => {
  if (!filled) {
    switch (name.toLowerCase()) {
      case "logo":
        return <Logo />;
      case "features":
        return <Star />;
      case "animals":
        return <Animals />;
      case "arcade":
        return <Arcade />;
      case "bar":
        return <Bar />;
      case "brewery":
        return <Brewery />;
      case "dance floor":
        return <Dance />;
      case "dog friendly":
        return <Dog />;
      case "fire pit":
        return <FirePit />;
      case "food truck":
        return <FoodTruck />;
      case "outdoors":
        return <Outdoors />;
      case "patio":
        return <Patio />;
      case "pool table":
        return <Pool />;
      case "rooftop":
        return <Rooftop />;
      case "speakeasy":
        return <Speakeasy />;
      case "sports bar":
        return <SportsBar />;
      case "view":
        return <View />;
      case "vineyard":
        return <Vineyard />;
      case "water sport":
        return <WaterSport />;
      case "indoor dining":
        return <IndoorDining />;
      case "outdoor dining":
        return <OutdoorDining />;
      case "take out":
        return <TakeOut />;
      case "curbside pickup":
        return <CurbsidePickup />;
      case "delivery":
        return <Delivery />;
      case "singles":
        return <Heart />;
      case "virtual":
        return <Video />;
      case "black owned":
        return <BlackOwned />;
      case "outdoor heater":
        return <OutdoorHeater />;
      case "deals":
        return <Deals />;
      case "instaworthy":
        return <Instaworthy />;
      case "postworthy":
        return <Instaworthy />;
      case "signature specialities":
        return <Award />;
      case "business":
        return <Business />;
      case "promotions":
        return <Promote />;
      case "enlarge":
        return <Enlarge />;
      case "no physical location":
        return <FoodTruck />;
      case "one location":
        return <Business />;
      case "multiple locations":
        return <ManyLocations />;
      case "search":
        return <Search />;
      case "popular":
        return <Popular />;
      case "food":
        return <Food />;
      case "drinks":
        return <Drinks />;
      case "activities":
        return <Activities />;
      case "events":
        return <Events />;
      case "name":
        return <Name />;
      case "close":
        return <Close />;
      case "qr":
        return <Qr />;
      case "bookmark":
        return <Bookmark />;
      case "happy":
        return <Happy />;
      case "small business":
        return <SmallBusiness />;
      case "clock":
        return <Clock />;
      case "features":
        return <Clock />;
      case "map":
        return <Map />;
      case "results":
        return <Results />;
      case "search":
        return <Search />;
      case "location":
        return <LocationStroke />;
      case "refresh":
        return <Refresh />;
      case "subCat":
        return <Category />;
      case "features":
        return <Features />;
      case "date":
        return <Calender />;
      case "time":
        return <Time />;
      case "distance":
        return <Distance />;
      case "sortBy":
        return <Sort />;
      case "limit":
        return <Limit />;
      case "close":
        return <Close />;
      case "map":
        return <Map />;
      case "results":
        return <Results />;
      case "search":
        return <Search />;
      case "location":
        return <LocationStroke />;
      case "refresh":
        return <Refresh />;
      case "subCat":
        return <Category />;
      case "features":
        return <Features />;
      case "date":
        return <Calender />;
      case "time":
        return <Time />;
      case "distance":
        return <Distance />;
      case "sortBy":
        return <Sort />;
      case "limit":
        return <Limit />;
      case "close":
        return <Close />;
      case "details":
        return <Details />;
      case "key":
        return <Key />;
      case "postad":
        return <PostAd />;
      case "location":
        return <Location />;
      case "pin":
        return <Pin />;
      case "sale":
        return <Sale />;
      case "lgbtq":
        return <LGBTQ />;
      case "glasses":
        return <Glasses />;
      case "signup":
        return <SignUp />;
      case "newpost":
        return <NewPost />;
      case "question":
        return <Question />;
      case "instagram":
        return <Instagram />;
      case "skinny arrow":
        return <SkinnyArrow />;
      case "social media":
        return <SocialMedia />;
      case "facebook":
        return <Facebook />;
      case "check":
        return <Check />;
      case "kids":
        return <Kids />;
      case "seasonal":
        return <Seasonal />;
      case "attraction":
        return <Attraction />;
      case "landmark":
        return <Landmark />;
      default:
        return <LogoIcon />;
    }
  } else {
    switch (name) {
      case "time":
        return <TimeFilled />;
    }
  }
};
