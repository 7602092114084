import React from "react";

// Styles
import "../../listing/listing.css";
import "./button-link.css";

export default ({
  isDateCurrent,
  status,
  link,
  text = "DELIVERY LINK",
  isClickable = true,
}) => {
  const isActiveStatus = status === "active" || status === "ending";
  const cardHeaderStyle = isDateCurrent
    ? `listing-header-container-${status}`
    : "listing-header-container-starting";

  const renderDeliveryLink = () => {
    let imageLink = "";
    if (link.indexOf("grubhub") > -1) {
      imageLink = "/img/grubhub.png";
    }
    if (link.indexOf("doordash") > -1) {
      imageLink = "/img/doordash.png";
    }
    if (link.indexOf("postmates") > -1) {
      imageLink = "/img/postmates.png";
    }
    if (link.indexOf("uber") > -1) {
      imageLink = "/img/ubereats.png";
    }
    if (imageLink) {
      return (
        <img
          className="business-delivery-logo"
          alt="EventHound delivery link"
          src={imageLink}
        />
      );
    }
    return (
      <p
        className={`redemtion-button-code-text ${
          isActiveStatus ? "redemtion-button-code-text-active" : ""
        }`}
      >
        {text}
      </p>
    );
  };

  return (
    !!link && (
      <div className="listing-spacing listing-center-content">
        {isClickable ? (
          <a
            className={`redemtion-button-code-button business-delivery-container ${cardHeaderStyle}`}
            href={link.indexOf("//") > -1 ? link : `//${link}`}
            target="blank"
          >
            {renderDeliveryLink()}
          </a>
        ) : (
          <div
            className={`redemtion-button-code-button business-delivery-container ${cardHeaderStyle}`}
          >
            {renderDeliveryLink()}
          </div>
        )}
      </div>
    )
  );
};
