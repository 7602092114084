import React from "react";

import "./svg.css";

export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 55 100"
    >
      <path
        className="svg-clip-rule"
        d="M29,1.6c-7.4,1-12.5,5.2-14.2,11.5c-0.5,2.1-0.6,2.6-0.6,13.2l-0.1,9.8H8.5H2.8v7.1v7.1l5.7,0
		l5.7,0v6.2c0,3.4,0,14.2,0,24l0,17.8l10.2,0l10.2,0v-24v-24h6.7h6.7l0.1-0.2c0.1-0.2,4.1-13.4,4.2-13.9l0.1-0.2h-8.9h-8.9l0-5.2
		c-0.1-7.5,0.1-8.7,1.6-10.4c1.2-1.3,1.5-1.3,8.9-1.3l5.9,0v-8.9V1.5l-10.4,0C32.3,1.5,29.8,1.5,29,1.6"
      />
    </svg>
  );
};
