import { toTitleCase } from "../utilites/format";

export const gApi =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyBiHQ0e6hYlswenbL6zT3ii24MyrDVUEFY&v=3.exp&libraries=geometry";

export const dayTimes = {
  dayStart: 600,
  dayEnd: 1700,
};

export const defaultDaysOfTheWeek = {
  Monday: false,
  Tuesday: false,
  Wednesday: false,
  Thursday: false,
  Friday: false,
  Saturday: false,
  Sunday: false,
};
export const dayOfTheWeek = Object.keys(defaultDaysOfTheWeek);

export const searchTypes = [
  "Deals",
  "Promotions",
  // "Instaworthy",
  // "Signature Specialities",
  "Business",
];

export const searchTypesDescriptions = {
  Deals: "People love a good deal, lets promote yours! Discounts belong here.",
  Promotions:
    "Want to get the word out? We want to help. Use this type to promote fun and engaging itmes your businesses has to offer.",
  Postworthy:
    "Do you offer an evelated experince or somehting that makes people say WOW and want to take a picture? That’s 'Postworthy' and it belongs to this type.",
  "Signature Specialities":
    "This is to promote what you specialize in. A signature item, product your customers love, or something you simply do better than most.",
};

export const locationOptions = (isEditProfile = true) => ({
  "No Physical Location": {
    title: "No Physical Location",
    description:
      "Have a business with no physical brick and mortar location? Such as a food truck, home business or pop-up? With this model, a map location will only be required when promoting.",
  },
  "One Location": {
    title: "One Location",
    description:
      "This model uses the entered address as the default address for all promotions. Still allows for on the go promoting at other locations.",
  },
  "Multiple Locations": {
    title: "Multiple Locations",
    description: `With this model many locations will be managed with one account. Customizable attributes are available for each location. ${
      isEditProfile ? "" : "Locations are entered after sign-up."
    }`,
  },
});

export const subCatOptions = {
  Food: [
    "American",
    "Asian",
    "Breakfast",
    "Desserts",
    "French",
    "Fusion",
    "Greek",
    "Indian",
    "Italian",
    "Latin",
    "Mediterranean",
    "Seafood",
    "Vegan",
    "Vegetarian",
    "Other",
  ],
  Drinks: ["Alcohol", "Coffee/Tea", "Juices/Smoothies", "Shakes", "Other"],
  Activities: [
    "Arts",
    "Educational",
    "Games",
    "Health",
    "Recreational",
    "Sports",
    "Tobacco/Cannabis",
    "Tours",
    "Treat Yourself",
    "Other",
  ],
  Events: ["Entertainment", "Exhibits", "Music", "Sports", "Other"],
};
export const categoryList = ["", ...Object.keys(subCatOptions)];

export const features = [
  "Animals",
  "Arcade",
  "Attraction",
  "Bar",
  "Black Owned",
  "Brewery",
  "Curbside Pickup",
  "Dance Floor",
  "Delivery",
  "Dog Friendly",
  "Fire Pit",
  "Food Truck",
  "Postworthy",
  "Kids",
  "Landmark",
  "LGBTQ",
  "Outdoors",
  "Outdoor Dining",
  "Outdoor Heater",
  "Patio",
  "Pool Table",
  "Rooftop",
  "Seasonal",
  "Speakeasy",
  "Sports Bar",
  "Take Out",
  "View",
  "Vineyard",
  "Water Sport",
];

export const getFilterList = (values, timeOptions, type) => ({
  // Time: {
  //   options: timeOptions,
  //   value: values[0],
  //   name: "time",
  //   legend: "Time:",
  // },
  Distance: {
    options: [
      "1 Mile",
      "5 Miles",
      "10 Miles",
      "25 Miles",
      "50 Miles",
      "100 Miles",
      "500 Miles",
    ],
    value: values[1],
    name: "distance",
    legend: "Distance:",
  },
  "Sort By": {
    options:
      type === "Business"
        ? ["Trending", "Newest"]
        : ["Trending", "Distance", "Start Time", "Newest"],
    value: values[2],
    name: "sortBy",
    legend: "Sort By:",
  },
  Limit: {
    options: [20, 30, 40, 50],
    value: values[3],
    name: "limit",
    legend: "Limit:",
  },
});

export const mapOptions = {
  standard: [
    {
      featureType: "poi",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "transit",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ],
  day: [
    {
      featureType: "poi.park",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#C2E994",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#F8E1BE",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#B08C55",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#5A7189",
        },
        {
          lightness: -15,
        },
      ],
    },
  ],
  night: [
    { elementType: "geometry", stylers: [{ color: "#4a4d4e" }] },
    {
      elementType: "labels.text.stroke",
      stylers: [{ color: "#242f3e" }],
    },
    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#263c3f" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#6b9a76" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#999B9C" }],
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [{ color: "#212a37" }],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9ca5b3" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#999B9C" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#1f2835" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#f3d19c" }],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [{ color: "#2f3948" }],
    },
    {
      featureType: "transit.station",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#17263c" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#515c6d" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.stroke",
      stylers: [{ color: "#17263c" }],
    },
  ],
};

export const verbiage = {
  acceptFreeMonthlyAd:
    "This will be set as your one free monthly ad. If accepted, you will not be able to edit the title or descritption for one month. If you would like to add more, upgrade to a Premium or Unlimited account. Do you wish to continue?",
  acceptPremiumMonthlyAd:
    "This will be set as one of your monthly ads. If accepted, you will not be able to edit the title or descritption for one month. If you would like to have more then 3 ads, upgrade to an Unlimited account. Do you wish to continue?",
};

export const covidMessage = (businessName) =>
  `This badge indicates that ${toTitleCase({
    input: businessName,
  })} requires proof of vaccination for entry. If you are unable to receive a vaccine, a negative COVID test from the last 72 hours may be permitted. Contact the establishment for more information.`;

export const salesInvalidTypeMessage = (type) =>
  `This post currently has active ${type} sales. All sales on the post must be the same type. Either create a new post or deactivate the prior sales and then create new ones.`;
