import { callAPI } from "./";
import { baseURL } from "./url";

const businessURL = baseURL("/business");
const listingURL = baseURL("/listing/set");

export function businessGet(id) {
  const url = baseURL(`/getbusiness?id=${id}`);
  return callAPI(url, "GET");
}

export function businessLogin(data) {
  const url = baseURL("/getbusiness");
  return callAPI(url, "POST", data);
}

export function createBusiness(businessData) {
  return callAPI(businessURL, "PUT", businessData);
}

export function updateBusiness(businessData) {
  if ("token" in businessData) delete businessData.token;
  if ("ads" in businessData) delete businessData.ads;
  if ("locations" in businessData) delete businessData.locations;
  if ("account" in businessData) delete businessData.account;
  return callAPI(businessURL, "POST", businessData);
}

export function deleteBusiness({ id, password }) {
  const url = `${businessURL}?id=${id}&password=${password}`;
  return callAPI(url, "DELETE");
}

export function yelpVerifyBusiness(businessData) {
  const url = `${businessURL}/verify/yelp`;
  return callAPI(url, "PUT", businessData);
}

export function yelpDeleteBusiness(id) {
  const url = `${businessURL}/verify/yelp?id=${id}`;
  return callAPI(url, "DELETE");
}

export function applyBusinessPromo(businessData) {
  return callAPI(`${businessURL}/promo`, "PUT", businessData);
}

export function reportBusiness(data) {
  return callAPI(`${businessURL}/report`, "POST", data);
}

export function viewedBusiness(businessId, date) {
  const url = `${businessURL}/viewed?businessId=${businessId}&date=${date}`;
  return callAPI(url, "GET");
}

export function setBusinessFree(listingData) {
  return callAPI(listingURL, "POST", listingData);
}

export function setBusinessCovid(data) {
  const url = `${businessURL}/covid`;
  return callAPI(url, "POST", data);
}
