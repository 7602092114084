import React from "react";

// Components
import Modal from "../modal";
import PasswordInput from "../common/password-input";

// Styles
import "./password-modal.css";

export default ({
  modalMessage,
  isCurrentPassword = true,
  password = "",
  newPassword,
  verifyPassword,
  handeChange,
  handleAccept,
  handleCancel,
}) => (
  <Modal
    directModalMessage={modalMessage}
    modalAccept="Submit"
    modalCancel="cancel"
    handleCancelClick={handleCancel}
    handleAcceptClick={handleAccept}
  >
    <div className="password-change-container">
      {isCurrentPassword && (
        <>
          <label id="passwordInput" className="label-form">
            Current Password:
          </label>
          <PasswordInput
            value={password}
            handleChange={(evt) => handeChange(evt, "password")}
          />
        </>
      )}
      <label id="newPasswordInput" className="label-form">
        {`${isCurrentPassword ? "New" : ""} Password:`}
      </label>
      <PasswordInput
        value={newPassword}
        handleChange={(evt) => handeChange(evt, "newPassword")}
      />
      <label id="verifyPasswordInput" className="label-form">
        {`Verify${isCurrentPassword ? " New " : " "}Password:`}
      </label>
      <PasswordInput
        value={verifyPassword}
        handleChange={(evt) => handeChange(evt, "verifyPassword")}
      />
      <legend className="legend-form">
        Requires: Min length 8, letter(lower & uppercase), number, and a special
        character (@$!%*?&).
      </legend>
    </div>
  </Modal>
);
