import { handleApiError, setMessage } from "./status";
import { isDev } from "../utilites/status";

export function callAPI(url, type, data) {
  const formattedUrl = type.toLowerCase() === "get" ? encodeURI(url) : url;
  const options = {
    method: type,
    headers: {
      "x-api-key": "vvPDOisNUu63coRD0MUDN8oHtEx2Z1i8306NQvo2",
      "Content-Type": "application/json",
    },
  };
  if (data) options.body = JSON.stringify(data);
  return fetch(formattedUrl, options)
    .then(async (res) => {
      return {
        status: res.status,
        responseData: await res.json(),
      };
    })
    .then(({ status, responseData }) => {
      if (isDev()) console.log("Test: API response", status, responseData);
      if (status >= 400) {
        const messageObj = {
          messageType: "error",
          message: "Internal Server Error",
        };
        if ("error" in responseData) {
          const { error } = responseData || {};
          const { option, description } = error || {};
          const { errmsg } = description || {};
          if (typeof description === "string") messageObj.message = description;
          if (errmsg) messageObj.message = errmsg;
          if (option) return { option };
        }
        setMessage(messageObj);
      }
      return responseData;
    })
    .catch((err) => {
      if (err.message === "Failed to fetch")
        return handleApiError({ error: { description: "Network Error" } });
    });
}
