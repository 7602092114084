import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import * as userActions from "../../../redux/actions/user-actions";
import momentTZ from "moment-timezone";
import moment from "moment";

// Components
import TimeSelector from "../../components/common/time-selector";
import ImageUpload from "../../components/image-upload-fullframe";
import SwitchButton from "../../components/common/switch-button";
import Button from "../../components/common/button";

// Utilities
import { isSafariAgent, isMobileAgent } from "../../../utilites";
import {
  militaryToCorrectMilitary,
  militaryTimeToReadable,
} from "../../../utilites/time";
import { formatUserTags } from "../../../utilites/format";
import getFeaturesIcon from "../../components/features-input/getFeatureIcon";
import { getCurrentDate, convertDateToDbDate } from "../../../utilites/date";
import { validateSocialPost } from "../../../utilites/validate";
import { storeImage, deleteImage } from "../../../api/image";
import { postToSocial } from "../../../api/facebook";

// Styles
import "./create-post.css";

const CreateListing = ({
  setPage,
  facebookPost,
  setFacebookPost,
  instagramPost,
  setInstagramPost,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    _id: businessId,
    social,
    scheduledSocial = {},
  } = useSelector((store) => store.user.user);
  const isMobile = isMobileAgent();
  const isSafairMobile = isMobile && isSafariAgent();
  const [categories, setCategories] = useState({ cat1Img: "" });
  const [userTagsInput, setUserTagsInput] = useState("");
  const [descriptionInput, setDescriptionInput] = useState("");
  const [isPostNow, setIsPostNow] = useState(true);
  const [startDateInput, setStartDateInput] = useState(getCurrentDate());
  const [startTimeInput, setStartTimeInput] = useState(800);
  const height = `calc(100vh - ${isSafairMobile ? 150 : 40}px`;
  const { cat1Img } = categories;
  const { fbPageAccId, instagram } = social || {};
  const isFacebook = social && fbPageAccId;
  const isInstagram = social && instagram;

  const handleChange = (evt, type, newValue) => {
    const { value } = evt.target;
    switch (type) {
      case "userTags":
        return setUserTagsInput(value);
      case "descriptionInput":
        return setDescriptionInput(value);
      case "startDateInput":
        if (moment(value).isSameOrAfter(moment(), "days"))
          return setStartDateInput(value);
      case "startTimeInput":
        return setStartTimeInput(value);
    }
  };

  const handlePostNow = (type) => {
    setIsPostNow(type === "Right Now");
  };

  const handleCategoryImage = (evt, type, newValue) => {
    return setCategories({ cat1Img: newValue });
  };

  const createScheduledData = () => {
    const utcDateTime = momentTZ(
      `${startDateInput} ${militaryToCorrectMilitary(startTimeInput)}`
    ).utc();
    const postDate = convertDateToDbDate(
      getCurrentDate(moment(utcDateTime).format("L"))
    );
    const postTime = Number(moment(utcDateTime).format("HHmm"));

    return {
      businessId,
      shareDetails: {
        isPostNow,
        shareAccounts: {
          facebook: facebookPost,
          instagram: instagramPost,
        },
        instagramTags: instagramPost ? formatUserTags(userTagsInput) : [],
      },
      description: descriptionInput.trim(),
      date: convertDateToDbDate(startDateInput),
      time: startTimeInput,
      postDate,
      postTime,
      categories,
      lastUpdated: convertDateToDbDate(),
      createdDate: convertDateToDbDate(),
      timeStamp: moment().format("lll"),
    };
  };

  const handleSubmit = async () => {
    if (
      !isPostNow &&
      moment(
        `${startDateInput} ${militaryTimeToReadable(startTimeInput)}`
      ).isSameOrBefore(moment())
    )
      return dispatch(
        stateActions.setMessage({
          messageType: "error",
          message: "Invalid Date & Time",
        })
      );

    const socialData = createScheduledData();
    const hasRequiredInfo = validateSocialPost(socialData);
    if (!hasRequiredInfo) return;
    dispatch(stateActions.setLoader(true));

    if (isPostNow) socialData.social = social;
    if (cat1Img) {
      dispatch(stateActions.setLoader("Saving Image"));
      const imageUrl = await storeImage(cat1Img, "social");
      socialData.categories.cat1Img = imageUrl;
    }

    dispatch(
      stateActions.setLoader(
        isPostNow ? "Posting to social media" : "Scheduling Post"
      )
    );
    const { completed, social: newSocial } = await postToSocial(socialData);

    if (newSocial) {
      dispatch(
        userActions.updateUser({
          scheduledSocial: [...scheduledSocial, newSocial],
        })
      );
      history.goBack();
      dispatch(stateActions.setMessage({ message: "Post Scheduled" }));
    } else if (completed) {
      history.goBack();
      dispatch(stateActions.setMessage({ message: "Posted Successfully" }));
    } else {
      dispatch(
        stateActions.setMessage({
          messageType: "error",
          message: "Posting Error",
        })
      );
      setCategories({ cat1Img: "" });
      await deleteImage(socialData.categories.cat1Img);
    }
    dispatch(stateActions.setLoader(false));
  };

  return (
    <div className="carousel-container">
      <div className="carousel-item" style={{ height }}>
        <div className="carousel-business-space-container" />
        <div className="carousel-business-item-content-container">
          <div className="business-signup-header-container">
            <div className="business-signup-icon">
              {getFeaturesIcon("postad")}
            </div>
            <h2 className="card-title-SignUp">DETAILS</h2>
            <p
              style={{
                fontSize: "1.1rem",
                textAlign: "center",
              }}
            >
              When would you like to post to social media?
            </p>
          </div>

          <div className="create-post-selector-container">
            <SwitchButton
              button1Name="Right Now"
              button2Name="Schedule Time"
              isFirst={isPostNow}
              handleClick={handlePostNow}
            />
          </div>

          {!isPostNow && (
            <>
              <label id="startDateInput" className="label-form">
                Date & Time:
              </label>
              <div className="new-post-split-container">
                <input
                  className="input-style input-form text-form"
                  style={{ marginRight: "5px" }}
                  type="date"
                  value={startDateInput}
                  onChange={(evt) => handleChange(evt, "startDateInput")}
                  placeholder="Format: yyyy-mm-dd"
                />
                <TimeSelector
                  name="startTimeInput"
                  startTime={50}
                  endTime={2350}
                  value={startTimeInput}
                  handleChange={handleChange}
                />
              </div>
              <div style={{ height: "5px" }} />
            </>
          )}

          <label id="cat1Img" className="label-form">
            Image:
          </label>
          <ImageUpload
            type="cat1ImgInput"
            imageInput={cat1Img}
            handleChange={handleCategoryImage}
          />

          <label id="descriptionInput" className="label-form">
            Caption:
          </label>
          <textarea
            className="input-style input-form"
            rows="5"
            maxLength="1500"
            value={descriptionInput}
            onChange={(evt) => handleChange(evt, "descriptionInput")}
            spellCheck="true"
          />

          {instagramPost && (
            <>
              <label className="label-form">
                User Tags - Separate tags with a space:
              </label>
              <textarea
                className="input-style input-form"
                placeholder="usertag1 usertag2 usertag3"
                rows="1"
                maxLength="1500"
                value={userTagsInput}
                onChange={(evt) => handleChange(evt, "userTags")}
              />
              <legend className="new-post-legend">
                You can only tag users with public Instagram accounts
              </legend>
            </>
          )}

          <div className="new-post-social-share-wrapper">
            {isFacebook && (
              <div className="new-post-social-share-container">
                <div
                  className="business-onboarding-arrow-outer"
                  style={{ margin: "10px 0px" }}
                >
                  <div
                    className={`create-post-arrow-inner ${
                      facebookPost ? "" : "create-post-arrow-inner-inactive"
                    }`}
                  >
                    {getFeaturesIcon("skinny arrow")}
                  </div>
                </div>
                <div
                  className="create-post-business-button create-post-business-button-hover facebook_background"
                  onClick={() => setFacebookPost(!facebookPost)}
                >
                  <img
                    className="social-media-facebook-icon"
                    src={"/img/facebook-icon.png"}
                  />
                  <p className="create-post-business-button-text">Facebook</p>
                </div>
              </div>
            )}

            {isInstagram && (
              <div className="new-post-social-share-container">
                <div
                  className="business-onboarding-arrow-outer"
                  style={{ margin: "10px 0px" }}
                >
                  <div
                    className={`create-post-instgram-arrow-inner ${
                      instagramPost ? "" : "create-post-arrow-inner-inactive"
                    }`}
                  >
                    {getFeaturesIcon("skinny arrow")}
                  </div>
                </div>
                <div
                  className="create-post-business-button create-post-business-button-hover insatgram_background"
                  onClick={() => setInstagramPost(!instagramPost)}
                >
                  <div className="social-login-icon-container">
                    {getFeaturesIcon("instagram")}
                  </div>
                  <p className="create-post-business-button-text">Instagram</p>
                </div>
              </div>
            )}
          </div>

          <div className="carousel-business-button-container">
            <Button
              size={isMobile ? "small" : "medium"}
              text="back"
              handleClick={() => setPage("new post")}
            />
            <Button
              size={isMobile ? "small" : "medium"}
              text="submit"
              handleClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateListing;
