import { Storage } from "aws-amplify";
import { generateUUID } from "../utilites";

export async function storeImage(imageFile, prefix, currentUrl) {
  if (imageFile) {
    try {
      const fileName = `${prefix}${generateUUID()}`;
      await Storage.put(`original-${fileName}`, imageFile, {
        contentType: "image/png",
      });
      const newImageFileName =
        prefix === "social" ? `original-${fileName}` : fileName;
      const newImage = await Storage.get(newImageFileName);
      const cutPossition = newImage.indexOf("?");
      const publicUrl = newImage.slice(0, cutPossition);
      if (currentUrl) await deleteImage(currentUrl);
      return publicUrl;
    } catch (err) {
      console.log("Image Store Error: ", err);
    }
  } else {
    return "";
  }
}

export const deleteImage = async (imageUrl) => {
  if (!imageUrl || typeof imageUrl !== "string") return;

  const isLarge =
    imageUrl.indexOf("business") > -1 ||
    imageUrl.indexOf("listing") > -1 ||
    imageUrl.indexOf("find") > -1;
  const imageName = imageUrl.split("/").pop();
  const deleteArray = [Storage.remove(imageName)];

  if (isLarge) deleteArray.push(Storage.remove(`${imageName}-large`));

  try {
    await Promise.all(deleteArray);
  } catch (err) {
    console.log("Image Delete Error ", err);
  }
};
