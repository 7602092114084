import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import * as dataActions from "../../../redux/actions/data-actions";

// Components
import Spacer from "../../components/common/spacer";
import SkeletonCard from "./skeleton-card";
import Modal from "../../components/modal";
import Map from "../leaflet-map";

// Utilities
import { isMobileAgent } from "../../../utilites";
import {
  formatSearchAnalytics,
  getSearchLimit,
  formatSearchQuery,
} from "../../../utilites/format";
import SetMapLocation from "../../../utilites/hooks/setMapLocation";
import Debounce from "../../../utilites/hooks/debounce";
import { searchAnalytics } from "../../../api/analytics";
import { searchListing, searchBusiness } from "../../../api/search";
import searchAPIs from "../../../utilites/hooks/search";

// Styles
import "./data-loader-hoc.css";

export default (WrappedComponent) => {
  const Component = (props) => {
    const { distanceSearch, standardSearch } = searchAPIs();
    const urlParams = new URLSearchParams(window.location.search);
    const searchParam = urlParams.get("search");
    const {
      mapLocation,
      isUpdatingLocation,
      askUpdateLocationPermissions,
      cancelLocations,
      setMapLocation,
    } = SetMapLocation();
    const [isLoaded, setIsLoaded] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [isMoreResults, setIsMoreResults] = useState(false);
    const [scrollY, setScrollY] = useState(0);
    const debouncedScrollFilter = Debounce(scrollY, 25);
    const debouncedScrollSearch = Debounce(debouncedScrollFilter, 350);
    const {
      isLiveSearch,
      setIsLiveSearch,
      reduxUser,
      location,
      geoPermission,
      geoLocation,
      setResults,
      match,
      filters,
      setFilter,
      setMap,
      addResults,
      skipIndex,
      setSkipIndex,
      searchMinDistanceIndex,
      setSearchMinDistance,
      stubHubIndex,
      setStubHubIndex,
      growthIndex,
      setGrowthIndex,
      ticketMasterIndex,
      setTicketMasterIndex,
      locationKey,
      setLocationKey,
    } = props;
    const { type, subCat, feature, search, date, time, distance, sortBy } =
      filters || {};
    const { path } = match || {};
    const isMobile = isMobileAgent();
    const category = path.split("/")[1];
    const isTopPage = category === "top";
    const totalLimit = isTopPage ? 40 : isMobile ? 20 : 30;

    useEffect(() => {
      if (searchParam) {
        setIsLiveSearch(false);
        setFilter({ type: "", search: searchParam });
        setIsLoaded(true);
      }
    }, []);

    useEffect(() => {
      setMap();
      if (!searchParam && !isUpdatingLocation) {
        if (locationKey !== props.history.location.key) {
          setGrowthIndex(0);
          setSearchMinDistance(0);
          setStubHubIndex(0);
          setTicketMasterIndex(0);
          setIsMoreResults(false);
          getData({
            skip: 0,
            growth: 0,
            stubhub: 0,
            ticketMaster: 0,
            searchMinDistance: 0,
          });
        } else {
          setIsLoaded(true);
        }
        setMap();
        setLocationKey(props.history.location.key);
      }
    }, [isUpdatingLocation, path]);

    useEffect(() => {
      if (isLoaded) {
        setGrowthIndex(0);
        setSearchMinDistance(0);
        setStubHubIndex(0);
        setTicketMasterIndex(0);
        setIsMoreResults(false);
        getData({
          skip: 0,
          growth: 0,
          stubhub: 0,
          ticketMaster: 0,
          searchMinDistance: 0,
        });
      }
    }, [
      isLiveSearch,
      type,
      geoPermission,
      date,
      subCat,
      feature,
      location,
      search,
      time,
      distance,
      sortBy,
    ]);

    useEffect(() => {
      if (!isTopPage && !isFetching) {
        const resultsWrapper = document.getElementById("results-wrapper");
        if (!resultsWrapper) return;
        const windowHeight =
          resultsWrapper.scrollHeight - window.screen.height - 60;
        const scrollAtBottom = debouncedScrollSearch > windowHeight;

        if (scrollAtBottom && isMoreResults) {
          getData({
            skip: skipIndex,
            growth: growthIndex,
            stubhub: stubHubIndex,
            ticketMaster: ticketMasterIndex,
            searchMinDistance: searchMinDistanceIndex,
          });
        }
      }
    }, [debouncedScrollSearch]);

    const getData = async (searchValues = {}) => {
      const {
        skip = -1,
        growth = -1,
        ticketMaster = -1,
        stubhub = -1,
        searchMinDistance = 0,
      } = searchValues;
      const isEventsApi =
        category === "events" || (type && type === "Promotions");
      const isDistanceSearch = sortBy === "Distance";
      const { numberOfSearchableApis, searchLimit } = getSearchLimit({
        searchValues,
        isEventsApi,
        isDistanceSearch,
        category,
        type,
        totalLimit,
      });

      if (skip === 0) {
        setIsLoaded(false);
        setResults({ results: [], pathname: "" });
        searchAnalytics(
          formatSearchAnalytics({ location, geoLocation, category, filters })
        );
      } else {
        if (!!numberOfSearchableApis) {
          setIsFetching(true);
        } else {
          return setIsMoreResults(false);
        }
      }
      setIsMoreResults(true);

      const searchOptions = formatSearchQuery({
        category,
        isLiveSearch,
        reduxUser,
        filters: {
          ...filters,
          ...(searchParam ? { search: searchParam } : {}),
        },
        params: {
          skip: skip ? skip + 1 : skip,
          limit: isTopPage ? totalLimit : searchLimit,
        },
      });
      let newResults = [];

      if (type === "Business") {
        const { results: businessResults = [] } = await searchBusiness(
          searchOptions
        );
        newResults = businessResults;
        const newSkipIndex =
          businessResults.length < searchLimit
            ? -1
            : skip + businessResults.length;
        setSkipIndex(newSkipIndex);
      } else {
        const { results: searchResults = [] } =
          skip > -1 ? await searchListing(searchOptions) : {};
        const newSkipIndex =
          searchResults.length < searchLimit ? -1 : skip + searchResults.length;
        setSkipIndex(newSkipIndex);
        newResults = [...searchResults];

        if (!isTopPage) {
          if (isDistanceSearch) {
            const combinedDistanceResults = await distanceSearch({
              results: newResults,
              isEventsApi,
              searchOptions,
              searchMinDistance,
            });
            newResults = combinedDistanceResults;
          } else {
            const combinedStandardResults = await standardSearch({
              results: newResults,
              isEventsApi,
              searchOptions,
              searchLimit,
              growth,
              ticketMaster,
              stubhub,
            });
            newResults = combinedStandardResults;
          }
        }
      }

      if (skip) {
        addResults({ results: newResults, pathname: path });
      } else {
        setResults({ results: newResults, pathname: path });
      }
      setIsLoaded(true);
      setIsFetching(false);
    };

    const renderSkeletonUi = () => {
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      const widthMultiple = Math.floor(width / 358) || 1;
      const heightMultiple = Math.floor(height / 258);
      const numberOfSkeletonEl = widthMultiple * heightMultiple;
      const skeletonEl = [];

      for (let i = 0; i < numberOfSkeletonEl; i++) {
        skeletonEl.push(<SkeletonCard key={i} />);
      }
      return skeletonEl;
    };

    return isLoaded ? (
      <WrappedComponent
        {...props}
        isFetching={isFetching}
        path={path}
        setScrollY={setScrollY}
        debouncedScroll={debouncedScrollFilter}
        isFetching={isFetching}
        isMoreResults={isMoreResults}
      />
    ) : (
      <>
        <div
          id="skelleton-wrapper"
          className={`no-scroll-bars ${
            isMobile ? "results-wrapper-mobile" : "results-wrapper-web"
          }`}
        >
          <div id="results-inner-wrapper">
            <Spacer height={isFetching ? 0 : isMobile ? 108 : 40} />
            {renderSkeletonUi()}
          </div>
        </div>

        <Modal
          directModalMessage={
            askUpdateLocationPermissions
              ? "Would you like to update your location to the last used map location?"
              : false
          }
          buttonSize="medium"
          modalCancel="Keep Location"
          modalAccept="New Location"
          handleCancelClick={cancelLocations}
          handleAcceptClick={setMapLocation}
        >
          <div
            style={{
              width: "100%",
              height: "300px",
            }}
          >
            <Map
              defaultZoom={11}
              userMarkerPostion={mapLocation}
              mapCenter={mapLocation}
              coordinates={mapLocation}
            />
          </div>
        </Modal>
      </>
    );
  };

  const mapStateToProps = (store) => ({
    reduxUser: store.user,
    location: store.user.location,
    geoPermission: store.user.geoPermission,
    geoLocation: store.user.geoLocation,
    filters: store.data.filters,
    skipIndex: store.data.skipIndex,
    searchMinDistanceIndex: store.data.searchMinDistance,
    stubHubIndex: store.data.stubHubIndex,
    growthIndex: store.data.growthIndex,
    ticketMasterIndex: store.data.ticketMasterIndex,
    locationKey: store.state.locationKey,
    isLiveSearch: store.data.isLiveSearch,
  });

  const mapDispatchToProps = (dispatch) => ({
    setMap: () => dispatch(stateActions.setMap(false)),
    setIsLiveSearch: (value) => dispatch(dataActions.setIsLiveSearch(value)),
    setFilter: (filterObj) => dispatch(dataActions.setFilter(filterObj)),
    setResults: (resultsObj) => dispatch(dataActions.setResults(resultsObj)),
    addResults: (resultsObj) => dispatch(dataActions.addResults(resultsObj)),
    setSkipIndex: (idx) => dispatch(dataActions.setSkipIndex(idx)),
    setSearchMinDistance: (dist) =>
      dispatch(dataActions.setSearchMinDistance(dist)),
    setStubHubIndex: (idx) => dispatch(dataActions.setStubHubIndex(idx)),
    setGrowthIndex: (idx) => dispatch(dataActions.setGrowthIndex(idx)),
    setTicketMasterIndex: (idx) =>
      dispatch(dataActions.setTicketMasterIndex(idx)),
    setLocationKey: (key) => dispatch(stateActions.setLocationKey(key)),
  });

  return connect(mapStateToProps, mapDispatchToProps)(Component);
};
