import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import * as userActions from "../../../redux/actions/user-actions";
// import AppleLogin from "react-apple-login";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";

// Components
import DetailViewLayout from "../../components/detail-view-scroll-layout";
import SwitchButton from "../../components/common/switch-button";
import Button from "../../components/common/button";
import TextInput from "../../components/common/text-input-underline";
import Modal from "../../components/modal";

// Assets
import User from "../../../assets/svg/user";
import Phone from "../../../assets/svg/phone";
import Password from "../../../assets/svg/password";

// Utilities]
import getIcon from "../../components/features-input/getFeatureIcon";
import { convertDateToDbDate } from "../../../utilites/date";
import { validateEmail } from "../../../utilites/validate";
import { userLogin, facebookLogin, googleLogin } from "../../../api/user";
import { businessLogin } from "../../../api/business";
import { getPaswordToken } from "../../../api/password";

// Styles
import "./login.css";

const Login = ({ setLoader, setUser, setMessage }) => {
  const history = useHistory();
  const [isUser, setIsUser] = useState(true);
  const [emailInput, setEmailInput] = useState("");
  const [phoneInput, setPhoneInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  // const appleButtonStyles = {
  //   height: 48,
  //   width: 220,
  //   color: "black",
  //   border: false,
  //   type: "sign-in",
  //   locale: "en_US",
  // };

  const handleUserType = (type) => {
    setPasswordInput("");
    switch (type) {
      case "USER":
        return setIsUser(true);
      case "BUSINESS":
        return setIsUser(false);
    }
  };

  const handleChange = (evt, type) => {
    const { value } = evt.target;
    switch (type) {
      case "emailInput":
        return setEmailInput(value);
      case "phoneInput":
        const newPhoneValue = value
          .replace("-", "")
          .replace(".", "")
          .match(/^[0-9]*$/gi);
        if (newPhoneValue === null) return setPhoneInput(phoneInput);
        return setPhoneInput(newPhoneValue[0].slice(0, 10));
      case "passwordInput":
        return setPasswordInput(value);
    }
  };

  const handleLogin = async () => {
    if (!passwordInput) return;
    if (passwordInput.length < 8)
      return setMessage({
        messageType: "error",
        message: "Incorrect Password",
      });
    if (isUser) {
      // User Login
      if (!validateEmail(emailInput))
        return setMessage({
          messageType: "error",
          message: "Incorrect Email",
        });
      setLoader(true);
      const { user } =
        (await userLogin({
          email: emailInput.toLowerCase(),
          password: passwordInput,
        })) || {};
      if (user) {
        const { token } = user;
        localStorage.setItem("user", token);
        setUser({ isUser: true, user });
        history.push("/user/profile");
      }
      setLoader(false);
    } else {
      // Business Login
      if (phoneInput.length < 10)
        return setMessage({
          messageType: "error",
          message: "Incorrect Phone Number",
        });
      setLoader(true);
      const { user } =
        (await businessLogin({
          phone: phoneInput,
          password: passwordInput,
        })) || {};
      if (user) {
        const { token } = user;
        localStorage.setItem("business", token);
        setUser({ isUser: false, user });
        history.push("/business/profile");
      }
      setLoader(false);
    }
  };

  const handleClick = () => {
    const signUpUrl = isUser ? "/user/sign-up" : "/business/sign-up";
    history.push(signUpUrl);
  };

  const handleFacebookOauth = async (response) => {
    setLoader(true);
    const { accessToken } = response;
    const { user } = await facebookLogin({
      identityToken: accessToken,
      createdDate: convertDateToDbDate(),
    });
    if (user) {
      const { token } = user;
      if (token) localStorage.setItem("user", token);
      setUser({ isUser: true, user });
      history.push("/user/profile");
    }
    setLoader(false);
  };

  const handleGoogleOauth = async (response) => {
    setLoader(true);
    const { accessToken } = response;
    const { user } = await googleLogin({
      accessToken,
      createdDate: convertDateToDbDate(),
    });
    if (user) {
      const { token } = user;
      if (token) localStorage.setItem("user", token);
      setUser({ isUser: true, user });
      history.push("/user/profile");
    } else {
      setMessage({
        messageType: "error",
        message: "Google Login Error",
      });
    }
    setLoader(false);
  };

  const handleResetPassword = async () => {
    const params = isUser
      ? { email: emailInput.toLowerCase() }
      : { phone: phoneInput };
    let verified = true;
    if (isUser) verified = validateEmail(emailInput);
    if (!verified) {
      return setMessage({ messageType: "error", message: "Invalid Email" });
    }
    setModalMessage("");
    setLoader(true);
    const { recoverEmail } = await getPaswordToken(params);
    if (recoverEmail) {
      setMessage({
        message: "Check email for reset link",
      });
    }
    setLoader(false);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(
      document.location.search.substring(1)
    );
    const typeParam = urlParams.get("type");
    const phoneParam = urlParams.get("phone");
    const emailParam = urlParams.get("phone");
    const passwordParam = urlParams.get("password");

    if (typeParam && typeParam === "business") setIsUser(false);
    if (phoneParam) setPhoneInput(phoneParam);
    if (emailParam) setEmailInput(emailParam);
    if (passwordParam && (phoneParam || emailParam)) {
      setPasswordInput(passwordParam);
      handleLogin();
    }
  }, [handleLogin]);

  return (
    <>
      <DetailViewLayout heightFromTop="20vh" showImage={false}>
        <div className="sign-up-wrapper">
          <div className="profile-selector-container">
            <SwitchButton
              button1Name="USER"
              button2Name="BUSINESS"
              isFirst={isUser}
              handleClick={handleUserType}
            />
          </div>

          <div
            className={`listing-content-container sign-up-content-wrapper ${
              isUser ? "sign-up-card-front" : "sign-up-card-back"
            }`}
          >
            <div className="sign-up-container-column">
              <TextInput
                placeholder="Email"
                value={emailInput}
                handleEnter={handleLogin}
                handleChange={(evt) => handleChange(evt, "emailInput")}
              >
                <User />
              </TextInput>
              <TextInput
                type="password"
                placeholder="Password"
                value={passwordInput}
                handleEnter={handleLogin}
                handleChange={(evt) => handleChange(evt, "passwordInput")}
              >
                <Password />
              </TextInput>
              <div className="sign-up-container-column">
                <Button size="large" text="login" handleClick={handleLogin} />
                <p
                  className="login-forgot-text-Profile"
                  onClick={() => setModalMessage("Recover Password")}
                >
                  Forgot Passowrd
                </p>
              </div>
            </div>

            <div className="sign-up-container">
              <Button
                size="extraLarge"
                text="Sign Up"
                handleClick={() => handleClick()}
              >
                <div className="login-basicty-icon-wrapper">
                  {getIcon("signup")}
                </div>
              </Button>
              {/* <AppleLogin
                clientId={"com.react.apple.login"}
                redirectURI={"https://redirectUrl.com"}
                responseType={"code"}
                responseMode={"query"}
                designProp={appleButtonStyles}
                autoLoad={false}
              /> */}
              <FacebookLogin
                cssClass="facebook-button"
                appId="409107940127951"
                fields="name,email,picture,location"
                callback={handleFacebookOauth}
                icon="fa-facebook"
                autoLoad={false}
                textButton="SIGN IN WITH Facebook"
                disableMobileRedirect={false}
                cookiePolicy={"single_host_origin"}
              />
              <GoogleLogin
                clientId="798981434-janrkjfj5qbcdsid8ma86tvi8svjq311.apps.googleusercontent.com"
                buttonText="LOGIN WITH GOOGLE"
                onSuccess={handleGoogleOauth}
                onFailure={(response) => {
                  console.log("test: failed ", response);
                }}
                autoLoad={false}
                buttonText="SIGN IN WITH GOOGLE"
                cookiePolicy={"single_host_origin"}
              />
            </div>
          </div>

          <div
            className={`listing-content-container sign-up-content-wrapper ${
              isUser ? "sign-up-card-back" : "sign-up-card-front"
            }`}
          >
            <div className="sign-up-container-column">
              <TextInput
                type="tel"
                placeholder="Phone"
                value={phoneInput}
                handleEnter={handleLogin}
                handleChange={(evt) => handleChange(evt, "phoneInput")}
              >
                <Phone />
              </TextInput>
              <TextInput
                type="password"
                placeholder="Password"
                value={passwordInput}
                handleEnter={handleLogin}
                handleChange={(evt) => handleChange(evt, "passwordInput")}
              >
                <Password />
              </TextInput>
              <div className="sign-up-container-column">
                <Button size="large" text="login" handleClick={handleLogin} />
                <p
                  className="login-forgot-text-Profile"
                  onClick={() => setModalMessage("Recover Password")}
                >
                  Forgot Passowrd
                </p>
              </div>
            </div>
            <div className="sign-up-container">
              <Button
                size="extraLarge"
                text="Start Posting"
                handleClick={() => handleClick()}
              >
                <div className="login-basicty-icon-wrapper">
                  {getIcon("newpost")}
                </div>
              </Button>
            </div>
            <div className="sign-up-container">
              <Button
                size="extraLarge"
                text="Learn More"
                handleClick={() => history.push("/welcome/start-up")}
              >
                <div className="login-basicty-icon-wrapper">
                  {getIcon("question")}
                </div>
              </Button>
            </div>
          </div>
        </div>
      </DetailViewLayout>
      <Modal
        directModalMessage={modalMessage}
        handleCancelClick={() => setModalMessage("")}
        modalAccept="submit"
        handleAcceptClick={handleResetPassword}
      >
        <label id="passwordInput" className="label-form">
          {isUser ? "Email" : "Business Phone"}
        </label>
        <input
          className="input-style input-form text-form"
          value={isUser ? emailInput : phoneInput}
          type="text"
          onChange={
            isUser
              ? (evt) => handleChange(evt, "emailInput")
              : (evt) => handleChange(evt, "phoneInput")
          }
        />
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setLoader: (loaderState) => dispatch(stateActions.setLoader(loaderState)),
  setUser: (userObj) => dispatch(userActions.setUser(userObj)),
  setMessage: (message) => dispatch(stateActions.setMessage(message)),
});

export default connect(null, mapDispatchToProps)(Login);
