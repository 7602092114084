import { callAPI } from ".";
import { baseURL } from "./url";

const ticketsURL = baseURL("/tickets");

export function getTickets(id) {
  return callAPI(`${ticketsURL}?id=${id}`, "GET");
}

export function createTickets(data) {
  return callAPI(ticketsURL, "PUT", data);
}

export function editTickets(data) {
  return callAPI(ticketsURL, "POST", data);
}

// export function deleteCorporate({ id, mainBusinessId }) {
//   const url = `${corporateURL}?id=${id}&mainBusinessId=${mainBusinessId}`;
//   return callAPI(url, "DELETE");
// }
