import { callAPI } from ".";
import { baseURL } from "./url";

const saleURL = baseURL("/sales");

export function getAccount(id) {
  return callAPI(`${saleURL}/account?id=${id}`, "Get");
}

export function getStripeSetUp(phone, email) {
  return callAPI(`${saleURL}/onboarding?phone=${phone}&email=${email}`, "Get");
}

export function createSale(data) {
  return callAPI(saleURL, "PUT", data);
}
