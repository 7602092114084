import React from "react";

// Components
import Spacer from "../../../components/common/spacer";

// Utilities
import { isBottomSpacing } from "../../../../utilites";

// Styles
import "../legal.css";

const TermsOfService = () => {
  return (
    <div className="legal-wrapper">
      <h2>Terms Of Service</h2>
      <p>
        Welcome to EventHound. We (EventHound, LLC.) hope you find it useful. By
        accessing or otherwise interacting with our servers, services, websites,
        mobile app ("App"), website, or any associated content/postings, you
        agree to these Terms of Use ("TOU") (last updated May 27, 2020). You
        acknowledge and agree that EventHound is privately owned and operated by
        EventHound, LLC. If you are accessing or using EventHound on behalf of a
        business, you represent and warrant to EventHound that you have
        authority to accept the TOU on behalf of that business and that business
        agrees to the TOU. If you do not agree to the TOU, you are not
        authorized to use EventHound or download the App. EventHound may modify
        the TOU at any time in our sole discretion. You are responsible for
        periodically checking for changes and are bound by them if you continue
        to use EventHound. EventHound’s privacy policy
        (EventHound.com/legal/privacy), and all other policies, site rules, and
        agreements referenced below or on EventHound, are fully incorporated
        into this TOU, and you agree to them as well.
      </p>

      <h2>LICENSE</h2>
      <p>
        If you agree to the TOU and (1) are of sufficient age and capacity to
        use EventHound and be bound by the TOU, or (2) use EventHound on behalf
        of a business, thereby binding that business to the TOU, EventHound
        grants you a limited, revocable, non-exclusive, non-assignable license
        to use EventHound’s services in compliance with the TOU; unlicensed use
        is unauthorized. You agree not to display, "frame," make derivative
        works, distribute, license, or sell, content from EventHound, excluding
        postings you create. You grant EventHound a perpetual, irrevocable,
        unlimited, worldwide, fully paid/sub-licensable license to use, copy,
        display, distribute, and make derivative works from content you post.
      </p>

      <h2>USE</h2>
      <p>
        You agree not to copy/collect EventHound’s content via robots, spiders,
        scripts, scrapers, crawlers, or any automated or manual equivalent
        (e.g., by hand). Misleading, unsolicited, and/or unlawful
        postings/communications/accounts are prohibited, as is user or business
        accounts. You agree not to post content that is prohibited by any of
        EventHound's policies or rules referenced in the Prohibited Content
        list. You agree not to abuse EventHound's flagging or reporting
        processes. You agree not to collect EventHound user information or
        interfere with EventHound. You agree EventHound may moderate access/use
        in it’s sole discretion, e.g., by blocking, filtering, re-categorizing,
        re-ranking, deleting, delaying, holding, omitting, verifying, or
        terminating your access/account. You agree (1) not to bypass said
        moderation, (2) EventHound is not liable for moderating or not
        moderating content, and (3) nothing EventHound says or does waives it’s
        right to moderate, or not. Unless licensed by us in a separate written
        or electronic agreement, you agree not to (i) rent, lease, sell,
        publish, distribute, license, sublicense, assign, transfer, or otherwise
        make available EventHound or our application programming interface
        ("API"), (ii) copy, adapt, create derivative works of, decompile,
        reverse engineer, translate, localize, port or modify the App, the API,
        any website code, or any software used to provide EventHound, (iii)
        combine or integrate EventHound or the API with any software,
        technology, services, or materials not authorized by us, (iv) circumvent
        any functionality that controls access to or otherwise protects
        EventHound or the API, or (v) remove or alter any copyright, trademark
        or other proprietary rights notices. You agree not to use EventHound or
        the API in any manner or for any purpose that infringes,
        misappropriates, or otherwise violates any intellectual property right
        or other right of any person, or that violates any applicable law.
      </p>

      <h2>LICENSE</h2>
      <p>
        When you provide content using EventHound’s services (directly or
        indirectly), you grant us a non-exclusive, worldwide, perpetual,
        irrevocable, royalty-free, sub-licensable (through multiple tiers) right
        to exercise any and all Intellectual Property Rights you have in that
        content in connection with our provision, expansion, and promotion of
        the Services, in any media known now or developed in the future. To the
        fullest extent permitted under applicable law, you waive your right to
        enforce your Intellectual Property Rights in that content against
        EventHound, our assignees, our sublicensees, and their assignees in
        connection with our, those assignees', and those sublicensees' use of
        that content in connection with our provision, expansion, and promotion
        of the Services.
      </p>
      <p>
        You represent and warrant that, for all such content you provide, you
        own or otherwise control all necessary rights to do so and to meet your
        obligations under this User Agreement. You represent and warrant that
        such content is accurate. You represent and warrant that use of any such
        content (including derivative works) by EventHound, our users, or others
        in contract with EventHound, and in compliance with this User Agreement,
        does not and will not infringe any Intellectual Property Rights of any
        third party. EventHound takes no responsibility and assumes no liability
        for any content provided by you or any third party
      </p>
      <p>
        The name "EventHound" and other Basicty marks, logos, designs, and
        phrases that EventHound uses in connection with our Services are
        trademarks, service marks, or trade dress of EventHound in the U.S. and
        other countries. They may not be used without the express written prior
        permission of EventHound.
      </p>

      <h2>Listing Conditions</h2>
      <ul>
        <li>
          Misleading, unsolicited, and/or unlawful postings are prohibited.
        </li>
        <li>
          You represent and warrant that, for all such content you provide, you
          own or otherwise control all necessary rights to do so and to meet
          your obligations under this User Agreement.
        </li>
        <li>
          Clear and accurate information about the specials, promotions, events,
          or activity must be included in listings.
        </li>
        <li>You represent and warrant that such content is accurate.</li>
        <li>
          Listings must only be created to promote specials, promotions, events,
          or activities.
        </li>
        <li>
          You should take your own images and write your own descriptions for
          the items you list.
        </li>
        <li>Listings will only be created with verified business accounts.</li>
        <li>Free accounts will be limited to 1 unique listing a month.</li>
        <li>
          EventHound does not allow more than one listing of an identical item
          at the same time from the same business.
        </li>
        <li>
          The listing “check-in” feature grants user access to redeem a
          listing’s promotion either one time or every time the listing is
          reposted based on the creators selection.
        </li>
        <li>
          The user is responsible for the content of the listing through the
          duration of the listing. Repeated listings will automatically run as
          selected by the user until the listing has been deactivated or
          deleted.
        </li>
        <li>
          “One Time” listings may be automatically deleted by EventHound at any
          time after the listings promotion date has past.
        </li>
        <li>
          Content that violates any of EventHound policies may be modified,
          obfuscated or deleted at EventHound discretion.
        </li>
        <li>
          Bacity may revise data associated with listings to supplement, remove,
          or correct information.
        </li>
        <li>
          EventHound strives to create an application where user finds what they
          are looking for. Therefore, the appearance or placement of listings in
          search and browse results will depend on a variety of factors,
          including, but not limited to:
        </li>
        <ul>
          <li>
            <li>User’s location, search query, day, time, features…</li>
          </li>
        </ul>
        <li>
          To drive a positive user experience, a listing may not appear in some
          search and browse results regardless of the sort order chosen by the
          user.
        </li>
        <li>
          Some advanced listing upgrades will only be visible on certain
          services.
        </li>
      </ul>

      <h2>FEES</h2>
      <p>
        When upgrading to a “Premium” account, you authorize basicity to charge
        your account. Fees are non-refundable, even for posts removed, delayed,
        omited, re-categorized, re-ranked, otherwise moderated or if your
        account is deleted due to terms violation. EventHound may refuse any
        posting or account.
      </p>

      <h2>DISCLAIMER & LIABILITY</h2>
      <p>
        EventHound work’s hard to provide the best products possible and to
        specify clear guidelines for everyone who uses them. Products, however,
        are provided "as is," and EventHound makes no guarantee that they will
        always be safe, secure, or error-free, or that they will function
        without disruptions, delays, or imperfections. To the extent permitted
        by law, we also DISCLAIM ALL CLAIMS CREATED BY BUSINESS. EventHound does
        not control or direct what people and others do or say, and EventHound
        is not responsible for their actions or conduct (whether online or
        offline) or any content they share (including offensive, inappropriate,
        obscene, unlawful, and other objectionable content). EventHound cannot
        predict when issues might arise with any business's or any associated
        listings. Accordingly, EventHound’s liability shall be limited to the
        fullest extent permitted by applicable law, and under no circumstance
        will EventHound be liable for any lost consequential, special, indirect,
        exemplary, punitive, or incidental damages arising out of or related to
        these Terms or the EventHound product, even if EventHound has been
        advised of the possibility of such damages. To the full extent permitted
        by law, EventHound, LLC., and its officers, directors, employees,
        agents, licensors, affiliates, and successors in interest ("EventHound
        Entities") (1) make no promises, warranties, or representations as to
        EventHound, including its completeness, accuracy, availability,
        timeliness, propriety, security or reliability; (2) provide EventHound
        on an "AS IS" and "AS AVAILABLE" basis and any risk of using EventHound
        is assumed by you; (3) disclaim all warranties, express or implied,
        including as to accuracy, merchantability, fitness for a particular
        purpose, and non-infringement, and all warranties arising from course of
        dealing, usage, or trade practice; and (4) disclaim any liability or
        responsibility for acts, omissions, or conduct of you or any party in
        connection with EventHound. EventHound entities are NOT liable for any
        direct, indirect, consequential, incidental, special, punitive, or other
        losses, including lost profits, revenues, data, goodwill, etc., arising
        from or related to EventHound, and in no event shall such liability
        exceed $100 or the amount you paid us in the year preceding such loss.
        Some jurisdictions restrict or alter these disclaimers and limits, so
        some may not apply to you.
      </p>
      <Spacer height={isBottomSpacing(6)} />
    </div>
  );
};

export default TermsOfService;
