import React from "react";

import "./radar-loader.css";

export default () => {
  return (
    <div className="radar-container">
      <div className="radar">
        <div className="pointer" />
      </div>
    </div>
  );
};
