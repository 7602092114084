import React from "react";
import { useHistory } from "react-router-dom";

// Components
import Button from "../../../../components/common/button";

// Utilities
import getFeaturesIcon from "../../../../components/features-input/getFeatureIcon";

// Styles
import "./post-add.css";

export default () => {
  const history = useHistory();
  return (
    <div className="listing-content-container">
      <div className="business-title-container">
        <div className="business-line-break bgc-medium" />
        <div className="social-login-title-container">
          <div className="social-title-icon-container">
            {getFeaturesIcon("newpost")}
          </div>
          <p className="social-login-title-text">Get Started</p>
        </div>
        <div className="business-line-break bgc-medium" />
      </div>

      <div className="no-posted-ad-details">
        <p className="no-posted-ad-details-text">
          Click the link below to create a post and promote the great apects of
          your business.
        </p>

        <Button
          size="large"
          text="create Post"
          handleClick={() => {
            history.push("/create-post");
          }}
        />
      </div>
    </div>
  );
};
