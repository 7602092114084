import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30"
    >
      <g transform="translate(-540.000000, -211.000000)">
        <path
          d="M555,231.7c1.9,0,3.4,1.2,4,2.9l0.1,0l0,0l0.2,0h8.5c0.8,0,1.4,0.6,1.4,1.4c0,0.7-0.5,1.3-1.3,1.4l-0.2,0h-8.5
			c-0.1,0-0.2,0-0.2,0c-0.6,1.7-2.2,2.9-4,2.9s-3.4-1.2-4-2.9l-0.1,0l0,0l-0.2,0h-8.5c-0.8,0-1.4-0.6-1.4-1.4c0-0.7,0.5-1.3,1.3-1.4
			l0.2,0h8.5c0.1,0,0.2,0,0.2,0C551.6,232.9,553.1,231.7,555,231.7z M555,234.5c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4
			s1.4-0.6,1.4-1.4S555.8,234.5,555,234.5z M545.1,221.7c1.9,0,3.4,1.2,4,2.9l0.1,0l0,0l0.2,0h18.5c0.8,0,1.4,0.6,1.4,1.4
			c0,0.7-0.5,1.3-1.3,1.4l-0.2,0h-18.5c-0.1,0-0.2,0-0.2,0c-0.6,1.7-2.2,2.9-4,2.9c-2.4,0-4.3-1.9-4.3-4.3S542.7,221.7,545.1,221.7z
			 M545.1,224.6c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S545.8,224.6,545.1,224.6z M560.7,211.8
			c1.9,0,3.4,1.2,4,2.9l0.1,0l0,0l0.2,0h2.8c0.8,0,1.4,0.6,1.4,1.4c0,0.7-0.5,1.3-1.3,1.4l-0.2,0h-2.8c-0.1,0-0.2,0-0.2,0
			c-0.6,1.7-2.2,2.9-4,2.9s-3.4-1.2-4-2.9l-0.1,0l0,0l-0.2,0h-14.2c-0.8,0-1.4-0.6-1.4-1.4c0-0.7,0.5-1.3,1.3-1.4l0.2,0h14.2
			c0.1,0,0.2,0,0.2,0C557.2,213,558.8,211.8,560.7,211.8z M560.7,214.6c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4
			c0.8,0,1.4-0.6,1.4-1.4S561.5,214.6,560.7,214.6z"
        />
      </g>
    </svg>
  );
};
