import React, { useRef, useState, useCallback, useEffect } from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";

// Utilities
import UseGetTheme from "../../../utilites/hooks/useGetTheme";

// Assets
import PersonDay from "../../../assets/svg/personDay.svg";
import PersonNight from "../../../assets/svg/personNight.svg";
import Pin from "../../../assets/svg/pin.svg";
import PinPrimary from "../../../assets/svg/pin-primary.svg";
import PinCurrent from "../../../assets/svg/pin-current.svg";
import PinSecondary from "../../../assets/svg/pin-secondary.svg";
import PinSelected from "../../../assets/svg/pin-selected.svg";

// Styles
import "./map-styles.css";

export default ({
  isGeoLocation,
  mapCenter,
  coordinates = [],
  userMarkerPostion,
  results = [],
  defaultZoom = 14,
  handleMarkerClick,
  handleClick,
  handleCenterMovedCoords,
  selectedId,
  status,
  draggable = true,
  zoomable = true,
}) => {
  const L = require("leaflet");
  const isMounted = useRef(false);
  const [map, setMap] = useState(null);
  const { isNight } = UseGetTheme();

  const onMove = useCallback(() => {
    if (!handleCenterMovedCoords) return;
    const { _northEast, _southWest } = map.getBounds();
    const { lng: northEastLng, lat: northEastLat } = _northEast || {};
    const { lng: southWestLng, lat: southWestLat } = _southWest || {};
    const { lng: centerLng, lat: centerLat } = map.getCenter();
    handleCenterMovedCoords(
      [
        [southWestLng, northEastLat],
        [northEastLng, northEastLat],
        [northEastLng, southWestLat],
        [southWestLng, southWestLat],
        [southWestLng, northEastLat],
      ],
      [centerLng, centerLat]
    );
  }, [map]);

  useEffect(() => {
    if (isMounted.current && mapCenter && mapCenter.length)
      setTimeout(() =>
        map.setView(new L.LatLng(mapCenter[1], mapCenter[0]), defaultZoom)
      );
    isMounted.current = true;
  }, [mapCenter]);

  useEffect(() => {
    if (map) {
      map.on("move", onMove);
      map.on("click", handleClick);
      return () => {
        map.off("move", onMove);
        map.off("click", handleClick);
      };
    }
  }, [map, onMove]);

  const getIcon = (icon) =>
    new L.Icon({
      iconUrl: icon,
      iconRetinaUrl: icon,
      iconAnchor: null,
      popupAnchor: null,
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: new L.Point(35, 35),
      className: "leaflet-div-icon",
    });

  const getPin = (status) => {
    switch (status) {
      case "starting":
        return PinPrimary;
      case "active":
        return PinCurrent;
      case "ending":
        return PinSecondary;
      case "ended":
        return Pin;
      default:
        return PinPrimary;
    }
  };

  const getPinStatus = (status) => {
    const statusList = Object.values(status);
    if (statusList.includes("active")) return "active";
    if (statusList.includes("ending")) return "ending";
    if (statusList.includes("starting")) return "starting";
    if (statusList.includes("ended")) return "ended";
    return "";
  };

  const renderListingMarkers = () =>
    map
      ? results.map((result, idx) => {
          const { id, coordinates, status: statusObj } = result;
          const status = getPinStatus(statusObj);
          const isSelected = id === selectedId;
          return (
            <Marker
              icon={getIcon(isSelected ? PinSelected : getPin(status))}
              position={[coordinates[1], coordinates[0]]}
              eventHandlers={{
                click: () => setTimeout(() => handleMarkerClick(id), 250),
              }}
              key={idx}
            />
          );
        })
      : null;

  return (
    <MapContainer
      center={
        mapCenter && mapCenter.length
          ? { lng: mapCenter[0], lat: mapCenter[1] }
          : { lng: -118.2436849, lat: 34.0522342 }
      }
      zoom={defaultZoom}
      zoomControl={false}
      whenCreated={setMap}
      dragging={draggable}
      touchZoom={zoomable}
      scrollWheelZoom={zoomable}
    >
      <TileLayer
        attribution="© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a><strong>"
        url={`https://api.mapbox.com/styles/v1/gshamalta/${
          isNight ? "cko63vuwd2sv617msg2plxu20" : "cko62wvxh2s0v17ozeo46mkdn"
        }/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZ3NoYW1hbHRhIiwiYSI6ImNrbzYydG5hMjB3ZzkydnM3enJibXE1cmYifQ.TzySfWL2xSolnRz0IjIXvA`}
      />

      {isGeoLocation && (
        <Marker
          icon={
            new L.Icon({
              iconUrl: isNight ? PersonNight : PersonDay,
              iconRetinaUrl: isNight ? PersonNight : PersonDay,
              iconAnchor: null,
              popupAnchor: null,
              shadowUrl: null,
              shadowSize: null,
              shadowAnchor: null,
              iconSize: new L.Point(35, 35),
              className: "leaflet-div-icon",
            })
          }
          position={[userMarkerPostion[1], userMarkerPostion[0]]}
        />
      )}

      {coordinates && !!coordinates.length && (
        <Marker
          icon={
            new L.Icon({
              iconUrl: getPin(status),
              iconRetinaUrl: getPin(status),
              iconAnchor: null,
              popupAnchor: null,
              shadowUrl: null,
              shadowSize: null,
              shadowAnchor: null,
              iconSize: new L.Point(35, 35),
              className: "leaflet-div-icon",
            })
          }
          position={[coordinates[1], coordinates[0]]}
        />
      )}

      {renderListingMarkers()}
    </MapContainer>
  );
};
