import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import lottie from "lottie-web";
import * as stateActions from "../../../../redux/actions/state-actions";
import * as userActions from "../../../../redux/actions/user-actions";

// Utilities
import UseGetTheme from "../../../../utilites/hooks/useGetTheme";
import { getCityLocation } from "../../../../utilites/location";
import { getCoordinates } from "../../../../api/search";
import useGeoLocation from "../../../../utilites/hooks/useGeoLocation";

// Assets
import noResultsDay from "../../../../assets/lotti/no-results-day.json";
import noResultsNight from "../../../../assets/lotti/no-results-night.json";
import Location from "../../../../assets/svg/location";
import LocationStroke from "../../../../assets/svg/location-stroke";
import Close from "../../../../assets/svg/close";

// Styles
import "./recommended.css";

export default () => {
  const { getCurrentPosition } = useGeoLocation();
  const noResultsLotti = useRef();
  const { isNight } = UseGetTheme();
  const dispatch = useDispatch();
  const { user, data } = useSelector((store) => store);
  const { type } = data.filters;
  const [locationInput, setLocationInput] = useState("");
  const cityLocation = getCityLocation(user);
  const { geoPermission, location } = user;
  const isLocation = "coordinates" in location;
  const geoLocationIsCurrent = geoPermission && !isLocation;

  useEffect(() => {
    lottie.loadAnimation({
      container: noResultsLotti.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
      animationData: isNight ? noResultsNight : noResultsDay,
    });
  }, []);

  const handleChange = (evt, type, name) => {
    const { value } = evt.target;
    switch (type) {
      case "locationInput":
        return setLocationInput(value);
    }
  };

  const handleLocationClick = () => {
    getCurrentPosition();
    if (isLocation) dispatch(userActions.setLocation({}));
  };

  const handleKeyDown = (evt) => {
    const { which, keyCode } = evt;
    const isEnter = which === 13 || keyCode === 13;
    if (isEnter) handleSearch();
  };

  const handleClearInput = (type) => {
    switch (type) {
      case "location":
        return setLocationInput("");
    }
  };

  const handleSearch = async () => {
    const { location } =
      (await getCoordinates({ address: locationInput })) || {};
    if ("coordinates" in location) {
      dispatch(userActions.setLocation(location));
    } else {
      dispatch(
        stateActions.setMessage({
          messageType: "error",
          message: "Location Not found",
        })
      );
    }
  };

  const getTypeText = () => {
    switch (type) {
      case "Deals":
        return "deals ";
      case "Promotions":
        return "promotional ";
      case "Postworthy":
        return "postworthy ";
      case "Signature Specialities":
        return "speciality ";
      case "Business":
        return "business ";
      default:
        return "";
    }
  };

  return (
    <div className="no-results-home-wrapper">
      <div ref={noResultsLotti} className="no-results-animation-container" />
      <p className="no-results-home-text">{`Sorry, no ${getTypeText()}results were found. Would you like to try another location?`}</p>
      <div className="no-results-home-location-wrapper">
        <div
          className={`home-search-svg-container pointer ${
            geoLocationIsCurrent ? "searchbar-svg-current-location" : ""
          }`}
          onClick={handleLocationClick}
        >
          {geoLocationIsCurrent ? <Location /> : <LocationStroke />}
        </div>
        <input
          className="home-search-slideup-input"
          type="text"
          placeholder={cityLocation || "Enter Location"}
          value={locationInput}
          onKeyDown={handleKeyDown}
          onChange={(evt) => handleChange(evt, "locationInput")}
        />
        {!!locationInput && (
          <div
            className="searchbar-input-clear-container pointer"
            onClick={() => handleClearInput("location")}
          >
            <Close />
          </div>
        )}
      </div>
    </div>
  );
};
