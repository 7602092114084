import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import moment from "moment";

// Components
import DetailViewLayout from "../../components/detail-view-scroll-layout";
import ButtonLink from "../../routes/business/button-link";
import Date from "../../routes/listing/date";
import Features from "../../routes/listing/features";
import Contact from "../../routes/listing/contact";
import Button from "../../components/common/button";

// Utilities
import useGetBusinessStatus from "../../../utilites/hooks/UseGetBusinessStatus";
import { toTitleCase } from "../../../utilites/format";

// Assets
import DirectionArrow from "../../../assets/svg/directionArrow";

// Styles
import "../../routes/listing/listing.css";
import "./confirm-listing.css";

export default ({
  data,
  setCurrentIndex,
  setIsConfirm,
  handleSave,
  isCreate = true,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.user);
  const { locationType } = user;
  const {
    images,
    title,
    businessName,
    description,
    virtualLink,
    promoCode,
    days,
    categories,
    features,
  } = data || {};

  const { accountType, uniqueListingIds } = useGetBusinessStatus();
  const isUnlimited = accountType === "unlimited";
  const currentDay = moment().format("dddd");
  const { open: startTime, close: endTime } = days.hours[currentDay];
  const { cat1Img, cat2Img } = categories;
  const isCorporate = locationType === "Multiple Locations";
  const allImages = [...images];
  if (cat2Img) allImages.unshift(cat2Img);
  allImages.unshift(cat1Img);

  const getUniqueListingText = () => {
    const uniquieAds = Object.keys(uniqueListingIds).length;
    const standardText =
      "Once confirmed you will not be able to edit the title or description for one month.";
    switch (accountType) {
      case "premium":
        return `This will be set as ad number ${
          uniquieAds + 1
        } of 3. ${standardText}`;
      default:
        return `This will be set as your free ad. ${standardText}`;
    }
  };

  const handlePromoClick = () => {
    const imageUrl =
      typeof promoCode === "string"
        ? promoCode
        : URL.createObjectURL(promoCode);
    dispatch(stateActions.setPromoCode(imageUrl));
  };

  return (
    <>
      <DetailViewLayout images={allImages} status="active">
        <div className="listing-content-container">
          <div className="listing-header-container listing-header-container-active">
            <p className="listing-title-main">{title.toUpperCase()}</p>
          </div>
          <div className="listing-business-info-conatiner">
            <p className="pointer listing-business-name">
              {toTitleCase({ input: businessName, lowerCaseRest: false })}
            </p>
            <div className="pointer listing-business-info-svg listing-fill-active">
              <DirectionArrow />
            </div>
          </div>
          <p className="listing-spacing listing-description-main">
            {description}
          </p>

          <ButtonLink
            isDateCurrent
            status="active"
            text="VIRTUAL LINK"
            link={virtualLink}
            isClickable={false}
          />

          {promoCode && (
            <div className="listing-spacing listing-center-content">
              <div
                className="redemtion-button-code-button listing-header-container-active"
                onClick={handlePromoClick}
              >
                <p className="redemtion-button-code-text color-white">
                  REDEMPTION CODE
                </p>
              </div>
            </div>
          )}

          <Date
            data={data}
            startTime={startTime}
            endTime={endTime}
            status="active"
          />
          <Features
            adTitle={title}
            name={businessName}
            categories={categories}
            features={features}
            adStatus="active"
            isClickable={false}
          />
        </div>

        {!isCorporate && (
          <Contact
            ad={data}
            business={user}
            status="active"
            isClickable={false}
          />
        )}
      </DetailViewLayout>

      <div className="confirm-button-wrapper">
        <div className="confirm-metadata-conatiner confirm-metadata-conatiner-warning">
          <p className="confirm-metadata-text">
            Links are not active on confirmation page
          </p>
        </div>
        {!isUnlimited && isCreate && (
          <div className="confirm-metadata-conatiner confirm-metadata-conatiner-noitce">
            <p className="confirm-metadata-text">{getUniqueListingText()}</p>
          </div>
        )}
        <div className="confirm-button-container">
          <Button
            className="submit-button-CreateListing"
            text="Edit"
            isPrimary={false}
            handleClick={() => {
              if (setCurrentIndex) setCurrentIndex(0);
              setIsConfirm(false);
            }}
          />
          <Button
            className="submit-button-CreateListing"
            text="Confirm"
            handleClick={handleSave}
          />
        </div>
      </div>
    </>
  );
};
