import React, { useRef, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as stateActions from "../../../../redux/actions/state-actions";
import * as userActions from "../../../../redux/actions/user-actions";
import lottie from "lottie-web";
import moment from "moment";

// Components
import DetailViewLayout from "../../../components/detail-view-scroll-layout";
import VerifyUser from "./verify-user";
import ProfileImageConatiner from "../user/profile-image-conatiner";
import WelcomeBanner from "./welcome-banner";
import PostAd from "./post-ad";
// import SwitchButton from "../../../components/common/switch-button";
import Pagination from "../../../components/pagination";
import PostedAd from "./posted-ad";
import ScheduledPost from "./scheduled-post";
import Button from "../../../components/common/button";
// import BarChart from "../../../components/chart/horizontal-bar-chart";
// import BarChartDynamic from "../../../components/chart/horizontal-bar-chart-dynamic";
import Instagram from "./instagram";
import Yelp from "../../listing/yelp";
import Modal from "../../../components/modal";

// Utilities
import { isDev } from "../../../../utilites/status";
import useGetBusinessStatus from "../../../../utilites/hooks/UseGetBusinessStatus";
import { charLimitTo, toTitleCase } from "../../../../utilites/format";
// import { analyticsGet, communtiyAnalytics } from "../../../../api/analytics";
import { yelpDeleteBusiness } from "../../../../api/business";
import { deleteScheduledPost } from "../../../../api/facebook";
import { deleteListingData } from "../../../../utilites/listing";
import { formatDbDateToReadable } from "../../../../utilites/date";
import { militaryTimeToReadable } from "../../../../utilites/time";

// Assets
// import Arrow from "../../../../assets/svg/back";
import MapQuestion from "../../../../assets/lotti/map-question.json";

// Styles
import "../profile.css";
import "./business.css";

const BusinessProfile = ({
  user,
  setLoader,
  setMessage,
  setAds,
  history,
  setImage,
  setCovidModal,
  updateUser,
}) => {
  const mapLotti = useRef();
  // const [isGrow, setIsGrow] = useState(true);
  const [savedPageNumber, setSavedPageNumber] = useState(1);
  const [scheduledPageNumber, setScheduledPageNumber] = useState(1);
  // const [analytics, setAnalytics] = useState(null);
  // const [terms, setTerms] = useState(null);
  const [modalMessage, setModalMessage] = useState("");
  const [corporateModal, setCorporateModal] = useState("");
  const [deleteAd, setDeleteAd] = useState(null);
  const {
    _id,
    isActive: busniessIsActive,
    verified,
    name,
    ads = [],
    scheduledSocial = [],
    images,
    yelp,
    account,
    covid,
    // location,
    locationType,
    locations,
  } = user;
  const { accountType } = account || {};
  const { uniqueListingIds } = useGetBusinessStatus();
  const sortedAds = ads.sort(({ views: a }, { views: b }) => b - a);
  const sortedScheduledPost = scheduledSocial.sort(
    ({ date: dA, time: tA }, { date: dB, time: tB }) =>
      Number(`${dA}${tA}`) - Number(`${dB}${tB}`)
  );
  // const isFreeAccount = !accountType || accountType.toLowerCase() === "free";
  // const { county } = location || {};
  const isCorporate = locationType === "Multiple Locations";

  // const getAnalytics = async () => {
  //   if (!_id) return;
  //   const { analytics } = await analyticsGet(_id);
  //   if (analytics) {
  //     const { seenTotals, viewTotals, promoViewsTotals } = analytics;
  //     const Impressions = Object.values(seenTotals).reduce(
  //       (acc, cur) => acc + cur,
  //       0
  //     );
  //     const Views = Object.values(viewTotals).reduce(
  //       (acc, cur) => acc + cur,
  //       0
  //     );
  //     const Promos = Object.values(promoViewsTotals).reduce(
  //       (acc, cur) => acc + cur,
  //       0
  //     );
  //     setAnalytics([
  //       { name: "Totals", Impressions, Views, "Promos Clicked": Promos },
  //     ]);
  //   }
  // };

  // const getCommunity = async () => {
  //   if (!county) return;
  //   const { analytics } = await communtiyAnalytics(county);

  //   const getAllTerms = (week) => {
  //     const { weeks } = analytics || {};
  //     if (!weeks && !(week in weeks)) return [];
  //     const weekValues = weeks[week] || {};
  //     if (!weekValues) return [];
  //     const { terms: topTerms = {} } = weekValues.top || {};
  //     const { terms: foodTerms = {} } = weekValues.food || {};
  //     const { terms: drinksTerms = {} } = weekValues.drinks || {};
  //     const { terms: activitiesTerms = {} } = weekValues.activities || {};
  //     const { terms: eventsTerms = {} } = weekValues.events || {};
  //     return [topTerms, foodTerms, drinksTerms, activitiesTerms, eventsTerms];
  //   };

  //   const sortFeild = (field) =>
  //     Object.keys(field).sort(
  //       (fieldA, fieldB) => field[fieldB] - field[fieldA]
  //     );

  //   if (analytics) {
  //     const previousTerms = getAllTerms(moment().subtract(7, "days").isoWeek());
  //     const currentTerms = getAllTerms(moment().isoWeek());
  //     const allTerms = [...previousTerms, ...currentTerms];
  //     const combinedTerms = allTerms.reduce((acc, terms) => {
  //       const updatedTerms = { ...acc };
  //       for (const key in terms) {
  //         if (updatedTerms[key]) {
  //           updatedTerms[key] = updatedTerms[key] + terms[key];
  //         } else {
  //           updatedTerms[key] = terms[key];
  //         }
  //       }
  //       return updatedTerms;
  //     }, {});
  //     const sortedTerms = sortFeild(combinedTerms).slice(0, 5).reverse();
  //     const topTerms = sortedTerms.map((term) => {
  //       const termData = {
  //         term,
  //         value: combinedTerms[term],
  //       };
  //       if (combinedTerms[term])
  //         termData.value = termData.value + combinedTerms[term];
  //       return termData;
  //     });
  //     setTerms(topTerms);
  //   }
  // };

  useEffect(() => {
    const { covidCompliant } = covid || {};
    const { covidModal: covidModalSeen } = window.localStorage || {};
    // if (_id) getAnalytics();
    // if (!isFreeAccount) getCommunity();
    if (isCorporate && locations && !locations.length) {
      setCorporateModal(
        'You have selected the "multiple location" model. Your post will not get seen untill you add a location. Would you like to add a business location now?'
      );
      lottie.loadAnimation({
        container: mapLotti.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
        animationData: MapQuestion,
      });
    } else if (!covidCompliant && !covidModalSeen) setCovidModal(true);
  }, [_id]);

  const handleConfirmAdDelete = (adData) => {
    const { _id: listingId, title } = adData;
    const isPremium = accountType === "premium";
    const uniqueListingDate = uniqueListingIds[listingId];
    const futureMonth = uniqueListingDate
      ? moment(uniqueListingDate).add(1, "M").format("LLL")
      : "";
    const message = uniqueListingDate
      ? isPremium
        ? `"${title}", is one of your unique listings. This listing will not be able to be replaced till ${futureMonth}. Are you sure you want to continue?`
        : `"${title}", is your free monthly listing. You will not be able to create a new free listing till ${futureMonth}. Are you sure you want to continue?`
      : `Are you sure you want to delete your "${title}" listing?`;
    setDeleteAd(adData);
    setModalMessage(message);
  };

  const handleAdCancel = () => {
    setModalMessage("");
    setDeleteAd(null);
  };

  const handleAdDelete = async () => {
    setLoader(true);
    setModalMessage("");
    const [{ ads }] = await deleteListingData(deleteAd);
    setDeleteAd(null);
    if (!(ads.length % 5)) {
      const newPageNumber = savedPageNumber - 1;
      setSavedPageNumber(newPageNumber > 0 ? newPageNumber : 1);
    }
    if (ads) setAds(ads);
    setLoader(false);
    setMessage({ message: "Successful" });
  };

  const handleConfirmSchedulePostDelete = (postData) => {
    const { date, time } = postData;

    const message = `Are you sure you want to delete your scheduled post on ${formatDbDateToReadable(
      { date, isForUser: true }
    )} at ${militaryTimeToReadable(time)}?`;
    setDeleteAd(postData);
    setModalMessage(message);
  };

  const handleSchedulePostDelete = async () => {
    const { _id: scheduledPostId } = deleteAd;
    setLoader(true);
    setModalMessage("");

    const { completed } = await deleteScheduledPost(scheduledPostId);

    if (completed) {
      setDeleteAd(null);
      const newScheduledPostList = scheduledSocial.filter(
        ({ _id }) => _id !== scheduledPostId
      );

      if (!(scheduledSocial.length % 5)) {
        const newScheduledPageNumber = scheduledPageNumber - 1;
        setScheduledPageNumber(
          newScheduledPageNumber > 0 ? newScheduledPageNumber : 1
        );
      }

      updateUser({ scheduledSocial: newScheduledPostList });
      setMessage({ message: "Scheduled Post Deleted" });
    } else {
      setMessage({ messageType: "error", message: "Error" });
    }

    setLoader(false);
  };

  const handleYelpDelete = async () => {
    setLoader(true);
    const { message } = await yelpDeleteBusiness(_id);
    if (message) {
      updateUser({ yelp: null });
      setMessage({ message: "Successful" });
    }
    setLoader(false);
  };

  // const handleSwitchClick = (type) => {
  //   setIsGrow(type === "Grow");
  // };

  const renderAds = () => {
    const sliceStartPosition = savedPageNumber * 5 - 5;
    let sliceEndPosition = savedPageNumber * 5;
    if (sliceEndPosition > sortedAds.length)
      sliceEndPosition = sortedAds.length;
    const displayAds = sortedAds.slice(sliceStartPosition, sliceEndPosition);
    return displayAds.map((ad, idx) => (
      <PostedAd
        ad={ad}
        ads={ads}
        setAds={setAds}
        verified={verified}
        busniessIsActive={busniessIsActive}
        handleDelete={handleConfirmAdDelete}
        key={idx}
      />
    ));
  };

  const renderSchedulePost = () => {
    const sliceStartPosition = scheduledPageNumber * 5 - 5;
    let sliceEndPosition = scheduledPageNumber * 5;
    if (sliceEndPosition > sortedScheduledPost.length)
      sliceEndPosition = sortedScheduledPost.length;
    const displayAds = sortedScheduledPost.slice(
      sliceStartPosition,
      sliceEndPosition
    );
    return displayAds.map((post, idx) => (
      <ScheduledPost
        post={post}
        handleDelete={handleConfirmSchedulePostDelete}
        key={idx}
      />
    ));
  };

  return (
    <DetailViewLayout images={images} name={name} showImage={false}>
      <VerifyUser
        verified={verified}
        id={_id}
        setLoader={setLoader}
        setMessage={setMessage}
        updateUser={updateUser}
      />

      <ProfileImageConatiner image={images && images.length ? images[0] : ""}>
        <WelcomeBanner />
        <div className="business-title-container">
          <div className="business-line-break bgc-medium" />
          <div className="social-login-title-container">
            <p className="social-login-title-text">
              {charLimitTo(name.toUpperCase(), 22)}
            </p>
          </div>
          <div className="business-line-break bgc-medium" />
        </div>

        <div className="profile-pagination-title">
          <p>Create Post:</p>
          <Button
            size="small"
            text="new post"
            handleClick={() => history.push("/create-post")}
          />
        </div>

        {isCorporate && (
          <div className="profile-pagination-title">
            <p>Manage Locations:</p>
            <Button
              size="small"
              text="Locations"
              handleClick={() => history.push("/business/corporate")}
            />
          </div>
        )}

        {isDev() && !isCorporate && (
          <div className="profile-pagination-title">
            <p>Manage Sales:</p>
            <Button
              size="small"
              text="Sales"
              handleClick={() => history.push("/business/sales")}
            />
          </div>
        )}
      </ProfileImageConatiner>

      {/* <div className="listing-content-container">
        <div className="business-selector-container">
          <SwitchButton
            button1Name="Grow"
            button2Name="Learn"
            isFirst={isGrow}
            handleClick={handleSwitchClick}
          />
        </div>
        {isGrow ? (
          <>
            <div className="profile-pagination-title">
              <p>Analytics</p>
              <Button
                size="small"
                text="See More"
                handleClick={() => history.push("/business/analytics")}
              />
            </div>
            <BarChart
              height="220px"
              primaryKey="Impressions"
              secondaryKey="Views"
              promoKey="Promos Clicked"
              data={analytics}
              layout="vertical"
              isToolTip={false}
            />
          </>
        ) : isFreeAccount ? (
          <div className="profile-category-upgarde-container">
            <p className="profile-category-upgarde-text">
              Upgrade now to get your local commuity staistics to find out what
              people are looking for!
            </p>
            <Button
              size="small"
              text="Upgrade"
              handleClick={() => setPaymentModal(true)}
            />
          </div>
        ) : (
          <>
            <div className="profile-pagination-title">
              {!!county ? (
                <p>Top Searched Terms</p>
              ) : (
                <p>No Business Location</p>
              )}
              {!!county && (
                <Button
                  size="small"
                  text="See Yours"
                  handleClick={() => history.push("/business/community")}
                />
              )}
            </div>
            <BarChartDynamic data={terms} />
          </>
        )}
      </div> */}

      {sortedAds && !!sortedAds.length ? (
        <Pagination
          page={savedPageNumber}
          setPage={setSavedPageNumber}
          noResultsText="No Posted Ads"
          list={sortedAds}
        >
          <>
            <div
              className="profile-pagination-title"
              style={{ marginTop: "10px" }}
            >
              <div className="profile-pagination-title">
                <p style={{ marginRight: "5px" }}>EventHound Postings:</p>
                {sortedAds.length && (
                  <p className="profile-account-type">{sortedAds.length}</p>
                )}
              </div>

              <div />
            </div>
            {renderAds()}
          </>
        </Pagination>
      ) : (
        <PostAd />
      )}

      {scheduledSocial && !!scheduledSocial.length && (
        <Pagination
          page={scheduledPageNumber}
          setPage={setScheduledPageNumber}
          noResultsText="No Schedule Post"
          list={scheduledSocial}
        >
          <>
            <div
              className="profile-pagination-title"
              style={{ marginTop: "10px" }}
            >
              <div className="profile-pagination-title">
                <p style={{ marginRight: "5px" }}>
                  Schedule Social Media Postings:
                </p>
                {scheduledSocial.length && (
                  <p className="profile-account-type">
                    {scheduledSocial.length}
                  </p>
                )}
              </div>

              <div />
            </div>
            {renderSchedulePost()}
          </>
        </Pagination>
      )}

      <Instagram />
      <Yelp
        yelp={yelp}
        name={name}
        setImage={setImage}
        handleDelete={handleYelpDelete}
      />

      <Modal
        directModalMessage={modalMessage}
        modalAccept="Delete"
        modalCancel="Cancel"
        handleCancelClick={handleAdCancel}
        handleAcceptClick={() => {
          const { postDate } = deleteAd;
          postDate ? handleSchedulePostDelete() : handleAdDelete();
        }}
      />
      <Modal
        directModalMessage={corporateModal}
        modalAccept="Add now"
        modalCancel="later"
        handleCancelClick={() => setCorporateModal("")}
        handleAcceptClick={() => history.push("/business/corporate")}
      >
        <div ref={mapLotti} className="no-locations-animation-container" />
      </Modal>
    </DetailViewLayout>
  );
};

const mapStateToProps = (store) => ({
  isFree: store.state.isFree,
  user: store.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (loaderState) => dispatch(stateActions.setLoader(loaderState)),
  setMessage: (dataObj) => dispatch(stateActions.setMessage(dataObj)),
  setAds: (adsArray) => dispatch(userActions.setAds(adsArray)),
  setImage: (value) => dispatch(stateActions.setImage(value)),
  setPaymentModal: (boolean) => dispatch(stateActions.setPaymentModal(boolean)),
  setCovidModal: (boolean) => dispatch(stateActions.setCovidModal(boolean)),
  updateUser: (userObj) => dispatch(userActions.updateUser(userObj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BusinessProfile));
