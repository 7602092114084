import React from "react";
import LazyLoad from "react-lazyload";

// Utilities
import { isMobileAgent } from "../../../../utilites";
import { charLimitTo, toTitleCase } from "../../../../utilites/format";
import { getAdStatus, getBusinessStatus } from "../../../../utilites/status";

// Assets
import Skyline from "../../../../assets/svg/skyline";
import RatingStarsClip from "../../../../assets/svg/rating-stars-clip";

export default ({ isBusinessSearch, ad, filterDate, handleClick }) => {
  const isMobile = isMobileAgent();
  const { status } = isBusinessSearch
    ? getBusinessStatus(ad, filterDate)
    : getAdStatus(ad, filterDate);
  const { categories, images, title, businessName, name, yelp } = ad;
  const { cat1Img } = categories || {};
  const { rating = 0, review_count } = yelp || {};
  const ratingPercent = (rating / 5) * 100;
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const isLargeScreen = width > 750;
  const image = (isBusinessSearch ? images[0] : cat1Img) || "";

  return (
    <LazyLoad height={120}>
      <div
        className={`pointer recommended-card-container result-card-${status}`}
        style={{ marginBottom: isMobile && !isLargeScreen ? 0 : "10px" }}
        onClick={handleClick}
      >
        <div className={`result-card-overlay result-card-overlay-gradient`} />
        <div
          className={`result-card-overlay result-card-status-overlay result-card-overlay-${status}`}
        />
        {image ? (
          <img
            className="recommended-card-image"
            src={image}
            alt={title}
            effect="blur"
          />
        ) : (
          <div className="recommended-card-skyline">
            <Skyline />
          </div>
        )}
        <h3 className="recommended-card-title">
          {toTitleCase({
            input: charLimitTo(title || name, 30),
            lowerCaseRest: false,
          })}
        </h3>
        {businessName && (
          <div className="recommended-card-name">
            <p>{toTitleCase({ input: charLimitTo(businessName, 25) })}</p>
          </div>
        )}
        {yelp && (
          <div className="recommended-yelp-conatiner">
            <p className="recommended-yelp-text">{`Yelp (${review_count})`}</p>
            <div className="yelp-review-result-conatiner">
              <div
                className="yelp-review-fill-clip"
                style={{ width: `${ratingPercent}%` }}
              >
                <RatingStarsClip />
              </div>
            </div>
          </div>
        )}
      </div>
    </LazyLoad>
  );
};
