import React from "react";

// Components
import DetailViewLayout from "../../../components/detail-view-scroll-layout";

// Styles
import "./listing-skeleton.css";

export default () => {
  return (
    <DetailViewLayout showImage={false}>
      <div className="listing-skeleton-card-image-container" />
      <div className="listing-skeleton-card-container" />
      <div className="listing-skeleton-card-container" />
    </DetailViewLayout>
  );
};
