import { deleteListing } from "../api/listing";
import { deleteImage } from "../api/image";

export const deleteListingData = async (ad) => {
  const { _id, businessId, categories, images, promoCode } = ad;
  const { cat1Img, cat2Img } = categories;

  const verifyImageDelete = () => {
    if (images.length) {
      return images.map((image) => deleteImage(image));
    } else {
      return [Promise.resolve()];
    }
  };

  const promiseDeleteArray = [
    deleteListing(_id, businessId),
    deleteImage(cat1Img),
    ...verifyImageDelete(),
  ];
  if (cat2Img) promiseDeleteArray.push(deleteImage(cat2Img));
  if (promoCode) promiseDeleteArray.push(deleteImage(promoCode));

  return await Promise.all(promiseDeleteArray);
};
