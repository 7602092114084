import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import * as userActions from "../../../redux/actions/user-actions";
import moment from "moment";

// Components
import SkeletonLoader from "./analytics-loader";
import DetailViewLayout from "../../components/detail-view-scroll-layout";
import SwitchButton from "../../components/common/switch-button";
import HorizontalBarChart from "../../components/chart/horizontal-bar-chart-dynamic";
import PieChart from "../../components/chart/pie-chart";

// Utilities
import { communtiyAnalytics } from "../../../api/analytics";
import GetFilterIcon from "../../components/filter-icons";
import { isSafariAgent } from "../../../utilites";

// Styles
import "../profile/profile.css";
import "../business/business.css";
import "./analytics-styles.css";

const BusinessProfile = ({ user, setMessage }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isCurrent, setIsCurrent] = useState(true);
  const [analytics, setAnalytics] = useState(null);
  const [metaData, setMetaData] = useState(null);
  const [cat1Pie, setCat1Pie] = useState(null);
  const [cat1Bar, setCat1Bar] = useState(null);
  const [cat2Pie, setCat2Pie] = useState(null);
  const [cat2Bar, setCat2Bar] = useState(null);
  const { images, name, location, categories } = user;
  const { county } = location || {};
  const { cat1, cat2 } = categories || {};
  const { startDate, endDate } = metaData || {};

  const getMetaData = (data, week) => {
    const { weeks } = data || {};
    if (!weeks || !(week in weeks)) return {};
    const { total } = weeks[week] || {};
    const date = moment().isoWeek(week).startOf("week").format("L");
    const startOfWeek = moment(date).subtract(6, "days").format("L");
    const endOfWeek = moment(date).format("L");
    return {
      startDate: startOfWeek,
      endDate: endOfWeek,
      total,
    };
  };

  const sortFeild = (field) =>
    Object.keys(field).sort((fieldA, fieldB) => field[fieldB] - field[fieldA]);

  const getFeatureValues = (data, week, cat) => {
    const { weeks } = data || {};
    if (!weeks || !(week in weeks)) return [];
    const weekValues = weeks[week];
    if (!weekValues || !weekValues[cat.toLowerCase()]) return [];
    const category = weekValues[cat.toLowerCase()];
    const { features = {} } = category || {};
    const { features: topFeatures } = weekValues.top || {};
    const sortedFeatures = sortFeild(features).slice(0, 10);
    const categoryFeatures = sortedFeatures.map((feature) => {
      const featureData = {
        name: feature,
        value: features[feature],
      };
      if (topFeatures && topFeatures[feature])
        featureData.value = featureData.value + topFeatures[feature];
      return featureData;
    });
    return categoryFeatures;
  };

  const getTermValues = (data, week, cat) => {
    const { weeks } = data || {};
    if (!weeks || !(week in weeks)) return [];
    const weekValues = weeks[week];
    if (!weekValues || !weekValues[cat.toLowerCase()]) return [];
    const category = weekValues[cat.toLowerCase()];
    const { terms = {} } = category || {};
    const { terms: topTerms } = weekValues.top || {};
    const sortedTerms = sortFeild(terms).slice(0, 10);
    const categoryTerms = sortedTerms.map((term) => {
      const termData = {
        term,
        value: terms[term],
      };
      if (topTerms && topTerms[term])
        termData.value = termData.value + topTerms[term];
      return termData;
    });
    return categoryTerms.reverse();
  };

  const getWeekValues = (data, week = moment().isoWeek()) => {
    const newMeatData = getMetaData(data, week);
    const catOneFeatures = getFeatureValues(data, week, cat1);
    const catOneTerms = getTermValues(data, week, cat1);
    const catTwoFeatures = getFeatureValues(data, week, cat2);
    const catTwoTerms = getTermValues(data, week, cat2);

    setMetaData(newMeatData);
    setCat1Pie(catOneFeatures);
    setCat1Bar(catOneTerms);
    setCat2Pie(catTwoFeatures);
    setCat2Bar(catTwoTerms);
  };

  const getAnalytics = async () => {
    setIsLoading(true);
    const { analytics: newAnalytics } = await communtiyAnalytics(county);
    if (!newAnalytics) {
      setMessage({ message: "No Statistics" });
      return setIsLoading(false);
    }
    setAnalytics(newAnalytics);
    getWeekValues(newAnalytics);
    setIsLoading(false);
  };

  useEffect(() => {
    getAnalytics();
  }, []);

  const handleClick = (type) => {
    const isSafari = isSafariAgent();
    if (isSafari) setIsLoading(true);
    const isCurrent = type === "Current";
    setIsCurrent(isCurrent);
    getWeekValues(
      analytics,
      isCurrent ? moment().isoWeek() : moment().subtract(7, "days").isoWeek()
    );
    if (isSafari)
      setTimeout(() => {
        setIsLoading(false);
      });
  };

  const renderGraphs = () => (
    <>
      <div className="listing-content-container">
        <div className="flex-center">
          <p className="analytics-title">{`${endDate} - ${startDate}`}</p>
        </div>
      </div>

      {cat1Pie && !!cat1Pie.length && (
        <div className="listing-content-container">
          <div className="flex-center">
            <p className="analytics-title">{`Top ${cat1} Features`}</p>
          </div>
          <div className="analytics-spacing">
            <PieChart data={cat1Pie} />
          </div>
        </div>
      )}
      {cat1Bar && !!cat1Bar.length && (
        <div className="listing-content-container">
          <div className="flex-center">
            <p className="analytics-title">{`Top ${cat1} Search Terms`}</p>
          </div>
          <div className="analytics-spacing">
            <HorizontalBarChart data={cat1Bar} />
          </div>
        </div>
      )}

      {cat2 && (
        <>
          {cat2Pie && !!cat2Pie.length && (
            <div className="listing-content-container">
              <div className="flex-center">
                <p className="analytics-title">{`Top ${cat2} Features`}</p>
              </div>
              <div className="analytics-spacing">
                <PieChart data={cat2Pie} />
              </div>
            </div>
          )}

          {cat2Bar && !!cat2Bar.length && (
            <div className="listing-content-container">
              <div className="flex-center">
                <p className="analytics-title">{`Top ${cat2} Search Terms`}</p>
              </div>
              <div className="analytics-spacing">
                <HorizontalBarChart data={cat2Bar} />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );

  return (
    <DetailViewLayout images={images} name={name} showImage={false}>
      <div className="analytics-header-container">
        <div className="flex-center">
          <p className="analytics-title">Your Local Statistics</p>
        </div>

        <div
          className="analytics-reset-container"
          onClick={() => getAnalytics()}
        >
          {GetFilterIcon("refresh")}
        </div>

        <div className="analytics-selector-container">
          <SwitchButton
            button1Name="Current"
            button2Name="Last Week"
            isFirst={isCurrent}
            handleClick={handleClick}
          />
        </div>
      </div>

      {isLoading ? (
        <SkeletonLoader />
      ) : endDate ? (
        renderGraphs()
      ) : (
        <p className="analytics-no-text">No Statistics At This Time</p>
      )}
    </DetailViewLayout>
  );
};

const mapStateToProps = (store) => ({
  user: store.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (loaderState) => dispatch(stateActions.setLoader(loaderState)),
  setMessage: (dataObj) => dispatch(stateActions.setMessage(dataObj)),
  setAds: (adsArray) => dispatch(userActions.setAds(adsArray)),
  setImage: (value) => dispatch(stateActions.setImage(value)),
  setPaymentModal: (boolean) => dispatch(stateActions.setPaymentModal(boolean)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BusinessProfile));
