import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as dataActions from "../../../../redux/actions/data-actions";

// components
import RecommendedSkeleton from "./recommended-skeleton";
import RecommendedCard from "./recommended-card";
import NoResults from "./no-results";

// Utilities
import { isMobileAgent } from "../../../../utilites";
import { formatSearchQuery } from "../../../../utilites/format";
import { searchListing, searchBusiness } from "../../../../api/search";
import { searchGrowth } from "../../../../api/growth";

// Styles
import "./recommended.css";

const Recommended = ({
  location,
  geoLocation,
  geoPermission,
  filterType,
  filterDate,
  reduxUser,
  history,
}) => {
  const [recommendations, setRecommendations] = useState(null);
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const isLargeScreen = width > 775;
  const isMobile = isMobileAgent();
  const isBusinessSearch = filterType === "Business";

  useEffect(() => {
    setRecommendations(null);
    if (geoPermission === null) return;
    (async () => {
      const searchOption = formatSearchQuery({
        reduxUser,
        isLiveSearch: false,
        filters: {
          type: filterType,
          date: "",
          time: "",
          subCat: "",
          search: "",
          distance: "75 Miles",
          sortBy: "Popularity",
        },
      });

      let { results = [] } = isBusinessSearch
        ? (await searchBusiness(searchOption)) || {}
        : (await searchListing(searchOption)) || {};

      if (!isBusinessSearch && (!results || results.length < 20)) {
        const { results: growthResults = [] } = await searchGrowth({
          ...searchOption,
          skip: 0,
        });
        results = [...results, ...growthResults];
      }

      if (results) {
        setRecommendations(results);
      } else {
        setRecommendations([]);
      }
    })();
  }, [geoPermission, geoLocation, filterType, location]);

  const renderSkeletonUi = () => {
    const skeletonEl = [];
    const padding = width * (isLargeScreen ? 0.2 : 0.1);
    const adjustedWidth = width - padding;
    const numberOfSkeletonEl = !isMobile
      ? 12
      : isLargeScreen
      ? ((adjustedWidth * 2) / 210).toFixed()
      : (adjustedWidth / 210).toFixed();

    for (let i = 0; i < numberOfSkeletonEl; i++) {
      skeletonEl.push(<RecommendedSkeleton key={i} />);
    }
    return skeletonEl;
  };

  return (
    <div
      className={`recommended-wrapper ${isMobile ? "no-scroll-bars" : ""}`}
      style={{
        maxHeight: isLargeScreen ? "calc(60vh - 220px)" : "100%",
        flexWrap: isLargeScreen ? "wrap" : "nowrap",
        justifyContent: isLargeScreen ? "center" : "flex-start",
      }}
    >
      {geoPermission !== undefined && !!recommendations ? (
        <>
          {recommendations.map((recommendation, idx) => {
            const { _id } = recommendation;
            const url = `/${isBusinessSearch ? "business" : "listing"}/${_id}`;
            return (
              <RecommendedCard
                isBusinessSearch={isBusinessSearch}
                ad={recommendation}
                filterDate={filterDate}
                handleClick={() => history.push(url)}
                key={idx}
              />
            );
          })}
          {recommendations.length ? (
            <div
              className="pointer recommended-card-container recommended-see-more"
              style={{ marginBottom: isMobile ? 0 : "10px" }}
              onClick={() => history.push("/top")}
            >
              <p className="recommended-see-more-text">{`Top ${
                filterType || "40"
              }`}</p>
            </div>
          ) : (
            <NoResults />
          )}
        </>
      ) : (
        renderSkeletonUi()
      )}
    </div>
  );
};

const mapStateToProps = (store) => ({
  reduxUser: store.user,
  filterType: store.data.filters.type,
  filterDate: store.data.filters.date,
  location: store.user.location,
  geoLocation: store.user.geoLocation,
  geoPermission: store.user.geoPermission,
});

const mapDispatchToProps = (dispatch) => ({
  setFilter: (filterObj) => dispatch(dataActions.setFilter(filterObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Recommended);
