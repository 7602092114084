import React from "react";

// Styles
import "../user.css";

export default ({ image, children }) => (
  <div
    className="profile-listing-content-container"
    style={{ marginTop: image ? "80px" : "0px" }}
  >
    {!!image && (
      <div className="user-profile-image-container">
        <img className="user-profile-image" src={image} />
      </div>
    )}
    {children}
  </div>
);
