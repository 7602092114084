import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as stateActions from "../../../../redux/actions/state-actions";
import * as userActions from "../../../../redux/actions/user-actions";
import moment from "moment";

// Components
import Button from "../../../components/common/button";
import InputLabeled from "../../../components/input-labeled";
import SwitchButton from "../../../components/common/switch-button";
import TimeSelector from "../../../components/common/time-selector";
import WeekdaysTimeInput from "../../../components/WeekdaysTimeInput";
import Modal from "../../../components/modal";

// utilies
import { isSafariAgent, isMobileAgent } from "../../../../utilites";
import defaultData from "../../sign-up/business/data-weekdats-default";
import {
  convertDateToDbDate,
  getCurrentDate,
  formatDbDateToReadable,
} from "../../../../utilites/date";
import {
  formatEndTime,
  militaryTimeToReadable,
} from "../../../../utilites/time";
import getFeaturesIcon from "../../../components/features-input/getFeatureIcon";
import {
  isOpenDay,
  validateSalesCarousel,
} from "../../../../utilites/validate";
import {
  toTitleCase,
  formateServiceFee,
  formatSameHours,
} from "../../../../utilites/format";
import { createTickets } from "../../../../api/tickets";
import { updateListing } from "../../../../api/listing";
import { updateAdInAds } from "../../../../utilites/update";
import { salesInvalidTypeMessage } from "../../../../constants/data-models";

// Styles
import "../../landing-pages/business-start-up/business-start-up-styles.css";

export default ({ match }) => {
  const carousel = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    _id: businessId,
    ads = [],
    tickets,
  } = useSelector((store) => store.user.user);
  const { id: adId } = match.params || {};
  const ad = ads.find(({ _id }) => _id === adId);
  const { title, days } = ad;
  const {
    presaleDate = false,
    startDate,
    dateAvailable,
    hours: adHours,
  } = days || {};
  const isAdTickets = !!tickets.filter(
    ({ isActive, listingId, isTicket }) =>
      isActive && listingId === adId && isTicket === true
  ).length;
  const isAdVouchers = !!tickets.filter(
    ({ isActive, listingId, isTicket }) =>
      isActive && listingId === adId && isTicket === false
  ).length;
  const [modalMessage, setModalMessage] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTicketInput, setIsTicketInput] = useState(true);
  const [nameInput, setNameInput] = useState("");
  const [startTimeInput, setStartTimeInput] = useState(800);
  const [endTimeInput, setEndTimeInput] = useState(2000);
  const [redeemablePeriod, setRedeemablePeriod] = useState("0");
  const [isAdHours, setIsAdHours] = useState(false);
  const [hours, setHours] = useState(
    formatSameHours(adHours) || defaultData.hours
  );
  const [originalPriceInput, setOriginalPriceInput] = useState("");
  const [priceInput, setPriceInput] = useState("0");
  const [isPresale, setIsPresale] = useState(false);
  const [quantityInput, setQuantityInput] = useState("");
  const [minimumQuantityInput, setMinimumQuantityInput] = useState("1");
  const [maximumQuantityInput, setMaximumQuantityInput] = useState("10");
  const [descriptionInput, setDescriptionInput] = useState("");
  const isMobile = isMobileAgent();
  const isSafairMobile = isSafariAgent() && isMobile;
  const isDiscriptionComplete = descriptionInput.length < 40;
  const discriptionLegend = isDiscriptionComplete
    ? `${40 - descriptionInput.trim().length} more characters needed`
    : `${descriptionInput.trim().length} characters`;
  const height = `calc(100vh - ${isSafairMobile ? 150 : 40}px`;

  useEffect(() => {
    if (isAdVouchers) handleType(false);
    if (isAdTickets) handleType(true);
  }, []);

  const handleChange = (evt, type) => {
    const { value } = evt.target;
    switch (type) {
      case "nameInput":
        return setNameInput(value);
      case "redeemablePeriod":
        const newRedeemablePeriod = value
          .replace("-", "")
          .replace(".", "")
          .match(/^[0-9]*$/gi);
        if (newRedeemablePeriod === null) return setRedeemablePeriod(90);
        return setRedeemablePeriod(newRedeemablePeriod[0]);
      case "startTimeInput":
        return setStartTimeInput(value);
      case "endTimeInput":
        return setEndTimeInput(formatEndTime(value));
      case "originalPriceInput":
        const newOriginalPriceValue = value
          .replace("-", "")
          .replace(".", "")
          .match(/^[0-9]*$/gi);
        if (newOriginalPriceValue === null) return setOriginalPriceInput("");
        return setOriginalPriceInput(newOriginalPriceValue[0]);
      case "priceInput":
        const newPriceValue = value
          .replace("-", "")
          .replace(".", "")
          .match(/^[0-9]*$/gi);
        if (newPriceValue === null) return setPriceInput("0");
        return setPriceInput(newPriceValue[0]);
      case "quantityInput":
        const newQuantityValue = value
          .replace("-", "")
          .replace(".", "")
          .match(/^[0-9]*$/gi);
        if (newQuantityValue === null) return setQuantityInput(quantityInput);
        return setQuantityInput(newQuantityValue[0]);
      case "minimumQuantityInput":
        const newMinimumQuantityValue = value
          .replace("-", "")
          .replace(".", "")
          .match(/^[0-9]*$/gi);
        if (newMinimumQuantityValue === null)
          return setMinimumQuantityInput(quantityInput);
        return setMinimumQuantityInput(newMinimumQuantityValue[0]);
      case "maximumQuantityInput":
        const newMaximumQuantityValue = value
          .replace("-", "")
          .replace(".", "")
          .match(/^[0-9]*$/gi);
        if (newMaximumQuantityValue === null)
          return setMaximumQuantityInput(quantityInput);
        return setMaximumQuantityInput(newMaximumQuantityValue[0]);
      case "descriptionInput":
        return setDescriptionInput(value);
    }
  };

  const handleType = (isTicket) => {
    setIsPresale(isTicket);
    setIsTicketInput(isTicket);
    setRedeemablePeriod(isTicket ? "0" : "90");
  };

  const handleSwitch = (type) => {
    const isTicket = type === "Ticket";
    if (isTicket === isTicketInput) return;
    if (isAdTickets || isAdVouchers) {
      if (isAdTickets) return setModalMessage("tickets");
      if (isAdVouchers) return setModalMessage("vouchers");
    }
    handleType(isTicket);
  };

  const handleSwitchTimesClick = (type) => {
    if (type === "Ad Hours" && !isOpenDay({ hours: adHours }))
      return dispatch(
        stateActions.setMessage({
          messageType: "error",
          message: "No Ad Hours",
        })
      );
    setIsAdHours(type === "Ad Hours");
  };

  const createSalesData = () => ({
    name: nameInput.trim().toLowerCase(),
    isTicket: isTicketInput,
    redeemablePeriod: redeemablePeriod.length
      ? Number(redeemablePeriod)
      : false,
    isAdHours,
    ...(dateAvailable
      ? { startTime: startTimeInput, endTime: endTimeInput }
      : { hours: isAdHours ? adHours : hours }),
    originalPrice: Number(originalPriceInput),
    price: priceInput.length ? Number(priceInput) : false,
    quantity: quantityInput.length ? Number(quantityInput) : false,
    min: minimumQuantityInput.length ? Number(minimumQuantityInput) : false,
    max: maximumQuantityInput.length ? Number(maximumQuantityInput) : false,
    description: descriptionInput.trim(),
  });

  const handleSubmit = async () => {
    dispatch(stateActions.setLoader(true));
    const ticketData = {
      ...createSalesData(),
      listingId: adId,
      businessId,
      lastUpdated: convertDateToDbDate(),
      createdDate: convertDateToDbDate(),
    };
    const promiseArray = [createTickets(ticketData)];

    if (isPresale && !presaleDate) {
      promiseArray.push(
        updateListing({
          ...ad,
          days: {
            ...days,
            presaleDate: convertDateToDbDate(
              getCurrentDate(
                moment(
                  formatDbDateToReadable({
                    date: startDate || dateAvailable,
                    toFullYear: true,
                    isForUser: true,
                  })
                )
                  .subtract(10, "days")
                  .format("L")
              )
            ),
          },
        })
      );
    } else {
      promiseArray.push({});
    }

    const [{ ticket }, { ad: updatedAd }] = await Promise.all(promiseArray);
    if (ticket) {
      dispatch(userActions.updateUser({ tickets: [...tickets, ticket] }));
      if (updatedAd) {
        dispatch(userActions.setAds(updateAdInAds(ads, updatedAd)));
      }
      history.goBack();
    }
    dispatch(stateActions.setLoader(false));
  };

  const slideTo = (index) => {
    if (index > -1 && index < 3) {
      const carouselElememt = carousel ? carousel.current : null;
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      const position = width * index;
      if (carouselElememt)
        carouselElememt.style.transform = `translate(-${position}px, 0)`;
      setCurrentIndex(index);
    }
  };

  const renderBusinessDays = () => {
    const filteredHours = Object.keys(hours).filter(
      (day) => day !== "isAdHours"
    );
    return filteredHours.map((day, key) => {
      const { isOpen, open, close } = adHours[day] || {};
      return (
        <div
          className="business-hour-conatiner business-hour-conatiner-inactive"
          style={{ backgroundColor: "transparent" }}
          key={key}
        >
          <p className="business-hour-item">{day}</p>
          {isOpen ? (
            <>
              <p className="business-hour-item">
                {militaryTimeToReadable(open)}
              </p>
              <p className="business-hour-item">
                {militaryTimeToReadable(close)}
              </p>
            </>
          ) : (
            <>
              <p className="business-hour-item">Closed</p>
              <p className="business-hour-item">Closed</p>
            </>
          )}
        </div>
      );
    });
  };

  return (
    <>
      <div className="full-page-carousel-wrapper" style={{ height }}>
        <img
          className="carousel-page-background-image"
          alt="EventHound Concert"
          src={"/img/food.jpg"}
        />

        <ul
          ref={carousel}
          className="carousel-container"
          style={{ width: "300%" }}
        >
          <li className="carousel-item" style={{ height }}>
            <div className="carousel-business-space-container" />
            <div className="carousel-business-item-content-container">
              <div className="business-signup-header-container">
                <div className="business-signup-icon">
                  {getFeaturesIcon("logo")}
                </div>
                <h2 className="card-title-SignUp">NEW SALE ITEM</h2>
                <p>{`For ${toTitleCase({ input: title })}`}</p>
              </div>
              <div className="listing-selector-container">
                <SwitchButton
                  button1Name="Ticket"
                  button2Name="Voucher"
                  isFirst={isTicketInput}
                  handleClick={handleSwitch}
                />
              </div>
              <p className="sales-type-text">
                {isTicketInput
                  ? "Tickets are available for presale prior to the event date and are only redeemable on the day and time they are purchased for."
                  : "Vouchers offer a redemption period set by you. The redemption period starts on the first available date."}
              </p>

              <InputLabeled
                name="nameInput"
                value={nameInput}
                label={`Name - e.g ${
                  isTicketInput ? "General Admission" : "Buy One, Get One FREE"
                }`}
                handleChange={(evt) => handleChange(evt, "nameInput")}
              />
              <InputLabeled
                name="originalPriceInput"
                value={originalPriceInput}
                label="Original Price (If Discounted)"
                handleChange={(evt) => handleChange(evt, "originalPriceInput")}
              />
              <InputLabeled
                name="priceInput"
                value={priceInput}
                label="Price Per Item"
                handleChange={(evt) => handleChange(evt, "priceInput")}
              />
              {priceInput && (
                <p className="ticket-adjusted-price-text">
                  {priceInput === "0"
                    ? "Items will be free"
                    : `$2 + 10% fee per sale, revenue will be $${formateServiceFee(
                        priceInput
                      )}`}
                </p>
              )}

              <div className="carousel-business-button-container">
                <Button
                  size={isMobile ? "small" : "medium"}
                  text="Cancel"
                  handleClick={() => history.goBack()}
                />
                <Button
                  size={isMobile ? "small" : "medium"}
                  text="Next"
                  handleClick={() => {
                    const hasRequiredInfo = validateSalesCarousel(
                      createSalesData(),
                      1
                    );
                    if (hasRequiredInfo) slideTo(currentIndex + 1);
                  }}
                />
              </div>
            </div>
          </li>

          <li className="carousel-item" style={{ height }}>
            <div className="carousel-business-space-container" />
            <div className="carousel-business-item-content-container">
              <div className="business-signup-header-container">
                <div className="business-signup-icon">
                  {getFeaturesIcon("clock")}
                </div>
                <h2 className="card-title-SignUp">TIME</h2>
              </div>

              {!dateAvailable && (
                <p>
                  {`Your post is repeated, select the days &
                times your ${
                  isTicketInput ? "tickets" : "vouchers"
                } are available.`}
                </p>
              )}

              {dateAvailable ? (
                <div className="time-selector-wrapper-CreateListing">
                  <TimeSelector
                    name="startTimeInput"
                    label="Start"
                    startTime={0}
                    value={startTimeInput}
                    handleChange={handleChange}
                  />
                  <TimeSelector
                    name="endTimeInput"
                    label="End"
                    startTime={startTimeInput}
                    value={endTimeInput}
                    handleChange={handleChange}
                  />
                </div>
              ) : (
                <>
                  {isTicketInput && (
                    <div className="listing-selector-container">
                      <SwitchButton
                        button1Name="Custom Hours"
                        button2Name="Ad Hours"
                        isFirst={!isAdHours}
                        handleClick={handleSwitchTimesClick}
                      />
                    </div>
                  )}

                  {isTicketInput && isAdHours ? (
                    renderBusinessDays()
                  ) : (
                    <WeekdaysTimeInput
                      openLabel="Start"
                      closeLabel="End"
                      page="create-tickets"
                      text="Availability days & hours:"
                      hours={hours}
                      setHours={setHours}
                      isCard={false}
                      isTimesSame
                    />
                  )}
                </>
              )}

              <div className="carousel-business-button-container">
                <Button
                  size={isMobile ? "small" : "medium"}
                  text="Back"
                  handleClick={() => slideTo(currentIndex - 1)}
                />
                <Button
                  size={isMobile ? "small" : "medium"}
                  text="Next"
                  handleClick={() => {
                    const hasRequiredInfo = validateSalesCarousel(
                      createSalesData(),
                      2
                    );
                    if (hasRequiredInfo) slideTo(currentIndex + 1);
                  }}
                />
              </div>
            </div>
          </li>

          <li className="carousel-item" style={{ height }}>
            <div className="carousel-business-space-container" />
            <div className="carousel-business-item-content-container">
              <div className="business-signup-header-container">
                <div className="business-signup-icon">
                  {getFeaturesIcon("postad")}
                </div>
                <h2 className="card-title-SignUp">DETAILS</h2>
              </div>

              <div className="legend-form-split-container">
                <label id="descriptionInput" className="label-form">
                  Description:
                </label>
                <label className="label-form">
                  <p
                    className={`legend-form-text ${
                      isDiscriptionComplete
                        ? "legend-form-incomplete"
                        : "legend-form-complete"
                    }`}
                  >
                    {discriptionLegend}
                  </p>
                </label>
              </div>
              <textarea
                className="input-style input-form"
                rows="5"
                maxLength="500"
                value={descriptionInput}
                onChange={(evt) => handleChange(evt, "descriptionInput")}
                spellCheck="true"
              />

              {!isTicketInput && (
                <InputLabeled
                  style={{ marginTop: "25px" }}
                  name="redeemablePeriod"
                  value={redeemablePeriod}
                  label="Redeemable Time Period (Days)"
                  handleChange={(evt) => handleChange(evt, "redeemablePeriod")}
                />
              )}
              <div style={{ width: "100%", height: "5px" }} />
              <InputLabeled
                name="quantityInput"
                value={quantityInput}
                label={`${dateAvailable ? "" : "Daily "}Available Quantity`}
                handleChange={(evt) => handleChange(evt, "quantityInput")}
              />
              <label className="label-form" style={{ marginTop: "25px" }}>
                Per Order Quantity:
              </label>
              <div className="time-selector-wrapper-CreateListing">
                <div className="time-outer-wrapper-TimeSelector">
                  <InputLabeled
                    name="minimumQuantityInput"
                    value={minimumQuantityInput}
                    label="Minimum Quantity"
                    handleChange={(evt) =>
                      handleChange(evt, "minimumQuantityInput")
                    }
                  />
                </div>

                <div className="time-outer-wrapper-TimeSelector">
                  <InputLabeled
                    name="maximumQuantityInput"
                    value={maximumQuantityInput}
                    label="Maximum Quantity"
                    handleChange={(evt) =>
                      handleChange(evt, "maximumQuantityInput")
                    }
                  />
                </div>
              </div>

              <div className="carousel-business-button-container">
                <Button
                  size={isMobile ? "small" : "medium"}
                  text="Back"
                  handleClick={() => slideTo(currentIndex - 1)}
                />
                <Button
                  size={isMobile ? "small" : "medium"}
                  text="Submit"
                  handleClick={() => {
                    const hasRequiredInfo = validateSalesCarousel(
                      createSalesData(),
                      3
                    );
                    if (hasRequiredInfo) handleSubmit();
                  }}
                />
              </div>
            </div>
          </li>
        </ul>
      </div>

      <Modal
        directModalMessage={!!modalMessage}
        modalAccept="got it"
        handleAcceptClick={() => setModalMessage("")}
      >
        <div className="guildelines-wrapper">
          <p className="guildelines-sub-title">
            {salesInvalidTypeMessage(modalMessage)}
          </p>
        </div>
      </Modal>
    </>
  );
};
