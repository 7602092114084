import React, { useState, useEffect, useLayoutEffect } from "react";
import { connect } from "react-redux";
import * as dataActions from "../../../redux/actions/data-actions";
import * as userActions from "../../../redux/actions/user-actions";
import * as stateActions from "../../../redux/actions/state-actions";

// Components
import DataLoaderHoc from "../../components/data-loader-hoc";
import Button from "../../components/common/app-style-button";
import EventCard from "./event-card";
import BusinessCard from "./business-card";
import SkeletonCard from "../../components/data-loader-hoc/skeleton-card";
import Modal from "../../components/modal";
import DidntFind from "./didnt-find";

// Utilities
import { isMobileAgent, isBottomSpacing } from "../../../utilites";
import { toTitleCase } from "../../../utilites/format";

// Assets
import Covid from "../../../assets/svg/covid";

// Styles
import "./results.css";

const Results = ({
  location,
  geoLocation,
  isLiveSearch,
  filters,
  results,
  isFetching,
  isMoreResults,
  path,
  history,
  pagePosition,
  setPagePosition,
  setSearchInput,
  setLocationInput,
  autoComplete,
  setAutoComplete,
  setScrollY,
  debouncedScroll,
}) => {
  const [scrollButton, setScrollButton] = useState(false);
  const [prevScrollY, setPrevScrollY] = useState(0);
  const [modalMessage, setModalMessage] = useState("");
  const { type, date } = filters;
  const pathName = path.split("/").pop();
  const formattedPathName = toTitleCase({ input: pathName });
  const isTop = pathName === "top";
  const isBusinessSearch = type === "Business";
  const isMobile = isMobileAgent();
  const isUsersGeoLocation =
    geoLocation &&
    geoLocation.coordinates &&
    (!location || (location && !location.coordinates));

  const renderSkeletonUi = () => {
    const skeletonEl = [];
    for (let i = 0; i < 5; i++) {
      skeletonEl.push(<SkeletonCard key={i} />);
    }
    return skeletonEl;
  };

  const handleScroll = (evt) => {
    const scrollPos = evt.target.scrollTop;
    setScrollY(scrollPos);
  };

  const handleScrollClick = () => {
    const element = document.getElementById("results-wrapper");
    if (element) element.scrollTop = 0;
  };

  const showheadersAndFooter = () => {
    const styledSearchBar = document.getElementById("mobile-header-search");
    const styledFilters = document.getElementById("mobile-header-filters");
    const footerNav = document.getElementById("footer-nav");
    if (styledSearchBar) {
      styledSearchBar.style.transform = "translate(0,0)";
      styledSearchBar.style.transitionDelay = "0ms";
    }
    if (styledFilters) {
      styledFilters.style.transform = "translate(0,0)";
      styledFilters.style.transitionDelay = "80ms";
    }
    if (footerNav) {
      footerNav.style.transform = "translate(0,0)";
      footerNav.style.transitionDelay = "80ms";
    }
  };

  useLayoutEffect(() => {
    if (isMobile) {
      const el = document.getElementById("results-inner-wrapper");
      const styledSearchBar = document.getElementById("mobile-header-search");
      const styledFilters = document.getElementById("mobile-header-filters");
      const footerNav = document.getElementById("footer-nav");
      const elHeight = el ? el.offsetHeight : 0;
      const scrollAtTop = debouncedScroll < 275;
      const isScrollingUp = debouncedScroll < prevScrollY;
      const offSet = isBottomSpacing();
      const scrollAtBottom =
        debouncedScroll > elHeight - (window.innerHeight + offSet);
      setScrollButton(
        (!isScrollingUp && debouncedScroll > 275) || scrollAtBottom
      );
      if (scrollAtTop || (isScrollingUp && !scrollAtBottom)) {
        styledSearchBar.style.transform = "translate(0,0)";
        styledSearchBar.style.transitionDelay = "0ms";
        styledFilters.style.transform = "translate(0,0)";
        styledFilters.style.transitionDelay = "80ms";
        footerNav.style.transform = "translate(0,0)";
        footerNav.style.transitionDelay = "80ms";
      } else {
        styledSearchBar.style.transform = "translate(0,-40px)";
        styledSearchBar.style.transitionDelay = "80ms";
        styledFilters.style.transform = "translate(0,-69px)";
        styledFilters.style.transitionDelay = "0ms";
        footerNav.style.transform = "translate(0,75px)";
        footerNav.style.transitionDelay = "80ms";
      }
      setPrevScrollY(debouncedScroll);
    }
    if (autoComplete.length) {
      setSearchInput("");
      setLocationInput("");
      setAutoComplete([]);
    }
  }, [debouncedScroll]);

  useEffect(() => {
    const resultsWrapper = document.getElementById("results-wrapper");
    if (resultsWrapper) resultsWrapper.scrollTop = pagePosition;
    showheadersAndFooter();
    return () => {
      showheadersAndFooter();
    };
  }, []);

  return (
    <>
      {results.length ? (
        <div
          id="results-wrapper"
          className={`no-scroll-bars ${
            isMobile ? "results-wrapper-mobile" : "results-wrapper-web"
          }`}
          onScroll={handleScroll}
        >
          {isMobile && scrollButton && (
            <div className="longFadeIn results-scroll-wrapper">
              <Button
                size="medium"
                text="Go To Top"
                handleClick={handleScrollClick}
              />
            </div>
          )}

          <div
            id="results-inner-wrapper"
            style={{
              paddingTop: `${isMobile ? 108 : 40}px`,
              paddingBottom: `${isBottomSpacing(results.length)}px`,
            }}
          >
            {results.map((result, idx) =>
              isBusinessSearch ? (
                <BusinessCard
                  isUsersGeoLocation={isUsersGeoLocation}
                  type={type}
                  filterDate={date}
                  result={result}
                  setPagePosition={setPagePosition}
                  setModalMessage={setModalMessage}
                  idx={idx}
                  key={idx}
                />
              ) : (
                <EventCard
                  isUsersGeoLocation={isUsersGeoLocation}
                  pathName={formattedPathName}
                  type={type}
                  filterDate={date}
                  result={result}
                  setPagePosition={setPagePosition}
                  idx={idx}
                  key={idx}
                />
              )
            )}

            {isFetching && renderSkeletonUi()}
            {(isTop || (isLiveSearch && !isMoreResults)) && <DidntFind />}
          </div>
        </div>
      ) : (
        <div className="no-results-wrapper">
          {isLiveSearch ? (
            <DidntFind />
          ) : (
            <>
              <h2>No Results</h2>
              {isTop ? (
                <p>No results, try different search parameters.</p>
              ) : (
                <>
                  <p>No results, try expanding your search parameters.</p>
                  <p>
                    Or click{" "}
                    <span
                      className="no-results-option-text"
                      onClick={() => history.push("/top")}
                    >
                      here
                    </span>{" "}
                    and let us see what we can find for you!
                  </p>
                </>
              )}
            </>
          )}
        </div>
      )}
      <Modal
        directModalMessage={!!modalMessage}
        modalAccept="Ok"
        handleAcceptClick={() => setModalMessage("")}
      >
        <div className="modal-covid-icon">
          <Covid />
        </div>
        <p>{modalMessage}</p>
        <a
          className="results-covid-modal-link"
          href="https://files.covid19.ca.gov/pdf/guidance-dine-in-restaurants.pdf"
          target="blank"
        >
          CDC Guildlines
        </a>
        <p className="results-covid-modal-legal">
          EventHound is not responsible for claims made by the businesses.
        </p>
      </Modal>
    </>
  );
};

const mapStateToProps = (store) => ({
  location: store.user.location,
  geoLocation: store.user.geoLocation,
  isLiveSearch: store.data.isLiveSearch,
  filters: store.data.filters,
  results: store.data.results,
  pagePosition: store.state.pagePosition,
  autoComplete: store.data.autoComplete,
});

const mapDispatchToProps = (dispatch) => ({
  setPagePosition: (value) => dispatch(stateActions.setPagePosition(value)),
  setSearchInput: (input) =>
    dispatch(dataActions.setFilter({ searchInput: input })),
  setLocationInput: (input) => dispatch(userActions.setLocationInput(input)),
  setAutoComplete: (autoCompleteList) =>
    dispatch(dataActions.setAutoComplete(autoCompleteList)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataLoaderHoc(Results));
