export const setResults = (resultsObj) => ({
  type: "setResults",
  payload: resultsObj,
});

export const addResults = (resultsObj) => ({
  type: "addResults",
  payload: resultsObj,
});

export const setSkipIndex = (idx) => ({
  type: "setSkipIndex",
  payload: idx,
});

export const setSearchMinDistance = (dist) => ({
  type: "setSearchMinDistance",
  payload: dist,
});

export const setGrowthIndex = (idx) => ({
  type: "setGrowthIndex",
  payload: idx,
});

export const setStubHubIndex = (idx) => ({
  type: "setStubHubIndex",
  payload: idx,
});

export const setTicketMasterIndex = (idx) => ({
  type: "setTicketMasterIndex",
  payload: idx,
});

export const setAutoComplete = (autoCompleteList) => ({
  type: "setAutoComplete",
  payload: autoCompleteList,
});

export const setIsLiveSearch = (value) => ({
  type: "setIsLiveSearch",
  payload: value,
});

export const setFilter = (filterObj) => ({
  type: "setFilter",
  payload: filterObj,
});

export const setSearchBarInput = (input) => ({
  type: "setSearchBarInput",
  payload: input,
});

export const resetFilters = () => ({
  type: "resetFilters",
});
