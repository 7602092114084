import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";

const ProtectedBusinessRoute = ({
  isUser,
  user,
  component: Component,
  setMessage,
  ...rest
}) => {
  const isNotBusiness = !user || isUser;
  const { account } = user || {};
  const { accountType } = account || {};
  const isFree = !accountType || accountType.toLowerCase() === "free";
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isNotBusiness) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        } else if (isFree) {
          setMessage({ messageType: "error", message: "Upgrade Account" });
          return (
            <Redirect
              to={{
                pathname: "/business/profile",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

const mapStateToProps = (store) => ({
  isUser: store.user.isUser,
  user: store.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  setMessage: (messageObj) => dispatch(stateActions.setMessage(messageObj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProtectedBusinessRoute);
