import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 32 32"
    >
      <path d="M29.23,20.51l-6.83-3.04C23.85,15.09,25,12.43,25,10c0-4.96-4.04-9-9-9c-4.96,0-9,4.04-9,9c0,2.42,1.16,5.08,2.61,7.47   l-6.84,3.04C1.68,20.99,1,21.95,1,23s0.68,2.01,1.77,2.49l11.66,5.18C14.92,30.89,15.46,31,16,31c0.54,0,1.08-0.11,1.57-0.33   l11.66-5.18C30.32,25.01,31,24.05,31,23S30.32,20.99,29.23,20.51z M16,6c2.21,0,4,1.79,4,4c0,2.21-1.79,4-4,4c-2.21,0-4-1.79-4-4   C12,7.79,13.79,6,16,6z M28.42,23.67l-11.67,5.18c-0.45,0.2-1.05,0.2-1.5,0L3.58,23.67C3.22,23.5,3,23.25,3,23s0.22-0.5,0.58-0.67   l7.13-3.17c2.12,3.07,4.4,5.37,4.58,5.55C15.49,24.9,15.74,25,16,25c0.25,0,0.51-0.1,0.71-0.29c0.17-0.18,2.46-2.47,4.59-5.54   l7.12,3.16C28.78,22.5,29,22.75,29,23S28.78,23.5,28.42,23.67z"></path>
    </svg>
  );
};
