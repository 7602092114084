import React from "react";

// Utilities
import { isMobileAgent } from "../../../../utilites";

// Styles
import "./skeleton-card.css";

export default () => {
  const isMobile = isMobileAgent();
  return (
    <div
      className="skeleton-card-container"
      style={{ margin: isMobile ? "10px 5px 0 5px" : "5px" }}
    />
  );
};
