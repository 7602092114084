import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
    >
      <g transform="translate(0,-952.36218)">
        <path
          d="M49.2,962.8c7.5,7.5,9.9,18.3,7,27.8l41.1,41v17H75.7v-13.2H63.3l0.1-12.4H50.9l-13.6-13.6c-9.5,2.8-20.3,0.5-27.8-7
		c-10.9-10.9-10.9-28.7,0-39.6C20.5,951.8,38.2,951.8,49.2,962.8L49.2,962.8z M32,971.2c-3.9-3.9-10.1-3.9-14,0
		c-3.9,3.9-3.9,10.1,0,14c3.9,3.9,10.1,3.9,14,0C35.8,981.4,35.8,975.1,32,971.2z"
        />
      </g>
    </svg>
  );
};
