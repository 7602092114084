import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as stateActions from "../../../../redux/actions/state-actions";
import * as userActions from "../../../../redux/actions/user-actions";

// Components
import InputLabeled from "../../../components/input-labeled";
import WeekdaysTimeInput from "../../../components/WeekdaysTimeInput";
import Features from "../../../components/features-input";
import ImageUpload from "../../../components/image-upload";

// utilies
import { features as featuresModel } from "../../../../constants/data-models";
import { isSafariAgent, isMobileAgent } from "../../../../utilites";
import { convertDateToDbDate } from "../../../../utilites/date";
import getFeaturesIcon from "../../../components/features-input/getFeatureIcon";
import { validateNewLocation } from "../../../../utilites/validate";

// Assets
import Button from "../../../components/common/button";

// Utilities
import { createCorporate } from "../../../../api/corporate";
import { storeImage, deleteImage } from "../../../../api/image";
import { formatAfter12Hours } from "../../../../utilites/format";

// Styles
import "../corporate-business-styles.css";

export default () => {
  const carousel = useRef();
  const history = useHistory();
  const {
    _id,
    hours: mainHours,
    features: userFeatures,
    website: userWebsite,
    deliveryLink: userDeliveryLink,
    images: userImages = [],
  } = useSelector((store) => store.user.user);
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [phoneInput, setPhoneInput] = useState("");
  const [showPhone, setShowPhone] = useState(true);
  const [addressInput, setAddressInput] = useState("");
  const [cityInput, setCityInput] = useState("");
  const [stateInput, setStateInput] = useState("");
  const [zipInput, setZipInput] = useState("");
  const [hours, setHours] = useState(mainHours);
  const [featuresInput, setFeaturesInput] = useState(userFeatures || {});
  const [websiteInput, setWebsiteInput] = useState(userWebsite);
  const [deliveryLinkInput, setDeliveryLinkInput] = useState(userDeliveryLink);
  const [locationImageInput, setLocationImageInput] = useState(userImages[0]);
  const userImage = userImages[0] || "";
  const isMobile = isMobileAgent();
  const isSafairMobile = isSafariAgent() && isMobile;
  const height = `calc(100vh - ${isSafairMobile ? 150 : 40}px`;

  const slideTo = (index) => {
    if (index > -1 && index < 4) {
      const carouselElememt = carousel ? carousel.current : null;
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      const position = width * index;
      if (carouselElememt)
        carouselElememt.style.transform = `translate(-${position}px, 0)`;
      setCurrentIndex(index);
    }
  };

  const createLocationData = () => ({
    mainBusinessId: _id,
    phone: phoneInput,
    showPhone,
    address: addressInput,
    city: cityInput,
    state: stateInput,
    zip: zipInput,
    hours,
    features: featuresInput,
    website: websiteInput,
    deliveryLink: deliveryLinkInput,
    images: locationImageInput ? [locationImageInput] : [],
    lastUpdated: convertDateToDbDate(),
    createdDate: convertDateToDbDate(),
  });

  const handleChange = (evt, type, newValue) => {
    const { value } = evt.target;
    switch (type) {
      case "phoneInput":
        const newPhoneValue = value
          .replace("-", "")
          .replace(".", "")
          .match(/^[0-9]*$/gi);
        if (newPhoneValue === null) return setPhoneInput(phoneInput);
        return setPhoneInput(newPhoneValue[0].slice(0, 10));
      case "websiteInput":
        return setWebsiteInput(value);
      case "deliveryLinkInput":
        return setDeliveryLinkInput(value);
      case "addressInput":
        return setAddressInput(value);
      case "cityInput":
        return setCityInput(value);
      case "stateInput":
        let newStateInput = "";
        if (value) newStateInput = value.match(/\w/gi).join("").slice(0, 2);
        return setStateInput(newStateInput);
      case "zipInput":
        let newZipInput = "";
        if (value) newZipInput = value.match(/\d/gi).join("").slice(0, 5);
        return setZipInput(newZipInput);
      case "imageInput":
        return setLocationImageInput(newValue);
    }
  };

  const handleShowToggle = (evt, newValue, params) => {
    evt.preventDefault();
    switch (params) {
      case "phoneInput":
        return setShowPhone(newValue);
    }
  };

  const handleFeatureClick = (feature) => {
    const value = featuresInput[feature] || false;
    if (!value) return setFeaturesInput({ ...featuresInput, [feature]: true });
    const newFeaturesInput = { ...featuresInput };
    delete newFeaturesInput[feature];
    setFeaturesInput(newFeaturesInput);
  };

  const handleSubmit = async () => {
    const locationData = createLocationData();
    dispatch(stateActions.setLoader(true));

    if (locationImageInput !== userImage) {
      const newImage = await storeImage(locationImageInput, "business");
      locationData.images = [newImage];
    }

    const { locations } =
      (await createCorporate({
        ...locationData,
        hours: formatAfter12Hours(locationData.hours),
      })) || {};
    if (locations) {
      setTimeout(() => {
        dispatch(userActions.updateUser({ locations }));
        history.push("/business/corporate");
        dispatch(stateActions.setLoader(false));
      }, 1500);
    } else {
      if (locationImageInput) await deleteImage(locationData.images[0]);
      dispatch(stateActions.setLoader(false));
    }
  };

  const addressInputs = [
    {
      name: "phoneInput",
      value: phoneInput,
      label: "This Locations Phone",
      toggle: true,
      toggleValue: showPhone,
    },
    {
      name: "addressInput",
      value: addressInput,
      label: "Address",
    },
    {
      name: "cityInput",
      value: cityInput,
      label: "City",
    },
    {
      name: "stateInput",
      value: stateInput,
      label: "State",
    },
    {
      name: "zipInput",
      value: zipInput,
      label: "Zip",
      type: "number",
    },
  ];

  const contactInputs = [
    {
      name: "websiteInput",
      value: websiteInput,
      label: "Website",
    },
    {
      name: "deliveryLinkInput",
      value: deliveryLinkInput,
      label: "Delivery Link",
    },
  ];

  return (
    <div className="full-page-carousel-wrapper" style={{ height }}>
      <img
        className="carousel-page-background-image"
        alt="EventHound Concert"
        src={"/img/food.jpg"}
      />

      <ul
        ref={carousel}
        className="carousel-container"
        style={{ width: "400%" }}
      >
        <li className="carousel-item" style={{ height }}>
          <div className="carousel-business-space-container" />
          <div className="carousel-business-item-content-container">
            <div className="business-signup-header-container">
              <div className="business-signup-icon">
                {getFeaturesIcon("logo")}
              </div>
              <h2 className="card-title-SignUp">NEW LOCATION</h2>
            </div>

            {addressInputs.map((input, idx) => {
              const { name, value, label, type, toggle, toggleValue } = input;
              return (
                <InputLabeled
                  name={name}
                  value={value}
                  label={label}
                  type={type}
                  handleChange={(evt) => handleChange(evt, name)}
                  toggle={toggle}
                  toggleValue={toggleValue}
                  handleToggle={handleShowToggle}
                  key={idx}
                />
              );
            })}

            <div className="carousel-business-button-container">
              <Button
                size={isMobile ? "small" : "medium"}
                text="Cancel"
                handleClick={() => history.goBack()}
              />
              <Button
                size={isMobile ? "small" : "medium"}
                text="Next"
                handleClick={() => {
                  const hasRequiredInfo = validateNewLocation(
                    createLocationData(),
                    1
                  );
                  if (hasRequiredInfo) slideTo(currentIndex + 1);
                }}
              />
            </div>
          </div>
        </li>

        <li className="carousel-item" style={{ height }}>
          <div className="carousel-business-space-container" />
          <div className="carousel-business-item-content-container">
            <div className="business-signup-header-container">
              <div className="business-signup-icon">
                {getFeaturesIcon("details")}
              </div>
              <h2 className="card-title-SignUp">DETAILS</h2>
            </div>

            {contactInputs.map((input, idx) => {
              const { name, value, label, type, toggle, toggleValue } = input;
              return (
                <InputLabeled
                  name={name}
                  value={value}
                  label={label}
                  type={type}
                  handleChange={(evt) => handleChange(evt, name)}
                  toggle={toggle}
                  toggleValue={toggleValue}
                  handleToggle={handleShowToggle}
                  key={idx}
                />
              );
            })}

            <label id="locationImageInput" className="label-form">
              Image:
            </label>
            <div className="time-selector-wrapper-CreateListing">
              <ImageUpload
                imageInput={locationImageInput}
                handleChange={handleChange}
              />
            </div>

            <div className="carousel-business-button-container">
              <Button
                size={isMobile ? "small" : "medium"}
                text="Back"
                handleClick={() => slideTo(currentIndex - 1)}
              />
              <Button
                size={isMobile ? "small" : "medium"}
                text="Next"
                handleClick={() => {
                  const hasRequiredInfo = validateNewLocation(
                    createLocationData(),
                    2
                  );
                  if (hasRequiredInfo) slideTo(currentIndex + 1);
                }}
              />
            </div>
          </div>
        </li>

        <li className="carousel-item" style={{ height }}>
          <div className="carousel-business-space-container" />
          <div className="carousel-business-item-content-container">
            <div className="business-signup-header-container">
              <div className="business-signup-icon">
                {getFeaturesIcon("clock")}
              </div>
              <h2 className="card-title-SignUp">TIME</h2>
            </div>

            <WeekdaysTimeInput
              page="create-corporate"
              title="Hours Of Operation:"
              hours={hours}
              setHours={setHours}
              isCard={false}
            />

            <div className="carousel-business-button-container">
              <Button
                size={isMobile ? "small" : "medium"}
                text="Back"
                handleClick={() => slideTo(currentIndex - 1)}
              />
              <Button
                size={isMobile ? "small" : "medium"}
                text="Next"
                handleClick={() => {
                  const hasRequiredInfo = validateNewLocation(
                    createLocationData(),
                    3
                  );
                  if (hasRequiredInfo) slideTo(currentIndex + 1);
                }}
              />
            </div>
          </div>
        </li>

        <li className="carousel-item" style={{ height }}>
          <div className="carousel-business-space-container" />
          <div className="carousel-business-item-content-container">
            <div className="business-signup-header-container">
              <div className="business-signup-icon">
                {getFeaturesIcon("features")}
              </div>
              <h2 className="card-title-SignUp">FEATURES</h2>
            </div>

            <Features
              options={featuresModel}
              values={featuresInput}
              handleClick={handleFeatureClick}
              handleChange={handleChange}
              isCard={false}
              isTitle={false}
            />

            <div className="carousel-business-button-container">
              <Button
                size={isMobile ? "small" : "medium"}
                text="Back"
                handleClick={() => slideTo(currentIndex - 1)}
              />
              <Button
                size={isMobile ? "small" : "medium"}
                text="Submit"
                handleClick={() => handleSubmit()}
              />
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};
