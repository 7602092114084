import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as stateActions from "../../../../redux/actions/state-actions";
import * as userActions from "../../../../redux/actions/user-actions";
import * as dataActions from "../../../../redux/actions/data-actions";

// Components
import DetailViewLayout from "../../../components/detail-view-scroll-layout";
import ProfileImageConatiner from "./profile-image-conatiner";
import WelcomeBanner from "../business/welcome-banner";
import SavedAds from "./saved-ads";
import Toggle from "../../../components/common/toggle";
import Promo from "./promo";

// Utilities
import UseGetTheme from "../../../../utilites/hooks/useGetTheme";
import { userPromoCodes } from "../../../../constants/feature-flags";
import { subCatOptions } from "../../../../constants/data-models";
import Debounce from "../../../../utilites/hooks/debounce";
import { toTitleCase } from "../../../../utilites/format";
import getIcon from "../../../components/features-input/getFeatureIcon";
import { updateUser } from "../../../../api/user";

// Styles
import "../profile.css";
import "./user.css";
import "./category-drop-down/category-drop-down.css";

const UserProfile = ({
  user,
  history,
  savedPage,
  updateReduxUser,
  setLoader,
  setFilter,
  resetFilters,
}) => {
  const { isNightTime } = UseGetTheme();
  const { fName, email, image, darkTheme: userThemeSettings, promoId } = user;
  const [mounted, setMounted] = useState(false);
  const [darkTheme, setDarkTheme] = useState(userThemeSettings);
  const debouncedDarkThemeValue = Debounce(darkTheme, 500);

  useEffect(() => {
    if (savedPage) {
      history.push(savedPage);
    }
  }, []);

  useEffect(() => {
    if (mounted) {
      const updatedUser = { ...user, darkTheme: debouncedDarkThemeValue };
      updateUser(updatedUser);
      updateReduxUser(updatedUser);
    }
    setMounted(true);
  }, [debouncedDarkThemeValue]);

  const handleToggle = (evt, value, params) => {
    switch (params) {
      case "theme":
        return setDarkTheme(value);
    }
  };

  const handleClick = (category) => {
    setFilter({ type: "" });
    resetFilters();
    history.push(`/${category.toLowerCase()}`);
  };

  return (
    <DetailViewLayout images={[image]} showImage={false}>
      <ProfileImageConatiner image={image}>
        <WelcomeBanner name={fName} />

        {!isNightTime && (
          <div className="profile-text-container profile-text-container-split">
            <div className="profile-ad-info-container">
              <p className="">Dark Theme</p>
            </div>
            <div className="profile-ad-manage-container">
              <Toggle
                value={darkTheme}
                handleDebouceToggle={handleToggle}
                params="theme"
              />
            </div>
          </div>
        )}

        <div className="business-title-container" style={{ marginTop: "10px" }}>
          <div className="business-line-break" />
          <p className="business-title-text" style={{ fontSize: "1.2rem" }}>
            Find Your Fun
          </p>
          <div className="business-line-break" />
        </div>

        <div className="user-search-wrapper-outter">
          <div className="user-search-wrapper">
            <div
              className="user-search-popular-container"
              onClick={() => handleClick("top")}
            >
              <div className="user-search-icon">{getIcon("Popular")}</div>
              <p className="user-search-text" style={{ marginLeft: "8px" }}>
                Popular
              </p>
            </div>
            <div className="user-search-wrapper-inner">
              {Object.keys(subCatOptions).map((cat, idx) => (
                <div
                  className="user-search-container"
                  onClick={() => handleClick(cat)}
                  key={idx}
                >
                  <div className="user-search-icon">{getIcon(cat)}</div>
                  <p className="user-search-text" style={{ marginTop: "3px" }}>
                    {toTitleCase({ input: cat })}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </ProfileImageConatiner>

      <SavedAds user={user} />

      {userPromoCodes && (
        <Promo
          promoId={promoId}
          email={email}
          setLoader={setLoader}
          updateUser={updateUser}
        />
      )}

      {/* 
      <div className="listing-content-container">
        <div className="profile-text-container profile-text-container-split">
          <div className="profile-ad-info-container">
            <p className="">Notifications</p>
          </div>
          <div className="profile-ad-manage-container">
            <Toggle
              value={notifications}
              handleDebouceToggle={(evt, value) => setNotifications(value)}
              params="theme"
            />
          </div>
        </div>
        {notifications &&
          Object.keys(subCatOptions).map((cat, idx) => (
            <CatergoryDropDown cat={cat} key={idx} />
          ))}
      </div> */}
    </DetailViewLayout>
  );
};

const mapStateToProps = (store) => ({
  savedPage: store.state.savedPage,
  user: store.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  updateReduxUser: (userObj) => dispatch(userActions.updateUser(userObj)),
  setLoader: (loaderState) => dispatch(stateActions.setLoader(loaderState)),
  setFilter: (filters) => dispatch(dataActions.setFilter(filters)),
  resetFilters: () => dispatch(dataActions.resetFilters()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserProfile));
