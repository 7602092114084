import React, { useEffect } from "react";

// Components
import InputLabeled from "../../components/input-labeled/index.js";

// Utilities
import { features as featuresModel } from "../../../constants/data-models";
import getFeaturesIcon from "./getFeatureIcon";

// Styles
import "./features.css";

export default ({
  options = featuresModel,
  values,
  handleClick,
  linkValue,
  handleChange,
  isCard = true,
  isTitle = true,
  isDeliveryOption = false,
  isVirtualOption = false,
  isWhiteText = false,
}) => {
  const isDelivery = values["Delivery"];
  const isVirtual = values["Virtual"];

  useEffect(() => {
    if (linkValue && !isDelivery)
      handleChange({ target: { value: "" } }, "DeliveryInput");
    if (linkValue && !isVirtual)
      handleChange({ target: { value: "" } }, "VirtualInput");
  }, [isDelivery, isVirtual]);

  const renderFeatures = () =>
    options.map((feature, idx) => {
      const isSelected = feature in values && values[feature];
      return (
        <div
          className={`business-feature-container ${
            isWhiteText
              ? "business-feature-container-white"
              : "business-feature-container-theme"
          } ${isSelected ? "business-feature-selected" : ""}`}
          onClick={() => handleClick(feature)}
          key={idx}
        >
          <div
            className={`business-feature-svg ${
              isSelected ? "business-feature-svg-selected" : ""
            }`}
          >
            {getFeaturesIcon(feature)}
          </div>
          <p className="business-feature-text">{feature}</p>
        </div>
      );
    });

  return isCard ? (
    <div className="card card-form">
      <div className="card-info-form">
        {isTitle && (
          <label id="features" className="label-form">
            Features:
          </label>
        )}
        <div className="no-scroll-bars card-features-container">
          {renderFeatures()}
        </div>

        {isDeliveryOption && isDelivery && (
          <InputLabeled
            name="deliveryLink"
            label="Order Delivery Link"
            value={linkValue}
            handleChange={(evt) => handleChange(evt, "DeliveryInput")}
            isWhiteText={isWhiteText}
          />
        )}

        {isVirtualOption && isVirtual && (
          <InputLabeled
            name="virtualLink"
            label="Virtual Web Link"
            value={linkValue}
            handleChange={(evt) => handleChange(evt, "VirtualInput")}
            isWhiteText={isWhiteText}
          />
        )}
      </div>
    </div>
  ) : (
    <>
      {isTitle && (
        <label id="features" className="label-form">
          Features:
        </label>
      )}
      <div className="no-scroll-bars card-features-container">
        {renderFeatures()}
      </div>

      {isDeliveryOption && isDelivery && (
        <InputLabeled
          name="deliveryLink"
          label="Order Delivery Link"
          value={linkValue}
          handleChange={(evt) => handleChange(evt, "DeliveryInput")}
          isWhiteText={isWhiteText}
        />
      )}

      {isVirtualOption && isVirtual && (
        <InputLabeled
          name="virtualLink"
          label="Virtual Web Link"
          value={linkValue}
          handleChange={(evt) => handleChange(evt, "VirtualInput")}
          isWhiteText={isWhiteText}
        />
      )}
    </>
  );
};
