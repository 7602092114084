import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

// Components
import ProfileImage from "./edit-profile-image";

// Assets
import Skyline from "../../../assets/svg/skyline";

export default ({
  input,
  dbImages,
  data,
  isBusiness,
  HeaderButtons,
  children,
  handleCommonData,
}) => {
  const [images, setImages] = useState(dbImages);
  const { categories } = data || {};
  const { cat1Img } = categories || {};
  const backgroundImage = isBusiness
    ? `url(${images[0]})`
    : cat1Img
    ? typeof cat1Img === "string"
      ? `url(${cat1Img})`
      : `url(${URL.createObjectURL(cat1Img)})`
    : "";

  useEffect(() => {
    setImages(dbImages);
  }, [dbImages]);

  useEffect(() => handleCommonData({ images }), [images]);

  const onDragEnd = (result) => {
    const { draggableId, source, destination } = result;
    const { index: sourceIndex } = source || {};
    const { index: destinationIndex } = destination || {};
    const itemIsInSamePosition = sourceIndex === destinationIndex;
    const imageName = images[Number(draggableId)];

    if (!destination || itemIsInSamePosition) return;
    const filteredImages = images.filter((image) => image !== imageName);
    filteredImages.splice(destinationIndex, 0, imageName);
    setImages(filteredImages);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div id="edit-listing" className="card-wrapper">
        <div
          className="card profile-top-selection-wrapper"
          style={{ backgroundImage }}
        >
          {!backgroundImage && (
            <div className="profile-skyline-container">
              <Skyline />
            </div>
          )}
          <div className="profile-gradient-overlay" />
          <div className="profile-top-selection-container">
            <div className="profile-images-wrapper-position profile-images-wrapper">
              <Droppable droppableId="listing-images" direction="horizontal">
                {(provided) => (
                  <div
                    className="profile-images-wrapper"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {images.map((image, idx) => (
                      <ProfileImage
                        isBusiness={isBusiness}
                        input={input}
                        image={image}
                        index={idx}
                        key={idx}
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              {images.length < (isBusiness ? 3 : 2) && (
                <ProfileImage isBusiness={isBusiness} input={input} />
              )}
            </div>
            {HeaderButtons && HeaderButtons()}
          </div>
        </div>
        {children}
      </div>
    </DragDropContext>
  );
};
