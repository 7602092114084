import React from "react";
import { CSSTransition } from "react-transition-group";

// Components
import Button from "../common/button";

// Assets
import EventHoundName from "../../../assets/svg/eventHound-name";

// Styles
import "./modal.css";

export default ({
  title,
  directModalMessage,
  modalAccept,
  modalCancel,
  handleCancelClick,
  handleAcceptClick = true,
  buttonSize = "small",
  children,
  zIndex = 991,
  styles = {},
}) => (
  <>
    <CSSTransition in={!!directModalMessage} timeout={250} classNames="fade">
      <div
        className="modal-page-click-overlay"
        style={{ zIndex: zIndex - 1 }}
      />
    </CSSTransition>
    <CSSTransition
      in={!!directModalMessage}
      timeout={250}
      classNames="top-slide"
    >
      <div className="modal-wrapper" style={{ zIndex, ...styles }}>
        {title ? (
          <p className="modal-logo-title">{title}</p>
        ) : (
          <div className="modal-logo-wrapper">
            <EventHoundName />
          </div>
        )}
        {directModalMessage && typeof directModalMessage === "string" && (
          <h4 className="modal-text">{directModalMessage}</h4>
        )}
        {children}
        <div className="modal-button-wrapper">
          {handleCancelClick && (
            <Button
              text={modalCancel ? modalCancel : "Cancel"}
              size={buttonSize}
              handleClick={handleCancelClick}
            />
          )}
          {handleAcceptClick && (
            <Button
              text={modalAccept ? modalAccept : "Got It"}
              size={buttonSize}
              handleClick={handleAcceptClick}
            />
          )}
        </div>
      </div>
    </CSSTransition>
  </>
);
