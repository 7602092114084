import React from "react";

// Components
import PasswordInput from "../common/password-input";
import Toggle from "../common/toggle";

// Styles
import "./input-labeled.css";

export default ({
  name,
  value,
  label,
  isWhiteText = false,
  type = "text",
  handleChange,
  toggle = false,
  toggleValue,
  toggleText = "Show to Users",
  handleToggle,
  style = {},
}) => {
  const isPassword = type.toLowerCase() === "password";

  return !toggle ? (
    <>
      <label
        id={name}
        className={`label-form ${
          isWhiteText ? "color-white" : "color-default"
        }`}
        style={style}
      >{`${label}:`}</label>
      {isPassword ? (
        <PasswordInput value={value} handleChange={handleChange} />
      ) : (
        <input
          className="input-style input-form text-form"
          type={type}
          value={value}
          onChange={handleChange}
          spellCheck="true"
        />
      )}
    </>
  ) : (
    <>
      <label
        id={name}
        className={`label-form ${
          isWhiteText ? "color-white" : "color-default"
        }`}
        style={style}
      >
        {`${label}:`}
        <div className="show-form-toggle">
          <p
            className={`show-form-toggle-text ${
              isWhiteText ? "color-white" : "color-default"
            }`}
          >
            {toggleText}
          </p>
          <Toggle
            value={toggleValue}
            handleToggle={handleToggle}
            params={name}
          />
        </div>
      </label>
      <input
        className="input-style input-form text-form"
        type={type}
        value={value}
        onChange={handleChange}
        spellCheck="true"
      />
    </>
  );
};
